import {
  gql
} from '@apollo/client'

export const LABOR_SUMMARY = gql`
  query GetLaborSummary($staffId: String, $workDateFrom: String, $workDateTo: String) {
    personalLaborSummary(filters: {staffId: $staffId, workDateGte: $workDateFrom, workDateLte: $workDateTo}) {
      totalHours
      totalDays
      progressOvertime 
      progressOvertimeMidnight
    }
  }
`
export const PAID_HOLIDAYS = gql`
  query GetPaidHolidays(
    $workDateFrom: String, $workDateTo: String,
    $warehouseId: String, $floorId: String, $zoneId: String
    ) {
    paidHolidays (
      workDateFrom: $workDateFrom, workDateTo: $workDateTo,
      warehouseId: $warehouseId, floorId: $floorId, zoneId: $zoneId 
      ) {
      staffId
      staffName
      details {
        targetMonth
        targetMonthDetail {
          count
          days
        }
      }
    }
  }
`
// const query = gql`
//   query User($okta: string) {
//     User(okta: $okta){
//       id
//     }
//   }
// `;

// client.query({
//   query: query,
//   variables: {
//     okta: 'some string'
//   }
// })

// export const LABOR_SUMMARY = gql`
//   query GetHealthCheck {
//     allStaffs (first:1) {
//       edges {
//         node {
//           staffName
//         }
//       }
//     }
//   }
// `
