import React, { useRef } from 'react'
import styled from 'styled-components'

const ModalWrap = styled.div`
  background-color: rgb(51, 51, 51, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: ${props => props.open ? 'flex' : 'none'};
  justify-content:center;
  align-items:center;
`

const ModalMain = styled.div`
  width: ${props => props.width || '650px'};
  height: ${props => props.height || '350px'};
  background: #000;
  border: 2px solid #eee;
  position: relative;
  padding: 20px;
`

const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  text-align: left;
  line-height: 30px;
  font-size: 30px;
`

const Modal = ({ children, open, setOpen, width, height }) => {
  const modalWrapRef = useRef()

  // 閉じるボタン
  function closeModal () {
    setOpen(false)
  }
  // モーダルの外クリック
  function clickModalOutside (event) {
    if (event.target === modalWrapRef.current) {
      closeModal()
    }
  }

  return (
    <ModalWrap open={open} onClick={event => clickModalOutside(event)} ref={modalWrapRef}>
      <ModalMain width={width} height={height}>
        <CloseButton onClick={() => closeModal()}>×</CloseButton>
        {children}
      </ModalMain>
    </ModalWrap>
  )
}

export default Modal
