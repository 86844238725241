import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  // 初期値
  position: '0px'
}

const SideMenuPositionSlice = createSlice({
  name: 'sideMenuPosition',
  initialState,
  reducers: {
    // サイドメニューの横軸 右から
    SetPosition: (state, action) => {
      state.position = action.payload
    }
  }
})

export const SideMenuPositionStates = () => {
  return useSelector((state) => state)
}

export const {
  SetPosition
} = SideMenuPositionSlice.actions

export default SideMenuPositionSlice.reducer
