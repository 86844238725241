import styled from 'styled-components'

const WrapDiv = styled.div`
  position: relative;
  margin: '16px';
  display: flex;
  width: '480px';

  .calendarIconWap{
    display: 'block';
  }

  .react-datepicker-popper{
    z-index: 6;
  }
  /* .react-datepicker__current-month {
    display: none;
  } */
  .react-datepicker__month-select, .react-datepicker__year-select {
    border: none;
    background: #eee;
    height: 20px;
    line-height: 20px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 280px;
    height: ${props => props.height};
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-wrapper {
    flex: 1;
  }
  .react-datepicker__month .react-datepicker__month-text {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__input-container{
    ::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background: #0036ff;
      cursor: pointer;
      border-radius: 0 5px 5px 0;
      pointer-events: none;
      z-index:2;
    }
  }

  .normalCalendar {
    height: 32px;
    position: relative;
    text-indent: 8px;
    width: 140px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: '#fff';
    border-radius: 5px;
    border: none;
    line-height: 16px;
    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
    color: #333;
    z-index: 1;
    cursor: pointer;
    &:focus {
      background: #fff;
      outline: none;
      border: 2px solid #0036ff;
      box-sizing: border-box;
      text-indent: 8px;
    }
    ::placeholder {
      color: #ccc;
    }
  }
`

export default WrapDiv
