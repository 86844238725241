import React from 'react'
import ReactDOM from 'react-dom'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

// import { createLogger } from 'redux-logger'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-oldschool-dark'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import rootReducer from './js/reducers'

import './css/index.scss'
import App from './js/App'
import { GetToken } from './js/Common/ApiAxios'
import { restfulApiConfig } from './js/Common/Config'

import * as serviceWorker from './serviceWorker'

const graphqlHost = restfulApiConfig.graphql

const httpLink = createHttpLink({
  uri: graphqlHost
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = GetToken()
  console.log(token)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Bearer: token || ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['commonSearch']
}

const middlewares = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  }
})

// if (process.env.NODE_ENV !== 'production') {
//   const logger = createLogger({
//     diff: true
//   })
//   middlewares.push(logger)
// }

// productionでconsole.log, console.tableを無効化
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  console.log = () => { }
  console.table = () => { }
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares
})

export const persistor = persistStore(store)

// alert option
const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: '30px',
  transition: transitions.SCALE
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
