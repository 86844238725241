/* eslint-env browser */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  LaborStates,
  setOperation,
  apiGetOperations,
  apiPostFavoriteOperations,
  apiDeleteFavoriteOperations,
} from './LaborSlice'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CommonSearchStates } from '../Common/Slice/SearchSlice'
import { GetApi } from '../Common/ApiAxios'
import ErrorHandling from '../Master/Common/ErrorHandling'
import Select from '../Common/Atoms/Select'
import PropTypes from 'prop-types'
import { FaStar } from 'react-icons/fa'

const SelectFilterWrap = styled.div`
  height: 175px;
  color: #fff;
  margin: 12px 16px;
`
const OperationsUl = styled.ul`
  height: calc(90vh - 210px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }
`
const FlexDiv = styled.div`
  display: flex;
  margin-bottom: 10px;
`
const FilterLabel = styled.label`
  font-size: 12px;
  line-height: 36px;
  margin-right: 12px;
`

const PopSpan = styled.span`
  display: ${(props) => props.display};
  top: ${(props) => props.top}px;
  left: 10px;
  color: #000;
  background: #f8fdb0;
  padding: 10px 12px;
  border-radius: 5px;
  pointer-events: none;
  position: absolute;
  z-index: 6;
  list-style: none;
  font-size: 14px;
  width: 280px;
  word-wrap: break-word;
`

// 工程詳細表示条件 useStateはなるべくまとめるとEffect1かいで済む
const SelectFilter = ({ companyList }) => {
  const dispatch = useDispatch()
  const commonSearch = CommonSearchStates()
  const stateWarehouseId = commonSearch.warehouseId
  const stateFloorId = commonSearch.floorId
  const stateZoneId = commonSearch.zoneId
  const companies = companyList.length > 0 ? companyList : null
  const company =
    companies && commonSearch
      ? companies.find(
          (company) => company.companyId === commonSearch.companyId
        )
      : null
  const warehouses = company ? company.warehouses : null
  const [warehouse, setWarehouse] = useState(
    warehouses && commonSearch
      ? warehouses.find(
          (warehouse) => warehouse.warehouseId === stateWarehouseId
        )
      : null
  )
  const [floors, setFloors] = useState(warehouse ? warehouse.floors : null)

  const [categoryList, setCategoryList] = useState()

  const [filters, setFilters] = useState({
    warehouseId: commonSearch.warehouseId,
    floorId: commonSearch.floorId || -1,
    zoneId: commonSearch.zoneId || -1,
    categoryId: -1,
  })

  useEffect(() => {
    setFilters({
      warehouseId: commonSearch.warehouseId,
      floorId: commonSearch.floorId || -1,
      zoneId: commonSearch.zoneId || -1,
      categoryId: -1,
    })
  }, [companyList])

  useEffect(() => {
    const w = warehouses?.find((d) => d.warehouseId === filters.warehouseId)
    setWarehouse(w)
    setFloors(w?.floors)
  }, [filters.warehouseId, companyList])

  // カテゴリー一覧
  const getCategories = async () => {
    const isMatch = floors?.find((item) => item.floorId === filters.floorId)
    const data = {
      warehouseId: filters.warehouseId,
      floorId: isMatch ? filters.floorId : -1,
      zoneId: isMatch ? filters.zoneId : -1,
    }
    const result = await GetApi('/api/labor/categories', data)
    if (ErrorHandling(result, alert)) return null
    setCategoryList(result.data)
  }

  useEffect(() => {
    if (filters.warehouseId) {
      const isMatch = floors?.find((item) => item.floorId === filters.floorId)
      const data = {
        warehouseId: filters.warehouseId,
        floorId: isMatch ? filters.floorId : -1,
        zoneId: isMatch ? filters.zoneId : -1,
        categoryId: filters.categoryId,
      }
      dispatch(apiGetOperations(data))
      getCategories()
    }
  }, [stateWarehouseId, filters, dispatch])

  useEffect(() => {
    setFilters({
      warehouseId: stateWarehouseId,
      floorId: -1,
      zoneId: -1,
      categoryId: -1,
    })
  }, [stateWarehouseId])

  const AllWarehouse = () => {
    return (
      <option key={-1} value={-1}>
        全て
      </option>
    )
  }

  const Warehouses = () => {
    if (stateWarehouseId === 0) return null
    if (!warehouses) return null
    return warehouses.map((warehouse) => {
      if (!warehouse.warehouseId) return null
      return (
        <option key={warehouse.warehouseId} value={warehouse.warehouseId}>
          {warehouse.warehouseName}
        </option>
      )
    })
  }

  const AllFloor = () => {
    return (
      <option key={-1} value={-1}>
        全て
      </option>
    )
  }

  const Floors = () => {
    if (!warehouse || filters.warehouseId === -1) return null

    return (
      warehouse.floors &&
      warehouse.floors.map((floor) => {
        if (!floor.floorId) return null
        return (
          <option key={floor.floorId} value={floor.floorId}>
            {floor.floorName}
          </option>
        )
      })
    )
  }

  const AllZone = () => {
    return (
      <option key={-1} value={-1}>
        全て
      </option>
    )
  }

  const Zones = () => {
    if (!floors) return null
    if (filters.floorId === -1 || filters.warehouseId === -1) return null
    const floor = floors.find((floor) => floor.floorId === filters.floorId)
    if (!floor) return null
    return (
      floor.zones &&
      floor.zones.map((zone) => {
        if (!zone.zoneId) return null
        return (
          <option key={zone.zoneId} value={zone.zoneId}>
            {zone.zoneName}
          </option>
        )
      })
    )
  }
  return (
    <SelectFilterWrap>
      <FlexDiv>
        <FilterLabel>倉庫</FilterLabel>
        <Select
          selectValue={filters.warehouseId}
          onChange={(e) => {
            dispatch(setOperation(null))
            setFilters({
              warehouseId: e.target.value - 0,
              floorId: -1,
              zoneId: -1,
              categoryId: -1,
            })
          }}
        >
          <AllWarehouse />
          <Warehouses />
        </Select>
      </FlexDiv>
      <FlexDiv>
        <FilterLabel>フロア</FilterLabel>
        <Select
          selectValue={filters.floorId}
          onChange={(e) => {
            dispatch(setOperation(null))
            setFilters({
              warehouseId: filters.warehouseId,
              floorId: e.target.value - 0,
              zoneId: -1,
              categoryId: -1,
            })
          }}
        >
          <AllFloor />
          <Floors />
        </Select>
      </FlexDiv>
      <FlexDiv>
        <FilterLabel>ゾーン</FilterLabel>
        <Select
          selectValue={filters.zoneId}
          onChange={(e) => {
            dispatch(setOperation(null))
            setFilters({
              warehouseId: filters.warehouseId,
              floorId: filters.floorId,
              zoneId: e.target.value - 0,
              categoryId: -1,
            })
          }}
        >
          <AllZone />
          <Zones />
        </Select>
      </FlexDiv>
      <FlexDiv>
        <FilterLabel>カテゴリー</FilterLabel>
        <Select
          selectValue={filters.categoryId}
          onChange={(e) => {
            dispatch(setOperation(null))
            setFilters({
              warehouseId: filters.warehouseId,
              floorId: filters.floorId,
              zoneId: filters.zoneId,
              categoryId: e.target.value - 0,
            })
          }}
        >
          <option key={-1} value={-1}>
            全て
          </option>
          {categoryList &&
            categoryList.map((category) => {
              return (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </option>
              )
            })}
        </Select>
      </FlexDiv>
    </SelectFilterWrap>
  )
}

SelectFilter.propTypes = {
  companyList: PropTypes.array,
}

const OperationItem = ({ operation, onMouse, index, loginUserInfo }) => {
  const dispatch = useDispatch()
  const [style, setStyle] = useState({
    backgroundColor: '',
    fontColor: 'white',
    fontSize: '12px',
    border: '',
    boxSizing: 'border-box',
    width: '160px',
    height: '32px',
    margin: '0 auto 10px',
    textAlign: 'center',
    lineHeight: '28px',
    textIndent: '5px',
    color: '#fff',
    borderRadius: '5px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  })

  const labor = LaborStates()
  const selectOperation = labor.selectOperation

  useEffect(() => {
    const newStyle = Object.assign({}, style)
    if (selectOperation && selectOperation.id === operation.id) {
      newStyle.backgroundColor = operation.backColor
    } else {
      newStyle.backgroundColor = ''
    }
    newStyle.border = '1px solid ' + operation.backColor
    setStyle(newStyle)
  }, [selectOperation, operation])

  const onClickOperation = useCallback((operation) => {
    const newStyle = Object.assign({}, style)
    if (selectOperation && selectOperation.id === operation.id) {
      newStyle.backgroundColor = ''
      dispatch(setOperation(null))
    } else {
      newStyle.backgroundColor = operation.backColor
      dispatch(setOperation(operation))
    }
    setStyle(newStyle)
  })

  const renderFavoriteStar = (operationId) => {
    var isRegisted = false
    if (labor.favoriteOperations) {
      labor.favoriteOperations.forEach((favOpe, index) => {
        if (favOpe.operationId === operationId) {
          isRegisted = true
        }
      })
    }
    if (isRegisted) {
      return (
        <div
          onClick={() => {
            deleteFavoriteOperation(operationId)
          }}
        >
          {' '}
          <FaStar style={{ color: 'rgb(245, 245, 61)' }} />{' '}
        </div>
      )
    } else {
      return (
        <div
          onClick={() => {
            postFavoriteOperation(operationId)
          }}
        >
          {' '}
          <FaStar style={{ color: '#FFFFFF' }} />{' '}
        </div>
      )
    }
  }

  const postFavoriteOperation = (operationId) => {
    const postData = {
      staffId: loginUserInfo.staffId,
      operationId: operationId,
      sort: labor.favoriteOperations.length + 1,
    }
    dispatch(apiPostFavoriteOperations(postData))
  }
  const deleteFavoriteOperation = (operationId) => {
    const postData = {
      staffId: loginUserInfo.staffId,
      operationId: operationId,
      sort: 0,
    }
    dispatch(apiDeleteFavoriteOperations(postData))
  }

  return (
    <li
      style={{
        width: '200px',
        margin: '0 auto 10px',
        lineHeight: '28px',
        textIndent: '5px',
        listStyle: 'none',
      }}
    >
      <div style={{ display: 'flex', zIndex: '10000' }}>
        <div
          style={style}
          onClick={() => onClickOperation(operation)}
          onMouseEnter={() => onMouse(index, operation)}
          onMouseLeave={() => onMouse(index, null)}
        >
          {operation.operationName}
        </div>
        <div>{renderFavoriteStar(operation.id)}</div>
      </div>
    </li>
  )
}

OperationItem.propTypes = {
  operation: PropTypes.object,
  onMouse: PropTypes.func,
}

// 工程一覧描写
const OperationList = ({ companyList, loginUserInfo }) => {
  console.log('companyList', companyList)
  const labor = LaborStates()
  const ulRef = useRef()
  const operations = labor.operations
  const [popOperation, setPopOperation] = useState()

  const OperationPop = useCallback(() => {
    return (
      <PopSpan
        display={popOperation ? 'block' : 'none'}
        top={popOperation?.top}
      >
        <div style={{ marginBottom: '5px' }}>
          フロア：{popOperation?.floorName}{' '}
        </div>
        <div style={{ marginBottom: '5px' }}>
          ゾーン：{popOperation?.zoneName}{' '}
        </div>
        <div style={{ marginBottom: '5px' }}>
          カテゴリー：{popOperation?.categoryName}
        </div>
        <div>工程名：{popOperation?.operationName}</div>
      </PopSpan>
    )
  }, [popOperation])

  const onMouse = (index, value) => {
    if (value) {
      const value_ = {
        floorName: value.floorName,
        zoneName: value.zoneName,
        categoryName: value.categoryName,
        operationName: value.operationName,
        top: index * 42 + 35 - ulRef.current.scrollTop,
      }
      setPopOperation(value_)
    } else {
      setPopOperation(null)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <SelectFilter companyList={companyList} />
      <OperationsUl ref={ulRef}>
        {operations &&
          operations.map((operation, index) => {
            return (
              <OperationItem
                key={operation.id}
                operation={operation}
                onMouse={onMouse}
                index={index}
                loginUserInfo={loginUserInfo}
              />
            )
          })}
      </OperationsUl>
      <OperationPop />
    </div>
  )
}

OperationList.propTypes = {
  companyList: PropTypes.array,
}

export default OperationList
