import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrap = styled.div`
  display: flex;
  margin: ${props => props.margin || '0 0 12px 16px'};
  color: #333;

  span label{
    border-right: 1px solid #bbb;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.25);
  }
  span:first-of-type label{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  span:last-of-type label{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: none;
  }
`
const Title = styled.label`
  line-height: 36px;
  font-size: 12px;
  color: #fff;
  margin-right: 16px;
`
const Input = styled.input`
  display:none;
`
const Label = styled.label`
  color: #333;
  background: #ccc;
  display: block;
  width: 120px;
  height: 36px;
  transition: all .5s ease;
  font-size: 12px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
`

const TabSelector = (props) => {
  const { selectValue, setSelectValue, title, tabsName, dataList, margin, selectedBackColor, selectedColor, titleStyle } = props
  const chooseLabel = {
    color: selectedColor || '#fff',
    backgroundColor: selectedBackColor || '#048b63'
  }
  return (
    <Wrap margin={margin}>
      {title && <Title style={titleStyle}>{title}</Title>}
      {dataList && dataList.map((data, index) => {
        return (
          <span key={index}>
            <Input
              type='radio'
              name={tabsName}
              id={tabsName + data.value}
              value={data.value}
              checked={selectValue === data.value}
              onChange={() => setSelectValue(data.value)}
            />
            <Label
              htmlFor={tabsName + data.value}
              style={selectValue === data.value ? chooseLabel : null}
            >
              {data.name}
            </Label>
          </span>
        )
      })}
    </Wrap>
  )
}

TabSelector.propTypes = {
  selectValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectValue: PropTypes.func,
  title: PropTypes.string,
  tabsName: PropTypes.string,
  dataList: PropTypes.array,
  margin: PropTypes.string,
  selectedBackColor: PropTypes.string,
  selectedColor: PropTypes.string,
  titleStyle: PropTypes.object
}

export default TabSelector
