import React, { useState, useRef, useEffect } from 'react'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import MasterSearch from '../../Common/Molecules/MasterSearch'
import { GetApi } from '../../Common/ApiAxios'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import ErrorHandling from '../Common/ErrorHandling'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import CategoryDetails from './CategoryDetails'
import Input from '../../Common/Atoms/Input'

export const DefaultUrl = '/api/master/category'

// メインコンポーネント
const CategoryMaster = () => {
  const idRef = useRef()
  const nameRef = useRef()
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState({})
  const [selectedId, setSelectedId] = useState()
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.CATEGORY)
  const alert = useAlert()
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({})

  const selectItem = async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${DefaultUrl}/${id}`)
    dispatch(SetLoadSpinner(false))
    ErrorHandling(result, alert) || setDetail(result.data)
  }

  const createNew = (e) => {
    setDetail({})
    setSelectedId(null)
  }

  // 検索APIの実行
  const onSearch = async (createId, page) => {
    dispatch(SetLoadSpinner(true))
    const categoryId = idRef.current.value
    const name = nameRef.current.value
    const data = {
      categoryId: categoryId === '' ? null : categoryId,
      categoryName: name === '' ? null : name,
      page: page
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    const resultList = result.data.responseList.map(item => {
      return {
        id: item.categoryId,
        name: item.categoryName
      }
    })
    setDataList(resultList)
    setPagination(result.data)
    const isSelected = resultList.find(item => item.id === (createId || selectedId))
    if (!isSelected) {
      setSelectedId(null)
      setDetail({})
    }
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onSearch(null, 1)
    }
  }

  const pageSelectFunc = (pageId) => {
    onSearch(null, pageId)
  }

  useEffect(() => {
    document.title = 'カテゴリーマスタ | Core First'
  }, [])

  if (!grants) return null
  return (
    <MasterWrap>
      <MasterSearch title='カテゴリーマスタ' onSearch={() => onSearch(null, 1)}>
        <label>ID</label>
        <Input width='200px' type='text' name='categoryId' inputRef={idRef} onKeyDown={(e) => onKeyDown(e)} />
        <label>名称</label>
        <Input width='200px' type='text' name='categoryName' inputRef={nameRef} onKeyDown={(e) => onKeyDown(e)} />
      </MasterSearch>
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
        />
        <CategoryDetails
          detail={detail}
          setSelectedId={setSelectedId}
          setDetail={setDetail}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          onSearch={onSearch}
        />
      </main>
    </MasterWrap>
  )
}

export default CategoryMaster
