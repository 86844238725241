
const FUNC = {
  HOME: '1010101001', // ホーム
  STAFF: '1040101001', // スタッフマスタ基本情報
  STAFF_GRANT: '1040101003', // スタッフマスタ権限
  STAFF_SKILL: '1040101004', // スタッフマスタスキル
  STAWW_WAREHOUSE: '1040101005', // スタッフマスタ倉庫
  STAFF_ROLE: '1040101006', // スタッフマスタ役割
  COMPANY: '1040201001', // 会社マスタ
  GRANT: '1040601001', // 権限管理
  SKILL: '1041101001', // スキルマスタ
  ROLE: '1041201001', // 役割マスタ
  WAREHOUSE: '1041301001', // 倉庫マスタ
  FLOOR: '1041401001', // フロアマスタ
  ZONE: '1041501001', // ゾーンマスタ
  UNIT: '1041601001', // 単位マスタ
  QUANTITY: '1041701001', // 物量マスタ
  CATEGORY: '1041801001', // カテゴリーマスタ
  OPERATION: '1041901001', // 工程マスタ
  PRODUCTIVITY: '1042001001', // 生産性マスタ
  TIMEFRAME: '1042101001', // 作業時間帯マスタ
  CREDENTIALSAPIKEY: '1042201001', // 認証APIキーマスタ
  WHITEBOARD: '1100101001', // ホワイトボード
  WHITEBOARD_SET: '1100201001', // ホワイトボード設定
  PLANNED_LABOR: '1110101001', // 予定工程入力
  RESULT_LABOR: '1110201001', // 実績工程入力
  RESULT_LABOR_POS_INFO: '1110201002', // 実績工程入力位置情報
  RESULT_LABOR_LIST: '1110301001', // 工程一覧
  INDIVIDUAL_MONTHLY_LABOR: '1110401001', // 個人別月単位
  LABOR_APPROVAL: '1110501001', // 実績工程承認
  OVERTIME_ALERT: '1110501002', // 残業アラート
  ANNUAL_LABOR: '1110601001', // 年間レイバー表示
  QUANTITY_INPUT: '1120101001', // 物量入力
  PRODUCTIVITY_MANAGE: '1120201001', // 生産性管理
  OPERATION_MANAGE: '1120301001', // 工程管理
  CSV_EXPORT: '1130101001', // レイバー情報エクスポート
  DATA_ANALYZE: '1130101002', // データ分析
  BUGYO_CSV_EXPORT: '1130101003', // 給与奉行CSVエクスポート
  DATA_ANALYZE2: '1130101004' // データ分析2
}

export default FUNC
