import React, { useState, useRef, useEffect } from 'react'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import Search from '../../Common/Search'
import { GetApi } from '../../Common/ApiAxios'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import ErrorHandling from '../Common/ErrorHandling'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import FloorDetails from './FloorDetails'
import Input from '../../Common/Atoms/Input'

export const DefaultUrl = '/api/master/floor'

// メインコンポーネント
const FloorMaster = () => {
  const idRef = useRef()
  const nameRef = useRef()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const { companyId, warehouseId } = commonSearch
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState({})
  const [selectedId, setSelectedId] = useState()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.FLOOR)
  const alert = useAlert()
  const dispatch = useDispatch()
  const [companyList, setCompanyList] = useState([])
  const [imageSrc, setImageSrc] = useState()
  const [pagination, setPagination] = useState({})

  const selectItem = async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${DefaultUrl}/${id}`)
    dispatch(SetLoadSpinner(false))
    ErrorHandling(result, alert) || setDetail(result.data)
  }

  const createNew = () => {
    setDetail({})
    setSelectedId(null)
    setImageSrc(null)
  }

  const getFloors = async (createId, pageId) => {
    dispatch(SetLoadSpinner(true))
    const data = {
      companyId: companyId,
      warehouseId: warehouseId,
      floorId: grants.readGrantDivision === 3 ? loginUserInfo.floorId : idRef.current.value,
      floorName: nameRef.current.value,
      page: pageId
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      const resultList = result.data.responseList.map(item => {
        return {
          id: item.floorId,
          name: item.floorName
        }
      })
      setDataList(resultList)
      setPagination(result.data)
      const isSelected = resultList.find(item => item.id === (createId || selectedId))
      if (!isSelected) {
        setSelectedId(null)
        setDetail({})
      }
    }
  }

  const onSearch = (createId) => {
    getFloors(createId, 1)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      getFloors(null, 1)
    }
  }
  const pageSelectFunc = (pageId) => {
    getFloors(null, pageId)
  }
  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 1
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    if (result.data.length === 0) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }

  useEffect(() => {
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  // ログインユーザー情報が変更された場合実行
  // eslint-disable-next-line
  }, [loginUserInfo])

  useEffect(() => {
    document.title = 'フロアマスタ | Core First'
  }, [])

  if (!grants) return null
  return (
    <MasterWrap>
      <Search title='フロアマスタ' onClick={onSearch} showWarehouses addAllWarehouse showButton grants={grants}>
        <li>
          <label>ID</label>
          <Input
            type='text' name='floorId' inputRef={idRef} onKeyDown={(e) => onKeyDown(e)}
            disabled={grants.readGrantDivision === 3}
          />
        </li>
        <li>
          <label>名称</label>
          <Input
            type='text' name='floorName' inputRef={nameRef} onKeyDown={(e) => onKeyDown(e)}
            disabled={grants.readGrantDivision === 3}
          />
        </li>
      </Search>
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
        />
        <FloorDetails
          companyList={companyList}
          detail={detail}
          setSelectedId={setSelectedId}
          setDetail={setDetail}
          onSearch={onSearch}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          canWrite={
            grants.writeGrantDivision === 2 ||
            (grants.writeGrantDivision === 1 && ((detail.warehouseId === loginUserInfo.warehouseId) || !detail.warehouseId))
          }
          grants={grants}
        />
      </main>
    </MasterWrap>
  )
}

export default FloorMaster
