/* eslint-env browser */
import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import stopwarchIcon from '../../image/stopwatch-icon.png'
import styled from 'styled-components'
import { CommonSearchStates } from './Slice/SearchSlice'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import { getLastTime } from './Slice/LastTimeSlice'
import { msToTime } from './utils'

const WatchImg = styled.img`
  margin-right: 5px;
  width: 15px; 
`
const TimeP = styled.p`
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  width:80px;
`

const RemainingTime = () => {
  const commonSearch = CommonSearchStates()
  const lastTimeInfo = useSelector(state => state.lastTimeInfo)
  const floorId = commonSearch.floorId
  const workDate = commonSearch.workDate
  const [showFlg, setShowFlg] = useState('none')
  const dispatch = useDispatch()
  const { location } = useReactRouter()

  const WrapDiv = styled.div`
    display: ${showFlg};
    width: 120px; 
  `

  useEffect(() => {
    if (workDate && floorId > 0 && location.pathname === '/whiteboard/whiteboard') {
      dispatch(getLastTime(floorId, workDate))
    }
  }, [floorId, location, dispatch, workDate])

  useEffect(() => {
    setShowFlg(location.pathname === '/whiteboard/whiteboard' ? 'flex' : 'none')
  }, [location])

  const nextTiming = () => 1000 - Date.now() % 1000
  const timeToString = useCallback(() => {
    const todayMoment = moment()
    const lastTime = lastTimeInfo.lastTime
    if (!lastTime) return '00:00:00'
    const lastTimeMoment = moment(lastTime)
    const diffTime = lastTimeMoment.diff(todayMoment)
    if (diffTime < 0) {
      return '00:00:00'
    } else {
      return msToTime(diffTime)
    }
  }, [lastTimeInfo.lastTime])
  const [time, setTime] = useState(() => timeToString(new Date()))

  useEffect(() => {
    if (location.pathname === '/whiteboard/whiteboard') {
      let timer = setTimeout(function main () {
        timer = setTimeout(main, nextTiming())
        setTime(() => timeToString())
      }, nextTiming())
      return () => clearTimeout(timer)
    }
  }, [time, lastTimeInfo.lastTime, location, timeToString])

  const spanStyle = {
    margin: '0px 10px',
    fontSize: '20px',
    height: '20px',
    lineHeight: '13px'
  }

  return (
    <WrapDiv>
      <span style={spanStyle}>[</span>
      <WatchImg src={stopwarchIcon} alt='stopwarchIcon' />
      <TimeP>{time}</TimeP>
      <span style={spanStyle}>]</span>
    </WrapDiv>
  )
}

export default RemainingTime
