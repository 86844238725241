import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { filter } from 'lodash'
import { CommonSearchStates } from './Common/Slice/SearchSlice'
import { PostApi, GetApi, PutApi, DeleteApi, exportErrorLog } from './Common/ApiAxios'
import ZoneInitPane from './ZoneInitPane.js'
import '../css/Floor.scss'
import ErrorHandling from './Master/Common/ErrorHandling'
import FUNC from './Common/FunctionEnum'

// import floorImage from '../image/background-1200.png'
const getZoneByPos = (zoneInfo, x, y) => {
  // eslint-disable-next-line array-callback-return
  const ret = zoneInfo.filter(zone => {
    if ((zone.posXStart <= x && x <= zone.posXEnd) &&
      (zone.posYStart <= y && y <= zone.posYEnd)) {
      // console.log(`
      //   zone for delete:
      //  ${zone.zoneId}, ${zone.zoneName}
      //   `)
      return zone
    }
    return null
  })

  return ret
}

const EditableFloor = () => {
  const drawing = useRef(false)
  const rectSx = useRef(0)
  const rectEx = useRef(0)
  const rectSy = useRef(0)
  const rectEy = useRef(0)
  const rectMaxEx = useRef(0)
  const rectMaxEy = useRef(0)
  const LINE_WIDTH = 5

  // const [bgimage, setBgimage] = useState(new Image())
  const [zoneInfo, setZoneInfo] = useState([])
  const zoneInfoRef = useRef([])
  const prevZoneInfo = usePrevious(zoneInfo)
  const [zoneForDelete, setZoneForDelete] = useState({})
  const [modalIsOpen, setIsOpen] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [simpleMode, setSimpleMode] = useState(true)
  const rightClickPos = useRef([0, 0])

  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const loginId = loginUserInfo.staffId
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ZONE)
  const canWrite = grants && grants.writeGrantDivision === 2

  const alert = useAlert()
  const commonSearch = CommonSearchStates()
  const canvasRef = useRef()
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId

  console.log('zoneForDelete =', zoneForDelete)

  // [ ] 編集、移動、削除、エリア名変更

  // useEffect(() => {
  // getContext()
  // bgimage = new Image()
  // bgimage.src = floorImage
  // bgimage.onload = () => {
  //   ctx.drawImage(bgimage, 0, 0)
  // }
  // }, [floorId])
  const getContext = useCallback(() => {
    return canvasRef.current.getContext('2d')
  }, [])

  const fetchMappingInfo = useCallback(async (warehouseId, floorId) => {
    console.log(`

        warehouseId, floorId
        -------------
        ${warehouseId},${floorId}

      `)
    const data = { warehouseId: warehouseId }
    const result = await GetApi('/api/whiteboard/warehouseZones', data)
    // console.info('getZone Result')
    // console.table(result.data)
    const floor = filter(result.data.floors, function (floor) { return parseInt(floor.floorId) === parseInt(floorId) })
    console.log(floor)
    if (floor[0] != null) {
      // console.log(zoneInfo, floor[0].zones)
      // 取得したゾーンが既存のゾーンと異なる場合のみstate更新
      if (JSON.stringify(zoneInfo) !== JSON.stringify(floor[0].zones)) {
        // console.log(`

        // zoneInfo更新

        // `)
        // console.log(floor[0].zones)
        const ctx = getContext()
        ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)

        setZoneInfo(
          floor[0].zones.map(data => {
            // console.log(data)
            return data
          })
        )
      }
      zoneInfoRef.current = Array.from(floor[0].zones)
      // console.log(`

      //     zoneInfoRef after Count: ${zoneInfoRef.current.length}

      // `)
    }
  }, [getContext, zoneInfo])

  useEffect(() => {
    if (floorId != null && grants) {
      const ctx = getContext()
      ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
      fetchMappingInfo(warehouseId, floorId)
    }
  // floorId変更時のみ実行
  // eslint-disable-next-line
  }, [floorId])

  const convertNumberToTime = t => {
    if (t === 0 || t === '') {
      return ''
    } else {
      // TODO 00:00と24:00の変換
      const base = ('0000' + t.toString()).slice(-4)
      return '~ ' + base.slice(0, 2) + ':' + base.slice(-2)
    }
  }

  const drawExistingZone = (xStart, xEnd, yStart, yEnd, zoneName, zoneEndTime = '') => {
    // console.log(`

    //     zoneName: ${zoneName}

    //   `)
    // zoneが定義されていない場合は描画しない
    if (zoneName == null) return

    const endTime = convertNumberToTime(zoneEndTime) // zoneEndTime === 0 ? '' : `~ ${moment(zoneEndTime, 'HHmm').format('HH:mm')}`
    const ctx = getContext()
    ctx.fillStyle = 'rgba(240, 248, 255, 80%)'

    // 背景描画
    ctx.fillRect(xStart, yStart, xEnd - xStart, yEnd - yStart)

    // ヘッダ描画
    ctx.fillStyle = 'rgba(16, 16, 16, 50%)'
    ctx.fillRect(xStart, yStart, xEnd - xStart, 75)

    // // 枠線描画
    // ctx.rect(xStart, yStart, xEnd - xStart, yEnd - yStart)
    // ctx.strokeStyle = 'gray'
    // ctx.stroke()

    // ゾーン名
    ctx.font = '20px Arial'
    ctx.fillStyle = '#fff'
    ctx.textBaseline = 'top'
    ctx.fillText(zoneName, xStart + 20, yStart + 20)
    ctx.fillText(endTime, xEnd - 120, yStart + 20)
  }

  function usePrevious (value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const drawRect = () => {
    // console.log('drawRect start')
    const ctx = getContext()
    // ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
    ctx.clearRect(
      rectSx.current - LINE_WIDTH,
      rectSy.current - LINE_WIDTH,
      rectMaxEx.current - rectSx.current + LINE_WIDTH * 2,
      rectMaxEy.current - rectSy.current + LINE_WIDTH * 2
    )

    console.log(rectSx.current, rectSy.current, rectEx.current, rectEy.current, rectMaxEx.current - rectSx.current, rectMaxEy.current - rectSy.current)
    if (rectSx.current > rectEx.current || rectSy.current > rectEy.current) {
      drawing.current = false
      return
    }

    ctx.moveTo(rectSx.current, rectSy.current)
    ctx.lineTo(rectEx.current, rectSy.current)

    // 下
    ctx.moveTo(rectSx.current, rectEy.current)
    ctx.lineTo(rectEx.current, rectEy.current)

    // 右
    ctx.moveTo(rectEx.current, rectSy.current)
    ctx.lineTo(rectEx.current, rectEy.current)

    // 左
    ctx.moveTo(rectSx.current, rectSy.current)
    ctx.lineTo(rectSx.current, rectEy.current)
    ctx.lineWidth = LINE_WIDTH
    // ctx.setLineDash([10, 5])
    ctx.strokeStyle = 'rgb(255, 0, 0)'
    // console.log('drawRect start')
  }

  const onMouseDownEvent = e => {
    switch (e.button) {
      // 右クリック
      case 2: {
        if (!canWrite) break
        const x = e.nativeEvent.offsetX
        const y = e.nativeEvent.offsetY
        const zone = getZoneByPos(zoneInfo, x, y)
        // ゾーン上のみコンテキストメニュー表示
        if (zone.length === 1) {
          // console.log(zone[0])
          // 削除対象ゾーン設定
          setZoneForDelete(zone[0])
          rightClickPos.current = [x, y]
          setShowContextMenu(true)
        } else if (zone.length > 1) {
          console.warn(`
          multiple zones
          --------------
          `)
        }

        break
      }
      default:
        // 簡易モードの場合はマウスでのゾーン作成を無効にする
        setShowContextMenu(false)
        if (simpleMode) return null
        startDrawing(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
        break
    }
  }

  const startDrawing = (x, y) => {
    console.log('start drawing')
    drawing.current = true
    const ctx = getContext()
    // ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)

    rectSx.current = x
    rectEx.current = x
    rectSy.current = y
    rectEy.current = y
    rectMaxEx.current = x
    rectMaxEy.current = y

    ctx.moveTo(x, y)
  }

  const draw = (x, y) => {
    if (!drawing.current) {
      return
    }
    // console.log('draw start')
    const ctx = getContext()
    rectEx.current = x
    rectEy.current = y
    rectMaxEx.current = x > rectMaxEx.current ? x : rectMaxEx.current
    rectMaxEy.current = y > rectMaxEy.current ? y : rectMaxEy.current

    // ctx.drawImage(bgimage, 0, 0)
    // ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
    // console.log('clear ctx: draw')
    ctx.beginPath()
    drawRect()
    ctx.stroke()
    // console.log('draw end')

    // console.log(x, y)
  }

  const endDrawing = () => {
    if (simpleMode) return null

    if (!drawing.current) {
      return
    }
    // console.log('end drawing')

    if (rectSx.current === rectEx.current && rectSy.current === rectEy.current) {
      drawing.current = false
      return
    }

    drawRect()

    if (drawing.current) {
      openModal()
    }
    // setDrawing(false)
    drawing.current = false
  }

  const style = {
    display: 'relative'
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  }
  const inputStyle = {
    margin: '10px',
    height: 25
  }

  let subtitle

  function openModal () {
    setShowContextMenu(false)
    setIsOpen(true)
  }

  function openEditZoneModal () {
    setShowContextMenu(false)
    setEditIsOpen(true)
  }

  function afterOpenModal () {
    // references are now sync'd and can be accessed.
    subtitle.style.margin = '10px'
  }

  function closeModal () {
    setIsOpen(false)
  }

  function closeEditModal () {
    setEditIsOpen(false)
  }

  function cancelDrawing () {
    closeModal()
    const ctx = getContext()
    ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
    // console.log(zoneInfo, zoneInfoRef.current)
    // eslint-disable-next-line array-callback-return
    zoneInfo.map(zone => {
      // console.log(zoneInfo.length, zoneInfoRef.current.length)
      drawExistingZone(zone.posXStart, zone.posXEnd, zone.posYStart, zone.posYEnd, zone.zoneName, zone.endTime)
    })
    // bgimage.src = floorImage
    // bgimage.onload = () => {
    //   ctx.drawImage(bgimage, 0, 0)
    // }
  }
  function cancelEdit () {
    closeEditModal()
    // const ctx = getContext()
    // ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
    // console.log(zoneInfo, zoneInfoRef.current)
    // zoneInfo.map(zone => {
    //   console.log(zoneInfo.length, zoneInfoRef.current.length)
    //   drawExistingZone(zone.posXStart, zone.posXEnd, zone.posYStart, zone.posYEnd, zone.zoneName)
    // })
    // bgimage.src = floorImage
    // bgimage.onload = () => {
    //   ctx.drawImage(bgimage, 0, 0)
    // }
  }
  const saveZone = e => {
    e.preventDefault()

    if (floorId === 0) {
      alert.error('フロア情報が登録されていません。')
      return false
    }

    const zoneName = checkZoneName(e.target.zonename.value)
    if (!zoneName) {
      alert.show('ゾーン名が入力されていません。')
      return false
    }

    if (!checkZoneEndTime(e.target.endtime.value)) {
      alert.show('終了時間は0 ~ 3600の間で設定してください。')
      return false
    }
    // console.info(`
    // === ZONE INFO ===
    // zoneName: ${zoneName}
    // floorId: ${floorId}
    // position:
    //   x start: ${rectSx.current}
    //   x end: ${rectEx.current}
    //   y start: ${rectSy.current}
    //   y end: ${rectEy.current}
    // `)

    const data = {
      floorId: floorId,
      zoneName: zoneName,
      posXStart: rectSx.current,
      posXEnd: rectEx.current,
      posYStart: rectSy.current,
      posYEnd: rectEy.current,
      endTime: e.target.endtime.value,
      createdId: 0 // TODO ユーザIDEA
    }
    PostApi('/api/whiteboard/createZone', data, true)
      .then(result => {
        // console.log('after save')
        // console.table(result.data)
        if (ErrorHandling(result, alert)) {
          exportErrorLog(result)
        } else {
          const ctx = getContext()
          ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
          // bgimage.src = floorImage
          // bgimage.onload = () => {
          //   ctx.drawImage(bgimage, 0, 0)
          // }
          fetchMappingInfo(warehouseId, floorId)
            .then(() => {
              // eslint-disable-next-line array-callback-return
              zoneInfo.map(zone => {
                drawExistingZone(zone.posXStart, zone.posXEnd, zone.posYStart, zone.posYEnd, zone.zoneName, zone.endTime)
              })
            })
            .finally(() => alert.success('登録しました'))
        }
      })

    closeModal()
  }

  const editZone = (e) => {
    e.preventDefault()
    const zoneName = checkZoneName(e.target.zonename.value)
    if (!zoneName) {
      alert.show('ゾーン名が入力されていません。')
      return false
    }
    // console.log(zoneInfo)
    // console.log(rightClickPos.current)

    const zone = getZoneByPos(zoneInfo, rightClickPos.current[0], rightClickPos.current[1])
    // console.log(zone)

    // console.info(`
    // === ZONE INFO ===
    // zoneName: ${zone[0].zoneName}
    // floorId: ${floorId}
    // position:
    //   x start: ${zone[0].posXStart}
    //   x end: ${zone[0].posXEnd}
    //   y start: ${zone[0].posYStart}
    //   y end: ${zone[0].posYEnd}
    //   ${moment().toISOString()}
    // `)
    const endTime = e.target.endtime.value === '' ? 0 : e.target.endtime.value
    const data = {
      zoneId: zone[0].zoneId,
      floorId: floorId,
      zoneName: e.target.zonename.value,
      // posXStart: zone[0].posXStart,
      // posXEnd: zone[0].posXEnd,
      // posYStart: zone[0].posYStart,
      // posYEnd: zone[0].posYEnd,
      endTime: endTime,
      updatedId: loginId,
      updatedAt: zoneForDelete.updatedAt
    }
    PutApi('/api/whiteboard/createZone', data, true)
      .then(result => {
        if (ErrorHandling(result, alert)) {
          console.error(result.errorDetail)
        } else {
          // console.log('after save')
          // console.table(result)
          const ctx = getContext()
          ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
          // bgimage.src = floorImage
          // bgimage.onload = () => {
          //   ctx.drawImage(bgimage, 0, 0)
          // }
          fetchMappingInfo(warehouseId, floorId)
            .then(() => {
              // console.log(zoneInfo)

              // zoneInfo.map(zone => {
              //   drawExistingZone(zone.posXStart, zone.posXEnd, zone.posYStart, zone.posYEnd, zone.zoneName)
              // })
            })
            .finally(() => alert.success('登録しました'))
        }
      })

    closeEditModal()
  }

  function checkZoneName (zoneName) {
    if (!zoneName) {
      return false
    }
    return zoneName
  }

  function checkZoneEndTime (endTime) {
    if (parseInt(endTime) >= 0 && parseInt(endTime) <= 3600) {
      return true
    } else if (parseInt(endTime) > 3600) {
      return false
    } else {
      return false
    }
  }

  const onZoneDelete = useCallback(e => {
    // console.log(`
    // === zone for delete ===
    // ${zoneForDelete.zoneId}
    // `)
    // console.log(zoneForDelete)
    // 削除API実行
    DeleteApi('/api/whiteboard/createZone', { zoneId: zoneForDelete.zoneId, updatedAt: zoneForDelete.updatedAt })
      .then(result => {
        if (ErrorHandling(result, alert)) {
          console.log(result.errorDetail)
        } else {
          // setZoneForDelete({})
          const ctx = getContext()
          ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
          fetchMappingInfo(warehouseId, floorId)
            .then(() => {
              alert.success('削除しました')
            })
        }
      }).catch(err => {
        console.log(err)
        alert.error('削除エラー')
      }).finally(() => {
      // メニュー非表示
        setShowContextMenu(false)
      })
  }, [alert, getContext, fetchMappingInfo, setShowContextMenu, floorId, warehouseId, zoneForDelete])

  // 右クリックコンテキストメニュー
  const ContextMenu = useCallback(() => {
    const menuStyle = {
      display: showContextMenu ? 'block' : 'none',
      margin: '5px 20px',
      position: 'absolute',
      backgroundColor: 'lightgray',
      top: `${rightClickPos.current[1]}px`,
      left: `${rightClickPos.current[0]}px`,
      height: '100px'
    }

    return (
      <div style={menuStyle}>
        <div
          className='contextMenuItem'
          onClick={e => openEditZoneModal(true)}
          onContextMenu={e => e.preventDefault()}
        >ゾーン情報編集
        </div>
        <div
          className='contextMenuItem'
          onClick={e => openEditZoneModal(true)}
          onContextMenu={e => e.preventDefault()}
        >ゾーン終了時間設定
        </div>
        <div
          className='contextMenuItem'
          onClick={e => onZoneDelete(e)}
          onContextMenu={e => e.preventDefault()}
          style={{ display: 'none' }}
        >ゾーン削除
        </div>
      </div>
    )
  }, [showContextMenu, onZoneDelete])

  const Zones = ({ zoneInfo }) => {
    return (
      <>
        {zoneInfo.map(zone => {
          console.log(`

              zoneInfo.length: ${zoneInfo.length}
              zoneInfoRef.current.length: ${zoneInfoRef.current.length}
              prevZoneInfo.length: ${prevZoneInfo.length}

            `)
          // console.log(prevZoneInfo, zoneInfo)
          // if (zoneInfo.length !== zoneInfoRef.current.length) {
          if (JSON.stringify(zoneInfo) !== JSON.stringify(prevZoneInfo)) {
            // console.log('== zone updated! ==')
            drawExistingZone(zone.posXStart, zone.posXEnd, zone.posYStart, zone.posYEnd, zone.zoneName, zone.endTime)
          }
          return <></>
        })}
      </>
    )
  }
  Zones.propTypes = {
    zoneInfo: PropTypes.object
  }
  const ZoneCreateModal = () => (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Create Zone Modal'
      ariaHideApp={false}
    >
      <h2 ref={_subtitle => (subtitle = _subtitle)}>ゾーン登録</h2>
      <form onSubmit={saveZone}>
        <p>
          <label htmlFor='zonename'>ゾーン名</label>
          <input style={inputStyle} type='text' name='zonename' id='zonename' />
        </p>
        <p>
          <label htmlFor='endtime'>最終時間</label>
          <input style={inputStyle} type='number' name='endtime' id='endtime' max={3600} />
        </p>
        <div>
          <button>登録</button>
          <button type='button' onClick={cancelDrawing}>キャンセル</button>
        </div>
      </form>
    </Modal>
  )

  const ZoneEditModal = () => (
    <Modal
      isOpen={editIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeEditModal}
      style={customStyles}
      contentLabel='Edit Zone Name Modal'
      ariaHideApp={false}
    >
      <h2 ref={_subtitle => (subtitle = _subtitle)}>ゾーン情報編集</h2>
      <form onSubmit={editZone}>
        <p>
          <label htmlFor='zonename'>ゾーン名</label>
          <input style={inputStyle} type='text' name='zonename' id='zonename' defaultValue={zoneForDelete.zoneName} />
        </p>
        <p>
          <label htmlFor='endtime'>最終時間</label>
          <input
            style={inputStyle} type='number' name='endtime' id='endtime' max={3600}
            defaultValue={zoneForDelete.endTime === 0 ? '' : zoneForDelete.endTime}
          />
        </p>
        <div>
          <button>登録</button>
          <button type='button' onClick={cancelEdit}>キャンセル</button>
        </div>
      </form>
    </Modal>
  )

  const handleAddZone = (row, column, zoneName, zoneEndTime) => {
    const HEIGHT = 70
    const WIDTH = 160
    const HEADER_WIDTH = 75
    const SCROLLBAR_WIDTH = 30
    const MARGIN = 25

    if (floorId === 0) {
      alert.error('フロア情報が登録されていません。')
      return false
    }

    const { xStart, xEnd, yEnd } = getDrawPosition(zoneInfoRef.current)

    if (xStart === 0 && xEnd === 0 && yEnd === 0) {
      // 初期状態
      rectSx.current = 0
      rectSy.current = 0
      rectEx.current = WIDTH * column + SCROLLBAR_WIDTH
      rectEy.current = HEIGHT * row + HEADER_WIDTH
    } else if ((HEIGHT * row) > (900 - yEnd)) {
      // 既存ゾーンの下側に描画可能な場合
      console.log('draw next')
      rectSx.current = xEnd + MARGIN
      rectSy.current = 0
      rectEx.current = WIDTH * column + xEnd + MARGIN + SCROLLBAR_WIDTH
      rectEy.current = HEIGHT * row + HEADER_WIDTH
    } else if ((HEIGHT * row) < (900 - yEnd)) {
      // 既存ゾーンの下側に描画不可能な場合
      console.log('draw below')
      rectSx.current = xStart
      rectSy.current = yEnd
      rectEx.current = WIDTH * column + xStart + SCROLLBAR_WIDTH
      rectEy.current = yEnd + HEIGHT * row + HEADER_WIDTH
    } else {
      // その他（ない）
      console.error(`
      ===============
      zone init error 
      ===============
      `)
    }
    const data = {
      floorId: floorId,
      zoneName: zoneName,
      posXStart: rectSx.current,
      posXEnd: rectEx.current,
      posYStart: rectSy.current,
      posYEnd: rectEy.current,
      endTime: zoneEndTime,
      createdId: loginId
    }
    // TODO function, await化
    PostApi('/api/whiteboard/createZone', data, true)
      .then(result => {
        if (result.errorDetail) {
          exportErrorLog(result)
          const errorStatus = result.errorDetail.response && result.errorDetail.response.status
          if (errorStatus === 500) {
            alert.error('ゾーン情報の登録に失敗しました。')
          }
        } else {
          console.log('after save')
          // console.table(result.data)
          const ctx = getContext()
          ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight)
          // bgimage.src = floorImage
          // bgimage.onload = () => {
          //   ctx.drawImage(bgimage, 0, 0)
          // }
          fetchMappingInfo(warehouseId, floorId)
            .then(() => {
              // eslint-disable-next-line array-callback-return
              zoneInfo.map(zone => {
                drawExistingZone(zone.posXStart, zone.posXEnd, zone.posYStart, zone.posYEnd, zone.zoneName, zone.endTime)
              })
            })
            .finally(() => alert.success('登録しました'))
        }
      })
    // drawExistingZone(rectSx.current, rectEx.current, rectSy.current, rectEy.current, name)
  }

  const getDrawPosition = zoneInfo => {
    console.log(zoneInfo)
    function getMaxPos (zoneInfo) {
      const xMax = zoneInfo.reduce((prev, current) => (prev.posXEnd > current.posXEnd) ? prev : current)
      const yMax = zoneInfo.reduce((prev, current) => (prev.posYEnd > current.posYEnd) ? prev : current)
      console.log(`
      
      maxPosX: ${xMax.posXEnd}
      maxPosY: ${yMax.posYEnd}
      
      `)
      return {
        xStart: xMax.posXStart,
        xEnd: xMax.posXEnd,
        yEnd: xMax.posXStart === yMax.posXStart ? yMax.posYEnd : xMax.posYEnd
      }
    }

    const { xStart, xEnd, yEnd } = zoneInfo.length === 0
      ? { xStart: 0, xEnd: 0, yEnd: 0 }
      : getMaxPos(zoneInfo)

    return { xStart, xEnd, yEnd }
  }

  return (
    <div style={{ display: 'flex' }}>
      {/* <Floor> */}
      <div className='floor'>
        {/* TODO width, height直書き修正 */}
        <canvas
          style={style}
          ref={canvasRef}
          width='1700px'
          height='900px'
          // onMouseDown={e => startDrawing(e, e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
          onMouseDown={e => onMouseDownEvent(e)}
          onMouseUp={() => endDrawing()}
          onMouseLeave={() => endDrawing()}
          onMouseMove={e => draw(e.nativeEvent.offsetX, e.nativeEvent.offsetY)}
          onContextMenu={e => e.preventDefault()}
        />
        <Zones zoneInfo={zoneInfo} />
        <ZoneCreateModal />
        <ZoneEditModal />
        <ContextMenu />
      </div>
      <ZoneInitPane
        canWrite={canWrite}
        handleAddZone={handleAddZone}
        simpleMode={simpleMode}
        setSimpleMode={setSimpleMode}
      />
    </div>
  )
}

export default EditableFloor
