import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { toFormatShortDateTime, getHoursDiff } from '../../../../Common/utils'
import { MdHome, MdBusiness } from 'react-icons/md'

const DetailUl = styled.ul`
  display: none;
  top: ${props => props.positionTop}px;
  left: ${props => props.positionLeft}px;
  color: #000;
  background: #f8fdb0;
  padding: 10px 12px;
  border-radius: 5px;
  pointer-events: none;
  position: fixed;
  z-index: 6;
  list-style: none;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  li{
    margin-bottom: 10px;
    :last-child{
      margin-bottom: 0;
    }
  }
`

// レイバーにマウスオン時の詳細表示
const LaborDetail = ({ isAllUpdateView, selectDetailLabor, wrapRef }) => {
  if (selectDetailLabor == null || wrapRef.current == null) return null
  const labor = selectDetailLabor.labor
  const index = selectDetailLabor.index
  const startTime = labor.startDt != null ? (labor.startDt.slice(0, 10) === labor.workDate ? '' : '翌') + labor.startDt.substring(11, 16) : ''
  const endTime = labor.endDt != null ? (labor.endDt.slice(0, 10) === labor.workDate ? '' : '翌') + labor.endDt.substring(11, 16) : ''
  const scrollTop = wrapRef.current.scrollTop
  const windowWidth = window.innerWidth
  const scrollLeft = wrapRef.current.scrollLeft
  const scrollDiffResult = 2370 - windowWidth + 35 + scrollLeft
  const rowHeadWidth = 195
  const positionLeft = labor.startPixel + rowHeadWidth - scrollDiffResult
  const positionTop = isAllUpdateView ? 10 : index * 53 + 30 - scrollTop
  const laborHours = getHoursDiff(labor.startDt, labor.endDt)

  return (
    <DetailUl
      className='detailLabor'
      positionLeft={positionLeft}
      positionTop={positionTop}
    >
      <li>
        <label>工程名 :
          {labor.workPlaceName === '出勤'
            ? <MdBusiness style={{ marginLeft: 4, marginRight: 4 }} />
            : <MdHome style={{ marginLeft: 4, marginRight: 4 }} />}
          {labor.operationName}
        </label>
      </li>
      <li>
        <label>作業時間 : {startTime}～{endTime} ({laborHours.hours}h)</label>
      </li>
      <li>
        <label style={{ marginRight: 16 }}>フロア : {labor.floorName}</label>
        <label>ゾーン : {labor.zoneName}</label>
      </li>
      <li>
        <label style={{ marginRight: 16 }}>登録 : {labor.createdUserName}</label>
        <label>{labor.createdAt ? toFormatShortDateTime(labor.createdAt) : ''}</label>
      </li>
      <li>
        <label style={{ marginRight: 16 }}>更新 : {labor.updatedUserName}</label>
        <label>{labor.createdAt ? toFormatShortDateTime(labor.updatedAt) : ''}</label>
      </li>
    </DetailUl>
  )
}

LaborDetail.propTypes = {
  isAllUpdateView: PropTypes.bool,
  selectDetailLabor: PropTypes.object,
  wrapRef: PropTypes.any
}

export default LaborDetail
