import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert'
import Modal from 'react-modal'

import { DefaultUrl } from './Operation'
import { InputGroup, HiddenInput, SelectGroup } from '../Common/CommonInputGroup'
import { Form, Alert } from '../Common/CommonStyled'
import ErrorHandling from '../Common/ErrorHandling'
import { PostApi, DeleteApi, PutApi, GetApi } from '../../Common/ApiAxios'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import Button from '../../Common/Atoms/NormalButton'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import Input from '../../Common/Atoms/Input'
import SelectAddValue from '../../Common/Atoms/SelectAddValue'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import CreaterInfo from '../../Common/Molecules/CreaterInfo'

const ModalStayles = {
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, .4)'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'none',
    color: 'white',
    border: 'none',
    width: '350px',
    height: '146px',
    zIndex: 101
  }
}

const RegisterContainer = styled.div`
  height: 100%;
  color: white;
  position: relative;
  margin-left: 10px;
  display: flex;
`

const CloseButton = styled.a`
  position: fixed;
  right: 4%;
  top: 2%;
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  &:hover {
    background: #80808087;
    cursor: default;
  }
`

const RegisterModalContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const RegisterModalHeader = styled.div`
  width: 100%;
  height: 36px;
  background-color: #656565;
  display: flex;
  align-items: center;
  h1 {
    margin-left: 8%;
    color: lightgray;
    font-weight: 100;
  }
`
const RegisterModalBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  input {
    height: 36px;
    text-indent: 8px;
    border-radius: 4px;
    border: 1px solid lightslategray;
    margin-right: 10px;
    background-color: ${({ hasError }) => hasError && 'pink'};
  }
  div {
    position: relative;
  }
  label {
    position: absolute;
    top: -18px;
    font-size: 15px;
  }
  p {
  display: ${({ hasError }) => hasError ? 'block' : 'none'};
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: -16px;
  }
`

const RegisterModalSubmitButton = styled.button.attrs({
  type: 'button'
})`
  width: 80px;
  height: 36px;
  background: transparent linear-gradient(0deg,#000000 0%,#080808 10%,#555555 100%) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  color: white;
  &:hover{
    background: transparent linear-gradient(0deg,#000000 0%,#151515 10%,#777777 100%) 0% 0% no-repeat padding-box;
  }
`

const CircleButton = styled.button.attrs({
  type: 'button'
})`
  width: 38px;
  height: 38px;
  margin: auto;
  border-radius: 20px;
  border: 1px, solid;
  background: #afafaf;
  opacity: 1;
  border: none;
  color: white;
  &:hover{
    background: #c5c5c3;
  }
`

const OperationMasterForm = ({
  register, errors, isDeleted,
  companyRef, warehouseRef, floorRef, zoneRef, categoryRef,
  selects, setSelects, canWrite, companyList, categoryList, detail, typeList,
  onCategorySearch, grants, loginUserInfo
}) => {
  const companies = companyList.length > 0 ? companyList : null

  useEffect(() => {
    if (categoryList.length > 0 && companyList.length > 0) {
      setSelects({
        companyId: companyList[0].companyId,
        warehouseId: companyList[0].warehouses[0].warehouseId,
        floorId: companyList[0].warehouses[0].floors[0].floorId,
        zoneId: companyList[0].warehouses[0].floors[0].zones[0].zoneId,
        categoryId: categoryList[0].categoryId
      })
    }
  // データ変更時のみ実行
  // eslint-disable-next-line
  }, [companyList, categoryList])

  // 倉庫を初期値に戻す
  const downSelectWarehouse = (companyId) => {
    const company = companies.find(company => company.companyId === companyId)
    return company && company.warehouses[0] && company.warehouses[0].warehouseId
  }
  // フロアを初期値に戻す
  const downSelectFloor = (companyId, warehouseId) => {
    const company = companies && companies.find(item => item.companyId === companyId)
    const warehouses = company && company.warehouses
    const warehouse = warehouses && warehouses.find(item => item.warehouseId === warehouseId)
    return warehouse && warehouse.floors[0] && warehouse.floors[0].floorId
  }
  // ゾーンを初期値に戻す
  const downSelectZone = (companyId, warehouseId, floorId) => {
    const company = companies && companies.find(item => item.companyId === companyId)
    const warehouses = company && company.warehouses
    const warehouse = warehouses && warehouses.find(item => item.warehouseId === warehouseId)
    const floors = warehouse && warehouse.floors
    const floor = floors && floors.find(item => item.floorId === floorId)
    return floor && floor.zones[0] && floor.zones[0].zoneId
  }

  // 会社
  const Companies = () => {
    const selectCompany = useCallback((event) => {
      const companyId = event.target.value
      const warehouseId = downSelectWarehouse(companyId)
      const floorId = downSelectFloor(companyId, warehouseId)
      const zoneId = downSelectZone(companyId, warehouseId, floorId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    return (
      <li>
        <label>会社</label>
        <div>
          <SelectAddValue
            width='240px'
            margin='0'
            selectValue={selects.companyId} selectRef={companyRef} disabled={isDeleted || !canWrite}
            onChange={event => selectCompany(event)}
          >
            {companies && companies.map(item => {
              if (grants.writeGrantDivision === 1 &&
                (
                  (!detail.companyId && item.companyId !== loginUserInfo.companyId) ||
                  (detail.companyId && detail.companyId === loginUserInfo.companyId && item.companyId !== loginUserInfo.companyId)
                )
              ) return null
              return (
                <option key={item.companyId} value={item.companyId}>{item.companyName}</option>
              )
            })}
          </SelectAddValue>
        </div>
      </li>
    )
  }

  // 倉庫
  const Warehouses = () => {
    const selectWarehouse = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = event.target.value - 0
      const floorId = downSelectFloor(companyId, warehouseId)
      const zoneId = downSelectZone(companyId, warehouseId, floorId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    const company = companies && companies.find(item => item.companyId === selects.companyId)
    const warehouses = company && company.warehouses
    return (
      <li>
        <label>倉庫</label>
        <div>
          <SelectAddValue
            width='320px'
            margin='0'
            selectValue={selects.warehouseId} selectRef={warehouseRef} disabled={isDeleted || !canWrite}
            onChange={event => selectWarehouse(event)}
          >
            {warehouses && warehouses.map(item => {
              if (grants.writeGrantDivision === 1 &&
                (
                  (!detail.warehouseId && item.warehouseId !== loginUserInfo.warehouseId) ||
                  (detail.warehouseId && detail.warehouseId === loginUserInfo.warehouseId && item.warehouseId !== loginUserInfo.warehouseId)
                )
              ) return null
              return (
                <option key={item.warehouseId} value={item.warehouseId}>{item.warehouseName}</option>
              )
            })}
          </SelectAddValue>
        </div>
      </li>
    )
  }

  // フロア
  const Floors = () => {
    const selectFloor = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = selects.warehouseId
      const floorId = event.target.value - 0
      const zoneId = downSelectZone(companyId, warehouseId, floorId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    const company = companies && companies.find(item => item.companyId === selects.companyId)
    const warehouses = company && company.warehouses
    const warehouse = warehouses && (warehouses.find(item => item.warehouseId === selects.warehouseId) || warehouses[0])
    const floors = warehouse && warehouse.floors
    return (
      <li>
        <label>フロア</label>
        <div>
          <SelectAddValue
            width='320px'
            margin='0'
            selectValue={selects.floorId} selectRef={floorRef} disabled={isDeleted || !canWrite}
            onChange={event => selectFloor(event)}
          >
            {floors && floors.map(item => {
              return (
                <option key={item.floorId} value={item.floorId}>{item.floorName}</option>
              )
            })}
          </SelectAddValue>
        </div>
      </li>
    )
  }

  // ゾーン
  const Zones = () => {
    const selectFloor = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = selects.warehouseId
      const floorId = selects.floorId
      const zoneId = event.target.value - 0
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    const company = companies && companies.find(item => item.companyId === selects.companyId)
    const warehouses = company && company.warehouses
    const warehouse = warehouses && (warehouses.find(item => item.warehouseId === selects.warehouseId) || warehouses[0])
    const floors = warehouse && warehouse.floors
    const floor = floors && (floors.find(item => item.floorId === selects.floorId) || floors[0])
    const zones = floor && floor.zones
    return (
      <li>
        <label>ゾーン</label>
        <div>
          <SelectAddValue
            width='320px'
            margin='0'
            selectValue={selects.zoneId} selectRef={zoneRef} disabled={isDeleted || !canWrite}
            onChange={event => selectFloor(event)}
          >
            {zones && zones.map(item => {
              return (
                <option key={item.zoneId} value={item.zoneId}>{item.zoneName}</option>
              )
            })}
          </SelectAddValue>
        </div>
      </li>
    )
  }

  // カテゴリー
  const Categories = () => {
    const alert = useAlert()
    const [registCategoryName, setRegistCategoryName] = useState('')
    const [registCategoryValidError, setRegistCategoryValidError] = useState({ value: false, message: '' })
    const changeRegistCategoryName = useCallback((e) => {
      setRegistCategoryName(e.target.value)
      e.target.value !== '' && setRegistCategoryValidError({ value: false, message: '' })
    }, [])
    const selectCategory = useCallback((event) => {
      setSelects({
        companyId: selects.companyId,
        warehouseId: selects.warehouseId,
        floorId: selects.floorId,
        zoneId: selects.zoneId,
        categoryId: event.target.value - 0
      })
    }, [])
    // カテゴリマスタ POST
    const registCategory = async () => {
      if (registCategoryName === '') {
        setRegistCategoryValidError({ value: true, message: '必須項目です' })
      } else {
        const postResult = await PostApi('/api/master/category', { categoryName: registCategoryName }, true)
        if (!ErrorHandling(postResult, alert)) {
          alert.success('登録しました。')
          onCategorySearch()
          handleModalHide()
          setRegistCategoryName('')
        }
      }
    }
    return (
      <li>
        <label>カテゴリー</label>
        <div>
          <SelectAddValue
            width='320px'
            margin='0'
            selectValue={selects.categoryId} selectRef={categoryRef} disabled={isDeleted || !canWrite}
            onChange={event => selectCategory(event)}
          >
            {categoryList && categoryList.map(item => {
              return (
                <option key={item.id} value={item.id}>{item.name}</option>
              )
            })}
          </SelectAddValue>
          {canWrite && !isDeleted &&
            <RegisterContainer>
              <CircleButton onClick={handleModalShow}>+</CircleButton>
              <Modal
                isOpen={isModalShow}
                onRequestClose={handleModalHide}
                style={ModalStayles}
                ariaHideApp={false}
              >
                <RegisterModalContainer>
                  <CloseButton onClick={handleModalHide}>×</CloseButton>
                  <RegisterModalHeader>
                    <h1>カテゴリマスタ登録</h1>
                  </RegisterModalHeader>
                  <RegisterModalBody hasError={registCategoryValidError.value}>
                    <div>
                      <label>カテゴリ名称</label>
                      <Input type='text' width='200px' value={registCategoryName} onChange={changeRegistCategoryName} />
                      <p>{registCategoryValidError.message}</p>
                    </div>
                    <RegisterModalSubmitButton onClick={registCategory}>登録</RegisterModalSubmitButton>
                  </RegisterModalBody>
                </RegisterModalContainer>
              </Modal>
            </RegisterContainer>}
        </div>
      </li>
    )
  }

  // Modal関連
  const [isModalShow, setIsModalShow] = useState(false)
  const handleModalShow = useCallback(() => setIsModalShow(true), [])
  const handleModalHide = useCallback(() => setIsModalShow(false), [])

  if (!selects) return null
  return (
    <MasterDetailWrap isView='true'>
      <ul>
        <li style={{ height: '15px' }}><label /><div /></li>
        {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
        <li>
          <label>ID</label>
          <InputGroup
            width='320px'
            margin='0'
            disabled
            placeholder='自動採番'
            name='operationId'
            register={register}
            errors={errors}
          />
        </li>
        <li>
          <label>工程名称<span style={{ color: 'red' }}>*</span></label>
          <div>
            <InputGroup
              width='320px'
              margin='0'
              name='operationName'
              register={register}
              rule={{
                required: '必須項目です',
                maxLength: { value: 50, message: '50文字以内で設定してください' }
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <Companies />
        <Warehouses />
        <Floors />
        <Zones />
        <Categories />
        <li>
          <label>ソート順<span style={{ color: 'red' }}>*</span></label>
          <div>
            <InputGroup
              width='120px'
              margin='0'
              name='sort'
              register={register}
              min={1}
              rule={{
                required: '必須項目です',
                min: { value: 1, message: '1～99までで設定してください' },
                max: { value: 99, message: '1～99までで設定してください' }
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>工程種別</label>
          <div>
            <SelectGroup
              name='type'
              width='120px'
              margin='12px 0 12px 0'
              options={typeList}
              register={register}
              rule={{
                required: '必須項目です'
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <HiddenInput
          name='updatedId'
          register={register}
        />
        <HiddenInput
          name='updatedAt'
          register={register}
        />
        <li style={{ height: '15px' }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

OperationMasterForm.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  isDeleted: PropTypes.bool,
  canWrite: PropTypes.bool,
  companyRef: PropTypes.any,
  warehouseRef: PropTypes.any,
  floorRef: PropTypes.any,
  zoneRef: PropTypes.any,
  categoryRef: PropTypes.any,
  selects: PropTypes.object,
  setSelects: PropTypes.func,
  companyList: PropTypes.array,
  categoryList: PropTypes.array,
  detail: PropTypes.object,
  onCategorySearch: PropTypes.func,
  grants: PropTypes.object,
  loginUserInfo: PropTypes.object
}

const OperationDetails = ({
  onSearch, setSelectedId, detail, setDetail, canWrite, categoryList, companyList, onCategorySearch, typeList,
  grants
}) => {
  const { register, handleSubmit, setValue, errors, reset, control, clearError } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isDeleted, setIsDeleted] = useState(false)
  const [selects, setSelects] = useState()
  const companyRef = useRef()
  const warehouseRef = useRef()
  const floorRef = useRef()
  const zoneRef = useRef()
  const categoryRef = useRef()
  const alert = useAlert()
  const dispatch = useDispatch()
  const { loginUserInfo } = useSelector(state => state)

  useEffect(() => {
    if (actionType === 'POST') {
      setIsDeleted(false)
    }
  }, [actionType])

  // Formに反映
  useEffect(() => {
    if (detail.id === undefined) {
      setActionType('POST')
      reset({
        operationId: '',
        operationName: '',
        type: 1,
        sort: 1
      })
      if (companyRef.current) {
        companyRef.current.selectedIndex = 0
        warehouseRef.current.selectedIndex = 0
        floorRef.current.selectedIndex = 0
        zoneRef.current.selectedindex = 0
        categoryRef.current.selectedIndex = 0
        setSelects({
          companyId: companyRef.current.value,
          warehouseId: warehouseRef.current.value - 0,
          floorId: floorRef.current.value - 0,
          zoneId: zoneRef.current.value - 0,
          categoryId: categoryRef.current.value - 0
        })
      }
    } else {
      clearError(undefined)
      setIsDeleted(detail.delFlag)
      setValue('operationId', detail.id)
      setValue('operationName', detail.operationName)
      setValue('sort', detail.sort)
      setValue('type', detail.type)
      setValue('companyId', detail.companyId)
      setValue('warehouseId', detail.warehouseId)
      setValue('floorId', detail.floorId)
      setValue('zoneId', detail.zoneId)
      setValue('categoryId', detail.categoryId)
      setValue('createdName', detail.createdName)
      setValue('createdAt', detail.createdAt)
      setValue('updatedName', detail.updatedName)
      setValue('updatedAt', detail.updatedAt)
      setValue('updatedId', detail.updatedId)
      setActionType('PUT')
      setSelects({
        companyId: detail.companyId,
        warehouseId: detail.warehouseId,
        floorId: detail.floorId,
        zoneId: detail.zoneId,
        categoryId: detail.categoryId
      })
    }
  // データ変更時のみ実行
  // eslint-disable-next-line
  }, [detail])

  // POST・PUT処理
  const onSubmit = (data, e) => {
    async function sendPost (requestBody) {
      dispatch(SetLoadSpinner(true))
      var result
      switch (actionType) {
        case 'POST':
          result = await PostApi(`${DefaultUrl}`, requestBody, true)
          break
        case 'PUT':
          result = await PutApi(`${DefaultUrl}/${requestBody.operationId}`, requestBody, true)
          break
        default:
          result = false
          break
      }
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setDetail({})
        setSelectedId(null)
        onSearch(null)
        setActionType('POST')
      }
    }
    data.zoneId = zoneRef.current.value - 0
    data.categoryId = categoryRef.current.value - 0
    if (actionType === 'PUT') {
      data.operationId && sendPost(data)
    } else {
      sendPost(data)
    }
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = (e) => {
    async function sendDelete (id, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${id}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
      if (result.data) alert.info('無効化しました')
    }
    if (detail.id !== undefined) {
      sendDelete(detail.id, { updatedAt: detail.updatedAt })
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <OperationMasterForm
        register={register} errors={errors} control={control} isDeleted={isDeleted}
        companyRef={companyRef} warehouseRef={warehouseRef} floorRef={floorRef} zoneRef={zoneRef} categoryRef={categoryRef}
        selects={selects} setSelects={setSelects} canWrite={canWrite}
        companyList={companyList} categoryList={categoryList} typeList={typeList}
        onCategorySearch={onCategorySearch}
        grants={grants} loginUserInfo={loginUserInfo} detail={detail}
      />
      <MasterDetailButtons style={{ display: canWrite ? 'flex' : 'none' }}>
        <Button
          name={actionType === 'PUT' ? detail.delFlag ? '有効化' : '更新' : '登録'}
          height='20px'
          width='90px'
          padding='0'
          type='submit'
        />
        {!detail.delFlag && detail.delFlag !== undefined &&
          <Button
            height='20px'
            width='90px'
            padding='0'
            name='削除'
            type='button'
            onClick={(e) => onClickHandleDelete(e)}
          />}
      </MasterDetailButtons>
      <CreaterInfo info={detail} />
    </Form>
  )
}

OperationDetails.propTypes = {
  canWrite: PropTypes.bool,
  detail: PropTypes.object,
  grants: PropTypes.object,
  setDetail: PropTypes.func,
  onSearch: PropTypes.func,
  setSelectedId: PropTypes.func,
  categoryList: PropTypes.array,
  typeList: PropTypes.array,
  companyList: PropTypes.array,
  onCategorySearch: PropTypes.func
}

export default OperationDetails
