import { GetApi } from '../ApiAxios'
import { DateTime } from 'luxon'

const ExportTimecardCsv = async (startDate, endDate, commonSearch, searchEmpCond, grant, staffId) => {
  const { warehouseId, floorId, zoneId } = commonSearch
  const urlArgs = buildArgs(warehouseId, floorId, zoneId, searchEmpCond, grant, staffId)
  const from = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
  const to = DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')
  console.log(startDate, endDate, urlArgs)
  const { data } = await GetApi(`/api/download/csv/attendance/${from}/${to}?${urlArgs}`)
  return data
}

function buildArgs (warehouseId, floorId, zoneId, searchEmpCond, grant, staffId) {
  const warehouse = warehouseId !== -1 ? `&warehouseId=${warehouseId}` : ''
  const floor = (floorId && (floorId !== '-' && floorId !== -1)) ? `&floorId=${floorId}` : ''
  const zone = (zoneId && (zoneId !== '-' && zoneId !== -1)) ? `&zoneId=${zoneId}` : ''
  const staffFilter = grant === 3
    ? `&staffId=${staffId}`
    : grant === 1
      ? `&bossStaffId=${staffId}`
      : ''
  return `staffDivisionFilter=${searchEmpCond}${warehouse}${floor}${zone}${staffFilter}`
}

export default ExportTimecardCsv
