import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useAlert } from 'react-alert'
import { setSelectedId } from './Mapping/EditZoneSlice'
import SideMenu from './Common/Molecules/SideMenu'
import NormalButton from './Common/Atoms/NormalButton'
import ListView from './Common/Atoms/ListView'
import Input from './Common/Atoms/Input'

const ZoneInitPane = (props) => {
  const dispatch = useDispatch()
  const zones = useSelector(state => state.zoneMaster.zones)
  const zoneEdit = useSelector(state => state.editZone)
  const { handleAddZone, canWrite, handleShowModal } = props
  // const ZoneInitContailer = styled.div`
  //   // width:${props => props.operationViewFlg ? '300px' : '30px'};
  //   width: 300px;
  //   background:#222222;
  //   border-top:1px solid #707070;
  //   border-left:1px solid #707070;
  //   border-bottom:1px solid #707070;
  //   border-radius: 5px 0 0 5px;
  //   position: absolute;
  //   width: 20%;
  //   right: 15px;
  //   top: 0px;
  //   height: calc(100vh - 20px);
  // `
  // const [selectedId, setSelectedId] = useState()
  const SettingNameContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    background: #555;
    height: 50px;
    line-height: 50px;
  `
  const SettingName = styled.div`
    width: 50%;
    color: white;
  `
  const SettingsContainer = styled.div`
    margin: 10px 20px;
    text-align: center;
    // border-style: solid;
    // border-color: white;
    // border-width: 1px;
    // border-radius: 5px;
    `

  const Setting = styled.div`
    color: white;
    margin: 20px;
    display: flex;
    `

  const alert = useAlert()
  const handleClick = e => {
    e.preventDefault()
    console.log(`

        e.target.row.value: ${e.target.row.value}
        e.target.column.value: ${e.target.column.value}

      `)
    console.log(e.target.column.value)
    // 未入力チェック
    if (e.target.zonename.value.trim() === '') {
      alert.show('ゾーン名称が入力されていません')
      return null
    }
    if (e.target.row.value.trim() === '') {
      alert.show('行の人数が入力されていません')
      return null
    }
    if (e.target.column.value.trim() === '') {
      alert.show('列の人数が入力されていません')
      return null
    }
    if (e.target.endtime.value.trim() === '') {
      alert.show('終了時間が入力されていません')
      return null
    }

    handleAddZone(e.target.row.value, e.target.column.value, e.target.zonename.value, e.target.endtime.value)
  }

  // const handleSimpleClick = () => {
  //   setSimpleMode(true)
  // }
  // const handleSpecificClick = () => {
  //   setSimpleMode(false)
  // }

  const labelStyle = {
    width: '100px',
    height: '48px',
    lineHeight: '48px'
  }

  const Unit = styled.div`
    height: 48px;
    line-height: 48px;
  `
  useEffect(() => {
    if (zones[0]) {
      const payload = {
        selectedId: zones[0].id
      }
      dispatch(setSelectedId(payload))
    }

    return () => {
      console.log('clean up')
    }
  }, [zones, dispatch])
  const handleSelect = id => {
    const payload = {
      selectedId: id
    }
    dispatch(setSelectedId(payload))
    // setSelectedId(id)
  }
  return (
    <div style={{ display: canWrite ? 'block' : 'none' }}>
      <SideMenu
        isView
        width='425px'
      >
        <div style={{ paddingLeft: '30px' }}>
          <SettingNameContainer>
            <SettingName>ゾーン設定</SettingName>
          </SettingNameContainer>
          <SettingsContainer>

            <form onSubmit={handleClick}>
              <Setting>
                <label style={labelStyle} htmlFor='name'>ゾーン名</label>
                <Input type='text' name='zonename' id='zonename' />
              </Setting>
              <Setting>
                <label style={labelStyle} htmlFor='row'>行</label>
                <Input type='number' id='row' name='row' min={1} max={12} />
                <Unit>人</Unit>
              </Setting>
              <Setting>
                <label style={labelStyle} htmlFor='column'>列</label>
                <Input type='number' id='column' name='column' min={1} max={10} /><Unit>人</Unit>
              </Setting>
              <Setting>
                <label style={labelStyle} htmlFor='endtime'>最終時間</label>
                <Input type='number' id='endtime' name='endtime' min={0} max={3600} /><Unit>時</Unit>
              </Setting>
              <div style={{ display: 'inline-block' }}>
                <NormalButton
                  style={{ margin: 'auto' }}
                  name='追加'
                  width='160px'
                />
              </div>
            </form>
          </SettingsContainer>
          <SettingNameContainer>
            <SettingName>ゾーン一覧</SettingName>
          </SettingNameContainer>
          <SettingsContainer>
            <ListView
              addStyle={'width: 100%; li { width: 100% }'}
              dataList={zones}
              selectedId={zoneEdit.selectedId}
              selectFunction={(id) => handleSelect(id)}
            />
            <div style={{ display: 'inline-block' }}>
              <NormalButton
                style={{ margin: 'auto' }}
                name='ゾーン編集'
                width='160px'
                onClick={() => handleShowModal(true)}
              />
            </div>
          </SettingsContainer>
        </div>
      </SideMenu>
    </div>
  )
}

ZoneInitPane.propTypes = {
  handleAddZone: PropTypes.func,
  handleShowModal: PropTypes.func,
  canWrite: PropTypes.bool
}
export default ZoneInitPane
