/* eslint-disable symbol-description */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import Calendar from '../Common/CommonCalendar'
import NormalButton from '../Common/Atoms/NormalButton'
import Input from '../Common/Atoms/Input'
import Select from '../Common/Atoms/Select'
// import { CommonSearchStates } from '../Common/Slice/SearchSlice'
import { CommonSearchStates } from '../Common/Slice/SearchSlice'
import moment from 'moment'

const ACTION = {
  CREATE: Symbol(),
  REFLECT: Symbol(),
  UPDATE: Symbol()
}

const BLOCK = 'block'
const NONE = 'none'

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
  // margin-left: 20px;
  color: #fff;
  position: absolute;
  left: 0;
  top: calc(100% - 70px);
  width: 100%;
`

const ModalWrap = styled.div`
  background-color: rgb(51, 51, 51, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: ${props => props.viewDivision ? 'flex' : 'none'};
  justify-content:center;
  align-items:center;
`

const ModalMain = styled.div`
  width: 650px;
  height: 350px;
  background: #000;
  border: 2px solid #eee;
`
const ActionWrap = styled.div`
  padding:3px;
  color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
`

const ActionMain = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
`
const ActionButtons = styled.div`
  display:flex;
  justify-content:space-evenly;
`
const Label = styled.label`
  width: 120px;
  line-height: 30px;
`
const DateLabel = styled.label`
  line-height: 30px;
  margin-right: 5px;
`

const SingleDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
`
// const CreateInput = styled.input`
//   width: 230px;
//   height: 30px;
//   border: none;
//   text-indent: 10px;
//   border-radius: 4px;
// `
const InputWrap = styled.div`
  position: relative;
  height: 30px;
  display: flex;

  p {
    display: ${props => props.errorMessageView};
    color: #ff7d7d;
    position: absolute;
    top: 35px;
    left: 0;
  }
`

const WidthWrap = styled.div`
  width: 360px;
  height: 70px;
  margin: 0 auto;
`
const ReflectRow = styled.div`
  display: flex;
  margin-bottom: 30px;
`
// const TemplateSelect = styled.select`
//   width: 250px;
//   height: 30px;
//   border: none;
//   text-indent: 10px;
//   border-radius: 4px;
// `

const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  text-align: left;
  line-height: 30px;
  font-size: 30px;
`

const Modal = ({
  onCreate = f => f, onReflect = f => f, onUpdate = f => f, onDelete = f => f,
  templates, viewDivision, setViewDivision, modalWrapRef,
  fromDate, toDate, setFromDate, setToDate, workDate
}) => {
  // const {
  //   onCreate, onReflect, onUpdate, templates, viewDivision, setViewDivision, modalWrapRef,
  //   fromDate, toDate, setFromDate, setToDate, workDate
  // } = props
  const createInputRef = useRef()
  const reflectSelectRef = useRef()
  const updateSelectRef = useRef()
  // バリデーション用
  const [createInputError, setCreateInputError] = useState(NONE)
  const [refrectSelectError, setRefrectSelectError] = useState(NONE)
  const [refrectDateError, setRefrectDateError] = useState(NONE)
  const [reflectDataErrorMessage, setRefrectDateErrorMessage] = useState()
  const [updateSelectError, setUpdateSelectError] = useState(NONE)

  useEffect(() => {
    if (fromDate && toDate && moment(fromDate).diff(moment(toDate), 'days') > 0) {
      setToDate(fromDate) // From日付設定時、toが過去なら自動でFromに合わせる
    }
  // fromDate 変更検知時のみ実行
  // eslint-disable-next-line
  }, [fromDate])

  // 閉じるボタン
  function closeModal () {
    setFromDate(workDate)
    setToDate(workDate)
    setCreateInputError(NONE)
    setRefrectSelectError(NONE)
    setRefrectDateError(NONE)
    setUpdateSelectError(NONE)
    setViewDivision(null)
  }
  // モーダルの外クリック
  function clickModalOutside (event) {
    if (event.target === modalWrapRef.current) {
      closeModal()
    }
  }
  // テンプレートリスト
  const TemplateOptions = () => {
    if (templates && templates.length === 0) {
      return (
        <option value='-1'>-</option>
      )
    }
    if (templates && templates.length > 0) {
      return templates.map((templ, index) => {
        return (
          <option key={index} value={templ.id}>{templ.templateName}</option>
        )
      })
    }
    return null
  }

  // 登録前処理
  const onBeforeCreate = () => {
    if (createInputRef.current.value) {
      setCreateInputError(NONE)
      onCreate(createInputRef.current.value)
      closeModal()
    } else {
      // 入力がなければエラー
      setCreateInputError(BLOCK)
    }
  }

  // 反映前処理
  const onBeforeReflect = () => {
    if (
      reflectSelectRef.current.value !== '-1' && // テンプレートが選択されている
      fromDate && toDate && // 日付がどちらも埋まっている
      moment(fromDate).diff(moment(toDate), 'days') <= 0 // 日付大小問題なし
    ) {
      // エラーなし
      setRefrectSelectError(NONE)
      onReflect(reflectSelectRef.current.value - 0, fromDate, toDate)
      closeModal()
    } else {
      // テンプレート未選択
      if (reflectSelectRef.current.value === '-1') {
        setRefrectSelectError(BLOCK)
      }
      // 日付が両方埋まっていない
      if (!(fromDate && toDate)) {
        setRefrectDateError(BLOCK)
        setRefrectDateErrorMessage('どちらも必須項目です')
      } else if (moment(fromDate).diff(moment(toDate), 'days') > 0) {
        // 日付の大小に問題あり
        setRefrectDateError(BLOCK)
        setRefrectDateErrorMessage('Fromよりtoが過去になっています')
      }
    }
  }

  // 更新前処理
  const onBeforeUpdate = () => {
    if (updateSelectRef.current.value !== '-1') {
      setUpdateSelectError(NONE)
      onUpdate(updateSelectRef.current.value - 0)
      closeModal()
    } else {
      setUpdateSelectError(BLOCK)
    }
  }

  // 削除前処理
  const onBeforeDelete = () => {
    if (updateSelectRef.current.value !== '-1') {
      setUpdateSelectError(NONE)
      onDelete(updateSelectRef.current.value - 0)
      closeModal()
    } else {
      setUpdateSelectError(BLOCK)
    }
  }

  const ModalContents = () => {
    switch (viewDivision) {
      case ACTION.CREATE:
        return (
          <ActionWrap>
            <ActionMain>
              <SingleDiv>
                <Label>テンプレート名</Label>
                <InputWrap errorMessageView={createInputError}>
                  {/* <input
                    type='text'
                    maxLength='50'
                    ref={createInputRef}
                    width='230px'
                  /> */}
                  <Input
                    type='text'
                    maxLength='50'
                    inputRef={createInputRef}
                    width='230px'
                  />
                  <p>必須項目です</p>
                </InputWrap>
              </SingleDiv>
            </ActionMain>
            <ActionButtons>
              <NormalButton
                onClick={() => onBeforeCreate()}
                name='登録'
              />
            </ActionButtons>
            <CloseButton onClick={() => closeModal()}>×</CloseButton>
          </ActionWrap>
        )
      case ACTION.REFLECT:
        return (
          <ActionWrap>
            <ActionMain>
              <WidthWrap>
                <ReflectRow>
                  <Label>テンプレート</Label>
                  <InputWrap errorMessageView={refrectSelectError}>
                    <Select
                      selectRef={reflectSelectRef}
                      width='270px'
                    >
                      <TemplateOptions />
                    </Select>
                    {/* <TemplateSelect ref={reflectSelectRef}>
                    </TemplateSelect> */}
                    <p>テンプレート未登録</p>
                  </InputWrap>
                </ReflectRow>
                <ReflectRow>
                  <Label>反映日時</Label>
                  <InputWrap errorMessageView={refrectDateError}>
                    <DateLabel>From</DateLabel>
                    <Calendar
                      dataValue={fromDate} setDateValue={setFromDate}
                      margin='0 10px 0 0' placeholder='From' width='90px' textIndent='5px'
                      canWrite
                    />
                    <DateLabel>To</DateLabel>
                    <Calendar
                      dataValue={toDate} setDateValue={setToDate}
                      placeholder='To' textIndent='5px' width='90px'
                      canWrite
                    />
                    <p>{reflectDataErrorMessage}</p>
                  </InputWrap>
                </ReflectRow>
              </WidthWrap>
            </ActionMain>
            <ActionButtons>
              <NormalButton
                onClick={() => onBeforeReflect()}
                name='反映'
              />
            </ActionButtons>
            <CloseButton onClick={() => closeModal()}>×</CloseButton>
          </ActionWrap>
        )
      default:
        return (
          <ActionWrap>
            <ActionMain>
              <SingleDiv>
                <Label>テンプレート</Label>
                <InputWrap errorMessageView={updateSelectError}>
                  {/* <TemplateSelect ref={updateSelectRef}>
                    <TemplateOptions />
                  </TemplateSelect> */}
                  <Select
                    selectRef={updateSelectRef}
                    width='270px'
                  >
                    <TemplateOptions />
                  </Select>
                  <p>テンプレート未登録</p>
                </InputWrap>
              </SingleDiv>
            </ActionMain>
            <ActionButtons>
              <NormalButton
                onClick={() => onBeforeUpdate()}
                name='更新'
              />
              <NormalButton
                onClick={() => onBeforeDelete()}
                name='削除'
              />
            </ActionButtons>
            <CloseButton onClick={() => closeModal()}>×</CloseButton>
          </ActionWrap>
        )
    }
  }

  return (
    <ModalWrap viewDivision={viewDivision} onClick={event => clickModalOutside(event)} ref={modalWrapRef}>
      <ModalMain>
        <ModalContents />
      </ModalMain>
    </ModalWrap>
  )
}

const TemplateButtons = (props) => {
  const commonSearch = CommonSearchStates()
  const workDate = commonSearch.workDate
  const modalWrapRef = useRef()
  const [viewDivision, setViewDivision] = useState()
  // const [templates, setTemplates] = useState()
  const [fromDate, setFromDate] = useState(workDate)
  const [toDate, setToDate] = useState(workDate)
  const { onCreate, onReflect, onUpdate, onDelete, templates } = props

  // async function apiGetTemplates (warehouseId) {
  //   const apiUrl = '/api/templates'
  //   const data = { warehouseId }
  //   const result = await GetApi(apiUrl, data)
  //   if (result.errorDetail) {
  //     exportErrorLog(result)
  //   } else {
  //     setTemplates(result.data)
  //   }
  // }

  const openModal = (action) => {
    setFromDate(workDate)
    setToDate(workDate)
    setViewDivision(action)
  }

  return (
    <Wrap>
      {/* <LabelTitle>テンプレート：</LabelTitle> */}
      {props.action && props.action === 'reflect'
        ? (
          <NormalButton
            onClick={() => openModal(ACTION.REFLECT)}
            name='テンプレート反映'
            width='272px'
          />)
        : (
          <>
            <NormalButton
              onClick={() => openModal(ACTION.CREATE)}
              name='テンプレート登録'
              width='272px'
            />
            <NormalButton
              onClick={() => openModal(ACTION.UPDATE)}
              name='テンプレート更新'
              width='272px'
            />
          </>
        )}

      <Modal
        templates={templates} onCreate={onCreate} onReflect={onReflect} onUpdate={onUpdate} onDelete={onDelete}
        viewDivision={viewDivision} setViewDivision={setViewDivision} modalWrapRef={modalWrapRef}
        fromDate={fromDate} setFromDate={setFromDate} toDate={toDate} setToDate={setToDate}
        workDate={workDate}
      />
    </Wrap>
  )
}

export default TemplateButtons
