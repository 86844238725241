import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '..//Atoms/NormalButton'

const SearchWrap = styled.div`
  height:${props => props.height || '48px'};
  margin-bottom: 12px;
  display:${props => props.display || 'flex'};
  position:${props => props.position || 'unset'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  padding: ${props => props.padding || '0'};
  color: #fff;
  h3 {
    line-height: 48px;
    font-size: 32px;
    margin-right: 32px;
  }
  label {
    flex: 0 0 40px; 
    line-height: 48px;
    text-align: right;
  }
`

const MasterSearch = ({ title, onSearch, children, firstSearch }) => {
  const [renderFlg, setRenderFlg] = useState(false)

  // レンダー終了判定 TODO:本当にあってる？
  useEffect(() => {
    if (children && !renderFlg) {
      setRenderFlg(true)
    }
  }, [children, renderFlg])

  // 初期検索
  useEffect(() => {
    if (renderFlg && firstSearch) {
      onSearch()
    }
  }, [renderFlg, onSearch, firstSearch])

  return (
    <SearchWrap>
      <h3>{title}</h3>
      {children}
      <Button name='検索' height='48px' margin='0 0 0 16px' onClick={() => onSearch()} />
    </SearchWrap>
  )
}

MasterSearch.propTypes = {
  title: PropTypes.string,
  onSearch: PropTypes.func,
  children: PropTypes.any,
  firstSearch: PropTypes.bool
}

export default MasterSearch
