import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FaArrowUp } from 'react-icons/fa'

const ButtonWrap = styled.button`
  display: ${props => props.pageTopView ? 'flex' : 'none'};
  width: 50px;
  height: 50px;
  position: fixed;
  right: 16px;
  bottom: 12px;
  background: #0036ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 31px;
  color: #fff;
  justify-content: center;
  z-index: 10000;
  :hover {
    background: #0026b3;
  }

  svg {
    margin: auto;
  }
`
const PageTop = () => { // ,pageTopView
  const [pageTopView, setPageTopView] = useState(false)

  // AppDivスクロールで見え隠れ
  useEffect(() => {
    const App = document.getElementById('App')
    const updateWindowDimensions = () => {
      if (App.scrollTop >= 100 && !pageTopView) {
        setPageTopView(true)
      } else if (App.scrollTop < 100 && pageTopView) {
        setPageTopView(false)
      }
    }
    updateWindowDimensions()
    App.addEventListener('scroll', updateWindowDimensions, { passive: true })
    return function cleanup () {
      App.removeEventListener('scroll', updateWindowDimensions, { passive: true })
    }
  })
  const onClick = () => {
    document.getElementById('App').scrollTop = 0
    setPageTopView(false)
  }
  useEffect(() => {
    setPageTopView(false)
  // パス変更時のみ実行
  // eslint-disable-next-line
  }, [window.location.pathname])
  return (
    <ButtonWrap onClick={onClick} pageTopView={pageTopView}>
      <FaArrowUp />
    </ButtonWrap>
  )
}

export default PageTop
