import { GetApi } from '../ApiAxios'
import { DateTime } from 'luxon'

const ExportBugyoCsv = async (startDate, endDate) => {
  // const urlArgs = buildUrlArgs(startDate, endDate)
  const { data } = await GetApi(`/api/download/csv/v2/kyuyoBugyo/${DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')}/${DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')}`)
  return data
}

/*
function buildUrlArgs (startDate, endDate) {
  const argsArr = []
  argsArr.push(`workDateFrom=${DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')}`)
  argsArr.push(`workDateTo=${DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')}`)
  return argsArr.join('&')
}
*/

export default ExportBugyoCsv
