import React from 'react'
import { ErrorMessage } from 'react-hook-form'
import styled from 'styled-components'
import Input from '../../Common/Atoms/Input'
import Select from '../../Common/Atoms/Select'
import PropTypes from 'prop-types'

// プロパティが階層を持つ場合に存在チェック
function getValue (obj, key, undefinedValue) {
  var k = key.split('.')
  for (var i = 0, v = obj; i < k.length; i++) {
    if (!(k[i] in v)) { return undefinedValue }
    v = v[k[i]]
  }
  return v
}

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: ${({ height }) => height || '100%'};
  > input, select {
    ${({ hasError }) => hasError ? 'background: pink;' : ''};
    + p {
      position: absolute;
      color: red;
      font-size: 0.8rem;
      bottom: -1px;
    }
  }
`
const Label = styled.label`
  font-size: 16px;
`

export const InputGroup = ({ rule, register, errors, name, displayName, disabled, ...props }) => {
  return (
    <Container errors={errors} name={name} hasError={getValue(errors, name)}>
      {displayName && <Label>{displayName}</Label>}
      <Input inputRef={register && register(rule)} name={name} {...props} disabled={disabled} />
      <ErrorMessage errors={errors} name={name}>
        {({ message }) => <p>{message}</p>}
      </ErrorMessage>
    </Container>
  )
}
InputGroup.propTypes = {
  rule: PropTypes.any,
  register: PropTypes.any,
  errors: PropTypes.any,
  name: PropTypes.string,
  displayName: PropTypes.string,
  disabled: PropTypes.bool
}

export const HiddenInput = ({ register, name, ...props }) => {
  return <input ref={register} name={name} style={{ display: 'none' }} {...props} />
}
HiddenInput.propTypes = {
  register: PropTypes.any,
  name: PropTypes.string
}
export const SelectGroup = ({ rule, register, options, errors, name, displayName, disabled, placeholder, ...props }) => {
  return (
    <Container errors={errors} name={name} hasError={getValue(errors, name)}>
      {displayName && <Label>{displayName}</Label>}
      <Select selectRef={register(rule)} name={name} {...props} disabled={disabled}>
        {placeholder && <option value='' style={{ display: 'none' }}>{placeholder}</option>}
        {Array.isArray(options) && options}
      </Select>
      <ErrorMessage errors={errors} name={name}>
        {({ message }) => <p>{message}</p>}
      </ErrorMessage>
    </Container>
  )
}
SelectGroup.propTypes = {
  rule: PropTypes.any,
  register: PropTypes.any,
  options: PropTypes.any,
  errors: PropTypes.any,
  name: PropTypes.string,
  displayName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
}
export default InputGroup
