import 'moment/locale/ja'
import moment from 'moment'
export const getFirstDay = (dateString) => {
  return moment(dateString).startOf('month').format('YYYY-MM-DD')
}

export const getLatestHour = (summaryData) => {
  let ret, current
  summaryData.forEach(item => {
    if (item.maxTime != null) {
      current = moment(item.maxTime).format('YYYYMMDD') > moment(item.workDate).format('YYYYMMDD')
        ? Number(moment(item.maxTime).format('H')) + 24
        : Number(moment(item.maxTime).format('H'))

      if (ret == null) {
        ret = current
        console.log(ret)
      }
      ret = ret > current ? ret : current
    }
  })
  return ret
}

export const getEarliestHour = summaryData => {
  // const startDateArray = []
  // summaryData.forEach(l => {
  //   if (l.minTime != null) {
  //     // console.log(l.minTime)
  //     startDateArray.push(moment(l.minTime).toDate())
  //   }
  // })
  // const ret = startDateArray.reduce((min, d) => moment(d).format('H') < min ? moment(d).format('H') : min, moment(startDateArray[0]).format('H'))
  let ret, current
  summaryData.forEach(item => {
    if (item.minTime != null) {
      current = moment(item.minTime).format('YYYYMMDD') > moment(item.workDate).format('YYYYMMDD')
        ? Number(moment(item.minTime).format('H')) + 24
        : Number(moment(item.minTime).format('H'))

      if (ret == null) {
        ret = current
        console.log(ret)
      }
      ret = ret < current ? ret : current
    }
  })
  return Number(ret)
}
