import React, { useState, useEffect } from 'react'
import { PostApi, DeleteApi, PutApi } from '../../Common/ApiAxios'
import { useForm } from 'react-hook-form'
import ErrorHandling from '../Common/ErrorHandling'
import { InputGroup, HiddenInput } from '../Common/CommonInputGroup'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import { Form, Alert } from '../Common/CommonStyled'
import Button from '../../Common/Atoms/NormalButton'
import { useAlert } from 'react-alert'
import { DefaultUrl } from './CredentialsApikey'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import PropTypes from 'prop-types'
import CreaterInfo from '../../Common/Molecules/CreaterInfo'

const CredentialsApikeyMasterForm = ({ register, errors, isDeleted, canWrite }) => {
  return (
    <MasterDetailWrap isView='true'>
      <ul>
        <li style={{ height: '15px' }}><label /><div /></li>
        {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
        <HiddenInput
          name='credentialsApikeyId'
          type='hidden'
          register={register}
          rule={{}}
          errors={errors}
        />
        <li>
          <label>認証APIキー名称<span style={{ color: 'red' }}>*</span></label>
          <div>
            <InputGroup
              width='320px'
              margin='0'
              name='name'
              placeholder='認証APIキー名称'
              register={register}
              rule={{
                required: '必須項目です',
                maxLength: { value: 50, message: '50文字以内で設定してください' }
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>認証APIキー</label>
          <div>
            <InputGroup
              width='480px'
              margin='0'
              name='apikey'
              placeholder='認証APIキー'
              register={register}
              errors={errors}
              disabled
            />
          </div>
        </li>
        <li style={{ height: 24 }}>
          <label />
          <div>
            <label>
              <input
                type='checkbox'
                ref={register}
                id='refreshToken'
                name='refreshToken'
                disabled={isDeleted || !canWrite}
              />
              認証APIキーを更新する
            </label>
          </div>
        </li>
        <HiddenInput
          name='updatedId'
          register={register}
        />
        <HiddenInput
          name='updatedAt'
          register={register}
        />
        <li style={{ height: '15px' }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

CredentialsApikeyMasterForm.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  isDeleted: PropTypes.bool,
  canWrite: PropTypes.bool
}

const CredentialsApikeyDetails = ({ onSearch, setSelectedId, detail, setDetail, canWrite }) => {
  const { register, handleSubmit, setValue, errors, reset } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isDeleted, setIsDeleted] = useState(false)
  const alert = useAlert()

  useEffect(() => {
    if (actionType === 'POST') {
      setIsDeleted(false)
    }
  }, [actionType])

  // 認証APIキー情報をFormに反映
  useEffect(() => {
    if (detail.id === undefined) {
      setActionType('POST')
      reset()
    } else {
      setValue('credentialsApikeyId', detail.id)
      setValue('name', detail.name)
      setValue('apikey', detail.apikey)
      setValue('createdName', detail.createdName)
      setValue('createdAt', detail.createdAt)
      setValue('updatedName', detail.updatedName)
      setValue('updatedAt', detail.updatedAt)
      setValue('updatedId', detail.updatedId)
      setValue('refreshToken', detail.refreshToken)
      setValue('delFlag', false)
      setActionType('PUT')
    }
  }, [detail, reset, setValue])

  // POST・PUT処理
  const onSubmit = (data, e) => {
    async function sendPost (requestBody) {
      var result
      switch (actionType) {
        case 'POST':
          result = await PostApi(`${DefaultUrl}`, requestBody, true)
          break
        case 'PUT':
          result = await PutApi(`${DefaultUrl}/${requestBody.credentialsApikeyId}`, requestBody, true)
          break
        default:
          result = false
          break
      }
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setDetail({})
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')
      }
    }
    if (actionType === 'PUT') {
      data.credentialsApikeyId && sendPost(data)
    } else {
      sendPost(data)
    }
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = (e) => {
    async function sendDelete (credentialsApikeyId) {
      const result = await DeleteApi(`${DefaultUrl}/${credentialsApikeyId}`, '')
      if (!ErrorHandling(result, alert)) {
        alert.success('削除しました')
        setDetail({})
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')
      }
    }
    if (detail.id !== undefined) {
      sendDelete(detail.id)
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CredentialsApikeyMasterForm
        register={register} errors={errors} isDeleted={isDeleted} canWrite={canWrite}
      />
      <MasterDetailButtons style={{ display: canWrite ? 'flex' : 'none' }}>
        <Button
          name={actionType === 'PUT' ? detail.delFlag ? '有効化' : '更新' : '登録'}
          height='20px'
          width='90px'
          padding='0'
          type='submit'
        />
        {!detail.delFlag && actionType === 'PUT' &&
          <Button
            height='20px'
            width='90px'
            padding='0'
            name='削除'
            type='button'
            className='deleteButton'
            onClick={(e) => onClickHandleDelete(e)}
          />}
      </MasterDetailButtons>
      <CreaterInfo info={detail} />
    </Form>
  )
}

CredentialsApikeyDetails.propTypes = {
  canWrite: PropTypes.bool,
  detail: PropTypes.object,
  setDetail: PropTypes.func,
  onSearch: PropTypes.func,
  setSelectedId: PropTypes.func
}

export default CredentialsApikeyDetails
