/* eslint-env browser */
import React from 'react'
import styled from 'styled-components'
import Hours from './Hours'
import PropTypes from 'prop-types'

const HeaderDiv = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 2;
  background: #111;
  padding-bottom: 3px;
`
const HeaderLeftDiv = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background: #555;
  position: sticky;
  left: 0;
`
const TitleDiv = styled.div`
  width: 150px;
`
const HoursDiv = styled.div`
  width: 50px;
  background: #444;
`
const HeaderRightDiv = styled.div`
  background: #555;
  height: 30px;
  display: flex;
  padding-left: 15px;
  width: 2175px;
`

const Header = (props) => {
  const { title } = props
  return (
    <HeaderDiv>
      <HeaderLeftDiv>
        <TitleDiv>{title}</TitleDiv>
        <HoursDiv>工数</HoursDiv>
      </HeaderLeftDiv>
      <HeaderRightDiv>
        <Hours />
      </HeaderRightDiv>
    </HeaderDiv>
  )
}
Header.propTypes = {
  title: PropTypes.string
}

export default Header
