import React, { useState, useEffect, useCallback } from 'react'
import { GetApi } from '../Common/ApiAxios'
import styled from 'styled-components'
import Search from '../Common/Search'
import { getFirstAndLastDate } from '../Common/utils'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import FUNC from '../Common/FunctionEnum'
import TabSelector from '../Common/Atoms/TabSelector'
import { useAlert } from 'react-alert'
import RangeMonthCalendar from '../Common/Atoms/RangeMonthCalendar'
import DataAnalyzeDetail from './DataAnalyzeDetail'
import StaffInfo from './Component/StaffInfo'

import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'

export const DefaultUrl = '/api/chart'

// <img src={QrIos} alt='iOSダウンロード' style={{ 'object-fit': 'contain' }} />
const FlexDiv = styled.div`
  display:flex;
  flex-wrap: wrap;
  margin-left: 16px;
  h3 {
    line-height: 30px;
    font-size: 16px;
    margin-right: 16px;
  }
  color: #fff;
`

const DataAnalyzeDiv = styled.div`
  flex-direction: column;
  ul {
      list-style:none;
  }
  .buttons {
    flex: 0 0 50px;
    display: flex;
    justify-content: space-between;

    a {
        background: #ddd;
        margin: 20px 40px;
        padding: 10px;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        font-size: 14px;
        min-width: 54px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        color: black;

        :hover {
          box-shadow: 0 1px 1px rgba(0,0,0,0.1);
          background-color: #f8f8f8;
          border: 1px solid #c6c6c6;
          color: #222;
        }
    }
  }
`

const MenuDiv = styled.div`
  display: flex;
  position: relative;
  color: #fff;
  flex-wrap: wrap;
  margin-bottom: 12px;
  padding-left: 16px;
  h3 {
    line-height: 30px;
    font-size: 16px;
    margin-right: 16px;
  }
`

const DataAnalyze = () => {
  const dispatch = useDispatch()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const [isLoading, setLoading] = useState(false)
  const [operationSummaryData, setOperationSummaryData] = useState({ allTotalTime: 0.0, data: [] })
  const [categorySummaryData, setCategorySummaryData] = useState({ allTotalTime: 0.0, data: [] })
  const [stackSummaryData, setStackSummaryData] = useState([])
  const [staffOvertimeSummaryData, setStaffOvertimeSummaryData] = useState({
    customerReason: { toppatsuNum: 0, toppatsuHours: 0.0, yokenNum: 0, yokenHours: 0.0 },
    staffReason: { toppatsuNum: 0, toppatsuHours: 0.0, yokenNum: 0, yokenHours: 0.0 },
    employeeReason: { toppatsuNum: 0, toppatsuHours: 0.0, yokenNum: 0, yokenHours: 0.0 }
  })
  const { companyId, warehouseId, floorId, zoneId } = commonSearch
  const grants = loginUserInfo.menuList &&
  loginUserInfo.menuList.find(menu => menu.functionMenuId === (FUNC.RESULT_LABOR))
  const [firstDate, lastDate] = getFirstAndLastDate(moment().format('YYYY-MM'))
  const [rangeMonth, setRangeMonth] = useState({ fromDate: moment(firstDate).toDate(), toDate: moment(lastDate).toDate() })
  const onChangeRangeCalendar = useCallback((fromDate, toDate) => setRangeMonth({ fromDate, toDate }), [setRangeMonth])
  const [staff, setStaff] = useState()
  const [searchCond, setSearchCond] = useState(0)
  const alert = useAlert()

  const searchCondList = [
    { value: 0, name: '組織' },
    { value: 1, name: '個人' }
  ]

  // 初期化メソッド
  useEffect(() => {
    dispatch(SetLoadSpinner(true))
    var queryString = ''
    if (searchCond === 1) {
      queryString = `?staffId=${staff.staffId || '-1'}`
    } else {
      queryString = `?warehouseId=${warehouseId || ''}&floorId=${floorId || ''}&zoneId=${zoneId || ''}`
    }
    const apiUrl = DefaultUrl + `/operation-summary-data/${moment(rangeMonth.fromDate).format('YYYY-MM-DD')}/${moment(rangeMonth.toDate).format('YYYY-MM-DD')}${queryString}`
    async function getCharts () {
      const data = {}
      const result = await GetApi(apiUrl, data)
      if (result.errorDetail) {
        alert.error('リストの取得に失敗しました')
      } else {
        const operationDataList = { allTotalTime: 0.0, data: [], csvData: [] }
        const categoryDataList = { allTotalTime: 0.0, data: [], csvData: [] }
        const stackDataList = []
        operationDataList.data.push(['operationName', 'totalWorkTime'])
        operationDataList.csvData.push(['工程名', '時間', '%'])

        categoryDataList.data.push(['categoryName', 'totalWorkTime'])
        categoryDataList.csvData.push(['カテゴリー名', '時間', '%'])

        // 総時間の計算
        // 工程
        result.data.operationSummaryList.forEach((row) => {
          operationDataList.allTotalTime += row.totalWorkTime
        })
        result.data.operationSummaryList.forEach((row) => {
          operationDataList.data.push([row.operationName, row.totalWorkTime])
          operationDataList.csvData.push([row.operationName, row.totalWorkTime, Math.round((row.totalWorkTime / operationDataList.allTotalTime) * 1000) / 10])
        })

        // カテゴリー
        result.data.categorySummaryList.forEach((row) => {
          categoryDataList.allTotalTime += row.totalWorkTime
        })
        result.data.categorySummaryList.forEach((row) => {
          categoryDataList.data.push([row.categoryName, row.totalWorkTime])
          categoryDataList.csvData.push([row.categoryName, row.totalWorkTime, Math.round((row.totalWorkTime / categoryDataList.allTotalTime) * 1000) / 10])
        })

        // スタックカラムン
        result.data.stackColumnSummaryList.forEach((row, idx) => {
          var header = ['Date']
          var laborData = []
          row.forEach((d, index) => {
            if (index === 0) {
              laborData.push(d.value)
            } else {
              if (idx === 0) {
                header.push(d.title)
              }
              laborData.push(parseFloat(d.value))
            }
          })
          if (idx === 0) {
            header.push({ role: 'annotation' })
            stackDataList.push(header)
          }
          laborData.push('')
          stackDataList.push(laborData)
        })

        if (!staff && loginUserInfo && loginUserInfo.staffId) {
          setStaff({ staffId: loginUserInfo.staffId, staffName: loginUserInfo.staffName })
        }
        setOperationSummaryData(operationDataList)
        setCategorySummaryData(categoryDataList)
        setStackSummaryData(stackDataList)
        setStaffOvertimeSummaryData(result.data.staffOvertimeReasonSummary)
        setLoading(true)
        dispatch(SetLoadSpinner(false))
      }
    }
    getCharts()
    // eslint-disable-next-line
  }, [companyId, warehouseId, floorId, zoneId, rangeMonth, staff, searchCond, loginUserInfo])
  if (!grants) return null
  return (
    <DataAnalyzeDiv>
      <FlexDiv>
        <h3>工程サマリチャート</h3>
        <TabSelector
          selectValue={searchCond}
          setSelectValue={setSearchCond}
          title=''
          tabsName='searchCondition'
          dataList={searchCondList}
        />
        {/* 共通検索 */}
        {searchCond === 1 &&
          <MenuDiv>
            <StaffInfo staff={staff} setStaff={setStaff} grants={grants} />
            <RangeMonthCalendar
              value={rangeMonth}
              onChange={onChangeRangeCalendar}
              isDateYMD
            />
          </MenuDiv>}
        {searchCond === 0 &&
          <>
            <Search
              title=''
              addStyle='padding: 0 0 0 16px;'
              showButton={false}
              showWarehouses showFloors showZones addAllWarehouse addAllFloor addAllZone
              inSubCompany inSubChildren
              grants={grants}
            >
              <li>
                <label>期間</label>
                <RangeMonthCalendar
                  value={rangeMonth}
                  onChange={onChangeRangeCalendar}
                  isDateYMD
                />
              </li>
            </Search>
          </>}
      </FlexDiv>
      <DataAnalyzeDetail
        operationSummaryData={operationSummaryData}
        categorySummaryData={categorySummaryData}
        stackSummaryData={stackSummaryData}
        staffOvertimeSummaryData={staffOvertimeSummaryData}
        isLoading={isLoading}
        rangeMonth={rangeMonth}
      />

    </DataAnalyzeDiv>
  )
}
export default DataAnalyze
