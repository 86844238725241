import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { red, yellow } from '@material-ui/core/colors'

import { getLegalHours, getDaysCountInMonth } from './helper'
import TabSelector from '../../Common/Atoms/TabSelector'

const searchEmpList = [
  { value: 0, name: '全データ' },
  { value: 1, name: '社員' },
  { value: 2, name: '社員以外' }
]

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '0.8rem'
  }
}))

const TableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { searchEmpCond, setSearchEmpCond, title = '' } = props
  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        {title}
      </Typography>
      <div style={{ padding: '10px 8px 0 0' }}>
        <TabSelector
          selectValue={searchEmpCond}
          setSelectValue={setSearchEmpCond}
          selectedBackColor='#303F9F'
          title=''
          tabsName='searchEmp'
          dataList={searchEmpList}
        />

      </div>
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  title: PropTypes.string
}

/**
 * Header
 * @param {*} props
 */
function SortableTableHead (props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

SortableTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  critical: {
    backgroundColor: red[100]
  },
  warn: {
    backgroundColor: yellow.A100
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

const PaidHolidaysTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff352',
    color: 'rgba(0, 0, 0, 0.75)',
    maxWidth: 220,
    padding: 8,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

/**
 * テーブル本体
 */
export default function SortableTable (props) {
  const { headCells, rows, workDate } = props
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [searchEmpCond, setSearchEmpCond] = useState(0)

  const filterRows = rows => {
    if (searchEmpCond === 0) return rows

    return searchEmpCond === 1
      ? rows.filter(r => r.staffId.length === 7 && r.staffId.substring(0, 1) === '9')
      : rows.filter(r => r.staffId.length !== 7 || r.staffId.substring(0, 1) !== '9')
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          title={workDate.substring(0, 7) + ' 標準労働時間: ' + getLegalHours(getDaysCountInMonth(workDate)) + 'h'}
          searchEmpCond={searchEmpCond}
          setSearchEmpCond={setSearchEmpCond}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
          >
            <SortableTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(filterRows(rows), getComparator(order, orderBy))
                .map(row => {
                  const over40hPerMonth = row.resultHours > row.resultDays * 8 + 40
                  const maybeOver40hPerMonth = row.resultHours > row.resultDays * 10
                  const plannedHolidayList = row.plannedLaborHolidayDateList.map(d => <p key={d}>{d}</p>)
                  const resultHolidayList = row.resultLaborHolidayDateList.map(d => <p key={d}>{d}</p>)
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell align='right' width={100}>{row.staffId}</TableCell>
                      <TableCell align='right' width={130}>{row.name}</TableCell>
                      <TableCell align='right'>{row.plannedHours}</TableCell>
                      <TableCell
                        align='right'
                        className={over40hPerMonth ? classes.critical : maybeOver40hPerMonth ? classes.warn : ''}
                      >
                        {row.resultHours}
                      </TableCell>
                      <TableCell align='right'>{row.remainingHours}</TableCell>
                      <TableCell align='right'>{row.plannedDays}</TableCell>
                      <TableCell align='right'>{row.resultDays}</TableCell>
                      {/* <TableCell align='right'>{row.remainingDays}</TableCell> */}
                      <TableCell align='right'>{row.plannedDayOff}</TableCell>
                      <TableCell align='right'>{row.resultDayOff}</TableCell>
                      <TableCell align='right'>
                        {
                          row.plannedLaborHolidayCnt === 0
                            ? (row.plannedLaborHolidayCnt)
                            : (
                              <PaidHolidaysTooltip title={<span>{plannedHolidayList}</span>} placement='top-end'>
                                <p>{row.plannedLaborHolidayCnt}</p>
                              </PaidHolidaysTooltip>

                            )
                        }

                      </TableCell>
                      <TableCell align='right'>
                        {
                          row.resultLaborHolidayCnt === 0
                            ? (row.resultLaborHolidayCnt)
                            : (
                              <PaidHolidaysTooltip title={<span>{resultHolidayList}</span>} placement='top-end'>
                                <p>
                                  {row.resultLaborHolidayCnt}
                                </p>
                              </PaidHolidaysTooltip>
                            )

                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}
