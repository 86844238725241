import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import styled from 'styled-components'
import { useAlert } from 'react-alert'
import '../../css/Mapping/mapping.scss'

import Floor from '../WhiteBoard/Floor'
import Modal from './Modal'
import FUNC from '../Common/FunctionEnum'
import Staffs from './Staffs'
import TabSelector from '../Common/Atoms/TabSelector'
import SideMenu from '../Common/Molecules/SideMenu'
import TemplateButtons from './TemplateButtons'
import { DragDropContext } from 'react-beautiful-dnd'
import { GetApi, PutApi, DeleteApi, PostApi, exportErrorLog, forceLogout } from '../Common/ApiAxios'
import moment from 'moment'
import Search from '../Common/Search'
// import { setTemplate, setCoreStaffs } from './MappingSlice'
import { setTemplate } from './MappingSlice'
import { getCoreWorkStatus } from './MappingUtil'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
// const Memo = styled.div`
//   background-color: #fff;
//   height: 28px;
// `

const onDragEnd = (result, zones, staffs, setStaffs, setZones, workDate, timeFrameId, loginStaffId) => {
  const { source, destination } = result

  // ドロップ可能エリア外の場合は何もしない
  if (!destination) {
    return
  }

  // ドロップしたエリアが同じ場合は何もしない
  // TODO 配置順を変えて保存 APIの修正も必要
  if (source.droppableId === destination.droppableId) {
    return
  }

  // ドラッグ元エリアスタッフ一覧
  const start = (source.droppableId === 'stafflist')
    ? staffs
    : zones.find(zone => {
      return zone.zoneId.toString() === source.droppableId
    }).staffs

  // ドロップ先エリアスタッフ一覧
  const finish = (destination.droppableId === 'stafflist')
    ? staffs
    : zones.find(zone => {
      return zone.zoneId.toString() === destination.droppableId
    }).staffs

  const srcStaffs = Array.from(start)
  const destStaffs = Array.from(finish)

  console.log(srcStaffs)

  // 移動対象スタッフ
  const movedStaff = srcStaffs.splice(source.index, 1)
  console.log(srcStaffs)

  destStaffs.splice(destination.index, 0, movedStaff[0])
  const updatedZones = zones.slice(0, zones.length)

  const srcZoneIndex = (zone) => zone.zoneId.toString() === source.droppableId
  const destZoneIndex = (zone) => zone.zoneId.toString() === destination.droppableId

  if (source.droppableId === 'stafflist') { // from スタッフリスト to ゾーン
    // 移動したスタッフを除いたスタッフ一覧をセット
    setStaffs(srcStaffs)
    const idx = updatedZones.findIndex(destZoneIndex)
    updatedZones[idx].staffs = destStaffs
    // スタッフリストからゾーンの移動
    const data = {
      staffId: movedStaff[0].staffId,
      zoneId: updatedZones[idx].zoneId,
      workDate: workDate,
      timeFrameId: parseInt(timeFrameId),
      createdId: loginStaffId
    }
    // TODO エラーハンドリング
    PostApi('/api/whiteboard/createPlacement', data, true)
      .then(result => {
        destStaffs[destination.index].relationId = result.data.relationId
        setZones(updatedZones)
      })
  } else {
    const index = updatedZones.findIndex(srcZoneIndex)
    updatedZones[index].staffs = srcStaffs
    if (destination.droppableId === 'stafflist') {
      //
      // from ゾーン to スタッフリスト
      //
      setStaffs(destStaffs)
      const data = {
        relationId: parseInt(movedStaff[0].relationId)
      }
      // ゾーンからスタッフリストの移動
      // TODO エラーハンドリング
      DeleteApi('/api/whiteboard/createPlacement', data)
        .then(result => console.info(result))
    } else {
      //
      // from ゾーン to ゾーン
      //
      const idx = updatedZones.findIndex(destZoneIndex)
      updatedZones[idx].staffs = destStaffs
      setZones(updatedZones)
      const data = {
        staffId: movedStaff[0].staffId,
        zoneId: updatedZones[idx].zoneId,
        workDate: workDate,
        timeFrameId: parseInt(timeFrameId),
        relationId: parseInt(movedStaff[0].relationId),
        updatedId: loginStaffId
      }
      // TODO エラーハンドリング
      PutApi('/api/whiteboard/createPlacement', data, true)
        .then(result => console.info(result))
    }
  }

  return updatedZones
}

const Mapping = (props) => {
  const dispatch = useDispatch()
  // [getter , setter]
  const [canGetTimeFrame, setCanGetTimeFrame] = useState(false)
  const [canGetZoneEndTime, setCanGetZoneEndTime] = useState(false)
  const [canGetPlacementTemplates, setCanGetPlacementTemplates] = useState(false)
  const [canGetZoneStaffs, setCanGetZoneStaffs] = useState(false)
  const [canGetStaffs, setCanGetStaffs] = useState(false)
  const [isModalShow, setIsModalShow] = useState(false)
  const modalTitle = ''
  const modalChild = ''
  // const [timeFrames, setTimeFrames] = useState([])
  const [zones, setZones] = useState([])
  const [staffs, setStaffs] = useState(null)
  // const [coreStaffs, setCoreStaffs] = useState([])
  const [timeFrameRelation, setTimeFrameRelation] = useState(null)
  const [timeFrameInfo, setTimeFrameInfo] = useState([])
  const [timeFrameId, setTimeFrameId] = useState()
  const [zoneEndTime, setZoneEndTime] = useState([])
  // const [templates, setTemplates] = useState([])
  const { loginUserInfo, commonSearch, loadSpinner } = useSelector(state => state)
  const { warehouseId, floorId, workDate } = commonSearch
  const alert = useAlert()

  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.WHITEBOARD_SET)
  const canWrite = grants && (
    grants.writeGrantDivision === 2 ||
    (grants.writeGrantDivision === 1 && warehouseId === loginUserInfo.warehouseId)
  )
  const template = useSelector(state => state.mapping.template)
  // const coreWorkStatus = useSelector(state => state.mapping.coreStaffs)
  // ユーザー一覧取得
  const getStaffs = async (disableStaffList) => {
    if (warehouseId > 0) {
      const apiUrl = '/api/whiteboard/staffs'
      const data = { warehouseId }
      const result = await GetApi(apiUrl, data)
      // TODO flg必要
      const coreStatus = await getCoreWorkStatus(warehouseId, workDate)
      setCanGetStaffs(true)
      if (result.errorDetail) {
        exportErrorLog(result)
        return null
      } else {
        setStaffs(
          result.data.staffs.map(staff => {
            const isStaff = disableStaffList.find(item => item === staff.staffId)
            if (isStaff) return null
            let shift
            if (coreStatus.length) {
              shift = coreStatus.find(obj => {
                return obj.staffId === staff.staffId
              })
              if (shift == null) return null // TODO 現状誰かが出勤しているとその人しか表示されなくなる
              // console.log(staff)
              // console.log(shift)
              // console.log(`

              //     shift.staffName: ${shift.staffName}
              //     shift.shiftStart: ${shift.shiftStart}

              //   `)
              if (shift.shiftStart != null) staff.shiftStart = moment(shift.shiftStart).format('HH:mm')
              if (shift.shiftEnd != null) staff.shiftEnd = moment(shift.shiftEnd).format('HH:mm')
              if (shift.workStatus != null) staff.workStatus = shift.workStatus
            }
            return staff
          })
        )
      }
    } else {
      return null
    }
  }

  // 作業時間帯一覧取得
  const getTimeFrame = async () => {
    if (warehouseId > 0 && floorId > 0) {
      setCanGetTimeFrame(false)
      const apiUrl = '/api/whiteboard/TimeFrameRelation'
      const data = { warehouseId }
      const result = await GetApi(apiUrl, data)
      setCanGetTimeFrame(true)
      if (result.errorDetail) {
        exportErrorLog(result)
        return null
      } else {
        setTimeFrameRelation(result.data)
      }
    }
  }

  const getZoneInfo = async (warehouseId, floorId) => {
    if (floorId > 0) {
      console.log('getZoneInfo')
      console.log(`

          getZoneInfo floorId: ${floorId}

        `)
      const data = { warehouseId: warehouseId, floorId: floorId }
      const result = await GetApi('/api/whiteboard/warehouseZones', data)
      console.log(result.data)
      const floorIndex = result.data.floors.findIndex(floor => floor.floorId === floorId)
      console.log(`

          floorIndex: ${floorIndex}

        `)
      return result.data.floors[floorIndex].zones
    }
  }

  // TODO function分割
  // 配置情報取得
  const getZoneStaffs = async (tartgetTimeFrameId) => {
    if (warehouseId > 0 && floorId > 0 && tartgetTimeFrameId > 0) {
      setCanGetZoneStaffs(false)
      setCanGetStaffs(false)
      const apiUrl = '/api/whiteboard/zoneStaffs'
      const data = {
        warehouseId: warehouseId,
        floorId: floorId,
        workDate: workDate,
        timeFrameId: tartgetTimeFrameId
      }
      const result = await GetApi(apiUrl, data)
      setCanGetZoneStaffs(true)
      const disableStaffList = []
      if (result.errorDetail) {
        exportErrorLog(result)
        return null
      } else {
        setZones(
          result.data.map(data => {
            return data
          })
        )
        // 排除スタッフの一覧を作成
        result.data.forEach(zone => {
          zone.staffs.forEach(staff => {
            // console.log(staff.staffId)
            disableStaffList.push(staff.staffId)
          })
        })
        // スタッフ一覧取得（上記排除スタッフは排除）
        getStaffs(disableStaffList)
      }
    } else {
      return null
    }
  }

  const createZoneEndTime = async (floorId) => {
    // ゾーンのデフォルト終了時間を取得
    console.log('createZoneEndTime')
    console.log(`

    createZoneEndTime floorId: ${floorId}

      `)
    const zones = await getZoneInfo(warehouseId, floorId)
    console.log(zones)

    // eslint-disable-next-line
    zones.map(zone => {
      console.log(zone)
      const data = {
        endTime: zone.endTime,
        targetDate: moment().format('YYYY-MM-DD') // TODO
      }
      PostApi(`/api/whiteboard/zoneEndTime/${zone.zoneId}`, data, true)
        .then(result => {
          if (result.errorDetail) {
            console.error(result.errorDetail)
          } else {
            console.log(result)
          }
        })
    })
    // デフォルト終了時間で当日終了時間を作成
  }

  const getZoneEndTime = async () => {
    if (floorId > 0) {
      setCanGetZoneEndTime(false)
      const apiUrl = `/api/whiteboard/zoneEndTime/${floorId}`
      const data = { targetDate: moment().format('YYYY-MM-DD') }
      const result = await GetApi(apiUrl, data)
      setCanGetZoneEndTime(true)
      if (result.errorDetail) {
        exportErrorLog(result)
        return null
      } else {
        // 当日の終了時間の情報が存在しない場合は作成
        if (result.data.length === 0) {
          createZoneEndTime(floorId)
        } else {
          setZoneEndTime(result.data)
        }
      }
    }
  }

  // // モーダル表示
  // const handleModalShow = () => {
  //   setIsModalShow(true)
  // }

  // モーダル非表示
  const handleModalHide = () => {
    setIsModalShow(false)
  }

  // // 作業時間帯マスタモーダルを表示
  // const setModalWorkingHours = () => {
  //   setModalTitle('作業時間帯マスタ編集')
  //   setModalChild('')
  //   handleModalShow()
  // }

  const handleSelectTimeframe = (index) => {
    // setZones(mappingInfo[index - 1].zones)
    console.log(`

        index: ${index}

      `)
    setTimeFrameId(index - 0)
  }

  const getPlacementTemplates = async () => {
    if (warehouseId > 0) {
      setCanGetPlacementTemplates(false)
      const apiUrl = '/api/whiteboard/templates'
      const data = { warehouseId }
      const result = await GetApi(apiUrl, data)
      setCanGetPlacementTemplates(true)
      if (result.errorDetail) {
        exportErrorLog(result)
        return null
      } else {
        const payload = {
          template: result.data
        }
        dispatch(setTemplate(payload))
        return result.data
      }
    }
  }

  const loginStaffId = useSelector(state => state.loginUserInfo.staffId)

  const onCreate = templateName => {
    console.log(`
    ---------------------
    Template::OnCreate ${templateName}
    ---------------------

    `)
    console.log(zones)
    if (!staffExists(zones)) {
      alert.error('配置されたスタッフが存在しません。')
      return null
    }

    // 登録データ作成
    const templatePlacements = []
    // TODO replace to foreach
    // eslint-disable-next-line
    zones.map(zone => {
    // eslint-disable-next-line
      zone.staffs.map(staff => {
        console.log(staff.staffId, zone.zoneId)
        templatePlacements.push(
          {
            zoneId: zone.zoneId,
            staffId: staff.staffId,
            workDate: workDate,
            timeFrameId: timeFrameId
          }
        )
      })
    })

    const data = {
      templateName: templateName,
      warehouseId: warehouseId,
      templatePlacements: templatePlacements
    }
    // API呼び出し
    PostApi('/api/whiteboard/template', data, true)
      .then(result => {
        if (result.errorDetail) {
          console.log(`

                  result.errorDetail: ${result.errorDetail}

              `)
        } else {
          console.log(result)
          getPlacementTemplates()
          alert.success('テンプレートを登録しました。')
          // TODO 登録成功時にテンプレート一覧を再取得
        }
      })
  }

  const onReflect = async (templateId, from, to) => {
    const start = moment(from)
    const end = moment(to)
    var registDates = []
    while (start.unix() <= end.unix()) {
      console.log(start.format('YYYY-MM-DD'))
      // 日付、作業時間帯、フロア
      console.log(`
        workDate: ${start}
        timeFrame: ${timeFrameId}
        floorId: ${floorId}
    `)
      registDates.push(start.format('YYYY-MM-DD'))

      start.add(1, 'days')
    }

    // 指定日付の配置情報に登録
    console.log(registDates)
    const result = await PostApi('/api/whiteboard/reflectTemplate', {
      templateId: templateId,
      fromDate: from,
      toDate: to
    }, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      if (result.errorDetail.response && result.errorDetail.response.status === 401) {
        forceLogout()
      } else {
        alert.error('取得に失敗しました。')
      }
    } else {
      getZoneStaffs(timeFrameId)
      alert.success('テンプレートを反映しました。')
    }
  }

  const onUpdate = (templateId) => {
    console.log(`

        templateId: ${templateId}

    `)
    // 登録データ作成
    const templatePlacements = []
    // eslint-disable-next-line
    zones.map(zone => {
      // eslint-disable-next-line
      zone.staffs.map(staff => {
        console.log(staff.staffId, zone.zoneId)
        templatePlacements.push(
          {
            zoneId: zone.zoneId,
            staffId: staff.staffId,
            workDate: workDate,
            timeFrameId: timeFrameId
          }
        )
      })
    })

    const data = {
      templateId: templateId,
      warehouseId: warehouseId,
      templatePlacements: templatePlacements
    }
    // API呼び出し
    PutApi('/api/whiteboard/template', data, true)
      .then(result => {
        if (result.errorDetail) {
          console.log(`

                  result.errorDetail: ${result.errorDetail}

              `)
        } else {
          console.log(result)
          getPlacementTemplates()
          alert.success('テンプレートを更新しました。')
          // TODO 登録成功時にテンプレート一覧を再取得
        }
      })
  }

  const onDelete = (templateId) => {
    console.log(`

        templateId: ${templateId}

    `)
    // API呼び出し
    DeleteApi(`/api/whiteboard/template/${templateId}`, null, true)
      .then(result => {
        if (result.errorDetail) {
          console.log(`

                  result.errorDetail: ${result.errorDetail}

              `)
        } else {
          console.log(result)
          getPlacementTemplates()
          alert.success('テンプレートを削除しました。')
          // TODO 登録成功時にテンプレート一覧を再取得
        }
      })
  }

  const staffExists = (zones) => {
    return zones.some(zone => zone.staffs.length > 0)
  }

  useEffect(() => {
    getZoneEndTime()
    // eslint-disable-next-line
  }, [floorId, workDate])

  useEffect(() => {
    getTimeFrame()
    getPlacementTemplates()
    // eslint-disable-next-line
  }, [warehouseId])

  useEffect(() => {
    if (timeFrameRelation != null) {
      const floorIndex = timeFrameRelation.floors.findIndex(floor => parseInt(floor.floorId) === parseInt(floorId))
      if (floorIndex < 0) {
        setZones([])
        setStaffs([])
        alert.error('作業時間帯が登録されていません。')
        return
      }
      let defaultTimeFrameId = null
      setTimeFrameInfo(
        timeFrameRelation.floors[floorIndex].timeFrame.map(data => {
          if (defaultTimeFrameId == null) defaultTimeFrameId = data.timeFrameId
          const ret = { ...data, name: data.timeFrameName, value: data.timeFrameId }
          return ret
        })
      )
      if (defaultTimeFrameId !== timeFrameId || timeFrameId === undefined) {
        setTimeFrameId(defaultTimeFrameId)
      }
    }
    // eslint-disable-next-line
  }, [timeFrameRelation])

  useEffect(() => {
    getZoneStaffs(timeFrameId)
    // eslint-disable-next-line
  }, [timeFrameId, floorId, workDate])

  useEffect(() => {
    if (canGetTimeFrame && canGetZoneEndTime && canGetPlacementTemplates && canGetZoneStaffs && canGetStaffs) {
      dispatch(SetLoadSpinner(false))
    } else {
      dispatch(SetLoadSpinner(true))
    }
    // eslint-disable-next-line
  }, [canGetTimeFrame, canGetZoneEndTime, canGetPlacementTemplates, canGetZoneStaffs, canGetStaffs])

  useEffect(() => {
    document.title = 'ホワイトボード設定 | Core First'
  }, [])

  if (!grants) return null
  // if (coreWorkStatus) {
  //     console.log(`
  //     ============
  //     core work status
  //     ------------
  //     `)
  //     console.log(coreWorkStatus)
  // }
  return (
    <div className='mapping'>
      <Search
        title='ホワイトボード設定' showButton={false} showWarehouses showFloors showCalendar grants={grants}
        addStyle='padding: 0 0 0 16px;'
      />
      {!(loadSpinner.modalIsShow || staffs == null) &&
        <div className='mapping_set'>
          <DragDropContext onDragEnd={result => {
            onDragEnd(result, zones, staffs, setStaffs, setZones, workDate, timeFrameId, loginStaffId)
          }}
          >
            <div className='mapping_set_left'>
              <div className='mapping_set_left_working_hours'>
                {/* 作業時間帯 */}
                <TabSelector
                  selectValue={timeFrameId}
                  setSelectValue={handleSelectTimeframe}
                  title='作業時間帯'
                  tabsName='registers'
                  dataList={timeFrameInfo}
                />
                {isModalShow && (
                  <Modal onClose={handleModalHide} title={modalTitle}>
                    {modalChild}
                  </Modal>
                )}
              </div>
              <Floor
                floorId={floorId}
                zones={zones}
                zoneEndTime={zoneEndTime}
                getZoneEndTime={getZoneEndTime}
                canWrite={canWrite}
              />
              {canWrite && (
                <div style={{ width: 'calc(100vw - 400px)' }}>
                  <TemplateButtons
                    templates={template}
                    onCreate={onCreate}
                    onReflect={onReflect}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                </div>
              )}
            </div>
            {canWrite && (
              <SideMenu
                isView
                width='425px'
              >
                <div className='mapping_set_right'>
                  <Staffs staffsJson={staffs} getZoneStaffs={getZoneStaffs} timeFrameId={timeFrameId} />
                </div>
              </SideMenu>
            )}
          </DragDropContext>
        </div>}
    </div>
  )
}

export default Mapping
