import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styled from 'styled-components'
registerLocale('ja', ja)

const WrapDiv = styled.div.attrs(props => ({
  width: props.width || '100px',
  height: props.height || '48px',
  margin: props.margin || '0px',
  textIndent: props.textIndent || '10px'
}))`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  > div {
    position: absolute;
    height: ${props => props.height};
    input {
      height: ${props => props.height};
      width: ${props => props.width};
      text-indent: ${props => props.textIndent};
      border-color: hsl(0,0%,80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
    }
  }
`

const Calendar = (props) => {
  const { dataValue, setDateValue, placeholder, width, height, margin, prefix, disabled } = props

  const toDate = (dataValue) => {
    return dataValue && (new Date(dataValue.substring(0, 4), dataValue.substring(5, 7) - 1, dataValue.substring(8, 10)))
  }

  const changeDateState = (date) => {
    if (!date) {
      setDateValue(null)
      return null
    }
    const dateStr = date.getFullYear() + `${prefix || '-'}` + ('00' + (date.getMonth() + 1)).slice(-2) + `${prefix || '-'}` + ('00' + date.getDate()).slice(-2)
    if (dataValue !== dateStr) {
      setDateValue(dateStr)
    }
  }

  return (
    <WrapDiv width={width} height={height} margin={margin}>
      <DatePicker
        locale='ja'
        selected={toDate(dataValue)}
        onChange={date => changeDateState(date)}
        value={dataValue}
        placeholderText={placeholder}
        disabled={disabled}
      />
    </WrapDiv>
  )
}

const MonthPickerWrapDiv = styled(WrapDiv)`
.react-datepicker-popper {
    z-index: 300;
    width: 200px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 280px;
    height: 175px;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-wrapper {
    flex: 1;
  }
  .react-datepicker__month .react-datepicker__month-text {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__header {
    height: 30px;
  }
  input {
    background-color: ${({ isError }) => isError && 'pink'};
  }
`

export const MonthPicker = (props) => {
  const { dataValue, setDateValue, placeholder, width, height, margin, prefix, disabled, isError } = props

  const toDate = (dataValue) => {
    return dataValue && (new Date(dataValue.substring(0, 4), dataValue.substring(5, 7) - 1, 1))
  }

  const changeDateState = useCallback((date) => {
    if (!date) {
      setDateValue(null)
      return null
    }
    const dateStr = date.getFullYear() + `${prefix || '/'}` + ('00' + (date.getMonth() + 1)).slice(-2)
    setDateValue(dateStr)
  }, [prefix, setDateValue])

  return (
    <MonthPickerWrapDiv width={width} height={height} margin={margin} isError={isError}>
      <DatePicker
        locale='ja'
        selected={toDate(dataValue)}
        onChange={date => changeDateState(date)}
        value={dataValue}
        placeholderText={placeholder}
        dateFormat='yyyy/MM'
        disabled={disabled}
        showMonthYearPicker
      />
    </MonthPickerWrapDiv>
  )
}

MonthPicker.propTypes = {
  dataValue: PropTypes.string,
  setDateValue: PropTypes.func,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.bool
}

Calendar.propTypes = {
  dataValue: PropTypes.string,
  setDateValue: PropTypes.func,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool
}

export default Calendar
