import styled from 'styled-components'

const MasterDetailWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 72vw;

  button {
    margin: 0 1vw 12px 0;
    :last-child {
      margin: 0 0 12px 0;
    }

  }
`
export default MasterDetailWrap
