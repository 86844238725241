import React, { useState, useEffect, useRef, useCallback } from 'react'
import { GetApi, exportErrorLog } from '../../Common/ApiAxios'
import Search from '../../Common/Search'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import RoleDetails from './RoleDetails'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import ErrorHandling from '../Common/ErrorHandling'
import Input from '../../Common/Atoms/Input'

export const defaultUrl = '/api/master/role'

// メインコンポーネント
const RoleMaster = () => {
  // 共通変数
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState({})
  const alert = useAlert()
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState({})

  // 検索条件管理
  const idRef = useRef()
  const nameRef = useRef()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const { companyId, warehouseId } = commonSearch

  // 検索API呼び出し
  const apiGetRole = async (createId, pageId) => {
    dispatch(SetLoadSpinner(true))
    const data = {
      companyId: companyId,
      warehouseId: (grants.readGrantDivision === 1 || grants.readGrantDivision === 3)
        ? loginUserInfo.warehouseId : warehouseId === -1 ? null : warehouseId,
      roleId: idRef.current.value,
      roleName: nameRef.current.value,
      createId: null,
      page: pageId
    }
    const result = await GetApi(defaultUrl, data)
    if (ErrorHandling(result, alert)) {
      exportErrorLog(result)
      return null
    } else {
      const resultList = result.data.responseList.map(item => ({ id: item.roleId, name: item.roleName }))
      setDataList(resultList)
      setPagination(result.data)
      const isSelected = resultList.find(item => item.id === (createId || selectedId))
      !isSelected && createNew()
    }
    dispatch(SetLoadSpinner(false))
  }

  // 検索イベントハンドラ
  const onSearch = (createId) => {
    apiGetRole(createId, 1)
  }
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      apiGetRole(null, 1)
    }
  }
  const pageSelectFunc = (pageId) => {
    apiGetRole(null, pageId)
  }

  // ListView 状態管理
  const [selectedId, setSelectedId] = useState()
  // 詳細情報取得API呼び出し
  const selectItem = useCallback(async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${defaultUrl}/${id}`)
    ErrorHandling(result, alert) || setDetail(result.data)
    dispatch(SetLoadSpinner(false))
  }, [dispatch, setDetail, setSelectedId, alert])
  // 新規作成ボタンクリックイベント
  const createNew = useCallback(() => {
    setDetail({})
    setSelectedId(null)
  }, [setDetail, setSelectedId])

  // 会社情報管理
  const [companyList, setCompanyList] = useState([]) // ゾーンまでそろっている会社情報取得
  // API呼び出し
  const onCompanyInfoSearch = useCallback(async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 1 // 会社-倉庫が欠損なしのレコード
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return
    if (!(result && result.data && result.data.length)) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }, [alert, setCompanyList, dispatch])
  // 取得イベント
  useEffect(() => { loginUserInfo && loginUserInfo.companyId && onCompanyInfoSearch() }, [loginUserInfo, onCompanyInfoSearch])

  useEffect(() => {
    document.title = '役割マスタ | Core First'
  }, [])

  // 権限管理
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ROLE)
  if (!grants) return null
  return (
    <MasterWrap>
      <Search
        title='役割マスタ' onClick={onSearch}
        showWarehouses
        addAllWarehouse
        showButton
        grants={grants}
      >
        <li>
          <label>ID</label>
          <Input
            type='text' name='id' inputRef={idRef} onKeyDown={(e) => onKeyDown(e)}
            disabled={grants.readGrantDivision === 3}
          />
        </li>
        <li>
          <label>名称</label>
          <Input
            type='text' name='roleName' inputRef={nameRef} onKeyDown={(e) => onKeyDown(e)}
            disabled={grants.readGrantDivision === 3}
          />
        </li>
      </Search>
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
        />
        <RoleDetails
          companyList={companyList}
          onSearch={onSearch}
          setSelectedId={setSelectedId}
          detail={detail}
          setDetail={setDetail}
          canWrite={
            grants.writeGrantDivision === 2 ||
            (grants.writeGrantDivision === 1 && ((detail.warehouseId === loginUserInfo.warehouseId) || !detail.warehouseId))
          }
          grants={grants}
        />
      </main>
    </MasterWrap>
  )
}

export default RoleMaster
