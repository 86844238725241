import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ListView from '../../Common/Atoms/ListView'
import Pagination from '../../Common/Atoms/Pagination'

const ListWrap = styled.div`
  width: 17vw;
  margin-right: 1vw;
  margin-bottom: 12px;
`

const StaffList = (props) => {
  const {
    dataList, selectedId, selectFunction, isIdView, listHeight, pageSelectFunc, pagination
  } = props

  const style = `
    max-height: calc(100vh - 230px);
    overflow-y: auto;
    margin-top: 45px;
  `

  return (
    <ListWrap>
      <ListView
        dataList={dataList} selectedId={selectedId} selectFunction={selectFunction}
        isIdView={isIdView} height={listHeight} addStyle={style} pagination={pagination}
      />
      {dataList && dataList.length > 0 && pagination &&
        <Pagination pagination={pagination} pageSelectFunc={pageSelectFunc} addStyle='margin-top: 24px;' />}
    </ListWrap>
  )
}

StaffList.propTypes = {
  dataList: PropTypes.array,
  selectedId: PropTypes.any,
  selectFunction: PropTypes.func,
  isIdView: PropTypes.bool,
  listHeight: PropTypes.string,
  pageSelectFunc: PropTypes.func,
  pagination: PropTypes.object
}

export default StaffList
