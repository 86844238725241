import { DateTime } from 'luxon'

export function getCsvFileName (exportType, startDate, endDate) {
  const fromDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
  const toDate = DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')

  let prefix = ''
  switch (exportType) {
    case 0:
      prefix = 'レイバー情報'
      break

    case 1:
      prefix = '勤怠情報'
      break
    case 2:
      prefix = '残業アラート情報'
      break
    case 3:
      prefix = '給与奉行CSV'
      break
    default:
      break
  }

  return `${prefix}_${fromDate}-${toDate}.csv`
}

export function getMonthStr (dateStr) {
  const arr = dateStr.split('-')

  switch (arr.length) {
    case 3: { // yyyy-MM-dd
      const pos = dateStr.lastIndexOf('-')

      return dateStr.substring(0, pos)
    }
    case 2: // yyyy-MM
      return dateStr
    default:
      return ''
  }
}
