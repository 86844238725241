
export const headCells = [
  { id: 'staffId', numeric: false, disablePadding: true, label: 'ID', width: 50 },
  { id: 'staffName', numeric: false, disablePadding: true, label: '氏名', width: 120 },
  { id: 'month04', numeric: true, disablePadding: true, label: '4月', width: 75 },
  { id: 'month05', numeric: true, disablePadding: true, label: '5月', width: 75 },
  { id: 'month06', numeric: true, disablePadding: true, label: '6月', width: 75 },
  { id: 'month07', numeric: true, disablePadding: true, label: '7月', width: 75 },
  { id: 'month08', numeric: true, disablePadding: true, label: '8月', width: 75 },
  { id: 'month09', numeric: true, disablePadding: true, label: '9月', width: 75 },
  { id: 'month10', numeric: true, disablePadding: true, label: '10月', width: 75 },
  { id: 'month11', numeric: true, disablePadding: true, label: '11月', width: 75 },
  { id: 'month12', numeric: true, disablePadding: true, label: '12月', width: 75 },
  { id: 'month01', numeric: true, disablePadding: true, label: '1月', width: 75 },
  { id: 'month02', numeric: true, disablePadding: true, label: '2月', width: 75 },
  { id: 'month03', numeric: true, disablePadding: true, label: '3月', width: 75 },
  { id: 'total', numeric: true, disablePadding: true, label: '合計', width: 100 }
]
