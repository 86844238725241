import React, { useState, useRef, useEffect, useCallback, typeRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useAlert } from 'react-alert'
import styled from 'styled-components'

import MasterWrap from '../../Common/Wrap/MasterWrap'
import Search from '../../Common/Search'
import { exportErrorLog, GetApi, PutApi } from '../../Common/ApiAxios'
import FUNC from '../../Common/FunctionEnum'
import ErrorHandling from '../Common/ErrorHandling'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import OperationDetails from './OperationDetails'
import Input from '../../Common/Atoms/Input'
import Select from '../../Common/Atoms/Select'
import OperationItem from './OperationItem'
import Button from '../../Common/Atoms/NormalButton'
import Pagination from '../../Common/Atoms/Pagination'
export const DefaultUrl = '/api/master/operation'

const OperationListContainer = styled.div`
  width: 500px;
  padding: 0 8px;
`
const OperationList = styled.div`
  padding: 0 8px;
`
const ListHeader = styled.div`
  width: 15vw;
  height: 48px;
  /* margin-bottom: 12px; */
  display: flex;
  justify-content: space-between;
`
const CountLabel = styled.label`
  color: white;
  height: 48px;
  line-height: 48px;
  /* margin-bottom: 12px; */
`
const zeroPadding = (s, digit) => {
  return ('0'.repeat(digit) + s).slice(digit * -1)
}

const buildDateTime = (dtObj) => {
  const year = dtObj.year
  const month = zeroPadding(dtObj.monthOfYear.toString(), 2)
  const day = zeroPadding(dtObj.dayOfMonth.toString(), 2)
  const hour = zeroPadding(dtObj.hourOfDay.toString(), 2)
  const minute = zeroPadding(dtObj.minuteOfHour.toString(), 2)
  const second = zeroPadding(dtObj.secondOfMinute.toString(), 2)

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}
const buildPutData = (ope) => {
  return {
    operationName: ope.name,
    sort: ope.sort,
    type: ope.type,
    zoneId: ope.zoneId,
    categoryId: ope.categoryId,
    delFlag: false,
    updatedAt: ope.updatedAt
  }
}
const DndList = ({ dataList, setDataList, selectedId, selectFunction, onSearch }) => {
  const dispatch = useDispatch()
  const alert = useAlert()
  async function UpdateOperation (id, data) {
    dispatch(SetLoadSpinner(true))
    const result = await PutApi(`${DefaultUrl}/${id}`, data, true)

    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      exportErrorLog(result)
      alert.error('保存できませんでした')
    }
    // onSearch(null)
  }

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    // 位置が変わっていない場合は何もしない
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) return
    const target = dataList.filter(d => 'id' + d.id === draggableId)
    if (target.length === 0) return
    const targetOperation = target[0]
    console.log(targetOperation)
    // reorder
    const newOpeIds = Array.from(dataList)
    newOpeIds.splice(source.index, 1)
    newOpeIds.splice(destination.index, 0, targetOperation)

    // ソート順をリストに格納
    const sortList = newOpeIds.map(item => Number(item.sort))
    sortList.sort((a, b) => a - b)
    console.log(sortList)
    for (const o in newOpeIds) {
      newOpeIds[o].sort = sortList[o]
    }
    // console.log(newOpeIds)
    setDataList(newOpeIds)

    // 変更のあったソート順のみPUT
    newOpeIds
      .filter(n => n.sort !== n.orgSort)
      .map(n => {
        // if (n.sort !== n.orgSort) {
        // TODO promese.allに変更
        UpdateOperation(n.id, buildPutData(n))
        // }
      })
    alert.success('保存しました')
  }
  // console.log(selectedId)
  return (
    <DragDropContext
      onDragEnd={onDragEnd}
    >
      <OperationListContainer>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <OperationList
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {dataList.map((d, index) => (
                <OperationItem key={'id' + d.id} oid={'id' + d.id} item={d} index={index} selected={d.id === selectedId} selectFunction={selectFunction} />
              ))}
              {provided.placeholder}
            </OperationList>

          )}
        </Droppable>
      </OperationListContainer>
    </DragDropContext>
  )
}

// メインコンポーネント
const DndOperationMaster = () => {
  const operationIdRef = useRef()
  const operationNameRef = useRef()
  const typeRef = useRef()
  const categoryIdRef = useRef()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const { companyId, warehouseId, floorId, zoneId } = commonSearch
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState({})
  const [selectedId, setSelectedId] = useState()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.OPERATION)
  // const canWrite = grants && grants.writeGrantDivision === 2
  const alert = useAlert()
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([]) // カテゴリー一覧取得
  const [companyList, setCompanyList] = useState([]) // ゾーンまでそろっている会社情報取得
  const [pagination, setPagination] = useState({})
  const [typeSearchList, setSearchTypeList] = useState([]) // 工程種別一覧取得
  const [typeOptions, setTypeOptions] = useState([])
  const selectItem = async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${DefaultUrl}/${id}`)
    dispatch(SetLoadSpinner(false))
    ErrorHandling(result, alert) || setDetail(result.data)
  }

  const createNew = () => {
    setDetail({})
    setSelectedId(null)
  }

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getType()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 工程種別取得
  const getType = useCallback(async () => {
    const result = await GetApi('/api/master/classification/operationType')
    if (Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setSearchTypeList(result.data.values)
      setTypeOptions(options)
    }
  }, [setTypeOptions])

  const getOperations = async (createId, pageId) => {
    dispatch(SetLoadSpinner(true))
    const data = {
      companyId: companyId,
      warehouseId: warehouseId,
      floorId: floorId,
      zoneId: zoneId,
      categoryId: categoryIdRef.current.value,
      operationId: operationIdRef.current.value,
      operationName: operationNameRef.current.value,
      type: typeRef.current.value,
      page: pageId
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      const resultList = result.data.responseList.map(item => {
        return {
          id: item.operationId,
          name: item.operationName,
          orgSort: item.sort,
          sort: item.sort,
          type: item.type,
          zoneId: item.zoneId,
          categoryId: item.categoryId,
          categoryName: item.categoryName,
          updatedAt: buildDateTime(item.updatedAt)
        }
      })

      setDataList(resultList)
      setPagination(result.data)
      const isSelected = resultList.find(item => item.id === (createId || selectedId))
      if (!isSelected) {
        setSelectedId(null)
        setDetail({})
      }
    }
  }

  const onSearch = (createId) => {
    getOperations(createId, 1)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      getOperations(null, 1)
    }
  }
  const pageSelectFunc = (pageId) => {
    getOperations(null, pageId)
  }

  // カテゴリー一覧
  const onCategorySearch = async () => {
    dispatch(SetLoadSpinner(true))
    const result = await GetApi('/api/master/category', null)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    const resultList = result.data.responseList.map(item => {
      return {
        id: item.categoryId,
        name: item.categoryName
      }
    })
    setCategoryList(resultList)
  }

  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 3 // 会社-倉庫-フロア-ゾーンが欠損なしのレコード
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    if (result.data.length === 0) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }

  useEffect(() => {
    onCategorySearch()
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  // ログインユーザー情報変更検知時のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo])

  useEffect(() => {
    document.title = '工程マスタ | Core First'
  }, [])
  // console.log(dataList)
  if (!grants) return null
  const canWrite = grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1
  return (
    <MasterWrap>
      <Search
        title='工程マスタ' onClick={onSearch}
        showWarehouses showFloors showZones addAllWarehouse addAllFloor addAllZone showButton
        inSubCompany inSubChildren
        grants={grants}
      >
        <li>
          <label>カテゴリー</label>
          <Select name='categoryId' selectRef={categoryIdRef}>
            <option value='-1'>全て</option>
            {categoryList && categoryList.map(item => {
              return (
                <option key={item.id} value={item.id}>{item.name}</option>
              )
            })}
          </Select>
        </li>
        <li>
          <label>ID</label>
          <Input type='text' name='operationId' inputRef={operationIdRef} onKeyDown={(e) => onKeyDown(e)} />
        </li>
        <li>
          <label>名称</label>
          <Input type='text' name='operationName' inputRef={operationNameRef} onKeyDown={(e) => onKeyDown(e)} />
        </li>
        <li>
          <label>工程種別</label>
          <Select name='type' selectRef={typeRef}>
            <option value='0'>全て</option>
            {typeSearchList && typeSearchList.map(item => {
              return (
                <option key={item.valueId} value={item.valueId}>{item.valueName}</option>
              )
            })}
          </Select>
        </li>
      </Search>
      <main>
        {/* <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
          style={{ flex: 1 }}
        /> */}
        <div>
          <ListHeader>
            <Button name='新規' margin='0' padding='0' display={canWrite ? 'block' : 'none'} onClick={createNew} />
            <CountLabel>{pagination && pagination.allRecordCount ? '全 ' + pagination.allRecordCount + '件' : '0件'}</CountLabel>
          </ListHeader>
          <DndList
            dataList={dataList}
            selectedId={selectedId}
            setDataList={setDataList}
            selectFunction={(id) => selectItem(id)}
            setDetail={setDetail}
            setSelectedId={setSelectedId}
            onSerch={onSearch}
            style={{ flex: 1 }}
          />
          {dataList && dataList.length > 0 && pagination &&
            <Pagination
              pagination={pagination} pageSelectFunc={pageSelectFunc}
              addStyle='margin-top: 16px; justify-content: center; width: 100%'
            />}

        </div>
        <OperationDetails
          companyList={companyList}
          categoryList={categoryList}
          onCategorySearch={() => onCategorySearch()}
          typeList={typeOptions}
          detail={detail}
          setSelectedId={setSelectedId}
          setDetail={setDetail}
          onSearch={onSearch}
          canWrite={
            grants.writeGrantDivision === 2 ||
            (grants.writeGrantDivision === 1 && ((detail.warehouseId === loginUserInfo.warehouseId) || !detail.warehouseId))
          }
          grants={grants}
          style={{ flex: 1 }}
        />
      </main>
    </MasterWrap>
  )
}

export default DndOperationMaster
