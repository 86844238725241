/* eslint-env browser */
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { PostApi, exportErrorLog, forceLogout } from './ApiAxios'
import { SetLoadSpinner } from './Slice/LoadSpinnerSlice'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { InputGroup } from '../Master/Common/CommonInputGroup'
import NormalButton from './Atoms/NormalButton'
import * as regex from './Regex'
import { clearAuthCookie } from './utils'

const Wrap = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 20vh);
        flex-flow: column;
        button {
          margin: 12px auto 0 auto;
        }
    `

const Row = styled.label`
        display:flex;
        margin-bottom:24px;
        input {
            text-indent:5px;
        }
    `

const Label = styled.label`
        width:200px;
        display: block;
        color: white;
        margin-right:20px;
        line-height: 48px;
    `

function ChanagePassword (props) {
  const alert = useAlert()
  const dispatch = useDispatch()

  // react-hook-form 関連
  const { register, handleSubmit, errors, watch } = useForm({ validateCriteriaMode: 'all', reValidateMode: 'onChange' })
  const onSubmit = useCallback(async (data, event) => {
    dispatch(SetLoadSpinner(true))
    event.preventDefault()
    const apiUrl = '/api/changePassword'
    const result = await PostApi(apiUrl, data)
    if (result.errorDetail) {
      dispatch(SetLoadSpinner(false))
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else {
        alert.error('更新に失敗しました。')
      }
    } else {
      dispatch(SetLoadSpinner(false))
      alert.success('更新しました。')
      clearAuthCookie()
      window.location.href = '/login'
    }
  }, [dispatch, alert])

  return (
    <Wrap>
      <form id='passwordForm' onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Label>現在のパスワード</Label>
          <InputGroup
            autoFocus
            width='320px'
            margin='0'
            type='password'
            name='password'
            register={register}
            rule={{
              required: regex.required,
              maxLength: regex.maxLength(32)
            }}
            maxLength='32'
            errors={errors}
          />
        </Row>
        <Row>
          <Label>新しいパスワード</Label>
          <InputGroup
            width='320px'
            margin='0'
            type='password'
            name='newPassword'
            register={register}
            rule={{
              required: regex.required,
              maxLength: regex.maxLength(32)
            }}
            maxLength='32'
            errors={errors}
          />
        </Row>
        <Row>
          <Label>新しいパスワード(確認)</Label>
          <InputGroup
            width='320px'
            margin='0'
            type='password'
            name='confirmationPassword'
            register={register}
            rule={{
              required: regex.required,
              maxLength: regex.maxLength(32),
              validate: {
                confirm: value => value === watch('newPassword') || 'パスワードが一致しません'
              }
            }}
            maxLength='32'
            errors={errors}
          />
        </Row>
        <NormalButton type='submit' name='変更' />
      </form>
    </Wrap>
  )
}

export default ChanagePassword
