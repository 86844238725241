import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const today = new Date()

const initialState = {
  companyId: null, // 会社ID
  warehouseId: null, // 倉庫ID
  floorId: null, // フロアID
  zoneId: null, // ゾーンID
  workDate: today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2), // 対象日
  includeRetired: null // 退職者含むフラグ
}

const CommonSearchSlice = createSlice({
  name: 'commonSearch',
  initialState,
  reducers: {

    // 対象日セット
    SetWorkDate: (state, action) => {
      state.workDate = action.payload
    },

    // 退職者含フラグセット
    SetIncludeRetired: (state, action) => {
      state.includeRetired = action.payload
    },

    SetCommonSearch: (state, action) => {
      const { payCompanyId, payWarehouseId, payFloorId, payZoneId, payWorkDate, payIncludeRetired } = action.payload
      state.companyId = payCompanyId
      state.warehouseId = payWarehouseId
      state.floorId = payFloorId
      state.zoneId = payZoneId
      state.workDate = payWorkDate
      state.includeRetired = payIncludeRetired
    },

    reset: (state, action) => {
      state.companyId = null
      state.warehouseId = null
      state.floorId = null
      state.zoneId = null
      state.workDate = null
      state.includeRetired = null
    }

  }
})

export const CommonSearchStates = () => {
  return useSelector((state) => state.commonSearch)
}

export const {
  SetWorkDate,
  SetCommonSearch,
  SetIncludeRetired,
  reset
} = CommonSearchSlice.actions

export default CommonSearchSlice.reducer
