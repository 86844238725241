// ガントチャートの描写動作部分
import { CreateName, isOverlap, Enum, mouseXToTaskIndex } from './Commons'

// 登録描画
export const newDraw = (event, mousePixel, startIndex, editingItem, setEditingItem, newItemRef, selectOperation, rowRef, labors) => {
  if (event.button !== 0) return null
  const mouseIndex = mouseXToTaskIndex(mousePixel)
  // 右向き
  if (mouseIndex >= startIndex) {
    editingItem.startIndex = startIndex
    editingItem.startPixel = startIndex * Enum.COLUMN_WIDTH
    editingItem.endIndex = (mouseIndex + 1 > Enum.MAX_INDEX) ? Enum.MAX_INDEX : mouseIndex + 1
    editingItem.endPixel = (mouseIndex + 1 > Enum.MAX_INDEX) ? Enum.MAX_INDEX * Enum.COLUMN_WIDTH : (mouseIndex + 1) * Enum.COLUMN_WIDTH
  } else {
    // 左向き
    editingItem.endIndex = startIndex + 1
    editingItem.endPixel = (startIndex + 1) * Enum.COLUMN_WIDTH
    editingItem.startIndex = (mouseIndex < Enum.MIN_INDEX) ? Enum.MIN_INDEX : mouseIndex
    editingItem.startPixel = mouseIndex * Enum.COLUMN_WIDTH
  }
  setEditingItem(editingItem)
  newItemRef.current.style.display = 'block'
  newItemRef.current.style.left = editingItem.startPixel + Enum.PADDING_LEFT + 'px'
  newItemRef.current.style.width = (editingItem.endIndex * Enum.COLUMN_WIDTH) - (editingItem.startIndex * Enum.COLUMN_WIDTH) + 'px'
  newItemRef.current.style.background = editingItem.backColor
  newItemRef.current.textContent = CreateName(editingItem.endIndex, editingItem.startIndex, selectOperation.operationName)
  rowRef.current.style.cursor = isOverlap(editingItem, labors) ? Enum.CURSOR_NO_DROP : Enum.CURSOR_POINTER
}

// 移動描画
export const moveDraw = (event, mousePixel, editingItem, setEditingItem, editItemRef, startDiffIndex, endDiffIndex, rowRef, labors) => {
  if (event.button !== 0) return null
  const mouseIndex = mouseXToTaskIndex(mousePixel)
  const moveRecord = Object.assign({}, editingItem)
  if (editItemRef.current[moveRecord.index] == null) return null
  if (mouseIndex - startDiffIndex >= 0 && mouseIndex + endDiffIndex <= Enum.MAX_INDEX) {
    // キャンバスの範囲内
    moveRecord.startIndex = mouseIndex - startDiffIndex
    moveRecord.endIndex = mouseIndex + endDiffIndex
  } else if (mouseIndex - startDiffIndex < 0 && mouseIndex + endDiffIndex <= Enum.MAX_INDEX) {
    // キャンバスより外には動かせない（左）
    const recordDiff = moveRecord.endIndex - moveRecord.startIndex
    moveRecord.startIndex = 0
    moveRecord.endIndex = recordDiff
  } else if (mouseIndex - startDiffIndex >= 0 && mouseIndex + endDiffIndex > Enum.MAX_INDEX) {
    // キャンバスより外には動かせない（右）
    const recordDiff = moveRecord.endIndex - moveRecord.startIndex
    moveRecord.startIndex = Enum.MAX_INDEX - recordDiff
    moveRecord.endIndex = Enum.MAX_INDEX
  }
  moveRecord.startPixel = moveRecord.startIndex * Enum.COLUMN_WIDTH
  moveRecord.endPixel = moveRecord.endIndex * Enum.COLUMN_WIDTH
  editItemRef.current[moveRecord.index].current.style.left = moveRecord.startPixel + Enum.PADDING_LEFT + 'px'
  setEditingItem(moveRecord)
  rowRef.current.style.cursor = isOverlap(moveRecord, labors) ? Enum.CURSOR_NO_DROP : Enum.CURSOR_POINTER
}

// リサイズ描画
export const resizeDraw = (event, mousePixel, editingItem, setEditingItem, editItemRef, rowRef, beforeItem, labors) => {
  if (event.button !== 0) return null
  const mouseIndex = mouseXToTaskIndex(mousePixel)
  const resizeRecord = Object.assign({}, editingItem)
  if (editItemRef.current[resizeRecord.index] == null) return null
  const cursor = rowRef.current.style.cursor
  if (cursor === Enum.CURSOR_W_RESIZE) { // 左端をつかんでいる
    if (mousePixel < beforeItem.endPixel) {
      if (mouseIndex >= 0) {
        resizeRecord.startIndex = mouseIndex
      } else {
        resizeRecord.startIndex = 0
      }
    } else {
      if (mouseIndex + 1 <= Enum.MAX_INDEX && mouseIndex === beforeItem.endIndex) {
        resizeRecord.startIndex = beforeItem.endIndex
        resizeRecord.endIndex = mouseIndex + 1
      } else if (mouseIndex < Enum.MAX_INDEX) {
        resizeRecord.startIndex = beforeItem.endIndex
        resizeRecord.endIndex = mouseIndex
      } else {
        resizeRecord.startIndex = beforeItem.endIndex
        resizeRecord.endIndex = Enum.MAX_INDEX
      }
    }
  } else if (cursor === Enum.CURSOR_E_RESIZE) { // 右端をつかんでいる
    if (mousePixel >= beforeItem.startPixel) {
      if (mouseIndex + 1 <= Enum.MAX_INDEX && mouseIndex === resizeRecord.startIndex) {
        resizeRecord.endIndex = mouseIndex + 1
      } else if (mouseIndex <= Enum.MAX_INDEX) {
        resizeRecord.endIndex = mouseIndex
      } else {
        resizeRecord.endIndex = Enum.MAX_INDEX
      }
    } else {
      if (mouseIndex >= 0) {
        resizeRecord.endIndex = beforeItem.startIndex
        resizeRecord.startIndex = mouseIndex
      } else {
        resizeRecord.endIndex = beforeItem.startIndex
        resizeRecord.startIndex = 0
      }
    }
  }
  resizeRecord.startPixel = resizeRecord.startIndex * Enum.COLUMN_WIDTH
  resizeRecord.endPixel = resizeRecord.endIndex * Enum.COLUMN_WIDTH
  editItemRef.current[beforeItem.index].current.style.left = resizeRecord.startPixel + Enum.PADDING_LEFT + 'px'
  editItemRef.current[beforeItem.index].current.style.width = (resizeRecord.endPixel - resizeRecord.startPixel) + 'px'
  editItemRef.current[beforeItem.index].current.textContent = CreateName(resizeRecord.endIndex, resizeRecord.startIndex, resizeRecord.operationName)
  setEditingItem(resizeRecord)
  rowRef.current.style.cursor = isOverlap(resizeRecord, labors) ? Enum.CURSOR_NO_DROP : cursor
}
