
/* eslint-env browser */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const QuartersDiv = styled.div`
  display: flex;
  min-width: 60px;
  max-width: 60px;
  height: 50px;
  border-left: 2px solid grey;
  box-sizing: border-box;
  div {
    &:nth-child(odd) {
      background: ${props => props.allUpdate ? '#d0e8da' : '#eee'}
    }
    ${props => props.first && (props.isHoliday ? css`
      &:first-child {
        background: rgba(235, 110, 160, 0.8);
      }
    ` : css`
      &:first-child {
        background: rgba(0, 127, 255, 0.4);
      }
    `)} 
    ${props => props.third && (props.isHoliday ? css`
      &:nth-child(3) {
        background: rgba(235, 110, 160, 0.8);
      }
    ` : css`
      &:nth-child(3) {
        background: rgba(0, 127, 255, 0.4);
      }
    `)} 
  }
`
// rgba(0, 127, 255, 0.4)
const QuarterDiv = styled.div`
  /* background: #fff; */
  background: ${props => props.planned ? props.isHoliday ? 'rgba(235, 110, 160, 0.6)' : 'rgba(0, 127, 255, 0.3)' : '#fff'};
  width: 15px;
`

const Quarters = ({ allUpdate, idx, plannedStartIndex, plannedEndIndex, isHoliday = false }) => {
  const base = idx * 4
  return (
    <QuartersDiv
      allUpdate={allUpdate}
      first={plannedStartIndex <= base && base < plannedEndIndex}
      third={plannedStartIndex <= (base + 2) && (base + 2) < plannedEndIndex}
      isHoliday={isHoliday}
    >
      <QuarterDiv planned={plannedStartIndex <= base && base < plannedEndIndex} isHoliday={isHoliday} />
      <QuarterDiv planned={plannedStartIndex <= (base + 1) && (base + 1) < plannedEndIndex} isHoliday={isHoliday} />
      <QuarterDiv planned={plannedStartIndex <= (base + 2) && (base + 2) < plannedEndIndex} isHoliday={isHoliday} />
      <QuarterDiv planned={plannedStartIndex <= (base + 3) && (base + 3) < plannedEndIndex} isHoliday={isHoliday} />
    </QuartersDiv>
  )
}

Quarters.propTypes = {
  allUpdate: PropTypes.bool
}

const Columns = React.memo(({ allUpdate, plannedStartIndex, plannedEndIndex, isHoliday }) => {
  // console.log(plannedStartIndex)
  // console.log(plannedEndIndex)
  const columns = []
  for (let i = 0; i < 36; i++) {
    columns.push(
      <Quarters
        key={i}
        idx={i}
        allUpdate={allUpdate}
        plannedStartIndex={plannedStartIndex}
        plannedEndIndex={plannedEndIndex}
        isHoliday={isHoliday}
      />
    )
  }
  return (
    <>
      {columns}
    </>
  )
})

Columns.propTypes = {
  allUpdate: PropTypes.bool
}

export default Columns
