import styled from 'styled-components'

const MasterWrap = styled.div` 
  display: flex;
  flex-direction: column;
  height: 100%;
  width: inherit;
  padding: 0 16px;
  main {
    display: flex;
  }
`
export default MasterWrap
