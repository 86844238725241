import { GetApi } from '../ApiAxios'
import { DateTime } from 'luxon'

const ExportLaborCsv = async (startDate, endDate, commonSearch, searchEmpCond, grant, staffId) => {
  const { warehouseId, floorId, zoneId } = commonSearch
  console.log(staffId)
  const urlArgs = buildLaborExportUrlArgs(startDate, endDate, warehouseId, floorId, zoneId, searchEmpCond, grant, staffId)
  const { data } = await GetApi(`/api/download/csv/labor?${urlArgs}`)
  return data
}

function buildLaborExportUrlArgs (startDate, endDate, warehouseId, floorId, zoneId, searchEmpCond, grant, staffId) {
  const argsArr = []
  argsArr.push(`workDateFrom=${DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')}`)
  argsArr.push(`workDateTo=${DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')}`)
  warehouseId != null && warehouseId !== -1 && argsArr.push(`warehouseId=${warehouseId}`)
  floorId != null && floorId !== -1 && argsArr.push(`floorId=${floorId}`)
  zoneId != null && zoneId !== -1 && argsArr.push(`zoneId=${zoneId}`)
  const staffFilter = grant === 3 ? `&staffId=${staffId}` : ''
  return argsArr.join('&') + `&registerStatus=1&workPlace=0&staffDivisionFilter=${searchEmpCond}&isPlanned=false${staffFilter}`
}

export default ExportLaborCsv
