import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../Atoms/NormalButton'
import ListView from '../Atoms/ListView'
import Pagination from '../Atoms/Pagination'

const ListWrap = styled.div`
  width: 17vw;
  margin-right: 1vw;
  margin-bottom: 12px;
`
const ListHeader = styled.div`
  width: 15vw;
  height: 48px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`
const CountLabel = styled.label`
  color: white;
  height: 48px;
  line-height: 48px;
  margin-bottom: 12px;
`

const PopSpan = styled.span`
  display: ${props => props.display};
  top: ${props => props.top}px;
  left: 10px;
  color: #000;
  background: #f8fdb0;
  padding: 16px 24px;
  border-radius: 5px;
  pointer-events: none;
  position: fixed;
  z-index: 6;
  list-style: none;
  font-size: 14px;
  max-width: 25vw;
  word-wrap: break-word;
`

const MasterListView = (props) => {
  const {
    dataList, selectedId, selectFunction, createFunction, canWrite, isIdView, listHeight, addStyle,
    pageSelectFunc, pagination
  } = props
  const [popItem, setPopItem] = useState()

  const OperationPop = useCallback(() => {
    // console.log(popItem)
    return (
      <PopSpan display={popItem ? 'block' : 'none'} top={popItem?.top}>
        <div>{popItem?.label}</div>
      </PopSpan>
    )
  }, [popItem])

  const onMouse = (index, value) => {
    if (value) {
      const value_ = {
        label: value,
        top: index * 35 + 120 - document.getElementById('App').scrollTop
      }
      setPopItem(value_)
    } else {
      setPopItem(null)
    }
  }

  return (
    <ListWrap>
      <OperationPop />
      <ListHeader>
        <Button name='新規' margin='0' padding='0' display={canWrite ? 'block' : 'none'} onClick={createFunction} />
        <CountLabel>{pagination && pagination.allRecordCount ? '全 ' + pagination.allRecordCount + '件' : '0件'}</CountLabel>
      </ListHeader>
      <ListView
        dataList={dataList} selectedId={selectedId} selectFunction={selectFunction}
        isIdView={isIdView} height={listHeight} addStyle={addStyle} pagination={pagination}
        onMouse={onMouse}
      />
      {dataList && dataList.length > 0 && pagination &&
        <Pagination pagination={pagination} pageSelectFunc={pageSelectFunc} addStyle='margin-top: 24px;' />}
    </ListWrap>
  )
}

MasterListView.propTypes = {
  dataList: PropTypes.array,
  selectedId: PropTypes.any,
  selectFunction: PropTypes.func,
  createFunction: PropTypes.func,
  canWrite: PropTypes.bool,
  isIdView: PropTypes.bool,
  listHeight: PropTypes.string,
  addStyle: PropTypes.string,
  pageSelectFunc: PropTypes.func,
  pagination: PropTypes.object
}

export default MasterListView
