import React, { useCallback, useRef, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DateCalendar from './Atoms/Calendar'
import Button from './Atoms/NormalButton'
import Select from './Atoms/SelectAddValue'
import { SetCommonSearch, SetWorkDate } from './Slice/SearchSlice'
import { SetLoadSpinner } from './Slice/LoadSpinnerSlice'
import { GetApi } from './ApiAxios'
import { useAlert } from 'react-alert'
import DateChangeButtons from './Atoms/DateChangeButtons'
import PropTypes from 'prop-types'
import SwitchButton from './SwitchButton'
import ZoomInIcon from '@material-ui/icons/ZoomIn'

const searchMode = 1 // 1: ログインユーザーに紐づく初期値 2: 権限毎の初期値

// 権限の値
// 0: 権限なし
// 1: 所属のみ
// 2: 全権限あり
// 3: 自分のみ

const SearchWrap = styled.div`
  display:${props => props.display || 'flex'};
  color: #fff;
  flex-wrap: wrap;
  margin: 0 0 12px 0;
  h3 {
    line-height: 32px;
    font-size: 16px;
    margin-right: 16px;
  }
  ul.mainSearch {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    position: relative;
    > li {
      display: flex;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 16px;
      }
    }
    li.plusButtonLi {
      display: flex;
      label {
        line-height: 32px;
        text-align: right;
        font-size: 16px;
      }
      svg{
        width: 24px;
        height: 32px;
        margin: 0 8px;
        cursor: pointer;

      }
    }
    li.subSearch{
      display: ${props => props.plusButton && props.viewSubSearch ? 'block' : 'none'};
      position: absolute;
      padding: 0 16px;
      margin: 0;
      top: 43px;
      z-index: 3;
      left: -16px;
      background-color: rgba(0,0,0,0.8);
      ul {
        display: flex;
        flex-wrap: wrap;
        > li {
          display: flex;
          margin: 12px 0;
          label {
            display: block;
            line-height: 32px;
            text-align: right;
            font-size: 16px;
          }
        }
      }
    }
  }
  ${props => props.addStyle}
`

const Search = (props) => {
  const dispatch = useDispatch()
  const companyRef = useRef()
  const warehouseRef = useRef()
  const floorRef = useRef()
  const zoneRef = useRef()
  const location = useLocation()
  const history = useHistory()
  const [renderFlg, setRenderFlg] = useState(false)
  const [plusButton, setPlusButton] = useState(false)
  const {
    strongTitle, strongTitleColor, title, showButton, onClick, firstSearch, grants,
    showWarehouses, showFloors, showZones, showCalendar, calendarType,
    addAllCompany, addAllWarehouse, addAllFloor, addAllZone,
    inSubCompany, inSubWarehouse, inSubFloor, inSubZone, inSubCalendar, inSubChildren,
    addStyle, children, userCompanyId, relationStatus, laborToggle, chartToggle, chartToggleValue, changeChartToggle, showIncludeRetired,
    showSpinner = true, isApprovedPage = false
  } = props
  const alert = useAlert()
  const [companyList, setCompanyList] = useState([])
  const { commonSearch, loginUserInfo } = useSelector(state => state)

  const today = new Date()
  const todayStr = today.getFullYear() + '-' + ('00' + (today.getMonth() + 1)).slice(-2) + '-' + ('00' + today.getDate()).slice(-2)

  const stateCompanyId = addAllCompany ? commonSearch.companyId : commonSearch.companyId === -1 ? loginUserInfo.companyId : commonSearch.companyId
  const stateWarehouseId = addAllWarehouse ? commonSearch.warehouseId : commonSearch.warehouseId === -1 ? loginUserInfo.warehouseId : commonSearch.warehouseId
  const stateFloorId = addAllFloor ? commonSearch.floorId ? commonSearch.floorId : -1 : commonSearch.floorId === -1 ? loginUserInfo.floorId : commonSearch.floorId
  const stateZoneId = addAllZone ? commonSearch.zoneId ? commonSearch.zoneId : -1 : commonSearch.zoneId === -1 ? loginUserInfo.zoneId : commonSearch.zoneId
  const stateWorkDate = commonSearch.workDate != null ? commonSearch.workDate : todayStr
  const stateIncludeRetired = commonSearch.includeRetired != null ? commonSearch.includeRetired : '0'

  const companies = companyList.length > 0 ? companyList : null
  let company = companies && stateCompanyId ? companies.find(company => company.companyId === stateCompanyId) : companies && companies[0]
  let warehouses = company ? company.warehouses : null
  let warehouse = warehouses && stateWarehouseId ? warehouses.find(warehouse => warehouse.warehouseId === stateWarehouseId) : warehouses && warehouses[0]
  let floors = warehouse ? warehouse.floors : null
  let floor = floors && stateFloorId ? floors.find(floor => floor.floorId === stateFloorId) : floors && floors[0]
  let zones = floor ? floor.zones : null
  // const zone = zones && stateZoneId ? zones.find(zone => zone.zoneId === stateZoneId) : zones && zones[0]
  if (grants.readGrantDivision === 1) {
  // 所属のみ
    company = companies ? companies.find(company => company.companyId === loginUserInfo.companyId) : null
    warehouses = company ? company.warehouses : null
    warehouse = warehouses ? warehouses.find(warehouse => warehouse.warehouseId === loginUserInfo.warehouseId) : null
    floors = warehouse ? warehouse.floors : null
    floor = floors ? floors.find(floor => floor.floorId === stateFloorId) : null
    zones = floor ? floor.zones : null
  } else if (grants.readGrantDivision === 3) {
    // 自分のみ
    company = companies ? companies.find(company => company.companyId === loginUserInfo.companyId) : null
    warehouses = company ? company.warehouses : null
    warehouse = warehouses ? warehouses.find(warehouse => warehouse.warehouseId === loginUserInfo.warehouseId) : null
    floors = warehouse ? warehouse.floors : null
    floor = floors ? floors.find(floor => floor.floorId === loginUserInfo.floorId) : null
    zones = floor ? floor.zones : null
  }
  // 倉庫を初期値に戻す
  const downSelectWarehouse = (companyId) => {
    if (addAllWarehouse || companyId === '-1' || !companies) return -1
    const company = companies.find(company => company.companyId === companyId)
    if (!company) return -1
    return company.warehouses[0] ? company.warehouses[0].warehouseId : -1
  }
  // フロアを初期値に戻す
  const downSelectFloor = (companyId, warehouseId) => {
    if (addAllWarehouse || addAllFloor || warehouseId === -1 || !warehouses) return -1
    const company = companyList.find(d => d.companyId === companyId)
    const house = company && company.warehouses.find(d => d.warehouseId === warehouseId)
    if (!house) return -1
    return house.floors[0] ? house.floors[0].floorId : -1
  }
  // ゾーンを初期値に戻す
  const downSelectZone = (companyId, warehouseId, floorId) => {
    if (addAllWarehouse || addAllFloor || addAllZone || floorId === -1 || !floors) return -1
    const company = companyList.find(d => d.companyId === companyId)
    const house = company && company.warehouses.find(d => d.warehouseId === warehouseId)
    const floor = house && house.floors.find(floor => floor.floorId === floorId)
    if (!floor) return -1
    return floor.zones[0] ? floor.zones[0].zoneId : -1
  }

  // 会社
  const Companies = () => {
    const selectCompany = useCallback((event) => {
      const companyId = event.target.value
      const warehouseId = downSelectWarehouse(companyId)
      const floorId = downSelectFloor(companyId, warehouseId)
      const zoneId = downSelectZone(companyId, warehouseId, floorId)
      const payload = {
        payCompanyId: companyId,
        payWarehouseId: warehouseId,
        payFloorId: floorId,
        payZoneId: zoneId,
        payWorkDate: stateWorkDate,
        payIncludeRetired: stateIncludeRetired
      }
      dispatch(SetCommonSearch(payload))
    }, [])
    return (
      <li>
        <label>会社</label>
        <Select selectValue={stateCompanyId || ''} onChange={event => selectCompany(event)} selectRef={companyRef}>
          {addAllCompany && grants.readGrantDivision === 2 && <option value={-1}>全て</option>}
          {companies && companies.map(item => {
            if (item.companyId === '') return null
            if ((grants.readGrantDivision === 1 || grants.readGrantDivision === 3) && loginUserInfo.companyId !== item.companyId) return null
            return (
              <option key={item.companyId} value={item.companyId}>{item.companyName}</option>
            )
          })}
        </Select>
      </li>
    )
  }

  // 倉庫
  const Warehouses = () => {
    const selectHouse = useCallback((event) => {
      const companyId = stateCompanyId
      const warehouseId = event.target.value - 0
      const floorId = downSelectFloor(companyId, warehouseId)
      const zoneId = downSelectZone(companyId, warehouseId, floorId)
      const payload = {
        payCompanyId: companyId,
        payWarehouseId: warehouseId,
        payFloorId: floorId,
        payZoneId: zoneId,
        payWorkDate: stateWorkDate,
        payIncludeRetired: stateIncludeRetired
      }
      dispatch(SetCommonSearch(payload))
    }, [])
    return (
      <li style={{ display: showWarehouses ? 'flex' : 'none' }}>
        <label>倉庫</label>
        <Select selectValue={stateWarehouseId || ''} onChange={event => selectHouse(event)} selectRef={warehouseRef}>
          {addAllWarehouse && grants.readGrantDivision === 2 && <option value={-1}>全て</option>}
          {warehouses && warehouses.map(item => {
            if (item.warehouseId === 0) return null
            if ((grants.readGrantDivision === 1 || grants.readGrantDivision === 3) && loginUserInfo.warehouseId !== item.warehouseId) return null
            return (
              <option key={item.warehouseId} value={item.warehouseId}>{item.warehouseName}</option>
            )
          })}
        </Select>
      </li>
    )
  }

  // フロア
  const Floors = () => {
    const selectFloor = useCallback((event) => {
      const companyId = stateCompanyId
      const warehouseId = stateWarehouseId
      const floorId = event.target.value - 0
      const zoneId = downSelectZone(companyId, warehouseId, floorId)
      const payload = {
        payCompanyId: companyId,
        payWarehouseId: warehouseId,
        payFloorId: floorId,
        payZoneId: zoneId,
        payWorkDate: stateWorkDate,
        payIncludeRetired: stateIncludeRetired
      }
      dispatch(SetCommonSearch(payload))
    }, [])
    return (
      <li style={{ display: showFloors ? 'flex' : 'none' }}>
        <label>フロア</label>
        <Select selectValue={stateFloorId || ''} onChange={event => selectFloor(event)} selectRef={floorRef} width='150px'>
          {addAllFloor && (grants.readGrantDivision === 2 || grants.readGrantDivision === 1) && <option value={-1}>全て</option>}
          {floors && floors.map(item => {
            if (item.floorId === 0) return null
            if (grants.readGrantDivision === 3 && loginUserInfo.floorId !== item.floorId) return null
            return (
              <option key={item.floorId} value={item.floorId}>{item.floorName}</option>
            )
          })}
        </Select>
      </li>
    )
  }

  // ゾーン
  const Zones = () => {
    const selectZone = useCallback((event) => {
      const payload = {
        payCompanyId: stateCompanyId,
        payWarehouseId: stateWarehouseId,
        payFloorId: stateFloorId,
        payZoneId: event.target.value - 0,
        payWorkDate: stateWorkDate,
        payIncludeRetired: stateIncludeRetired
      }
      dispatch(SetCommonSearch(payload))
    }, [])
    return (
      <li style={{ display: showZones ? 'flex' : 'none' }}>
        <label>ゾーン</label>
        <Select selectValue={stateZoneId || ''} onChange={event => selectZone(event)} selectRef={zoneRef}>
          {addAllZone &&
            (grants.readGrantDivision === 2 || grants.readGrantDivision === 1) && <option value={-1}>全て</option>}
          {zones && zones.map(item => {
            if (item.zoneId === 0) return null
            if (grants.readGrantDivision === 3 && loginUserInfo.zoneId != null && loginUserInfo.zoneId !== item.zoneId) return null
            return (
              <option key={item.zoneId} value={item.zoneId}>{item.zoneName}</option>
            )
          })}
        </Select>
      </li>
    )
  }

  // カレンダー
  const Calendar = () => {
    return (
      <li style={{ display: showCalendar ? 'flex' : 'none' }}>
        <label>日付</label>
        <DateCalendar changeCalendar={changeCalendar} dataValue={stateWorkDate} isViewIcon type={calendarType} viewTodayButton />
      </li>
    )
  }

  // 退職者を検索結果に含めるかフラグ
  const IncludeRetired = () => {
    const changeIncludeRetired = useCallback((event) => {
      const payload = {
        payCompanyId: stateCompanyId,
        payWarehouseId: stateWarehouseId,
        payFloorId: stateFloorId,
        payZoneId: stateZoneId,
        payWorkDate: stateWorkDate,
        payIncludeRetired: stateIncludeRetired === '1' ? '0' : '1'
      }
      dispatch(SetCommonSearch(payload))
    }, [])
    return (
      <li className='includeRetiredSearch' style={{ display: showIncludeRetired ? 'flex' : 'none' }}>
        <label>
          <input
            type='checkbox'
            id='IncludeRetired'
            name='IncludeRetired'
            defaultChecked={false}
            onChange={e => { changeIncludeRetired(e) }}
            value='1'
            checked={stateIncludeRetired === '1'}
          />
          退職者を含める
        </label>
      </li>
    )
  }

  // 追加検索条件
  const SubSearch = () => {
    return (
      <li className='plusButtonLi'>
        <ZoomInIcon onClick={() => setPlusButton(!plusButton)} />
      </li>
    )
  }

  // 初期値
  useEffect(() => {
    if (companyList && companyList.length > 0 && loginUserInfo && loginUserInfo.companyId !== '' && searchMode === 2) {
      // 初期共通検索表示

      const gRead = grants.readGrantDivision
      let payCompanyId = null // 会社初期値
      let payWarehouseId = null // 倉庫初期値
      let payFloorId = null // フロア初期値
      let payZoneId = null // ゾーン初期値

      if (gRead === 2) {
        payCompanyId = addAllCompany ? -1 : companyList[0].companyId
        payCompanyId = addAllWarehouse ? -1 : warehouses[0].warehouseId
        payFloorId = addAllFloor ? -1 : floors[0].floorId
        payZoneId = addAllZone ? -1 : zones[0].zoneId
      } else if (gRead === 1) {
        payCompanyId = loginUserInfo.companyId
        payWarehouseId = loginUserInfo.warehouseId
        payFloorId = addAllFloor ? -1 : floors[0].floorId
        payZoneId = addAllZone ? -1 : zones[0].zoneId
      } else if (gRead === 3) {
        payCompanyId = loginUserInfo.companyId
        payWarehouseId = loginUserInfo.warehouseId
        payFloorId = loginUserInfo.floorId
        payZoneId = loginUserInfo.zoneId
      } else {
        payCompanyId = null
        payWarehouseId = null
        payFloorId = null
        payZoneId = null
      }

      const payload = {
        payCompanyId: payCompanyId,
        payWarehouseId: payWarehouseId,
        payFloorId: payFloorId,
        payZoneId: payZoneId,
        payWorkDate: todayStr,
        payIncludeRetired: '0'
      }

      dispatch(SetCommonSearch(payload))
    } else if (loginUserInfo && loginUserInfo.companyId && stateCompanyId == null) {
      const companyId = loginUserInfo.companyId
      const warehouseId = loginUserInfo.warehouseId
      const floorId = loginUserInfo.floorId ? loginUserInfo.floorId : downSelectFloor(companyId, warehouseId)
      const zoneId = loginUserInfo.zoneId ? loginUserInfo.zoneId : downSelectZone(companyId, warehouseId, floorId)
      const payload = {
        payCompanyId: companyId,
        payWarehouseId: warehouseId,
        payFloorId: floorId,
        payZoneId: zoneId,
        payWorkDate: todayStr,
        payIncludeRetired: '0'
      }
      dispatch(SetCommonSearch(payload))
    }
    // else if (
    //   companyList && companyList.length > 0 && (
    //     (!addAllCompany && commonSearch.companyId === -1) ||
    //     (!addAllWarehouse && commonSearch.warehouseId === -1) ||
    //     (!addAllFloor && commonSearch.floorId === -1) ||
    //     (!addAllZone && commonSearch.zoneId === -1)
    //   )
    // ) {
    //   const payload = {
    //     payCompanyId: company ? company.companyId : null,
    //     payWarehouseId: warehouse ? warehouse.warehouseId : null,
    //     payFloorId: floor ? floor.floorId : null,
    //     payZoneId: zone ? zone.zoneId : null,
    //     payWorkDate: todayStr
    //   }
    //   dispatch(SetCommonSearch(payload))
    // }
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, companyList, dispatch])

  // レンダー終了判定
  useEffect(() => {
    if (stateWarehouseId && !renderFlg) {
      setRenderFlg(true)
    }
  }, [stateWarehouseId, renderFlg])

  // 初期検索
  useEffect(() => {
    if (renderFlg && firstSearch) {
      onClick()
    }
  }, [renderFlg, firstSearch, onClick])

  useEffect(() => {
    // 会社情報一覧
    const onCompanyInfoSearch = async (id, status) => {
      showSpinner && dispatch(SetLoadSpinner(true))
      const data = {
        companyId: id,
        notBlankStatus: status
      // 0 or null :全部 LEFT JOIN、1: 倉庫はINNER フロア・ゾーンはLEFT、2: 倉庫・フロアはINNER ゾーンはLEFT、3: 全部 INNER JOIN
      }
      const result = await GetApi('/api/companyInfo', data)
      showSpinner && dispatch(SetLoadSpinner(false))
      if (result.errorDetail) return null
      if (result.data.length === 0) {
        alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
      }
      setCompanyList(result.data)
      return result.data
    }
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch(userCompanyId, relationStatus)
    }
  // 必要なデータが更新された場合のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo, relationStatus, userCompanyId])

  const changeCalendar = (dateStr) => {
    if (!dateStr) {
      dispatch(SetWorkDate(null))
      return null
    }
    if (stateWorkDate !== dateStr) {
      dispatch(SetWorkDate(dateStr))
    }
  }
  const viewSubSearch = inSubCompany || inSubWarehouse || inSubFloor || inSubZone || inSubCalendar || inSubChildren

  return (
    <SearchWrap addStyle={addStyle} plusButton={plusButton} viewSubSearch={viewSubSearch}>
      {title !== '' && <h3>{strongTitle && <strong style={{ color: strongTitleColor }}>{strongTitle}</strong>}{title}</h3>}
      {laborToggle &&
        <SwitchButton
          switchName='laborToggle'
          flg={location.pathname === '/labor/planned' ? 1 : 0}
          onChangeFlg={() => { history.push(location.pathname === '/labor/planned' ? '/labor/result' : '/labor/planned') }}
          labels={{ left: '予定', right: '実績' }}
        />}
      {chartToggle &&
        <SwitchButton
          switchName='chartToggle'
          flg={chartToggleValue === 'individual' ? 1 : 0}
          onChangeFlg={() => { changeChartToggle() }}
          labels={{ left: '個人', right: '組織' }}
        />}
      <ul className='mainSearch'>
        {!inSubCompany && <Companies />}
        {!inSubWarehouse && <Warehouses />}
        {!inSubFloor && <Floors />}
        {!inSubZone && <Zones />}
        {!inSubCalendar && showCalendar && <Calendar />}
        {!inSubCalendar && showCalendar && <DateChangeButtons dateStr={stateWorkDate} type={calendarType} changeCalendar={changeCalendar} isApprovedPage={isApprovedPage} />}
        {!inSubChildren && children}
        {viewSubSearch && <SubSearch />}
        <li className='subSearch'>
          <ul>
            {inSubCompany && <Companies />}
            {inSubWarehouse && <Warehouses />}
            {inSubFloor && <Floors />}
            {inSubZone && <Zones />}
            {inSubCalendar && showCalendar && <Calendar />}
            {inSubCalendar && showCalendar && <DateChangeButtons dateStr={stateWorkDate} type={calendarType} changeCalendar={changeCalendar} />}
            {inSubChildren && children}
            {inSubChildren && showIncludeRetired && <IncludeRetired />}
          </ul>
        </li>
      </ul>
      {showButton && <Button margin='0 0 0 16px' name='検索' padding='0px' onClick={onClick} />}
    </SearchWrap>
  )
}

Search.propTypes = {
  strongTitle: PropTypes.string, // 強調タイトル
  strongTitleColor: PropTypes.string, // 強調タイトル色
  title: PropTypes.string, // タイトル
  showButton: PropTypes.bool, // 検索ボタン表示
  onClick: PropTypes.func, // 検索処理
  firstSearch: PropTypes.bool, // 最初に検索処理をするか
  grants: PropTypes.object, // 権限関連
  showWarehouses: PropTypes.bool, // 倉庫選択ボックスの表示・非表示
  showFloors: PropTypes.bool, // フロア選択ボックスの表示・非表示
  showZones: PropTypes.bool, // ゾーン選択ボックスの表示・非表示
  showCalendar: PropTypes.bool, // カレンダーの表示・非表示
  calendarType: PropTypes.number, // カレンダーの種類 1 or null:年月日 2:年月
  addAllCompany: PropTypes.bool, // フロア選択ボックスの「全て」を表示する
  addAllWarehouse: PropTypes.bool, // フロア選択ボックスの「全て」を表示する
  addAllFloor: PropTypes.bool, // フロア選択ボックスの「全て」を表示する
  addAllZone: PropTypes.bool, // ゾーン選択ボックスの「全て」を表示する
  inSubCompany: PropTypes.bool, // 会社をサブメニューに表示 メインには表示しない
  inSubWarehouse: PropTypes.bool, // 倉庫をサブメニューに表示 メインには表示しない
  inSubFloor: PropTypes.bool, // フロアをサブメニューに表示 メインには表示しない
  inSubZone: PropTypes.bool, // ゾーンをサブメニューに表示 メインには表示しない
  inSubCalendar: PropTypes.bool, // カレンダーをサブメニューに表示 メインには表示しない
  inSubChildren: PropTypes.bool, // その他メニューをサブメニューに表示 メインには表示しない
  addStyle: PropTypes.string, // css用追加用
  children: PropTypes.any, // 子要素
  userCompanyId: PropTypes.string, // 会社ID指定するとその会社のみ表示
  relationStatus: PropTypes.number, // 0 or null :全部 LEFT JOIN、1: 倉庫はINNER フロア・ゾーンはLEFT、2: 倉庫・フロアはINNER ゾーンはLEFT、3: 全部 INNER JOIN
  laborToggle: PropTypes.bool, // レイバー用のトグルあるなし
  chartToggle: PropTypes.bool, // チャート用のトグルあるなし
  chartToggleValue: PropTypes.string,
  changeChartToggle: PropTypes.func,
  showIncludeRetired: PropTypes.bool, // 退職者を検索結果に含めるかボックスの表示・非表示
  showSpinner: PropTypes.bool
}

export default Search
