import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { setZones } from './ZoneMasterSlice'
import GridLayout from 'react-grid-layout'
import _ from 'lodash'
import FUNC from '../../Common/FunctionEnum'
import { GetApi, PostApi, PutApi, DeleteApi, exportErrorLog } from '../../Common/ApiAxios'
import ErrorHandling from '../Common/ErrorHandling'
import ZoneInitPane from '../../ZoneInitPane.js'
import Search from '../../Common/Search'
import { CommonSearchStates } from '../../Common/Slice/SearchSlice'
import '../../../css/Master/ZoneMaster.scss'
import { useAlert } from 'react-alert'
import ZoneEditModal from './ZoneEditModal'

const HEIGHT = 70
const WIDTH = 160
// const HEADER_HEIGHT = 70
// const SCROLLBAR_WIDTH = 30
// const MARGIN = 25

const style = {
  // border: '1px solid white',
  // width: '300px',
  border: '1px solid gray',
  color: 'white'
}

// let layout = []

const ZoneMaster = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const [layout, setLayout] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [zone, setZone] = useState(null)
  const commonSearch = CommonSearchStates()
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId
  // const workDate = states.commonSearch.workDate
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const selectedId = useSelector(state => state.editZone.selectedId)
  const zones = useSelector(state => state.zoneMaster)
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ZONE)
  const canWrite = grants && (grants.writeGrantDivision === 2 || (grants.writeGrantDivision === 1 && warehouseId === loginUserInfo.warehouseId))
  const ZoneName = styled.div`
    width: 100%;
    height: 72px;
    font-size: 20px;
    text-align: center;
    padding: 12px 0px;
  `

  /**
   * ゾーン描画.
   */
  const generateDOM = () => {
    console.log(`
    
        called generateDOM
    
      `)

    console.log(layout)
    return _.map(layout, l => {
      console.log(l.zone)
      return (
        <div key={l.i} style={style} className={l.static ? 'static' : ''}>
          {canWrite && <div className='hide-button' onClick={() => onDelete(l)}> &times; </div>}
          <ZoneName className='text'>{l.zone.zoneName}</ZoneName>
          {/* <div className='text'>
            x pos: {l.x} / y pos: {l.y} / {(l.zone.posYStart - HEADER_HEIGHT) / HEIGHT}
          </div>
          <div className='text'>
            pos: {l.zone.posXStart}:{l.zone.posXEnd}/{l.zone.posYStart}:{l.zone.posYEnd}
          </div>
          <div className='text'>
            x w: {Math.floor((l.zone.posXEnd - l.zone.posXStart) / WIDTH)} / y h: {Math.floor((l.zone.posYEnd - l.zone.posYStart - HEADER_HEIGHT) / HEIGHT)}
          </div> */}
          {/* <Staff staffName='xxx' /> */}
        </div>
      )
    })
  }

  /**
   * 登録済のゾーン一覧の取得.
   * @param {Int} warehouseId
   * @param {Int} floorId
   */
  const fetchMappingInfo = async (warehouseId, floorId) => {
    if (!warehouseId) return null
    console.log(`
  
      called fetchMappingInfo
  
    `)

    const data = { warehouseId: warehouseId }
    const result = await GetApi('/api/whiteboard/warehouseZones', data)
    console.info('getZone Result')
    console.log(result.data)
    // console.table(result.data.floors[0].zones[0])
    const floor = _.filter(result.data.floors, function (floor) { return parseInt(floor.floorId) === parseInt(floorId) })
    console.log(floor)
    // setZones(floor[0].zones)
    // setLayout(generateLayout(floor[0].zones))
    if (floor[0] && floor[0].zones) {
      const generatedLayout = generateLayout(floor[0].zones)
      if (JSON.stringify(layout) !== JSON.stringify(generatedLayout)) {
        const tmpLayout = generateLayout(floor[0].zones)
        console.log('--- tmpLayout ---')
        console.log(tmpLayout)
        if (tmpLayout[0] != null) {
          setLayout(tmpLayout)
          const payload = {
            zones: tmpLayout
          }
          dispatch(setZones(payload))
        } else {
          console.log('setlayout <- []')
          setLayout([])
        }
      }
    }
  }

  useEffect(() => {
    console.log('useEffect')
    fetchMappingInfo(warehouseId, floorId)
    // layout = generateLayout()
    return () => {
      console.log('unmounted')
    }
  // 倉庫・フロア変更時のみ実行
  // eslint-disable-next-line
  }, [warehouseId, floorId])

  useEffect(() => {
    if (selectedId >= 0) {
      console.log(selectedId)
      console.log('=== zones ===')
      console.log(zones.zones)
      const zone = _.filter(zones.zones, function (z) {
        return z.zone.zoneId === selectedId
      })
      console.log(zone)
      console.log('============')
      setZone(zone[0].zone)
    }
    return () => {
      console.log('clean up')
    }
  // 選択対象が変更された場合のみ実行
  // eslint-disable-next-line
  }, [selectedId])

  /**
   *
   * @param {*} zones
   */
  function generateLayout (zones) {
    console.log(`
    
        called generateLayout
    
      `)
    console.log(zones)
    // TODO zoneinfoから表示
    return _.map(zones, function (zone, i) {
      if (!(zone.posXStart === zone.posXEnd && zone.posYStart === zone.posYEnd)) {
        const w = Math.floor((zone.posXEnd - zone.posXStart) / (WIDTH))
        const h = Math.floor((zone.posYEnd - zone.posYStart) / HEIGHT)
        const y = Math.floor(zone.posYStart / HEIGHT)
        const x = Math.floor(zone.posXStart / (WIDTH)) // (_.random(0, 3) * 2) % 12
        console.log(`
        
        i: ${i}: x: ${x}, y: ${y}, h: ${h}
        
        `)
        return {
          x: x,
          y: y,
          w: w, //  スタッフのWidth
          h: h,
          i: zone.zoneId.toString(),
          minH: 2,
          zone: zone
          // i: i.toString()
        }
      }
    })
  }

  /**
   * 新規ゾーンの配置位置計算.
   *
   * @param {object} layout
   */
  function getMappingPosition (layout) {
    let maxX = 0
    const maxY = 0 // TODO Y座標計算ロジック追加
    console.log(layout)
    layout.forEach(l => {
      if ((l.x + l.w) > maxX) maxX = l.x + l.w
    })

    return [maxX, maxY]
  }

  /**
   * ゾーンの行列数から配置位置(px)を計算する.
   * @param {Int} x
   * @param {Int} y
   * @param {Int} w
   * @param {Int} h
   */
  function calcPosition (x, y, w, h) {
    return {
      xStart: x * (WIDTH + 16),
      xEnd: (x + w) * WIDTH + (x + w - 1) * 16,
      yStart: y * (HEIGHT + 12),
      yEnd: (y + h) * HEIGHT + (y + h) * 12
    }
  }

  /**
   * x, y, w, hからpx単位の座標を計算する.
   * @param {Int} x
   * @param {Int} y
   * @param {Int} w
   * @param {Int} h
   */
  async function createZone (data) {
    // TODO function, await化
    const result = await PostApi('/api/whiteboard/createZone', data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 500) {
        alert.error('ゾーン情報の登録に失敗しました。')
      }
    } else {
      console.log('after save')
      console.log(result.data)
      alert.success('ゾーンを作成しました。')
      return result.data
    }
  }

  async function changeZone (data) {
    console.log('data for changeZone')
    console.log(data)
    const result = await PutApi('/api/whiteboard/createZone', data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 500) {
        alert.error('ゾーン情報の更新に失敗しました。')
      } else if (errorStatus === 400) {
        alert.error('ゾーン情報の更新に失敗しました(パラメータ不正)。')
      } else if (errorStatus === 409) {
        // alert.error('ゾーン情報の更新に失敗しました(他ユーザ更新)。')
        // TODO エラー回避 or Layout Changeが2回走る原因調査
      }
    } else {
      console.log('=== after changeZone ===')
      console.log(result.data)
      return result.data
    }
  }

  // ------------
  //   イベント
  // ------------

  /**
   *
   * @param {*} newLayout
   */
  const onLayoutChange = newLayout => {
    console.log('=== new layout ===')
    console.log(newLayout)
    if (newLayout[0] == null || newLayout[0].i === 'null') return null
    _.map(newLayout, (l, i) => {
      const changed = _.find(layout, function (org) { return parseInt(org.i) === parseInt(l.i) })
      if (changed == null) return
      if (!(changed.x === l.x && changed.y === l.y && changed.w === l.w && changed.h === l.h)) {
        console.log('--- changed layout ---')
        console.log(changed, l)
        const newPos = calcPosition(l.x, l.y, l.w, l.h)
        console.log(newPos)

        const data = {
          zoneId: changed.zone.zoneId,
          floorId: floorId,
          // zoneName: e.target.zonename.value,
          posXStart: newPos.xStart,
          posXEnd: newPos.xEnd,
          posYStart: newPos.yStart,
          posYEnd: newPos.yEnd,
          //   // endTime: endTime,
          updatedId: loginUserInfo.staffId,
          updatedAt: changed.zone.updatedAt
        }
        changeZone(data)
          .then(result => {
            fetchMappingInfo(warehouseId, floorId)
            console.log(`result: ${result}`)
          })
      } else {
        console.log('--- same layout ---')
      }
    })
  }

  // ZONE削除
  const onDelete = (delLayout) => {
    console.log(delLayout)
    const data = { zoneId: delLayout.zone.zoneId, updatedAt: delLayout.zone.updatedAt }
    console.log(data)
    DeleteApi('/api/whiteboard/createZone', data)
      .then(result => {
        if (result.errorDetail) {
          exportErrorLog(result)
          console.log(result.errorDetail)
          alert.error('ゾーンの削除に失敗しました。')
        } else {
          fetchMappingInfo(warehouseId, floorId)
          alert.success('ゾーンを削除しました。')
        }
      })
  }

  /**
   * ゾーン追加ハンドラー
   * @param {*} row
   * @param {*} column
   * @param {*} zoneName
   * @param {*} zoneEndTime
   */
  const handleAddZone = async (row, column, zoneName, zoneEndTime) => {
    console.log(`
    
        row: ${row}
        column: ${column}
        zoneName: ${zoneName}
        zoneEndTime: ${zoneEndTime}
    
      `)

    console.log(layout)

    const [x, y] = getMappingPosition(layout)

    const addedLayout = {
      x: x,
      y: y,
      w: parseInt(column),
      h: parseInt(row) + 1, // +1はヘッダ分
      i: zoneName,
      minH: 2,
      zone: {
        zoneName: zoneName
      }
    }

    const { xStart, xEnd, yStart, yEnd } = calcPosition(
      addedLayout.x, addedLayout.y, addedLayout.w, addedLayout.h)

    console.log(`

        x start: ${xStart}
        x end: ${xEnd}
        y start: ${yStart}
        y end: ${yEnd}

      `)

    const newZone = await createZone({
      floorId: floorId,
      zoneName: zoneName,
      posXStart: xStart,
      posXEnd: xEnd,
      posYStart: yStart,
      posYEnd: yEnd,
      endTime: zoneEndTime,
      createdId: loginUserInfo.staffId
    })
    console.log(newZone)
    // addedLayout.zone.zoneId = newZone.zoneId
    // addedLayout.zone.floorId = newZone.floorId
    // const newLayout = [...layout, addedLayout]
    // console.log(newLayout)
    // setLayout(newLayout)
    fetchMappingInfo(warehouseId, floorId)
  }
  const handleShowModal = (modal) => {
    // console.log(`
    //     ===========
    //     handleShowModal: ${modal}

    //   `)
    // console.log(modal)
    setShowModal(modal)
  }

  const onUpdate = (zoneName, endTime) => {
    // console.log(`
    //   ===================
    //   OnUpdate Called
    //   -------------------
    //   ${zoneName} / ${endTime}
    // `)
    // console.log(zone)
    const data = {
      zoneId: zone.zoneId,
      floorId: floorId,
      zoneName: zoneName,
      endTime: endTime,
      updatedId: loginUserInfo.staffId,
      updatedAt: zone.updatedAt
    }

    console.table(data)

    PutApi('/api/whiteboard/createZone', data, true)
      .then(result => {
        console.log('=== result ===')
        console.log(result)
        if (ErrorHandling(result, alert)) {
          console.error(result.errorDetail)
        } else {
          if (result.errorDetail) {
            console.error(result.errorDetail)
          } else {
            fetchMappingInfo(warehouseId, floorId)
              .then(() => {
                console.log('== after fetchMappingInfo ==')
              })
              .catch((e) => {
                console.error(e)
              })
              .finally(() => alert.success('登録しました'))
          }
        }
      })
  }

  useEffect(() => {
    document.title = 'ゾーンマスタ | Core First'
  }, [])

  if (!grants) return null
  return (
    <>
      <Search title='ゾーンマスタ' showWarehouses showFloors showButton={false} grants={grants} />
      <GridLayout
        mounted={false}
        className='layout'
        layout={layout}
        cols={10}
        rowHeight={HEIGHT} // TODO スタッフの高さに合わせる
        width={1700}
        margin={[12, 16]}
        onLayoutChange={onLayoutChange}
        isResizable={canWrite}
        isDraggable={canWrite}
      >
        {/* {console.log(layout.length)} */}
        {(layout.length)
          ? generateDOM()
          : console.log('=== no layout ===')}

      </GridLayout>
      <ZoneInitPane
        canWrite={canWrite}
        handleAddZone={handleAddZone}
        handleShowModal={handleShowModal}
      />
      <ZoneEditModal
        onUpdate={onUpdate}
        isModal={showModal}
        setIsModal={setShowModal}
        zone={zone}
      />
    </>
  )
}

export default ZoneMaster
