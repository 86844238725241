import moment from 'moment'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  zones: []
}
const zoneMasterReducer = createSlice({
  name: 'zoneMasterReducer',
  initialState,
  reducers: {
    setZones (state, action) {
      const { zones } = action.payload
      state.zones = zones
      // eslint-disable-next-line
      state.zones.map(item => {
        item.id = item.zone.zoneId
        let endTime
        if (item.zone.endTime > 2400) {
          endTime = `${item.zone.endTime.toString().slice(0, 2)}:${item.zone.endTime.toString().slice(-2)} `
        } else {
          endTime = `${moment(String(item.zone.endTime).padStart(4, '0'), 'hmm').format('H:mm')}`
        }
        item.name = `${item.zone.zoneName}  / 最終時間: ${endTime}`
      })
    }
  }
})

export const {
  setZones
} = zoneMasterReducer.actions

export default zoneMasterReducer.reducer
