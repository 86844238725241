import styled from 'styled-components'

export const ShowSpinner = (spinnerRef, positionLeft) => {
  if (spinnerRef) {
    spinnerRef.style.display = 'block'
    spinnerRef.style.position = 'absolute'
    spinnerRef.style.top = '8px'
    spinnerRef.style.left = positionLeft + 'px'
  }
}

export const HideSpinner = (spinnerRef) => {
  if (spinnerRef) spinnerRef.style.display = 'none'
}

export const DotSpinner = styled.div.attrs(props => ({
  // 初期値
  width: props.width || '1em',
  height: props.height || '1em',
  fontSize: props.fontSize || '5px',
  spinnerColor: props.spinnerColor || '255, 255, 255'
}))`
width: ${props => props.width};
height: ${props => props.height};
font-size: ${props => props.fontSize};
margin-top: 15px;
display:none;
border-radius: 50%;
text-indent: -9999em;
animation: load5 1.1s infinite ease;
transform: translateZ(0);
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.2), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.5), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.7), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.2), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.5), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.7), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.2), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.2), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.5), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.7), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.2), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.5), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.7), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.2), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.5), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.7), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.2), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.2), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.5), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.7), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(${props => props.spinnerColor}, 0.2), 1.8em -1.8em 0 0em rgba(${props => props.spinnerColor}, 0.2), 2.5em 0em 0 0em rgba(${props => props.spinnerColor}, 0.2), 1.75em 1.75em 0 0em rgba(${props => props.spinnerColor}, 0.2), 0em 2.5em 0 0em rgba(${props => props.spinnerColor}, 0.2), -1.8em 1.8em 0 0em rgba(${props => props.spinnerColor}, 0.5), -2.6em 0em 0 0em rgba(${props => props.spinnerColor}, 0.7), -1.8em -1.8em 0 0em rgba(${props => props.spinnerColor});
  }
}
`
