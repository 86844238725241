import React, { useState, useEffect } from 'react'
import warchIcon from '../../image/watch-icon.png'
import styled from 'styled-components'

const WrapDiv = styled.div`
  display: flex;
  width: 200px;
  font-size: 12px;
`
const IconImg = styled.img`
  height: 20px;
  margin-right: 5px; 
`
const DateP = styled.p`
  margin-right: 10px;
  height: 20px;
  line-height: 20px;
`
const TimeP = styled.p`
  height: 20px;
  line-height: 20px;
  font-size: 12px;
`

const CurrentDatetime = () => {
  const nextTiming = () => 1000 - Date.now() % 1000
  const dateToString = (date) => {
    const dayOfWeek = date.getDay()
    const dayOfWeekStr = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][dayOfWeek]
    return date.getFullYear() + ' / ' + ('00' + (date.getMonth() + 1)).slice(-2) + ' / ' + ('00' + date.getDate()).slice(-2) + ' ' + dayOfWeekStr
  }
  const timeToString = (date) => {
    return ('00' + (date.getHours())).slice(-2) + ':' + ('00' + (date.getMinutes())).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2)
  }
  const [today, setToday] = useState(() => dateToString(new Date()))
  const [time, setTime] = useState(() => timeToString(new Date()))

  useEffect(() => {
    let timer = setTimeout(function main () {
      timer = setTimeout(main, nextTiming())
      setToday(dateToString(new Date()))
      setTime(timeToString(new Date()))
    }, nextTiming())
    return () => clearTimeout(timer)
  }, [setToday])

  return (
    <WrapDiv>
      <IconImg src={warchIcon} alt='warchIcon' />
      <DateP>{today}</DateP>
      <TimeP>{time}</TimeP>
    </WrapDiv>
  )
}

export default CurrentDatetime
