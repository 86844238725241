import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import styled from 'styled-components'
import qs from 'qs'

import moment from 'moment'
// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { getLatestHour, getEarliestHour } from './MonthlyLaborUtil'
// import { FlexDiv } from './MonthlyLaborComponent'
// import Search from '../Common/Search'
import FUNC from '../../Common/FunctionEnum'
import { GetApi } from '../../Common/ApiAxios'
import { SetWorkDate } from '../../Common/Slice/SearchSlice'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
// import { LaborStaffStates } from './MonthlyLaborSlice'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'
import { MdHome, MdBusiness } from 'react-icons/md'
// import CalenderIcon from '../../image/svg/CalenderIcon.js'
moment.locale('ja')

const workHeaderWidth = '100px 120px 80px'
// Container
const Container = styled.div`
  position: relative;
  display: grid;
  box-sizing: border-box;
  width: calc(100vw - 20px);
  height: calc(100vh - 150px);
  /* overflow: auto; */
`

const LaborContainer = styled.div`
  position: relative;
  display: grid;
  box-sizing: border-box;
  width: 100vw;
  //height: calc(100vh - 200px);
  overflow-x: visible;
  overflow-y: scroll;
`

// Row
const Row = styled.div`
  width: calc(100vw - 20px);
  display: grid;
  grid-template-columns: ${workHeaderWidth} 1fr;
  background-color: ${props => props.weekday === 0 ? 'rgba(255, 209, 209, 0.5)' : (props.weekday === 6 ? 'rgba(203, 249, 255,0.5)' : 'transparent')};
  /* background-color: ${props => props.weekday === 0 ? '#FFD1D1' : (props.weekday === 6 ? '#CCF9FF' : 'transparent')}; */
  border-bottom: 1px solid lightgrey;
`

const Labor = styled.div`
  position: relative;
  /* min-height: 15px; */
  height: 24px;
  line-height: 12px;
  padding: 6px 8px;
  margin: 0px 2px;
  background-color: #1abc9c;
  border-radius: 5px;
  font-weight: 500;
  text-align: left;
  font-size: .8em;
  overflow:hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  border: 2px dotted green;
`
const RemoteLabor = styled(Labor)`
  background-color: #1a77d0;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.1) 5px, rgba(255, 255, 255, 0.1) 12px);
  border: 2px dotted navy;
`

const TableCell = styled.div`
  padding: 12px 16px;
  color: #333;
  font-size: 0.8em;
  text-align: center;
`

const CellData = styled.span`
  /* padding: 6px 0px; */
`

const Total = styled.div`
  display: grid;
  grid-template-columns: ${workHeaderWidth} 1fr;
  background-color: whitesmoke;
  font-weight: bold;
  height: 36px;
  z-index: 2;
  border-top: gray double 1px;
`

const LaborTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff352',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

const Title = '月別個人勤怠'
const MontylyLabor = () => {
  // const dispatch = useDispatch()
  const [summary, setSummary] = useState([])
  const [labor, setLabor] = useState([])
  const [totalHour, setTotalHour] = useState([])
  const [hourTick, setHourTick] = useState(0)
  const [quarterTick, setQuarterTick] = useState(0)
  const [startHour, setStartHour] = useState(0)
  const [endHour, setEndHour] = useState(0)
  const [staffId, setStaffId] = useState(null)
  const [searchDate, setSearchDate] = useState(null)
  const [searchWarehouseId, setSearchWarehouseId] = useState(null)
  const [searchFloorId, setSearchFloorId] = useState(null)

  const workHours36h = new Array(hourTick).fill(1).map((n, i) => Number(startHour) + i)
  // TODO undefined考慮
  const workHours15min = new Array(quarterTick).fill(1).map((n, i) => n + i - 1)

  const dispatch = useDispatch()
  const { location } = useReactRouter()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const grants = loginUserInfo.menuList &&
    loginUserInfo.menuList.find(menu => menu.functionMenuId === (FUNC.RESULT_LABOR_LIST))

  const companyId = commonSearch.companyId
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId
  const workDate = commonSearch.workDate
  const loginStaffId = loginUserInfo.staffId

  // const laborStaffSearch = LaborStaffStates()
  // const staffId_ = laborStaffSearch.laborStaffId
  // let staffId = useSelector(state => state.laborStaffId)
  // console.log(`
  //
  // staffId: ${staffId}
  // staffId_: ${staffId_}
  //
  // `)
  // if (staffId == null) staffId = loginStaffId

  const [targetDate, setTargetDate] = useState(moment(workDate).toDate())
  const changeTargetDate = date => {
    setTargetDate(date)
    setSearchDate(date)
    dispatch(SetWorkDate(moment(date).format('YYYY-MM-DD')))
  }

  // ===============
  // Styled Component
  // ===============
  const BorderContainerStyle = styled.div`
    position: absolute;
    width: calc(100vw - 20px);
    height: calc(100vh - 150px);
    display: grid;
    grid-template-columns: ${workHeaderWidth} ${props => 'repeat(' + props.quarterTick + ', 1fr)'};
    background-color: #F5F5F5;
  `
  const BorderCol = styled.span`
    box-sizing: border-box;
    display: block;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  `
  const BorderContainer = ({ quarterTick }) => (
    <BorderContainerStyle quarterTick={quarterTick}>
      <BorderCol /><BorderCol /><BorderCol />
      {workHours15min.map(h => (
        <BorderCol key={h} />
      ))}

    </BorderContainerStyle>
  )
  // header
  const HeaderCellData = styled.span`
  color: #fff;
`
  const HeaderCell = styled.div`
  padding: 12px 16px;
  color: #333;
  font-size: 0.8em;
`
  const HeaderHourCell = styled.div`
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #fff;
  font-size: 0.8em;
  white-space:nowrap;
  border-right: 1px solid darkgray;
`

  const Header = styled.div`
    display: grid;
    grid-template-columns: ${workHeaderWidth} 1fr;
    background-color: #202125;
    z-index: 1;
    width: calc(100vw - 20px);
    /* position: fixed;
    width: 3600px; */
  `
  const HeaderLabor = styled.div`
    display: grid;
    grid-template-columns:  ${props => 'repeat(' + props.hourTick + ', 1fr)'};
  `

  const TableHeader = ({ hourTick }) => (
    <Header>
      <HeaderCell><HeaderCellData>日付</HeaderCellData></HeaderCell>
      <HeaderCell><HeaderCellData>勤務時間</HeaderCellData></HeaderCell>
      <HeaderCell><HeaderCellData>時間</HeaderCellData></HeaderCell>
      <HeaderLabor hourTick={hourTick}>
        {workHours36h.map(h => {
          return (
            <HeaderHourCell key={h}>{h}</HeaderHourCell>
          )
        })}
      </HeaderLabor>
    </Header>
  )

  // Labor
  const LaborRow = styled.ul`
    display: grid;
    list-style: none;
    padding: 6px 0px;
    grid-template-columns: ${props => 'repeat(' + props.quarterTick + ', 1fr)'};
  `

  // ===============
  // useEffect
  // ===============
  useEffect(() => {
    document.title = `${Title} | Core First`
  }, [])

  useEffect(() => {
    console.log('--- location ---')
    console.log(location)
    console.log(loginStaffId)

    const _staffId = location.search === ''
      ? loginStaffId
      : qs.parse(location.search, { ignoreQueryPrefix: true }).staff_id
    console.log(_staffId)
    setStaffId(_staffId)

    const _searchDate = location.search === ''
      ? workDate
      : qs.parse(location.search, { ignoreQueryPrefix: true }).work_date
    console.log(_searchDate)
    setSearchDate(_searchDate)

    const _floorId = location.search === ''
      ? floorId
      : qs.parse(location.search, { ignoreQueryPrefix: true }).floor_id
    console.log(_floorId)
    setSearchFloorId(_floorId)

    const _warehouseId = location.search === ''
      ? warehouseId
      : qs.parse(location.search, { ignoreQueryPrefix: true }).warehouse_id
    console.log(_warehouseId)
    setSearchWarehouseId(_warehouseId)

    // console.log(`
    //
    // staff_id: ${location.query.staff_id}
    // work_date: ${location.query.work_date}
    //
    // `)
  }, [warehouseId, floorId, loginStaffId, workDate])

  useEffect(() => {
    async function getLabor () {
      dispatch(SetLoadSpinner(true))
      const startDate = moment(searchDate).startOf('month').format('YYYY-MM-DD')
      const endDate = moment(searchDate).endOf('month').format('YYYY-MM-DD')

      console.log(`

      startDate: ${startDate}
      endDate: ${endDate}

      `)
      // 勤怠サマリ
      const summaryRet = await GetApi(`/api/labor/summary/${staffId}/${startDate}/${endDate}`)
      const summaryData = summaryRet.data
      console.log(summaryData)
      // console.log(summaryData[0])
      setSummary(summaryData)
      dispatch(SetLoadSpinner(false))

      // 合計時間
      const total = summaryData.reduce((total, h) => ({ workingHours: total.workingHours + h.workingHours }))
      console.log(total.workingHours)
      setTotalHour(total.workingHours / 60)

      // 工程実績
      // const uri = `/api/labor/${searchWarehouseId}/${searchFloorId}/-/${staffId}/${startDate}/${endDate}`
      const uri = `/api/labor/${searchWarehouseId}/-/-/${staffId}/${startDate}/${endDate}`
      console.log(uri)
      const laborRet = await GetApi(uri)
      const laborData = await laborRet.data
      // console.log(laborData)
      setLabor(laborData)
      // 出勤min
      // const startDateArray = []
      // summaryData.forEach(l => {
      //   if (l.minTime != null) {
      //     // console.log(l.minTime)
      //     startDateArray.push(moment(l.minTime).toDate())
      //   }
      // })
      // console.log(startDateArray)
      // const minStart = moment(Math.min.apply(null, startDateArray))
      const _startHour = getEarliestHour(summaryData)
      // 退勤max
      let lastDay
      const maxEnd = getLatestHour(summaryData)
      console.log('=== maxEnd ===')
      console.log(maxEnd)
      // const _startHour = moment(minStart).format('H')
      const _endHour = maxEnd + 1
      const displayHours = _endHour - _startHour
      setStartHour(_startHour)
      setEndHour(_endHour)
      setHourTick(displayHours)
      setQuarterTick(displayHours * 4)

      console.log(lastDay)
      console.log(maxEnd)
      console.log(displayHours)
    }
    console.log(`

        companyId: ${companyId}
        warehouseId: ${warehouseId}
        searchWarehouseId: ${searchWarehouseId}
        floorId: ${floorId}
        searchFloorId: ${searchFloorId}

      `)
    if (companyId > 0 && searchWarehouseId != null && searchFloorId != null && staffId != null && searchDate != null) {
      getLabor()
    }
    return () => {
      console.log('clean up')
    }
  }, [searchWarehouseId, searchFloorId, searchDate])

  if (!grants) return null
  return (
    <>
      {/* <FlexDiv>
        <Search
          title={Title}
          addStyle='padding: 0 0 0 16px;'
          showButton={false}
          inSubCompany
          grants={grants}
        /> */}
      {/* <WrapDiv>
          <DatePicker
            className='normalCalendar'
            dateFormat='yyyy/MM'
            locale='ja'
            selected={targetDate}
            onChange={date => changeTargetDate(date)}
            peekNextMonth
            showMonthYearPicker
          />
          <CalenderIcon />
        </WrapDiv> */}
      {/* </FlexDiv> */}
      <div style={{ padding: '12px 16px' }}>
        <div style={{ color: 'white', marginBottom: '8px' }}>{labor && labor[0] && labor[0].staffId} {labor && labor[0] && labor[0].staffName}</div>
        <Container>
          <TableHeader hourTick={hourTick} />
          <BorderContainer quarterTick={quarterTick} />
          <LaborContainer>
            {summary && labor && summary.map((data) => {
              const operations = labor.filter(l => (l.workDate === data.workDate))
              // console.log('=== workDate ===')
              // console.log(data.workDate)
              // console.log(operations)
              const startTime = data.minTime == null ? null : moment(data.minTime).format('H:mm')
              const endTime = data.maxTime == null ? null : moment(data.maxTime).format('H:mm')
              const workTime = startTime == null && endTime == null ? '' : startTime + '～' + endTime
              // console.log(data.workDate)
              // console.log(operations)
              const hasRemote = operations.some(o => o.workPlace === 2)
              return (
                <React.Fragment key={data.workDate}>
                  <Row weekday={moment(data.workDate).weekday()}>
                    {/* 日付 */}
                    <TableCell>
                      <CellData style={{ display: 'flex' }}>
                        {moment(data.workDate).format('M/D')}({moment(data.workDate).format('ddd')})
                        {workTime === '' ? null : (hasRemote ? <MdHome size={16} style={{ marginLeft: '3px' }} /> : <MdBusiness size={16} style={{ marginLeft: '3px' }} />)}
                      </CellData>
                    </TableCell>
                    {/* 勤務時間 */}
                    <TableCell>
                      <CellData>{workTime}</CellData>
                    </TableCell>
                    {/* 労働時間 */}
                    <TableCell>
                      <CellData>{(data.workingHours / 60).toFixed(2)} h</CellData>
                    </TableCell>
                    <LaborRow quarterTick={quarterTick}>
                      {operations && operations[0] && operations[0].laborId > 0 && operations.map((o, idx) => {
                        const startIdx = o.startIndex + 1 - Number(startHour) * 4
                        const endIdx = o.endIndex + 1 - Number(startHour) * 4
                        return (
                          o.workPlace === 2 ? <LaborTooltip title={o.operationName} placement='top-start'><RemoteLabor key={idx} style={{ gridColumn: `${startIdx}/${endIdx}` }}>{o.operationName}</RemoteLabor></LaborTooltip>
                            : <LaborTooltip title={o.operationName} placement='top-start'><Labor key={idx} style={{ gridColumn: `${startIdx}/${endIdx}` }}>{o.operationName}</Labor></LaborTooltip>
                        )
                      })}
                    </LaborRow>
                  </Row>
                </React.Fragment>
              )
            })}
          </LaborContainer>
          <Total>
            <TableCell><CellData>合計</CellData></TableCell>
            <TableCell><CellData /></TableCell>
            <TableCell><CellData />{totalHour}h</TableCell>
            <div />
          </Total>
        </Container>
      </div>
    </>
  )
}

export default MontylyLabor
