/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Row from './Row'
import AllUpdate from './AllUpdate/AllUpdate'

const WrapDiv = styled.div`
`
const ListUl = styled.ul`
  box-sizing: content-box;
`

const Body = (props) => {
  const { laborToggle, allUpdateOpen, temps } = props
  const { individualMonthly } = useSelector(state => state)
  const { laborSlice } = useSelector(state => state)
  const [dateRecords, setDateRecords] = useState()

  useEffect(() => {
    setDateRecords(individualMonthly.dateRecords)
  }, [individualMonthly])

  if (!dateRecords) return null

  return (
    <WrapDiv>
      <ListUl id='LaborList'>
        {laborToggle === 'planned' && allUpdateOpen && <AllUpdate dateItems={dateRecords[0]} {...props} />}
        {dateRecords.map((dateItems, index) => {
          const temperature = temps?.filter(temp => temp.filingDate === dateItems.dateValue)
          const planned = laborSlice?.attendance?.attendance.find(att => att.workDt === dateItems.dateValue)
          return (
            <Row
              key={dateItems.dateValue}
              dateItems={dateItems}
              rowIndex={index + 1}
              temperature={temperature}
              planned={planned}
              {...props}
            />
          )
        })}
      </ListUl>
    </WrapDiv>
  )
}
Body.propTypes = {
  laborToggle: PropTypes.string,
  allUpdateOpen: PropTypes.bool
}
export default Body
