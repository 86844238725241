import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import 'react-datepicker/dist/react-datepicker.css'

import { FlexDiv } from './MonthlyLaborComponent'
import Search from '../../Common/Search'
import FUNC from '../../Common/FunctionEnum'
import { GetApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import SortableTable from './SortableTable'
import SideMenu from '../../Common/Molecules/SideMenu'
import { getFirstAndLastDate } from '../../Common/utils'
import { calcOvertime, getDaysCountInMonth } from './helper'
import GetPaidHolidays from '../../Common/Api/GetPaidHolidays'

const Title = '実績工程一覧'

const FlexRow = styled(FlexDiv)`
  line-height: 39px;
`
const FlexItem = styled.div`
  width: ${props => props.width || '30'}px;
  padding: 0 0 0 10px;
  height: 40px;
`
const CategorySummaryDiv = styled.div`
  background: #222;
  margin-left: 25px;
  color: white;
`
const CategorySummaryBody = styled.div`
  height: calc(100vh - 50px);
  overflow-y: scroll;
`

const CategorySummaryHeader = () => (
  <FlexRow style={{ fontWeight: 'bold', borderBottom: '1px solid white', width: 300 }}>
    <FlexItem width={170}>カテゴリ名</FlexItem>
    <FlexItem width={90} style={{ textAlign: 'right' }}>時間</FlexItem>
    <FlexItem width={60} style={{ textAlign: 'right' }}>(%)</FlexItem>
  </FlexRow>
)

function orgRound (value, base) {
  return Math.round(value * base) / base
}

const calcPercentabe = (value, total, base = 10) => {
  return orgRound(value / total * 100, base)
}

/**
 * Labor summary on a floor in a month
 */
function MonthlyLaborSummary () {
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const grants = loginUserInfo.menuList &&
    loginUserInfo.menuList.find(menu => menu.functionMenuId === (FUNC.RESULT_LABOR_LIST))

  // const companyId = commonSearch.companyId
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId
  const workDate = commonSearch.workDate
  // const loginStaffId = loginUserInfo.staffId

  const [summary, setSummary] = useState()
  const [catSummary, setCatSummary] = useState()
  const [catSummaryTotalHours, setCatSummaryTotalHours] = useState()

  // const [totalValues, setTotalValues] = useState()
  const dispatch = useDispatch()

  // 月別個人別実績工程サマリ
  const getDataByPerson = async () => {
    dispatch(SetLoadSpinner(true))
    const [firstDay, lastDay] = getFirstAndLastDate(workDate.substring(0, 7))
    const uri = `/api/labor/summary/monthly/${warehouseId}/${floorId}/-/${firstDay}/${lastDay}`
    console.log(uri)

    Promise.all([GetApi(uri), GetPaidHolidays(warehouseId, floorId, workDate)])
      .then(results => {
        const summaryData = results[0].data.staffs.map(staff => {
          const paidHoliday = staff.staffId in results[1].data ? results[1].data[staff.staffId] : null

          staff.plannedLaborHolidayCnt = paidHoliday ? paidHoliday.plannedLaborHolidayCnt : 0
          staff.plannedLaborHolidayDateList = paidHoliday ? paidHoliday.plannedLaborHolidayDateList : []
          staff.resultLaborHolidayCnt = paidHoliday ? paidHoliday.resultLaborHolidayCnt : 0
          staff.resultLaborHolidayDateList = paidHoliday ? paidHoliday.resultLaborHolidayDateList : []
          return staff
        })
        setSummary(summaryData)
        dispatch(SetLoadSpinner(false))
      })
  }

  // 月別カテゴリ別実績工程サマリ
  const getDataByCategory = async () => {
    dispatch(SetLoadSpinner(true))
    const [firstDay, lastDay] = getFirstAndLastDate(workDate.substring(0, 7))
    const uri = `/api/labor/category/summary/monthly/${warehouseId}/${floorId}/-/${firstDay}/${lastDay}`
    console.log(uri)
    const { data } = await GetApi(uri)
    const sumTotal = (acc, summary) => {
      // console.log(summary.totalHours)
      return acc + Number(summary.totalHours)
    }
    console.log(data.summary.reduce(sumTotal, 0))
    setCatSummaryTotalHours(data.summary.reduce(sumTotal, 0))
    setCatSummary(data)
    dispatch(SetLoadSpinner(false))
  }

  // useEffect
  useEffect(() => {
    document.title = `${Title} | Core First`
  }, [])

  useEffect(() => {
    getDataByPerson()
    getDataByCategory()
  }, [commonSearch])

  // useEffect(() => {
  //   if (summary && summary.staffs && summary.staffs.length > 0) {
  //     const staffs = summary.staffs
  //     const resultTotalHours = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.resultTotalHours
  //     }, 0)
  //     const plannedTotalHours = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.plannedTotalHours
  //     }, 0)
  //     const remainingHours = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.remainingHours
  //     }, 0)
  //     const resultTotalWorkDate = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.resultTotalWorkDate
  //     }, 0)
  //     const plannedTotalWorkDate = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.plannedTotalWorkDate
  //     }, 0)
  //     const remainingWorkDate = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.remainingWorkDate
  //     }, 0)
  //     const resultHoliday = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.resultHoliday
  //     }, 0)
  //     const plannedHoliday = staffs.reduce(function (total, currentValue) {
  //       return total + currentValue.plannedHoliday
  //     }, 0)
  //     setTotalValues({
  //       resultTotalHours: resultTotalHours,
  //       plannedTotalHours: plannedTotalHours,
  //       remainingHours: remainingHours,
  //       resultTotalWorkDate: resultTotalWorkDate,
  //       plannedTotalWorkDate: plannedTotalWorkDate,
  //       remainingWorkDate: remainingWorkDate,
  //       resultHoliday: resultHoliday,
  //       plannedHoliday: plannedHoliday
  //     })
  //   } else {
  //     setTotalValues(null)
  //   }
  // }, [summary])

  function createData (
    staffId, name, plannedHours, resultHours, remainingHours,
    plannedDays, resultDays, plannedDayOff, resultDayOff,
    plannedLaborHolidayCnt, plannedLaborHolidayDateList,
    resultLaborHolidayCnt, resultLaborHolidayDateList) {
    return {
      staffId,
      name,
      plannedHours,
      resultHours,
      remainingHours,
      plannedDays,
      resultDays,
      plannedDayOff,
      resultDayOff,
      plannedLaborHolidayCnt,
      plannedLaborHolidayDateList,
      resultLaborHolidayCnt,
      resultLaborHolidayDateList
    }
  }

  let rows = []

  if (summary && summary.length > 0) {
    rows = summary?.map(staff => (
      createData(
        staff.staffId,
        staff.staffName,
        staff.plannedTotalHours,
        staff.resultTotalHours,
        calcOvertime(staff.resultTotalHours, getDaysCountInMonth(workDate)),
        // staff.remainingHours,
        staff.plannedTotalWorkDate,
        staff.resultTotalWorkDate,
        // staff.remainingWorkDate,
        staff.plannedHoliday,
        staff.resultHoliday,
        staff.plannedLaborHolidayCnt,
        staff.plannedLaborHolidayDateList,
        staff.resultLaborHolidayCnt,
        staff.resultLaborHolidayDateList
      )
    ))
  }

  const headCells = [
    { id: 'staffId', numeric: false, disablePadding: false, label: 'ID' },
    { id: 'name', numeric: false, disablePadding: false, label: '氏名' },
    { id: 'plannedHours', numeric: true, disablePadding: false, label: '予定工数' },
    { id: 'resultHours', numeric: true, disablePadding: false, label: '実績工数' },
    { id: 'remainingHours', numeric: true, disablePadding: false, label: '残業時間' },
    { id: 'plannedDays', numeric: true, disablePadding: false, label: '予定出勤日数' },
    { id: 'resultDays', numeric: true, disablePadding: false, label: '実績出勤日数' },
    // { id: 'remainingDays', numeric: true, disablePadding: false, label: '残出勤日数' },
    { id: 'plannedDayOff', numeric: true, disablePadding: false, label: '当月予定有給取得数' },
    { id: 'resultDayOff', numeric: true, disablePadding: false, label: '当月実績有給取得数' },
    { id: 'plannedLaborHolidayCnt', numeric: true, disablePadding: false, label: '年間予定有給取得数' },
    { id: 'resultLaborHolidayCnt', numeric: true, disablePadding: false, label: '年間実績有給取得数' }
  ]

  if (!grants) return null
  return (
    <>
      <Search
        title={Title} showWarehouses showFloors showCalendar calendarType={2}
        addAllWarehouse addAllFloor addStyle='padding: 0 0 0 16px;'
        showButton={false} grants={grants}
      />
      <SortableTable
        size='small' width='100%'
        headCells={headCells} rows={rows}
        workDate={workDate}
      />
      {/* カテゴリ別 */}
      <SideMenu isView width='350px' isClose>
        <CategorySummaryDiv>
          <CategorySummaryHeader />
          <CategorySummaryBody>
            {catSummary && catSummary.summary && catSummary.summary.length > 0 && catSummary.summary.map((s) => (
              <FlexRow style={{ borderBottom: '1px solid white', width: 300 }} key={s.categoryId}>
                <FlexItem width={170}>{s.categoryName}</FlexItem>
                <FlexItem width={90} style={{ textAlign: 'right' }}>{s.totalHours} h</FlexItem>
                <FlexItem width={60} style={{ textAlign: 'right' }}>{calcPercentabe(s.totalHours, catSummaryTotalHours)}%</FlexItem>

              </FlexRow>
            ))}
          </CategorySummaryBody>
        </CategorySummaryDiv>
      </SideMenu>
    </>
  )
}

export default MonthlyLaborSummary
