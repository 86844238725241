/* eslint-env browser */
import axios from 'axios'
import qs from 'qs'
import { restfulApiConfig } from '../Common/Config'
import { clearAuthCookie, setAccessTokenCookie } from './utils'
const host = restfulApiConfig.host

// クッキー取得
export const GetToken = () => {
  return getCookieValue('accessToken')
}

// エラーログ排出
export const exportErrorLog = (result) => {
  const error = result.errorDetail
  if (error.response) {
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
  } else if (error.request) {
    console.log(error.request)
  } else {
    console.log('Error', error.message)
  }
}

// トークン有効期限切れ
export const forceLogout = () => {
  console.log('=== overTimeToken')
  console.log('href: ', window.location.href)
  clearAuthCookie()
  window.location.href = '/login'
}

function getCookieValue(name) {
  const re = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*=\\s*([^;]*).*$)|^.*$`)
  const ret = document.cookie.replace(re, '$1')
  return ret
}

export async function refreshAccessToken(apiUrl) {
  const refreshToken = getCookieValue('refreshToken')
  // リフレッシュトークンの期限が過ぎている場合はログアウト
  if (apiUrl != null && apiUrl !== '/api/login' && !refreshToken) {
    forceLogout()
  }

  const loginStaffId = ''
  const url = host + '/api/refresh'
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
      // Authorization: `Bearer ${GetToken()}`
    },
    data: qs.stringify({ refreshToken: refreshToken, loginStaffId: loginStaffId }),
    url
  }
  return axios(options)
    .then(function (result) {
      setAccessTokenCookie(result.data.accessToken)
      return result.data.accessToken
    })
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      return errorReponse
    })
}

// POST
export const PostApi = async (api, data, isJson = false) => {
  const token = GetToken() || await refreshAccessToken(api)
  if (api != null && api !== '/api/login') {
    if (!token) forceLogout()
  }
  const url = host + api
  const convertedData = isJson ? JSON.stringify(data) : qs.stringify(data)
  const contentType = isJson ? 'application/json' : 'application/x-www-form-urlencoded'
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`
    },
    data: convertedData,
    url
  }

  // InsertLogtoMongoDB('POST', token, data, url)

  return axios(options)
    .then(results => results)
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      return errorReponse
    })
}

// GET
export const GetApi = async (api, data) => {
  const token = GetToken() || await refreshAccessToken(api)
  if (!token) forceLogout()

  const url = host + api
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: data,
    url
  }

  // InsertLogtoMongoDB('GET', token, data, url)

  return axios(options)
    .then(results => results)
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      console.log(error)
      return errorReponse
    })
}

// PUT
export const PutApi = async (api, data, isJson = false) => {
  const token = GetToken() || await refreshAccessToken(api)
  if (!token) forceLogout()

  const url = host + api
  const convertedData = isJson ? JSON.stringify(data) : qs.stringify(data)
  const contentType = isJson ? 'application/json' : 'application/x-www-form-urlencoded'
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`
    },
    data: convertedData,
    url
  }

  // InsertLogtoMongoDB('PUT', token, data, url)

  const result = axios(options)
    .then((results) => {
      return results
    })
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      return errorReponse
    })
  return result
}

// DELETE
/**
 *
 * @param {*} api host部分を除いたuri
 * @param {*} data payload
 * @param {*} isJson applicatin/jsonでdataを渡すかどうか
 * @returns
 */
export const DeleteApi = async (api, data, isJson = false) => {
  const token = GetToken() || await refreshAccessToken(api)
  if (!token) forceLogout()

  const url = host + api
  const convertedData = isJson ? JSON.stringify(data) : qs.stringify(data)
  const contentType = isJson ? 'application/json' : 'application/x-www-form-urlencoded'
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`
    },
    data: convertedData,
    url
  }

  // InsertLogtoMongoDB('DELETE', token, data, url)

  const result = axios(options)
    .then((results) => {
      return results
    })
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      return errorReponse
    })
  return result
}

// multipart/form-data
export const MultipartApi = async (api, method, formData, data) => {
  // let token = GetToken()
  // if (hasToRefreshAccessToken(api)) {
  //   const result = await regenerateAccessTokenWithRefreshToken()
  //   if (!result.errorDetail) {
  //     token = result.data.accessToken
  //   }
  // }

  const token = GetToken() || await refreshAccessToken(api)
  if (!token) forceLogout()

  const url = host + api
  const options = {
    method: method,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    },
    data: formData,
    url
  }

  // InsertLogtoMongoDB(method, token, data, url)

  const result = axios(options)
    .then((results) => {
      return results
    })
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      return errorReponse
    })
  return result
}

// mongoDBにログ保存処理
// export const InsertLogtoMongoDB = async (method, token, data, apiUrl) => {
//   const today = new Date()
//   const year = today.getFullYear()
//   const month = ('00' + (today.getMonth() + 1)).slice(-2)
//   const day = ('00' + today.getDate()).slice(-2)
//   const hour = ('00' + today.getHours()).slice(-2)
//   const minute = ('00' + today.getMinutes()).slice(-2)
//   const second = ('00' + today.getSeconds()).slice(-2)
//   const nowDateTime = year + '/' + month + '/' + day + ' ' + hour + ':' + minute + ':' + second

//   const url = '/mongoLog'
//   const params = {
//     method: method,
//     token: token,
//     params: data,
//     url: apiUrl,
//     dateTime: nowDateTime
//   }
//   const options = {
//     method: 'GET',
//     params: params,
//     url
//   }

//   // return null

//   return axios(options)
//     .then(results => results)
//     .catch(error => {
//       const errorReponse = {
//         errorDetail: error
//       }
//       return errorReponse
//     })
// }

// 休日一覧取得
export const GetHoliday = async (setHoliday) => {
  const url = 'https://holidays-jp.github.io/api/v1/date.json'
  const options = {
    method: 'GET',
    url
  }
  return axios(options)
    .then(results => {
      setHoliday(results.data)
    })
    .catch(error => {
      const errorReponse = {
        errorDetail: error
      }
      console.log(error)
      return errorReponse
    })
}
