import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GetApi } from '../../Common/ApiAxios'
import ErrorHandling from '../Common/ErrorHandling'
import Select from 'react-select'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import { useAlert } from 'react-alert'
import PropTypes from 'prop-types'

const SelectWrapper = styled.div`
  width: 80%;
`
const StaffSkillMasterForm = ({ isDeleted, register, setValue, defaultSkills, canWrite, isView }) => {
  const alert = useAlert()
  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getSkillOptions()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 【API】スタッフスキル一覧取得
  const [staffSkillOptions, setStaffSkillOptions] = useState(<></>)
  const getSkillOptions = async () => {
    const result = await GetApi('/api/master/skill')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.responseList)) {
      const options = result.data.responseList.map(value => {
        return Object.assign({
          value: value.skillId,
          label: value.skillName,
          lastUpdatedAt: '',
          lastUpdatedId: ''
        })
      })
      setStaffSkillOptions(options)
    }
  }

  // スキル情報
  register({ name: 'skills' })
  const [selectedSkills, setSelectedSkills] = useState({ selectedOption: [] })

  // スキル選択状態イベントハンドラ
  const handleMultiChange = selectedOption => {
    setValue('skills', selectedOption)
    setSelectedSkills({ selectedOption })
  }

  // スタッフ切り替え、新規登録ボタン押下時のスキル選択状態制御
  useEffect(() => {
    if (Array.isArray(defaultSkills)) {
      const options = defaultSkills.map(value =>
        Object.assign({
          value: value.skillId,
          label: value.skillName,
          lastUpdatedId: value.updatedId,
          lastUpdatedAt: value.updatedAt
        })
      )
      setValue('skills', options)
      setSelectedSkills({ selectedOption: options })
    } else {
      setValue('skills', [])
      setSelectedSkills({ selectedOption: [] })
    }
  // デフォルトスキル変更検知時のみ実行
  // eslint-disable-next-line
  }, [defaultSkills])

  return (
    <MasterDetailWrap isView={isView}>
      <ul>
        <li style={{ height: '2vh' }}><label /><div /></li>
        <li>
          <label style={{ fontSize: '0.8rem' }}>スタッフ所有スキル</label>
          <div>
            <SelectWrapper>
              <Select
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                menuPosition='absolute'
                menuPlacement='bottom'
                className='react-select'
                name='skills'
                placeholder='所有スキルを選択してください'
                value={selectedSkills.selectedOption}
                options={staffSkillOptions}
                onChange={handleMultiChange}
                isMulti
                closeMenuOnSelect={false}
                isDisabled={!canWrite || isDeleted}
              />
            </SelectWrapper>
          </div>
        </li>
        <li style={{ height: '2vh' }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

StaffSkillMasterForm.propTypes = {
  isDeleted: PropTypes.bool,
  register: PropTypes.any,
  setValue: PropTypes.func,
  defaultSkills: PropTypes.array,
  isView: PropTypes.bool,
  canWrite: PropTypes.bool
}

export default StaffSkillMasterForm
