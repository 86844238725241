/* eslint-env browser */
import React, { useRef } from 'react'
import styled from 'styled-components'
import Header from './Header/Header'
import Body from './Body/Body'

const WrapDiv = styled.div`
  width: calc(100vw - 20px);
  max-height: calc(100vh - 150px);
  overflow: auto;
  direction: rtl;

  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  ::-webkit-scrollbar-track {
    :horizontal {
      margin-left: 200px;
    }
    :vertical {
      margin-top: 30px;
    }
  }

  ::-webkit-scrollbar-thumb {
    border: 3px solid #111;
    background-clip: padding-box;
    background-color: rgba(112, 112, 112);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
`

const InnerDiv = styled.div`
  direction: ltr;
  width: 2420px;
`

const GanttChart = (props) => {
  const wrapRef = useRef()
  return (
    <WrapDiv id='laborsWrap' ref={wrapRef}>
      <InnerDiv>
        <Header {...props} />
        <Body {...props} wrapRef={wrapRef} />
      </InnerDiv>
    </WrapDiv>
  )
}

export default GanttChart
