/* eslint-env browser */
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdHome, MdBusiness, MdPhoneIphone, MdComputer } from 'react-icons/md'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { PutApi, DeleteApi, PostApi, exportErrorLog, forceLogout } from '../Common/ApiAxios'
import { DotSpinner, ShowSpinner, HideSpinner } from '../Common/Spinner'
import { toFormatShortDateTime, getHoursDiff } from '../Common/utils'
import {
  mouseXToTaskIndex, drawNewLabor,
  drawMoveResizeLabor, apiGetLabors
} from './LaborSlice'

const iconStyle = {
  marginLeft: 4, marginRight: 4
}
// styled
const LaborRow = styled.li`
  display: flex;
  width:2220px;
  min-height:50px;
  max-height:50px;
  background:#fff;
  margin-bottom: 3px;
  canvas{
      margin:0;
      padding:0;
      vertical-align:bottom;
  }
`

const DeleteButtonDiv = styled.div`
  display: ${props => props.display};
  z-index: 1;
  margin-top: 12px;
  margin-left: ${props => (props.contextMenuPosition - 2220)}px;
`

const DetailUl = styled.ul`
  display: ${props => props.display};
  top: ${props => props.detailPositionTop}px;
  ${props => props.detailPositionLeft}
  ${props => props.detailPositionRight}
  color: #000;
  background: #f8fdb0;
  padding: 10px 12px;
  border-radius: 5px;
  pointer-events: none;
  position: fixed;
  z-index: 6;
  list-style: none;
  font-size: 14px;
  li{
    margin-bottom: 10px;
    :last-child{
      margin-bottom: 0;
    }
  }
`

// 定数
const BUTTON_NUM_LEFT = 0 // マウスイベント 左
const BUTTON_NUM_RIGHT = 2 // マウスイベント 右
const CANV_LEFT = 15 // キャンバスのLEFTの位置
const MINUTES_15_WIDTH = 15 // 15分の幅
const MIN_INDEX = 0 // 36時間を15分単位での最小INDEX
const MAX_INDEX = 144 // 36時間を15分単位での最大INDEX
const CUR_DEFAULT = 'default' // マウスポインター デフォルト矢印
const CUR_TEXT = 'text' // マウスポインター テキスト
const CUR_POINTER = 'pointer' // マウスポインター 指
const CUR_NO_DROP = 'no-drop' // マウスポインター ドロップ禁止
const CUR_W_RESIZE = 'w-resize' // マウスポインター 左端矢印
const CUR_E_RESIZE = 'e-resize' // マウスポインター 右端矢印
const DRAW_NEW = 'new' // 描画する内容 新規
const DRAW_MOVE = 'move' // 描画する内容 移動
const DRAW_RESIZE = 'resize' // 描画する内容 リサイズ
const DRAW_NONE = '' // 何もしてない状態
const INTERVAL_TIME = 200 // インターバル
const SPINNER_COLOR = '200, 200, 100'

// キャンバス行
const CanvasRow = ({
  isPlanned, grants, staffId, register, workPlace, id, nowTimeIndex, wrapRef, approved, staffDivisionFilter
}) => {
  // 共通
  const dispatch = useDispatch()
  const { commonSearch, laborSlice, loginUserInfo } = useSelector(state => state)
  const workDate = commonSearch.workDate
  const operations = laborSlice.operations // 工程一覧
  const selectOperation = laborSlice.selectOperation // 選択した工程
  const selectOpeWorkPlace = laborSlice.selectOpeWorkPlace // 選択した勤怠区分
  const dbLabors = laborSlice.labors // DBレイバーリストからこの行のレイバーのみ抽出
  const plannedAttendance = laborSlice.attendance
  const canvasRef = useRef()
  const spinnerRef = useRef()
  const [drawing, setDrawing] = useState('') // 描写のモード new：新規、move：既存移動、resize：既存リサイズ
  const [labors, setLabors] = useState([]) // 既存レイバーリスト
  const [labor, setLabor] = useState() // 現在操作しているレイバー
  const [beforeLabor, setBeforeLabor] = useState()// 移動・リサイズ前のレイバー位置を覚えとく

  const canWrite = grants && (grants.writeGrantDivision === 2 ||
    (grants.writeGrantDivision === 1 && loginUserInfo.warehouseId === commonSearch.warehouseId) ||
  (grants.writeGrantDivision === 3 && loginUserInfo.staffId === staffId))

  // 移動用
  const [startDiffIndex, setStartDiffIndex] = useState(0) // 移動開始時のマウスの位置と対象レイバーのスタート位置との差
  const [endDiffIndex, setEndDiffIndex] = useState(0) // 移動開始時のマウスの位置と対象レイバーのエンド位置との差

  // 新規用
  const [startIndex, setStartIndex] = useState(0) // 新規登録時のスタート位置

  // 右クリック用
  const [showContextMenu, setShowContextMenu] = useState(false) // 右クリックメニュー表示フラグ
  const [contextMenuPosition, setContextMenuPosition] = useState(0) // 右クリックメニュー表示位置
  const [selectDelLabor, setSelectDelLabor] = useState() // 右クリックしたレイバー

  // 詳細表示用
  const [showDetail, setShowDetail] = useState(false) // マウスオン時詳細表示
  const [detailPositionLeft, setDetailPositionLeft] = useState(null) // 詳細表示位置左
  const [selectDetailLabor, setSelectDetailLabor] = useState() // マウスオン時レイバー

  // 初期背景描画
  useEffect(() => {
    let cleanedUp = false
    const laborList = []
    if (dbLabors && operations) {
      dbLabors.forEach(dbLabor => {
        if (dbLabor.laborId) {
          const item = Object.assign({}, dbLabor)
          if (operations && item.staffId === staffId) {
            const ope = operations.filter(ope => ope.id === item.operationId)
            if ((dbLabor.matchWorkPlace || commonSearch.warehouseId === -1) && ope.length > 0) {
              item.backColor = ope[0].backColor
            } else {
              item.backColor = 'rgba(111,111,111,0.5)'
            }
            laborList.push(item)
          }
        }
      })
      if (!cleanedUp) {
        setLabors(laborList)
      }
    }
    const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
    drawNewLabor(getContext(), null, laborList, nowTimeIndex, startEndTime)
    const cleanup = () => {
      localStorage.setItem('processingFlg', false)
      HideSpinner(spinnerRef.current)
      cleanedUp = true
    }
    return cleanup
  }, [dbLabors, operations, plannedAttendance])

  // 最初だけ必ず実行
  useEffect(() => {
    localStorage.setItem('processingFlg', false)
  }, [])

  // 対象キャンバス取得
  const getContext = () => {
    return canvasRef.current.getContext('2d')
  }

  // ---------------------------------------------------- マウス動作部分 start -------------------------------------------------------------

  // マウスの状態判定
  const checkCursor = useCallback((event) => {
    const x = event.nativeEvent.offsetX
    const index = mouseXToTaskIndex(x)
    if (index < MIN_INDEX || index > MAX_INDEX) {
      selectOperation
      // 工程選択中の場合
        ? canvasRef.current.style.cursor = CUR_TEXT
      // 範囲外の場合
        : canvasRef.current.style.cursor = CUR_DEFAULT
      return null
    }

    const targetLabor = labors && labors.filter(item => x >= item.startPixel && x <= item.endPixel)
    // レイバーと重なっている
    if (targetLabor && targetLabor.length > 0) {
      // 工程詳細表示
      setSelectDetailLabor(targetLabor[0])
      const windowWidth = window.innerWidth
      const scrollLeft = wrapRef.current.scrollLeft
      const scrollDiffResult = 2420 - windowWidth + 35 + scrollLeft
      const rowHeadWidth = 230
      setDetailPositionLeft(targetLabor[0].startPixel + rowHeadWidth - scrollDiffResult)
      setShowDetail(true)

      if (!canWrite || approved) {
        canvasRef.current.style.cursor = CUR_NO_DROP // 書き込み権限がない
        return null
      }

      const leftDiff = x - targetLabor[0].startPixel
      const rightDiff = targetLabor[0].endPixel - x
      // 端ならリサイズ 真ん中よりなら移動
      const cursor = leftDiff < 5 ? CUR_W_RESIZE
        : rightDiff < 5 ? CUR_E_RESIZE
          : leftDiff > 4 && rightDiff > 4 ? CUR_POINTER
            : selectOperation ? CUR_TEXT : CUR_DEFAULT
      canvasRef.current.style.cursor = cursor
      // マウスオンしているレイバーの幅が3以下の場合
      // if (targetLabor[0].endIndex - targetLabor[0].startIndex < 4) {
      //   setShowDetail(true)
      //   setDetailPosition(targetLabor[0].endPixel + 10)
      //   setSelectDetailLabor(targetLabor[0])
      // } else {
      //   setShowDetail(false)
      // }
    } else {
      // レイバーと重なっていない
      canvasRef.current.style.cursor = selectOperation ? CUR_TEXT : CUR_DEFAULT
    }
  }, [labors, selectOperation])

  // レイバーの重なり判定
  const isOverlap = (target) => {
    // 新規、移動、リサイズ中のレイバーが他の既存レイバーとかぶってるか
    const targetLabor = labors.filter(item =>
      item.branchNo !== target.branchNo && (
        (target.startIndex >= item.startIndex && target.startIndex < item.endIndex) ||
        (target.endIndex > item.startIndex && target.endIndex <= item.endIndex) ||
        (item.startIndex >= target.startIndex && item.startIndex < target.endIndex) ||
        (item.endIndex > target.startIndex && item.endIndex <= target.endIndex)
      )
    )
    return targetLabor.length > 0
  }

  // マウスクリック
  const mouseDown = useCallback((event) => {
    setShowDetail(false)
    if (
      localStorage.getItem('processingFlg') === 'true' || // 処理中
      !canWrite || approved || // 書き込み権限がない
      event.nativeEvent.offsetX < CANV_LEFT || // 範囲外は操作不可
      event.nativeEvent.offsetX > MAX_INDEX * MINUTES_15_WIDTH + CANV_LEFT || // 範囲外は操作不可
      (spinnerRef && spinnerRef.current.style.display === 'block') // || // DB処理中は操作できない
      // floorId < 0 // フロアが全体なら編集できない
    ) return null
    // 左クリック
    if (event.button === BUTTON_NUM_LEFT) {
      setShowContextMenu(false)
      const mousePixel = event.nativeEvent.offsetX // マウスのxピクセル
      const mouseIndex = mouseXToTaskIndex(mousePixel) // キャンバスのインデックス
      // レイバー上にマウスがあるか
      const targetLabor = labors.find(labor => mousePixel >= labor.startPixel && mousePixel <= labor.endPixel)
      // 工程を選んでいるかつマウスマークがデフォルトかテキスト
      if (selectOperation && (canvasRef.current.style.cursor === CUR_DEFAULT || canvasRef.current.style.cursor === CUR_TEXT)) {
        // 新規
        const newLabor = Object.assign({}, labor)
        newLabor.staffId = staffId
        let maxNum = 1
        labors.forEach(item => {
          if (newLabor.staffId === item.staffId && maxNum < item.branchNo) {
            maxNum = item.branchNo
          }
        })
        newLabor.branchNo = (labors.length > 0) ? maxNum + 1 : 1
        newLabor.backColor = selectOperation.backColor
        newLabor.operationId = selectOperation.id
        newLabor.operationName = selectOperation.operationName
        newLabor.operationType = selectOperation.operationType
        newLabor.workPlace = selectOpeWorkPlace
        setStartIndex(mouseIndex)
        setLabor(newLabor)
        setDrawing(DRAW_NEW)
      } else if (targetLabor && (
        mousePixel - targetLabor.startPixel < 5 || targetLabor.endPixel - mousePixel < 5)) {
        // 既存リサイズ
        const before = Object.assign({}, targetLabor)
        setBeforeLabor(before)
        setStartIndex(targetLabor.startIndex)
        setLabor(targetLabor)
        setDrawing(DRAW_RESIZE)
      } else if (targetLabor && (
        mousePixel - targetLabor.startPixel > 6 || targetLabor.endPixel - mousePixel > 6)) {
        // 既存移動
        const before = Object.assign({}, targetLabor)
        setBeforeLabor(before)
        setStartDiffIndex(mouseIndex - targetLabor.startIndex)
        setEndDiffIndex(targetLabor.endIndex - mouseIndex)
        setLabor(targetLabor)
        setDrawing(DRAW_MOVE)
      }
    } else if (event.button === BUTTON_NUM_RIGHT) {
      // 右クリック
      const mousePixel = event.nativeEvent.offsetX
      const targetLabor = labors.filter(labor => mousePixel > labor.startPixel && mousePixel < labor.endPixel)
      if (targetLabor.length > 0) {
        setShowContextMenu(true)
        setContextMenuPosition(mousePixel)
        setSelectDelLabor(targetLabor[0])
      } else {
        if (canvasRef.current.style.cursor === CUR_DEFAULT || canvasRef.current.style.cursor === CUR_TEXT) {
          setShowContextMenu(true)
          setContextMenuPosition(mousePixel)
          setSelectDelLabor(null)
        }
      }
    }
  }, [selectOperation, labors])

  // マウスドラッグ/マウス移動
  const mouseMove = useCallback((event) => {
    setShowDetail(false)
    if (spinnerRef && spinnerRef.current.style.display === 'block') return null
    if (drawing === DRAW_NEW) {
      if (!selectOperation || event.button !== 0) return null
      // 新規描写
      setTimeout(newDraw(event), INTERVAL_TIME)
    } else if (drawing === DRAW_MOVE) {
      // 既存移動
      setTimeout(laborMove(event), INTERVAL_TIME)
    } else if (drawing === DRAW_RESIZE) {
      // 既存リサイズ
      setTimeout(laborResize(event), INTERVAL_TIME)
    } else {
      // ただのマウス移動
      setTimeout(checkCursor(event), INTERVAL_TIME)
    }
  }, [selectOperation, drawing, labors])

  // 新規描写
  const newDraw = (event) => {
    const mouseIndex = mouseXToTaskIndex(event.nativeEvent.offsetX)
    // 右向き
    if (mouseIndex >= startIndex) {
      labor.startIndex = startIndex
      labor.startPixel = labor.startIndex * MINUTES_15_WIDTH + CANV_LEFT
      labor.endIndex = (mouseIndex + 1 > MAX_INDEX) ? MAX_INDEX : mouseIndex + 1
      labor.endPixel = labor.endIndex * MINUTES_15_WIDTH + CANV_LEFT
    } else {
      // 左向き
      labor.endIndex = startIndex + 1
      labor.endPixel = labor.endIndex * MINUTES_15_WIDTH + CANV_LEFT
      labor.startIndex = (mouseIndex < MIN_INDEX) ? MIN_INDEX : mouseIndex
      labor.startPixel = labor.startIndex * MINUTES_15_WIDTH + CANV_LEFT
    }
    setLabor(labor)
    const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
    drawNewLabor(getContext(), labor, labors, nowTimeIndex, startEndTime)
    canvasRef.current.style.cursor = isOverlap(labor) ? CUR_NO_DROP : CUR_POINTER
  }

  // 既存移動
  const laborMove = (event) => {
    const mousePixel = event.nativeEvent.offsetX
    const mouseXIndex = mouseXToTaskIndex(mousePixel)
    const moveLabor = Object.assign({}, labor)
    if (mouseXIndex - startDiffIndex >= 0 && mouseXIndex + endDiffIndex <= MAX_INDEX) {
      // キャンバスの範囲内
      moveLabor.startIndex = mouseXIndex - startDiffIndex
      moveLabor.endIndex = mouseXIndex + endDiffIndex
    } else if (mouseXIndex - startDiffIndex < 0 && mouseXIndex + endDiffIndex <= MAX_INDEX) {
      // キャンバスより外には動かせない（左）
      const laborDiff = moveLabor.endIndex - moveLabor.startIndex
      moveLabor.startIndex = 0
      moveLabor.endIndex = laborDiff
    } else if (mouseXIndex - startDiffIndex >= 0 && mouseXIndex + endDiffIndex > MAX_INDEX) {
      // キャンバスより外には動かせない（右）
      const laborDiff = moveLabor.endIndex - moveLabor.startIndex
      moveLabor.startIndex = MAX_INDEX - laborDiff
      moveLabor.endIndex = MAX_INDEX
    }
    moveLabor.startPixel = moveLabor.startIndex * MINUTES_15_WIDTH + CANV_LEFT
    moveLabor.endPixel = moveLabor.endIndex * MINUTES_15_WIDTH + CANV_LEFT
    setLabor(moveLabor)
    const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
    drawMoveResizeLabor(getContext(), moveLabor, labors, nowTimeIndex, startEndTime)
    canvasRef.current.style.cursor = isOverlap(moveLabor) ? CUR_NO_DROP : CUR_POINTER
  }

  // 既存リサイズ
  const laborResize = (event) => {
    const cursor = canvasRef.current.style.cursor
    const mousePixel = event.nativeEvent.offsetX
    const mouseIndex = mouseXToTaskIndex(mousePixel)
    const resizeLabor = Object.assign({}, labor)
    if (cursor === CUR_W_RESIZE) { // 左端をつかんでいる
      if (mousePixel < resizeLabor.endPixel) {
        if (mouseIndex >= 0) {
          resizeLabor.startIndex = mouseIndex
        } else {
          resizeLabor.startIndex = 0
        }
      } else {
        if (mouseIndex + 1 <= MAX_INDEX && mouseIndex === resizeLabor.endIndex) {
          resizeLabor.startIndex = resizeLabor.endIndex
          resizeLabor.endIndex = mouseIndex + 1
        } else if (mouseIndex < MAX_INDEX) {
          resizeLabor.startIndex = resizeLabor.endIndex
          resizeLabor.endIndex = mouseIndex
        } else {
          resizeLabor.startIndex = resizeLabor.endIndex
          resizeLabor.endIndex = MAX_INDEX
        }
      }
    } else if (cursor === CUR_E_RESIZE) { // 右端をつかんでいる
      if (mousePixel >= resizeLabor.startPixel) {
        if (mouseIndex + 1 <= MAX_INDEX && mouseIndex === resizeLabor.startIndex) {
          resizeLabor.endIndex = mouseIndex + 1
        } else if (mouseIndex <= MAX_INDEX) {
          resizeLabor.endIndex = mouseIndex
        } else {
          resizeLabor.endIndex = MAX_INDEX
        }
      } else {
        if (mouseIndex >= 0) {
          resizeLabor.endIndex = resizeLabor.startIndex
          resizeLabor.startIndex = mouseIndex
        } else {
          resizeLabor.endIndex = resizeLabor.startIndex
          resizeLabor.startIndex = 0
        }
      }
    }
    resizeLabor.startPixel = resizeLabor.startIndex * MINUTES_15_WIDTH + CANV_LEFT
    resizeLabor.endPixel = resizeLabor.endIndex * MINUTES_15_WIDTH + CANV_LEFT
    setLabor(resizeLabor)
    const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
    drawMoveResizeLabor(getContext(), resizeLabor, labors, commonSearch, startEndTime)
    canvasRef.current.style.cursor = isOverlap(resizeLabor) ? CUR_NO_DROP : cursor
  }

  // マウスアップ
  const mouseUp = useCallback((event) => {
    setShowDetail(false)
    if (event.button !== 0 && spinnerRef && spinnerRef.current.style.display === 'block') return null
    if (selectOperation && drawing === DRAW_NEW) {
      // 新規レイバーをリストに追加
      if (canvasRef.current.style.cursor === CUR_POINTER) {
        ShowSpinner(spinnerRef.current, event.nativeEvent.offsetX)
        const newLabor = Object.assign({}, labor)
        PostLabor(newLabor) // API 保存
      } else if (canvasRef.current.style.cursor === CUR_NO_DROP) {
        const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
        drawNewLabor(getContext(), null, labors, nowTimeIndex, startEndTime)
      }
    } else if (drawing === DRAW_MOVE) {
      // 移動したレイバーをリスト内の自分に更新
      if (canvasRef.current.style.cursor === CUR_POINTER) {
        ShowSpinner(spinnerRef.current, event.nativeEvent.offsetX)
        const moveLabor = Object.assign({}, labor)
        PutLabor(moveLabor) // API 更新
      } else if (canvasRef.current.style.cursor === CUR_NO_DROP) {
        const moveLabor = Object.assign({}, beforeLabor)
        const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
        drawMoveResizeLabor(getContext(), moveLabor, labors, nowTimeIndex, startEndTime)
      }
    } else if (drawing === DRAW_RESIZE) {
      // リサイズしたレイバーをリスト内の自分に更新
      if (canvasRef.current.style.cursor === CUR_E_RESIZE ||
        canvasRef.current.style.cursor === CUR_W_RESIZE
      ) {
        ShowSpinner(spinnerRef.current, event.nativeEvent.offsetX)
        const resizeLabor = Object.assign({}, labor)
        if (resizeLabor.startIndex > resizeLabor.endIndex) {
          const startIndex = resizeLabor.startIndex
          const endIndex = resizeLabor.endIndex
          resizeLabor.startIndex = endIndex
          resizeLabor.endIndex = startIndex
          resizeLabor.startPixel = endIndex * MINUTES_15_WIDTH + CANV_LEFT
          resizeLabor.endPixel = startIndex * MINUTES_15_WIDTH + CANV_LEFT
        }
        PutLabor(resizeLabor) // API 更新
      } else if (canvasRef.current.style.cursor === CUR_NO_DROP) {
        const resizeLabor = Object.assign({}, beforeLabor)
        const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
        drawMoveResizeLabor(getContext(), resizeLabor, labors, commonSearch, startEndTime)
      }
    }
    setDrawing(DRAW_NONE) // 描画終了
  }, [selectOperation, labors, drawing, labor])

  // 右クリック時の削除表示
  const ContextMenu = useCallback(() => {
    if (localStorage.getItem('processingFlg') === 'true') return null
    return (
      <DeleteButtonDiv display={showContextMenu ? 'block' : 'none'} contextMenuPosition={contextMenuPosition}>
        {(selectDelLabor !== null)
          ? <button className='deleteButton' onClick={e => onDelete(e)} onContextMenu={e => e.preventDefault()}>削除</button>
          : <button className='deleteButton' onClick={e => onAllDelete(e)} onContextMenu={e => e.preventDefault()}>全削除</button>}
      </DeleteButtonDiv>
    )
  }, [showContextMenu, contextMenuPosition, labors])

  // 削除
  const onDelete = useCallback((event) => {
    const harfXpoint = selectDelLabor.startPixel + ((selectDelLabor.endPixel - selectDelLabor.startPixel) / 2)
    ShowSpinner(spinnerRef.current, harfXpoint)
    const newLabors = []
    labors.map(item => {
      if (item.branchNo !== selectDelLabor.branchNo) {
        newLabors.push(item)
      }
    })
    setShowContextMenu(false)
    deleteLabor(selectDelLabor.laborId, newLabors) // DB更新
  }, [labors, selectDelLabor])

  // 全削除
  const onAllDelete = useCallback((event) => {
    const ret = window.confirm('全削除します。よろしいですか？')
    if (ret) {
      const mouseXpixel = event.nativeEvent.offsetX
      ShowSpinner(spinnerRef.current, mouseXpixel)
      setShowContextMenu(false)
      deleteAllLabor() // DB更新
    }
  }, [labors])

  // ---------------------------------------------------- マウス動作部分 end -------------------------------------------------------------

  // ---------------------------------------------------- API出力処理 start -------------------------------------------------------------
  // API POST レイバー登録
  const PostLabor = async (targetLabor) => {
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor'
    const staffId = labor.staffId
    const data = {
      staffId: staffId,
      floorId: selectOperation.floorId,
      workDate: workDate,
      labor: targetLabor,
      isPlanned: isPlanned,
      workPlace: selectOpeWorkPlace
    }
    const result = await PostApi(apiUrl, data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else if (errorStatus === 422 && result.errorDetail.response.data.message) {
        alert(result.errorDetail.response.data.message)
      } else {
        alert('登録に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
      const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
      drawNewLabor(getContext(), null, labors, nowTimeIndex, startEndTime)
    } else {
      dispatch(apiGetLabors(commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter))
    }
  }

  // API PUT レイバー更新
  const PutLabor = async (targetLabor) => {
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor'
    const data = {
      staffId: labor.staffId,
      floorId: targetLabor.floorId,
      workDate: workDate,
      labor: targetLabor,
      isPlanned: isPlanned
    }
    const result = await PutApi(apiUrl, data, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else if (errorStatus === 422 && result.errorDetail.response.data.message) {
        alert(result.errorDetail.response.data.message)
      } else {
        alert('登録に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)

      const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
      drawNewLabor(getContext(), null, labors, commonSearch, startEndTime)
    } else {
      const newLabors = []
      labors.forEach(item => {
        if (item.branchNo === targetLabor.branchNo) {
          newLabors.push(targetLabor)
        } else {
          newLabors.push(item)
        }
      })
      setLabors(newLabors)
      dispatch(apiGetLabors(commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter))
    }
  }

  // API DELETE レイバー削除
  const deleteLabor = async (laborId, newLabors) => {
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor'
    const data = { laborId, isPlanned }
    const result = await DeleteApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else if (errorStatus === 422 && result.errorDetail.response.data.message) {
        alert(result.errorDetail.response.data.message)
      } else {
        alert('削除に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
      const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
      drawNewLabor(getContext(), null, labors, commonSearch, startEndTime)
    } else {
      setSelectDelLabor(null)
      if (canvasRef.current) canvasRef.current.style.cursor = selectOperation ? CUR_TEXT : CUR_DEFAULT
      setLabors(newLabors)
      dispatch(apiGetLabors(commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter))
    }
  }

  // API DELETE レイバー全削除
  const deleteAllLabor = async () => {
    localStorage.setItem('processingFlg', true)
    const apiUrl = '/api/labor/all'
    const data = { staffId, workDate, isPlanned }
    const result = await DeleteApi(apiUrl, data)
    if (result.errorDetail) {
      exportErrorLog(result)
      const errorStatus = result.errorDetail.response && result.errorDetail.response.status
      if (errorStatus === 401) {
        forceLogout()
      } else if (errorStatus === 422 && result.errorDetail.response.data.message) {
        alert(result.errorDetail.response.data.message)
      } else {
        alert('削除に失敗しました。')
      }
      HideSpinner(spinnerRef.current)
      localStorage.setItem('processingFlg', false)
      const startEndTime = plannedAttendance && plannedAttendance.attendance.filter(a => a.staffId === staffId)
      drawNewLabor(getContext(), null, labors, nowTimeIndex, startEndTime)
    } else {
      if (canvasRef.current) canvasRef.current.style.cursor = selectOperation ? CUR_TEXT : CUR_DEFAULT
      setLabors(null)
      dispatch(apiGetLabors(commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter))
    }
  }

  // ---------------------------------------------------- API出力処理 end ---------------------------------------------------------------

  // クリック時、削除or全削除が出ていたら表示を消す
  // const funcShowFlg = (e) => {
  //   if (e.target === deleteButtonRef.current.target && showContextMenu) {
  //     setShowContextMenu(false)
  //   }
  // }
  // document.body.onclick = (e) => funcShowFlg(e)

  // レイバーにマウスオン時の詳細表示
  const LaborDetail = useCallback(() => {
    if (selectDetailLabor == null || wrapRef.current == null) return null //  || !showDetail
    // スタッフリスト抜き出し
    const beforeExist = []
    const user = []
    dbLabors.forEach(item => {
      if (item.staffId === loginUserInfo.staffId && user.indexOf(item.staffId) === -1) {
        user.push(item.staffId)
      }
      if (beforeExist.indexOf(item.staffId) === -1 && item.staffId !== loginUserInfo.staffId) {
        beforeExist.push(item.staffId)
      }
    })
    const staffsExist = user.concat(beforeExist)
    const startDt = new Date(selectDetailLabor.startDt)
    const detailLaborIndex = staffsExist.findIndex(item => item === selectDetailLabor.staffId)
    const startDate = selectDetailLabor.startDt.slice(0, 10) === selectDetailLabor.workDate ? '' : '翌'
    const startTime = startDate + ('00' + startDt.getHours()).slice(-2) + ':' + ('00' + startDt.getMinutes()).slice(-2)
    const endDt = new Date(selectDetailLabor.endDt)
    const endDate = selectDetailLabor.endDt.slice(0, 10) === selectDetailLabor.workDate ? '' : '翌'
    const endTime = endDate + ('00' + endDt.getHours()).slice(-2) + ':' + ('00' + endDt.getMinutes()).slice(-2)
    const laborHours = getHoursDiff(selectDetailLabor.startDt, selectDetailLabor.endDt)
    const scrollTop = wrapRef.current.scrollTop

    return (
      <DetailUl
        display={showDetail ? 'block' : 'none'}
        detailPositionLeft={detailPositionLeft ? 'left: ' + (detailPositionLeft) + 'px;' : ''}

        detailPositionTop={detailLaborIndex * 53 + 70 - scrollTop}
      >
        <li>
          <label>工程：
            {selectDetailLabor.workPlaceName === '出勤'
              ? <MdBusiness style={iconStyle} />
              : <MdHome style={iconStyle} />}
            {selectDetailLabor.operationName}
          </label>
        </li>
        <li>
          <label>作業時間：{startTime}～{endTime} ({laborHours.hours}h)</label>
        </li>
        <li>
          <label style={{ marginRight: 8 }}>{selectDetailLabor.floorName}</label>
          <label>/</label>
          <label style={{ marginLeft: 8 }}>{selectDetailLabor.zoneName}</label>
          <label>/</label>
          <label style={{ marginLeft: 8 }}>{selectDetailLabor.categoryName}</label>
        </li>
        <li>
          <label style={{ marginRight: 8 }}>登録：
            {!isPlanned
              ? selectDetailLabor.platform === 'mobile'
                ? <MdPhoneIphone style={iconStyle} />
                : <MdComputer style={iconStyle} />
              : null}
            {selectDetailLabor.createdUserName.replace('　', ' ')}
          </label>
          <label>{toFormatShortDateTime(selectDetailLabor.createdAt)}</label>
        </li>
        <li>
          <label style={{ marginRight: 8 }}>更新：{selectDetailLabor.updatedUserName.replace('　', ' ')}</label>
          <label>{toFormatShortDateTime(selectDetailLabor.updatedAt)}</label>
        </li>
      </DetailUl>
    )
  }, [showDetail, detailPositionLeft, selectDetailLabor, wrapRef.current])

  if (document.getElementById('App') != null) {
    document.getElementById('App').onclick = function (e) {
      if (e.target.className !== 'deleteButton') {
        setShowContextMenu(false)
      }
    }
  }

  return (
    <LaborRow>
      <canvas
        id={id} width='2220' height='50' ref={canvasRef}
        onMouseDown={(e) => mouseDown(e)}
        onMouseMove={e => mouseMove(e)}
        onMouseUp={e => mouseUp(e)}
        onMouseLeave={e => mouseUp(e)}
        onContextMenu={e => e.preventDefault()}
      />
      <ContextMenu />
      <DotSpinner spinnerColor={SPINNER_COLOR} ref={spinnerRef} />
      <LaborDetail />
    </LaborRow>
  )
}

CanvasRow.propTypes = {
  isPlanned: PropTypes.bool,
  grants: PropTypes.object,
  staffId: PropTypes.string,
  register: PropTypes.number,
  workPlace: PropTypes.number,
  id: PropTypes.string,
  nowTimeIndex: PropTypes.number,
  wrapRef: PropTypes.any,
  approved: PropTypes.bool,
  staffDivisionFilter: PropTypes.number
}

export default CanvasRow
