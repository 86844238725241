/* eslint-env browser */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { useAlert } from 'react-alert'
import SwitchButton from '../../Common/SwitchButton'
import Calendar from './Calendar'
import StaffInfo from './StaffInfo'
import { useDispatch } from 'react-redux'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { setLaborTemplate } from './IndividualMonthlySlice'
import { exportErrorLog, GetApi } from '../../Common/ApiAxios'
import { Enum } from './GanttChart/Body/Commons'

const MenuDiv = styled.div`
  display: flex;
  position: relative;
  color: #fff;
  flex-wrap: wrap;
  margin-bottom: 12px;
  h3 {
    line-height: 30px;
    font-size: 16px;
    margin-right: 16px;
  }
`
const Button = styled.button`
  display: ${props => props.laborToggle === 'planned' ? 'block' : 'none'};
  width: 70px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #0036ff;
  border-radius: 5px;
  border: none;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  color: ${props => props.allUpdateOpen ? (
    '#ccc'
  ) : (
    '#fff'
  )};
  box-shadow: ${props => props.allUpdateOpen ? (
      '0 0 1px 2px rgba(200,200,200,0.4), inset 3px 3px 1px 0px rgba(0,0,0,0.4)'
    ) : (
      '1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4)'
    )};
  &:hover{
      background: #0026b3;
  }
`
const GetPredictionButton = styled.button`
  width: 70px;
  height: 30px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #0036ff;
  border-radius: 5px;
  border: none;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  box-shadow: '1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4)';
  &:hover{
      background: #0026b3;
  }
`
const Menu = (props) => {
  const { laborToggle, setLaborToggle, month, setMonth, allUpdateOpen, setAllUpdateOpen } = props
  const dispatch = useDispatch()
  const alert = useAlert()
  const changeFlag = () => {
    dispatch(SetLoadSpinner(true))
    setLaborToggle(laborToggle === 'result' ? 'planned' : 'result')
    setAllUpdateOpen(false)
  }
  const clickUpdateButton = () => {
    setAllUpdateOpen(!allUpdateOpen)
    document.querySelectorAll('.allUpdateCheckBox').forEach((i, index) => {
      i.checked = false
    })
  }

  async function getLaborPrediction (staffId, month) {
    const thisMonth = DateTime.fromFormat(month, 'yyyy-MM')
    const lastMonth = thisMonth.minus({ months: 1 })

    dispatch(SetLoadSpinner(true))
    const from = lastMonth.startOf('month').toFormat('yyyyMMdd')
    const to = lastMonth.endOf('month').toFormat('yyyyMMdd')
    const apiUrl = `/api/labor/individual/predictionlabor/${staffId}/${from}/${to}`
    try {
      const result = await GetApi(apiUrl, {})
      if (result.errorDetail) {
        exportErrorLog(result)
        dispatch(SetLoadSpinner(false))
        alert.error('データ取得に失敗しました')
        return null
      }
      if (result.data.length === 0) {
        dispatch(SetLoadSpinner(false))
        alert.info('前月の実績データが登録されていません')
        dispatch(SetLoadSpinner(false))
        return null
      }
      const predicted = result.data.map(ret => {
        return {
          startPixel: ret.startIndex * Enum.COLUMN_WIDTH,
          endPixel: ret.endIndex * Enum.COLUMN_WIDTH,
          ...ret
        }
      })
      dispatch(setLaborTemplate(predicted))
    } catch (error) {
      alert.error('データ取得に失敗しました')
    }
    dispatch(SetLoadSpinner(false))
  }

  return (
    <MenuDiv>
      <h3>個人別月単位</h3>
      <SwitchButton
        switchName='laborToggle'
        flg={laborToggle === 'result' ? 0 : 1}
        onChangeFlg={() => changeFlag()}
        labels={{ left: '予定', right: '実績' }}
      />
      <StaffInfo {...props} />
      <Calendar
        dataValue={month}
        changeCalendar={(dateString) => {
          setMonth(dateString)
          dispatch(setLaborTemplate([]))
        }}
        type={2}
        width='100px'
        isViewIcon
      />
      <Button title='デフォルト工程を入力して選択した日に一括反映します' {...props} onClick={() => clickUpdateButton()}>一括入力</Button>
      {allUpdateOpen && <GetPredictionButton title='前月の実績から1日の工程を作成します' {...props} onClick={() => getLaborPrediction(props.staff.staffId, props.month)}>工程予測</GetPredictionButton>}
    </MenuDiv>
  )
}

Menu.propTypes = {
  laborToggle: PropTypes.string,
  setLaborToggle: PropTypes.func,
  month: PropTypes.string,
  setMonth: PropTypes.func,
  allUpdateOpen: PropTypes.bool,
  setAllUpdateOpen: PropTypes.func
}

export default Menu
