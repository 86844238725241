
// 定数
export const Enum = {
  BUTTON_NUM_LEFT: 0, // マウスイベント 左
  BUTTON_NUM_RIGHT: 2, // マウスイベント 右
  PADDING_LEFT: 15, // キャンバスのLEFTの位置
  CURSOR_DEFAULT: 'default', // マウスポインター デフォルト矢印
  CURSOR_TEXT: 'text', // マウスポインター テキスト
  CURSOR_POINTER: 'pointer', // マウスポインター 指
  CURSOR_NO_DROP: 'no-drop', // マウスポインター ドロップ禁止
  CURSOR_W_RESIZE: 'w-resize', // マウスポインター 左端矢印
  CURSOR_E_RESIZE: 'e-resize', // マウスポインター 右端矢印
  MIN_INDEX: 0, // 36時間を15分単位での最小INDEX
  MAX_INDEX: 144, // 36時間を15分単位での最大INDEX
  DRAW_NEW: 'new', // 描画する内容 新規
  DRAW_MOVE: 'move', // 描画する内容 移動
  DRAW_RESIZE: 'resize', // 描画する内容 リサイズ
  DRAW_NONE: '', // 何もしてない状態
  SPINNER_COLOR: '200, 200, 100',
  COLUMN_WIDTH: 15
}

// 表示用名称作成
export const CreateName = (end, start, name) => {
  let viewName = ''
  if (end - start > 4) {
    viewName = name
    const nameLength = encodeURIComponent(viewName).replace(/%../g, 'x').length
    const indexDiff = end - start
    if (indexDiff * 3.5 < nameLength) {
      viewName = viewName.slice(0, indexDiff) + '...'
    }
  }
  return viewName
}

// レイバーの重なり判定
export const isOverlap = (target, labors) => {
  // 新規、移動、リサイズ中のレイバーが他の既存レイバーとかぶってるか
  const targetLabor = labors.filter(item =>
    item.branchNo !== target.branchNo && (
      (target.startIndex >= item.startIndex && target.startIndex < item.endIndex) ||
      (target.endIndex > item.startIndex && target.endIndex <= item.endIndex) ||
      (item.startIndex >= target.startIndex && item.startIndex < target.endIndex) ||
      (item.endIndex > target.startIndex && item.endIndex <= target.endIndex)
    )
  )
  return targetLabor.length > 0
}

// マウス位置からINDEX抽出
export const mouseXToTaskIndex = (mousePixel) => {
  let result = -1
  for (let i = Enum.MIN_INDEX; i <= Enum.MAX_INDEX; i++) {
    if (mousePixel >= Enum.COLUMN_WIDTH * i) result = i
  }
  return result
}
