import React, { useEffect } from 'react'
import { useIdGenerator } from '../utils'
import { PropTypes } from 'prop-types'

const ScriptTag = ({ src, charset = 'UTF-8', async = false }) => {
  const id = useIdGenerator()
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = async
    script.charset = charset
    document.getElementById(id).replaceWith(script)
  // 初回のみ実行
  // eslint-disable-next-line
  }, [])
  return <div id={id} />
}

ScriptTag.propTypes = {
  src: PropTypes.string.isRequired,
  async: PropTypes.bool
}

export default ScriptTag
export const YubinbangoScript = () => <ScriptTag src='https://yubinbango.github.io/yubinbango-core/yubinbango-core.js' />
