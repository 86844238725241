import { combineReducers } from '@reduxjs/toolkit'
import loginUserInfoReducer from '../Common/Slice/LoginSlice'
import LastTimeInfoReducer from '../Common/Slice/LastTimeSlice'
import CommonSearchSlice from '../Common/Slice/SearchSlice'
import CompanyInfoSlice from '../Common/Slice/CompanyInfoSlice'
import mappingReducer from '../Mapping/MappingSlice'
import editZoneReducer from '../Mapping/EditZoneSlice'
import LaborSlice from '../Labor/LaborSlice'
import MonthlyLaborSummarySlice from '../Labor/MonthlyLabor/MonthlyLaborSlice'
import IndividualMonthlySlice from '../Labor/IndividualMonthly/IndividualMonthlySlice'
import LoadSpinnerReducer from '../Common/Slice/LoadSpinnerSlice'
import SideMenuPositionSlice from '../Common/Slice/SideMenuPositionSlice'
import zoneMasterReducer from '../Master/Zone/ZoneMasterSlice'

const rootReducer = combineReducers({
  loginUserInfo: loginUserInfoReducer,
  lastTimeInfo: LastTimeInfoReducer,
  mapping: mappingReducer,
  zoneMaster: zoneMasterReducer,
  editZone: editZoneReducer,
  laborSlice: LaborSlice,
  monthlyLaborStaff: MonthlyLaborSummarySlice,
  individualMonthly: IndividualMonthlySlice,
  commonSearch: CommonSearchSlice,
  companyInfo: CompanyInfoSlice,
  loadSpinner: LoadSpinnerReducer,
  sideMenuPosition: SideMenuPositionSlice
})

export default rootReducer
