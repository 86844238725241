export const required = '必須項目です'
export const halfWidthAlphaNumeric = { value: /^[A-Za-z0-9]*$/, message: '半角英数字のみ' }
export const halfWidthAlphaNumericHyphen = { value: /^([A-Za-z0-9]|-)*$/, message: '半角英数字とハイフンのみ' }
export const halfNumericHyphen = { value: /^([0-9０-９])+([0-9０-９]|-)*$/, message: '半角数字とハイフンのみ' }
export const halfWidthNumber = { value: /^[0-9]*$/, message: '半角数字のみ' }
export const kana = { value: /^([ァ-ンｧ-ﾝﾞﾟー\s]|-)*$/, message: 'カナのみ' }
export const halfKana = { value: /^([ｧ-ﾝﾞﾟ ]|-)*$/, message: '半角カナのみ' }
export const mail = { value: /^\S+@\S+\.\S+$/, message: 'アドレスの形式ではありません' }
export const phoneNumWithHyphen = { value: /^0\d{1,4}-\d{1,4}-\d{3,4}$/, message: '電話番号の形式ではありません' }

export const maxLength = (length) => ({ value: length, message: `${length}文字以内で設定してください` })
export const minLength = (length) => ({ value: length, message: `${length}文字以上で設定してください` })
export const max = (max) => ({ value: max, message: '有効桁数を超えています' })
export const min = (min) => ({ value: min, message: `${min}以上で入力してください` })
