import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import NormalButton from '../../Common/Atoms/NormalButton'
import Input from '../../Common/Atoms/Input'
import InputAddValue from '../../Common/Atoms/InputAddValue'

const BLOCK = 'block'
const NONE = 'none'
const Wrap = styled.div`
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
  // margin-left: 20px;
  color: #fff;
  position: absolute;
  left: 0;
  top: calc(100% - 70px);
  width: 100%;
`

const ModalWrap = styled.div`
  background-color: rgb(51, 51, 51, 0.5);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: ${props => props.isModal ? 'flex' : 'none'};
  justify-content:center;
  align-items:center;
`

const ModalMain = styled.div`
  width: 650px;
  height: 350px;
  background: #000;
  border: 2px solid #eee;
`

const ActionWrap = styled.div`
  padding:3px;
  color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
`

const ActionMain = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
`
const ActionButtons = styled.div`
  display:flex;
  justify-content:space-evenly;
`
const Label = styled.label`
  width: 120px;
  line-height: 30px;
`

const SingleDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
`
const InputWrap = styled.div`
  position: relative;
  height: 30px;
  display: flex;

  p {
    display: ${props => props.errorMessageView};
    color: #ff7d7d;
    position: absolute;
    top: 35px;
    left: 0;
  }
`

const WidthWrap = styled.div`
  width: 360px;
  height: 70px;
  margin: 0 auto;
`
const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  text-align: left;
  line-height: 30px;
  font-size: 30px;
`
const ZoneEditModal = (
  {
    onUpdate = f => f,
    isModal = false,
    setIsModal = f => f,
    zone = null
  }) => {
  const createInputRef = useRef()
  const createInputValRef = useRef()
  const [createInputError, setCreateInputError] = useState(NONE)
  const [createInputValError, setCreateInputValError] = useState(NONE)

  // 閉じるボタン
  function closeModal () {
    setCreateInputError(NONE)
    setCreateInputValError(NONE)
    setIsModal(false)
  }
  // 登録前処理
  const onBeforeUpdate = () => {
    if (createInputRef.current.value && createInputValRef.current.value) {
      setCreateInputError(NONE)
      setCreateInputValError(NONE)
      onUpdate(createInputRef.current.value, createInputValRef.current.value)
      closeModal()
    } else {
      // 入力がなければエラー
      setCreateInputError(BLOCK)
      setCreateInputValError(BLOCK)
    }
  }

  useEffect(() => {
    createInputRef.current.value = zone ? zone.zoneName : ''
    createInputValRef.current.value = zone ? zone.endTime : 0
    return () => {
      console.log('clean up')
    }
  }, [zone])

  return (
    <Wrap>
      <ModalWrap isModal={isModal}>
        <ModalMain>
          <ActionWrap>
            <ActionMain>
              <WidthWrap>
                <SingleDiv>
                  <Label>ゾーン名</Label>
                  <InputWrap errorMessageView={createInputError}>
                    <Input
                      type='text'
                      maxLength='50'
                      inputRef={createInputRef}
                      width='230px'
                    />
                    <p>必須項目です</p>
                  </InputWrap>
                </SingleDiv>
                <SingleDiv>
                  <Label>ゾーン最終時間</Label>
                  <InputWrap errorMessageView={createInputValError}>
                    <InputAddValue
                      type='number'
                      maxLength='50'
                      inputRef={createInputValRef}
                      width='230px'
                    />
                    <p>必須項目です</p>
                  </InputWrap>
                </SingleDiv>
              </WidthWrap>
            </ActionMain>
            <ActionButtons>
              <NormalButton
                onClick={() => onBeforeUpdate()}
                name='登録'
              />
            </ActionButtons>
            <CloseButton onClick={() => closeModal()}>×</CloseButton>
          </ActionWrap>
        </ModalMain>
      </ModalWrap>
    </Wrap>
  )
}

ZoneEditModal.propTypes = {
  onUpdate: PropTypes.func,
  isModal: PropTypes.bool,
  setIsModal: PropTypes.func,
  zone: PropTypes.object
}
export default ZoneEditModal
