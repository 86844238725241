import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  modalIsShow: false
}

const modalShowSlice = createSlice({
  name: 'modalShow',
  initialState,
  reducers: {
    SetLoadSpinner (state, action) {
      // console.log(action)
      state.modalIsShow = action.payload
    }
  }
})

export const LoadSpinnerStates = () => {
  return useSelector((state) => state)
}

export const { SetLoadSpinner } = modalShowSlice.actions
export default modalShowSlice.reducer
