/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import Search from '../../Common/Search'
import { GetApi } from '../../Common/ApiAxios'
import { useAlert } from 'react-alert'
import ErrorHandling from '../../Master/Common/ErrorHandling'
import StaffList from './StaffList'
import PropTypes from 'prop-types'
import { setLaborTemplate } from './IndividualMonthlySlice'

const FlexDiv = styled.div`
  display: flex;
`

const WrapDiv = styled.div`
  h3 {
    margin-bottom: 15px;
  }
  ul.mainSearch {
    display: unset;
    li {
      margin-bottom: 10px;
      label {
        width: 50px;
      }
      div {
        select {
          width: 200px;
        }
      }
    }
  }
  li.subSearch {
    display: unset;
    ul {
      position: fixed;
      top : 300px;
      padding-left: 50px;
    }
    li.includeRetiredSearch {
      label {
        width: 150px;
      }
    }
  }
`

const StaffSearch = (props) => {
  const { staff, setStaff, grants } = props
  const alert = useAlert()
  const { commonSearch } = useSelector(state => state)
  const [staffs, setStaffs] = useState([])
  const [pagination, setPagination] = useState({})
  const [selectedId, setSelectedId] = useState()
  const dispatch = useDispatch()
  // スタッフ検索
  const getStaffs = async (page) => {
    const data = {
      companyId: commonSearch.companyId,
      warehouseId: commonSearch.warehouseId,
      floorId: commonSearch.floorId,
      zoneId: commonSearch.zoneId,
      includeRetired: commonSearch.includeRetired,
      page: page
    }
    const result = await GetApi('/api/master/staff', data)
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      const resultList = result.data.responseList.map(item => {
        return {
          id: item.staffId,
          name: item.staffName
        }
      })
      setStaffs(resultList)
      setPagination(result.data)
      const isSelected = resultList.find(item => item.id === selectedId)
      if (!isSelected) {
        setSelectedId(staff.staffId)
      }
    }
  }
  const pageSelectFunc = (pageId) => {
    getStaffs(pageId)
  }

  const staffSelect = (staffId) => {
    setSelectedId(staffId)
    const name = staffs.find(item => item.id === staffId).name
    setStaff({ staffId: staffId, staffName: name })
    dispatch(setLaborTemplate([]))
  }

  useEffect(() => {
    getStaffs(1)
    // eslint-disable-next-line
  }, [commonSearch])

  return (
    <FlexDiv>
      <WrapDiv>
        <h3>スタッフ検索</h3>
        <Search
          showButton={false}
          showCompany showWarehouses showFloors showZones
          addAllWarehouse addAllFloor addAllZone inSubChildren showIncludeRetired
          grants={grants}
        />
      </WrapDiv>
      <StaffList
        dataList={staffs}
        selectedId={staff.staffId}
        selectFunction={(id) => staffSelect(id)}
        pageSelectFunc={pageSelectFunc}
        pagination={pagination}
        isIdView
      />
    </FlexDiv>
  )
}
StaffSearch.propTypes = {
  staff: PropTypes.object,
  setStaff: PropTypes.func,
  grants: PropTypes.object
}

export default StaffSearch
