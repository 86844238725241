import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

export const InputCss = css`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #fff;
  height: 48px;
  border-radius: 5px;
  border: none;
  line-height: 16px;
  text-indent: 16px;
  box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
  color: #333;
  &:focus{
    background: #fff;
    border: 2px solid #0036ff;
    outline: none;
  }
  ::placeholder {
    color: #ccc;
  }
`

const InputWrap = styled.input.attrs(props => ({
  // 初期値
  margin: props.margin || '0 16px',
  width: props.width || '95px',
  display: props.display || 'block'
}))`
  margin: ${props => props.margin};
  width: ${props => props.width};
  min-width: ${props => props.width};
  display: ${props => props.display};
  font-size: 16px;
  letter-spacing: 0.05em;
  background: ${props => props.disabled ? '#eee' : '#fff'};
  height: 36px;
  border-radius: 5px;
  border: none;
  line-height: 16px;
  text-indent: 16px;
  box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
  color: #333;
  position: relative;
  &:focus {
    background: #fff;
    outline: none;
    border: 2px solid #0036ff;
    box-sizing: border-box;
    text-indent: 14px;
  }
  ::placeholder {
    color: #ccc;
  }
  ${props => props.addStyle}
`
const Input = ({
  disabled,
  display,
  margin,
  width,
  name,
  type,
  inputRef,
  placeholder,
  onKeyDown,
  maxLength,
  addStyle,
  ...props
}) => (
  <InputWrap
    disabled={disabled}
    margin={margin}
    width={width}
    type={type}
    display={display}
    name={name}
    ref={inputRef}
    onKeyDown={onKeyDown}
    placeholder={placeholder}
    maxLength={maxLength}
    addStyle={addStyle}
    {...props}
  />
)

Input.propTypes = {
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  width: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  display: PropTypes.string,
  inputRef: PropTypes.any,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  addStyle: PropTypes.string
}

export default Input
