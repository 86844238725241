import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import GrantSelect from './GrantSelect'
import Button from '../../Common/Atoms/NormalButton'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'
import Input from '../../Common/Atoms/Input'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import CreaterInfo from '../../Common/Molecules/CreaterInfo'

const DetailWrap = styled.div`
  width: 74vw;
`

const ErrorMessage = styled.p`
  display: ${props => props.errorMessageView ? 'block' : 'none'};
  color: #ff7d7d;
  height: 6vh;
  line-height: 6vh;
  margin-left: 1vw;
`
const DetailBody = styled.div`
  width: 74vw;
  margin-bottom: 12px;
`
const BodyHeaderUl = styled.ul`
  display: flex;
  list-style: none;
  width: 72vw;
  height: 64px;
  background: #777;
  color: #fff;
`
const BodyHeaderLi = styled.li`
  width: ${props => props.width || '20vw'};
  line-height: 64px;
  text-align: ${props => props.textAlign || 'center'};
  text-indent: ${props => props.textIndent || '0'};
`
const BodyServiceUl = styled.ul`
  list-style: none;
  width: 73vw;
`
const BodyServiceLi = styled.li`
  width: 72vw;
  background: #CCC;
  color: #000;
  display: flex;
  margin-bottom: 2px;
  :last-child {
    margin-bottom: 0;
  }
`
const BodyServiceLabel = styled.label`
  width: 10vw;
  display: flex;
  padding-top: 12px;
  justify-content: center;
`
const BodyFunctionUl = styled.ul`
  list-style: none;
  width: 62vw;
`
const BodyFunctionLi = styled.li`
  height: 50px;
  background: #fff;
  color: black;
  display: flex;
  :nth-child(even){
    background: #eee;
    select {
      background: #fff;
    }
  }
`
const BodyFunctionLabel = styled.label`
  width: 22vw;
  display: flex;
  align-items: center;
  text-indent: 16px;

`

const GrantDetail = (props) => {
  const alert = useAlert()
  const [grantNameError, setGrantNameError] = useState(false)
  const [sortError, setSortError] = useState(false)
  const { grantDetail, menuList, inputGrantNameRef, sortRef, onChangeFlg, onCreate, onUpdate, onDelete, canWrite, divisions } = props
  const loginUserInfo = useSelector(state => state.loginUserInfo)

  const validation = (grantName, sort) => {
    if (grantName === '') {
      setGrantNameError(true)
      alert.error('権限名は必須です')
    }
    if (sort != null && !(sort >= 0 && sort <= 99)) {
      setSortError(true)
      alert.error('ソート順は0～99までで設定してください')
    }
    if (grantName !== '' && (sort == null || (sort >= 0 && sort <= 99))) {
      setGrantNameError(false)
      setSortError(false)
      return true
    } else {
      return false
    }
  }

  const createButton = () => {
    const grantName = inputGrantNameRef.current.value
    const sort = sortRef.current.value
    if (validation(grantName, sort)) {
      onCreate(grantName, sort)
    }
  }

  const updateButton = () => {
    const grantName = inputGrantNameRef.current.value
    const sort = sortRef.current.value
    if (validation(grantName, sort)) {
      onUpdate(grantDetail.grantNameId, grantName, sort)
    }
  }

  const deleteButton = () => {
    setGrantNameError(false)
    onDelete(grantDetail.grantNameId)
  }

  useEffect(() => {
    setGrantNameError(false)
  }, [grantDetail])

  if (!grantDetail) return null
  return (
    <DetailWrap>
      <MasterDetailWrap height='unset' isView='true' margin='0'>
        <ul>
          <li style={{ height: '72px' }}>
            <label>権限ID</label>
            <div>
              {grantDetail && grantDetail.grantNameId}
              <ErrorMessage errorMessageView={grantDetail.delFlag}>無効</ErrorMessage>
            </div>
          </li>
          <li style={{ height: '72px' }}>
            <label>権限名</label>
            <div>
              <Input
                type='text'
                width='320px'
                margin='12px 0 12px 0'
                inputRef={inputGrantNameRef}
                maxLength='50' disabled={grantDetail.delFlag || !canWrite}
                errorMessageView={grantNameError}
              />
              <ErrorMessage errorMessageView={grantNameError}>必須です</ErrorMessage>
            </div>
          </li>
          <li style={{ height: '72px' }}>
            <label>ソート順</label>
            <div>
              <Input
                type='number'
                width='120px'
                margin='0'
                name='sort'
                inputRef={sortRef}
                disabled={grantDetail.delFlag || !canWrite}
                errorMessageView={sortError}
              />
              <ErrorMessage errorMessageView={sortError}>0～99までで設定してください</ErrorMessage>
            </div>
          </li>
          <li style={{ height: '2vh' }}><label /><div /></li>
        </ul>
      </MasterDetailWrap>
      <DetailBody>
        <BodyHeaderUl>
          <BodyHeaderLi width='10vw' />
          <BodyHeaderLi width='22vw' textAlign='left' textIndent='16px'>機能名</BodyHeaderLi>
          <BodyHeaderLi>読取権限</BodyHeaderLi>
          <BodyHeaderLi>書込権限</BodyHeaderLi>
        </BodyHeaderUl>
        <BodyServiceUl>
          {menuList && menuList.map((service, index) => {
            return (
              <BodyServiceLi key={index}>
                <BodyServiceLabel>{service.serviceMenuName}</BodyServiceLabel>
                <BodyFunctionUl>
                  {service.manageMenuList.map((manage) => {
                    return manage.functionMenuList.map((func, index) => {
                      return (
                        <BodyFunctionLi key={index}>
                          <BodyFunctionLabel>{func.functionMenuName}</BodyFunctionLabel>
                          <GrantSelect
                            selectName={'read_' + func.functionMenuId}
                            flg={func.readGrantDivision}
                            isNoChange={(grantDetail.grantNameId === 1 && func.functionMenuId === '1040601001') || grantDetail.delFlag || !canWrite}
                            onChangeFlg={(e) => onChangeFlg(service.serviceMenuId, manage.manageMenuId, func.functionMenuId, 'read', e.target.value)}
                          >
                            {divisions && divisions.map((item, index) => {
                              return (
                                <option key={index} value={item.valueId}>{item.valueName}</option>
                              )
                            })}
                          </GrantSelect>
                          <GrantSelect
                            selectName={'write_' + func.functionMenuId}
                            flg={func.writeGrantDivision}
                            isNoChange={(grantDetail.grantNameId === 1 && func.functionMenuId === '1040601001') || grantDetail.delFlag || !canWrite}
                            onChangeFlg={(e) => onChangeFlg(service.serviceMenuId, manage.manageMenuId, func.functionMenuId, 'write', e.target.value)}
                          >
                            {divisions && divisions.map((item, index) => {
                              return (
                                <option key={index} value={item.valueId}>{item.valueName}</option>
                              )
                            })}
                          </GrantSelect>
                        </BodyFunctionLi>
                      )
                    })
                  })}
                </BodyFunctionUl>
              </BodyServiceLi>
            )
          })}
        </BodyServiceUl>
      </DetailBody>
      {/* 第一フェーズでは削除ボタン非表示 */}
      <MasterDetailButtons style={{ display: canWrite ? 'flex' : 'none' }}>
        {!grantDetail.grantNameId ? <Button name='登録' height='3vh' padding='0' onClick={() => createButton()} /> : null}
        {grantDetail.grantNameId && grantDetail.delFlag === false ? <Button name='更新' height='3vh' padding='0' onClick={() => updateButton()} /> : null}
        {grantDetail.grantNameId && grantDetail.delFlag === false &&
          grantDetail.grantNameId !== 1 && loginUserInfo.grantNameId !== grantDetail.grantNameId ? <Button display='none' name='削除' height='3vh' padding='0' onClick={() => deleteButton()} /> : null}
        {grantDetail.grantNameId && grantDetail.delFlag === true ? <Button margin='0' name='有効化' height='3vh' padding='0' onClick={() => deleteButton()} /> : null}
      </MasterDetailButtons>
      <CreaterInfo info={grantDetail} />
    </DetailWrap>
  )
}

GrantDetail.propTypes = {
  grantDetail: PropTypes.object,
  menuList: PropTypes.array,
  inputGrantNameRef: PropTypes.any,
  sortRef: PropTypes.any,
  onChangeFlg: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  canWrite: PropTypes.bool,
  divisions: PropTypes.array
}

export default GrantDetail
