import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import 'react-datepicker/dist/react-datepicker.css'

import {
  useQuery
} from '@apollo/client'
import { FlexDiv } from '../MonthlyLabor/MonthlyLaborComponent'
import Search from '../../Common/Search'
import FUNC from '../../Common/FunctionEnum'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { GetAnnualLabors } from '../../Common/Api'
import Select from '../../Common/Atoms/SelectAddValue'
import TabSelector from '../../Common/Atoms/TabSelector'
import { PAID_HOLIDAYS } from '../../graphql/Schema'
import AnnualLaborTable from './AnnualLaborTable'
import {
  headCells
} from './const'
import {
  buildAnnualLabor, buildPailHolidays
} from './helper'
import MaterialTable from './MaterialTable'

const Title = '年間労働時間'

const YearPicker = ({ selectedYear, setValue }) => {
  const thisYear = (new Date()).getFullYear()

  return (
    <li style={{ display: 'flex' }}>
      <label style={{ color: 'white', paddingTop: '7px' }}>年度</label>
      <Select
        onChange={(e) => setValue(e.target.value)}
        disabled={false}
        selectValue={selectedYear}
      >
        <option key={thisYear - 2} value={thisYear - 2}>{thisYear - 2}</option>
        <option key={thisYear - 1} value={thisYear - 1}>{thisYear - 1}</option>
        <option key={thisYear} value={thisYear}>{thisYear}</option>

      </Select>
    </li>
  )
}

YearPicker.propTypes = {
  selectedYear: PropTypes.number,
  setValue: PropTypes.func
}

const funcList = [
  { value: 0, name: '労働時間' },
  { value: 1, name: '有給休暇' }
]
/**
 * Labor summary on a floor in a month
 */
function AnnualLabor () {
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  // loginUserInfo && loginUserInfo.menuList && console.log(loginUserInfo.menuList)
  const grants = loginUserInfo && loginUserInfo.menuList &&
      loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ANNUAL_LABOR)
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId
  const zoneId = commonSearch.zoneId
  const dispatch = useDispatch()
  const dateStr = new Date()
  const yearStr = dateStr.getFullYear()

  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [targetYear, setTargetYear] = useState(yearStr)
  const [annualLabor, setAnnualLabor] = useState()
  const [paidHolidays, setPaidHolidays] = useState()
  const [func, setFunc] = useState(0)
  const [totalHours, setTotalHours] = useState()

  const getData = async () => {
    if (!grants) return null
    const staffId = (grants.readGrantDivision === 1 || grants.readGrantDivision === 3) // 所属のみ、自分のみ
      ? loginUserInfo.staffId
      : null

    dispatch(SetLoadSpinner(true))
    const ret = await GetAnnualLabors(
      from, to, commonSearch, grants.readGrantDivision, staffId)
    setAnnualLabor(ret.data)
    dispatch(SetLoadSpinner(false))
  }

  // useEffect
  useEffect(() => {
    document.title = `${Title} | Core First`
  }, [])

  useEffect(() => {
    // 当年4月から翌年3月まで
    const nextYear = Number(targetYear) + 1
    setFrom(`${targetYear}-04`)
    setTo(`${nextYear}-03`)
  }, [targetYear])

  useEffect(() => {
    if (from && to) getData()
  }, [grants, warehouseId, floorId, from, to, func])

  const { loading, error, data } = useQuery(PAID_HOLIDAYS, {
    fetchPolicy: 'network-only',
    variables: {
      workDateFrom: from + '-01',
      workDateTo: to + '-31',
      warehouseId: warehouseId,
      floorId: floorId,
      zoneId: zoneId
    }
  })

  useEffect(() => {
    if (func === 1) {
      if (!loading && data) {
        console.log(data)
        setPaidHolidays(data)
      }
    }
  }, [func, warehouseId, floorId, zoneId, targetYear, data])

  if (!annualLabor || loading) return <div style={{ color: 'white', padding: '8px' }}>Loading...</div>
  if (loading) return <div style={{ color: 'white', padding: '8px' }}>Loading...</div>
  if (error) console.error(error)
  if (!grants) return <div style={{ color: 'white' }}>権限がありません</div>

  return (
    <>
      <FlexDiv>
        <Search
          title={Title} showWarehouses showFloors
          addAllWarehouse addAllFloor addStyle='padding: 0 0 0 16px;'
          showButton={false} grants={grants}
        />
        <YearPicker
          setValue={setTargetYear}
          selectedYear={targetYear}
        />
      </FlexDiv>
      <TabSelector
        selectValue={func}
        setSelectValue={setFunc}
        title=''
        tabsName='registers'
        dataList={funcList}
      />
      {
        func === 0 ? (
          <AnnualLaborTable
            size='small'
            width='100%'
            headCells={headCells}
            rows={buildAnnualLabor(annualLabor, totalHours)}
            title=''
            from={from}
            to={to}
            totalHours={totalHours}
            setTotalHours={setTotalHours}
          />
        ) : func === 1 && paidHolidays ? (
          <MaterialTable
            size='small'
            width='100%'
            headCells={headCells}
            rows={buildPailHolidays(paidHolidays, targetYear)}
            title=''
            from={from}
            to={to}
          />
        ) : null
      }
    </>
  )
}

export default AnnualLabor
