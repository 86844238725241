import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import moment from 'moment'
import Zone from './Zone'
// import useReactRouter from 'use-react-router'
import '../../css/Floor.scss'
// TODO 動的に取得
// import floorImage from '../image/background-800.png'

const FloorDiv = styled.div`
    position: relative;
    background-image: ${props => props.image ? 'url(' + props.image + ')' : 'none'};
    background-repeat: no-repeat;
    background-size: contain;
    ${props => props.isMapping ? 'height: calc(100vh - 250px);' : ''}
    ${props => props.isMapping ? 'width: calc(100vw - 300px);' : ''}
    ${props => props.isMapping ? 'overflow: auto;' : ''}
    
    ::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }
  
    ::-webkit-scrollbar-thumb {
      border: 3px solid #000;
      background-clip: padding-box;
      background-color: rgba(112, 112, 112);
      border-radius: 10px;
    }
  
    ::-webkit-scrollbar-corner {
      display: none;
    }
`

const Floor = ({
  floorId,
  zones = [],
  zoneEndTime = [],
  getZoneEndTime = f => f,
  openModal = f => f,
  canWrite,
  mode = ''
}, props) => {
  // TODO width, height 動的に変更
  // TODO props, stateに変更
  // 表示、編集モード分け
  mode = canWrite ? '' : 'whiteboard'
  // const { location } = useReactRouter()

  // const isMapping = location.pathname === '/whiteboard/mapping'

  return (
    <FloorDiv isMapping={props.isMapping}>
      {/* TODO エリア定義がない場合の考慮 */}
      {zones.map(zone => {
        let endTime = null
        const index = zoneEndTime.findIndex(t => t.zoneId.toString() === zone.zoneId.toString())
        if (index >= 0) {
          const _endTime = zoneEndTime[index].endTime
          // 24時以降対応
          if (_endTime > 2400) {
            endTime = `~${_endTime.toString().slice(0, 2)}:${_endTime.toString().slice(-2)} `
          } else {
            endTime = `~${moment(String(_endTime).padStart(4, '0'), 'hmm').format('H:mm')}`
          }
        }
        return (
          <Zone
            floorId={floorId}
            {...zone}
            key={zone.zoneId}
            zoneEndTime={endTime}
            staffs={zone.staffs}
            openModal={openModal}
            getZoneEndTime={getZoneEndTime}
            mode={mode}
          />
        )
      })}
    </FloorDiv>
  )
}

Floor.propTypes = {
  floorId: PropTypes.number,
  zones: PropTypes.array,
  zoneEndTime: PropTypes.array,
  getZoneEndTime: PropTypes.func,
  openModal: PropTypes.func,
  canWrite: PropTypes.bool,
  mode: PropTypes.string
}
export default Floor
