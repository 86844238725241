import React, { useState, useEffect } from 'react'
import { GetApi } from '../Common/ApiAxios'
import styled from 'styled-components'
import Search from '../Common/Search'
import { useSelector, useDispatch } from 'react-redux'
import FUNC from '../Common/FunctionEnum'
import TabSelector from '../Common/Atoms/TabSelector'
import { restfulApiConfig } from '../Common/Config'
import { useAlert } from 'react-alert'
import DataAnalyzeDetail from './DataAnalyzeDetail'
import Calendar from '../Labor/IndividualMonthly/Calendar'
import { DateTime } from 'luxon'

import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'

export const DefaultUrl = '/api/chart'

// <img src={QrIos} alt='iOSダウンロード' style={{ 'object-fit': 'contain' }} />
const FlexDiv = styled.div`
  display:flex;
  flex-wrap: wrap;
  margin-left: 16px;
  h3 {
    line-height: 30px;
    font-size: 16px;
    margin-right: 16px;
  }
  color: #fff;
`

const DataAnalyzeDiv = styled.div`
  flex-direction: column;
  ul {
      list-style:none;
  }
  .buttons {
    flex: 0 0 50px;
    display: flex;
    justify-content: space-between;

    a {
        background: #ddd;
        margin: 20px 40px;
        padding: 10px;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        font-size: 14px;
        min-width: 54px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        color: black;

        :hover {
          box-shadow: 0 1px 1px rgba(0,0,0,0.1);
          background-color: #f8f8f8;
          border: 1px solid #c6c6c6;
          color: #222;
        }
    }
  }
`

const DataAnalyze = () => {
  const dispatch = useDispatch()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const [isLoading, setLoading] = useState(false)
  const [stackSummaryData, setStackSummaryData] = useState([])
  const { companyId, warehouseId, floorId, zoneId } = commonSearch
  const grants = loginUserInfo.menuList &&
  loginUserInfo.menuList.find(menu => menu.functionMenuId === (FUNC.RESULT_LABOR))
  const [percentViewCond, setPercentViewCond] = useState(0)
  const [operationViewCond, setOperationViewCond] = useState(0)
  const date = new Date()
  const [month, setMonth] = useState(() => {
    if (date.getDate() >= restfulApiConfig.startDay) {
      if (date.getMonth() === 11) {
        return (date.getFullYear() + 1) + '-' + ('0' + '1').slice(-2)
      } else {
        return date.getFullYear() + '-' + ('0' + (date.getMonth() + 2)).slice(-2)
      }
    } else {
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
    }
  })
  const [staff, setStaff] = useState()
  const isOperationList = [
    { value: 0, name: '工程' },
    { value: 1, name: 'カテゴリ' }
  ]
  const isPercentList = [
    { value: 0, name: '時間表示' },
    { value: 1, name: '割合表示' }
  ]
  const alert = useAlert()

  // 初期化メソッド
  useEffect(() => {
    dispatch(SetLoadSpinner(true))
    const fromDate = DateTime.fromFormat(month + '-' + '21', 'yyyy-MM-dd').plus({ months: -1 })
    const toDate = DateTime.fromFormat(month + '-' + '20', 'yyyy-MM-dd')
    var queryString = ''
    queryString = `?warehouseId=${warehouseId || ''}&floorId=${floorId || ''}&zoneId=${zoneId || ''}&isOperation=${operationViewCond || '0'}`
    const apiUrl = DefaultUrl + `/operation-summary-data2/${fromDate}/${toDate}${queryString}`
    async function getCharts () {
      const data = {}
      const result = await GetApi(apiUrl, data)
      if (result.errorDetail) {
        const errorStatus = result.errorDetail.response && result.errorDetail.response.status
        if (errorStatus === 422) {
          alert.info(result.errorDetail.response.data.message)
        } else {
          alert.error('リストの取得に失敗しました')
        }
      } else {
        const stackDataList = []
        // スタックカラムン
        result.data.forEach((row, idx) => {
          var header = ['name']
          var laborData = [row.bundleName]

          row.dataList.forEach((d, index) => {
            if (idx === 0) {
              header.push(d.name)
            }
            laborData.push(parseFloat(d.totalWorkTime))
          })
          if (idx === 0) {
            header.push({ role: 'annotation' })
            stackDataList.push(header)
          }
          laborData.push('')
          stackDataList.push(laborData)
        })
        if (!staff && loginUserInfo && loginUserInfo.staffId) {
          setStaff({ staffId: loginUserInfo.staffId, staffName: loginUserInfo.staffName })
        }
        setStackSummaryData(stackDataList)
        setLoading(true)
      }
      dispatch(SetLoadSpinner(false))
    }
    getCharts()
    // eslint-disable-next-line
  }, [companyId, warehouseId, floorId, zoneId, month, staff, loginUserInfo, operationViewCond])
  if (!grants) return null
  return (
    <DataAnalyzeDiv>
      <FlexDiv>
        <h3>工程サマリチャート</h3>

        <TabSelector
          selectValue={operationViewCond}
          setSelectValue={setOperationViewCond}
          title=''
          tabsName='operationViewCond'
          dataList={isOperationList}
        />

        <TabSelector
          selectValue={percentViewCond}
          setSelectValue={setPercentViewCond}
          title=''
          tabsName='percentViewCond'
          dataList={isPercentList}
        />

        {/* 共通検索 */}
        <>
          <Search
            title=''
            addStyle='padding: 0 0 0 16px;'
            showButton={false}
            showWarehouses showFloors showZones addAllWarehouse addAllFloor addAllZone
            inSubCompany inSubChildren
            grants={grants}
          >
            <li>
              <label>期間</label>
              <Calendar
                dataValue={month}
                changeCalendar={(dateString) => {
                  setMonth(dateString)
                }}
                type={2}
                width='100px'
                isViewIcon
              />
            </li>
          </Search>
        </>
      </FlexDiv>
      <DataAnalyzeDetail
        stackSummaryData={stackSummaryData}
        percentViewCond={percentViewCond}
        isLoading={isLoading}
      />

    </DataAnalyzeDiv>
  )
}
export default DataAnalyze
