// React Libraries
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

// Third Party Libraries
import Button from '@material-ui/core/Button'
import { IconContext } from 'react-icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'

import DatePicker from 'react-datepicker'
import { CSVLink } from 'react-csv'
import { CsvToHtmlTable } from 'react-csv-to-table'
import { useAlert } from 'react-alert'

// CoreFirst Libraries
import FUNC from '../Common/FunctionEnum'
import Search from '../Common/Search'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import {
  ExportLaborCsv,
  ExportOverTimeAlertCsv,
  ExportTimecardCsv,
  ExportBugyoCsv
} from '../Common/Api'
import TabSelector from '../Common/Atoms/TabSelector'
import WrapDiv from './WrapDiv'
import CalenderIcon from '../../image/svg/CalenderIcon.js'
import {
  getCsvFileName,
  getMonthStr
} from './helper'
import { getFirstAndLastDate } from '../Common/utils'
import '../../css/CsvTable.scss'
const Container = styled.div`
  padding: 32px 16px 64px 16px;
`
const Center = styled.div`
  display: flex;
  
  /* justify-content: space-between; */
  & > button {
    margin: 16px;
  }
  & > div {
    margin: 16px;
  }
`
// -------------------------------
const Title = 'CSVエクスポート'
const exportDataList = [
  { value: 0, name: 'レイバー情報' },
  { value: 1, name: '勤怠情報' },
  { value: 2, name: '残業アラート情報' },
  { value: 3, name: '給与奉行CSV' }
]
const searchEmpList = [
  { value: 0, name: '全データ' },
  { value: 1, name: '社員' },
  { value: 2, name: '社員以外' }
]

const CsvExport = () => {
  const dispatch = useDispatch()
  const alert = useAlert()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const grants = loginUserInfo && loginUserInfo.menuList &&
      loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.CSV_EXPORT)
  const bugyoGrants = loginUserInfo && loginUserInfo.menuList &&
      loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.BUGYO_CSV_EXPORT)
  // state
  // const [month, setMonth] = useState(new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2))
  const [exportType, setExportType] = useState(0)
  const [searchEmpCond, setSearchEmpCond] = useState(0)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [csvData, setCsvData] = useState(null)
  const [csvRecordCount, setCsvRecordCount] = useState()

  const onDownloadClick = async () => {
    console.log(`
    startDate: ${startDate}
    endDate: ${endDate}
    warehouseId: ${commonSearch.warehouseId}
    floorId: ${commonSearch.floorId}
    zoneId: ${commonSearch.zoneId}
    exportType: ${exportType}
    `)

    dispatch(SetLoadSpinner(true))

    let ret
    const staffId = (grants.readGrantDivision === 1 || grants.readGrantDivision === 3) // 所属のみ、自分のみ
      ? loginUserInfo.staffId
      : null
    switch (exportType) {
      case 0:
        ret = await ExportLaborCsv(
          startDate, endDate, commonSearch, searchEmpCond, grants.readGrantDivision, staffId)
        break

      case 1:
        ret = await ExportTimecardCsv(
          startDate, endDate, commonSearch, searchEmpCond, grants.readGrantDivision, staffId)
        break

      case 2:
        ret = await ExportOverTimeAlertCsv(
          startDate, endDate, commonSearch, searchEmpCond, grants.readGrantDivision, staffId)
        break

      case 3:
        if (bugyoGrants.readGrantDivision === 2) {
          ret = await ExportBugyoCsv(startDate, endDate)
        } else {
          ret = null
          // alert
        }
        break
      default:
        ret = null
        break
    }

    setCsvData(ret)
    setCsvRecordCount(ret != null ? ret.split('\n').length - 2 : 0)
    dispatch(SetLoadSpinner(false))
    return true
  }

  // タイトル
  useEffect(() => {
    document.title = `${Title} | Core First`
  }, [])

  useEffect(() => {
    console.log(commonSearch.workDate)
    const month = getMonthStr(commonSearch.workDate)
    console.log(month)
    const [from, to] = getFirstAndLastDate(month)
    console.log(from, to)
    setStartDate(new Date(from))
    setEndDate(new Date(to))
  }, [commonSearch.workDate])

  // 給与奉行CSVエクスポートはは全権限があるユーザのみ実行可能
  useEffect(() => {
    if (exportType === 3 && (bugyoGrants == null || bugyoGrants.readGrantDivision !== 2)) {
      setExportType(0)
      alert.error('権限がありません。')
    }
  }, [exportType])

  if (!grants) return <div style={{ color: 'white' }}>権限がありません</div>

  return (
    <>
      <Search
        title='CSVエクスポート'
        addStyle='padding: 0 16px 0px 16px;'
        showButton={false} showWarehouses showFloors showZones
        addAllWarehouse addAllFloor addAllZone
        inSubCompany
        grants={grants}
      />

      <Container>
        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '16px 0' }}>
          <p style={{ color: 'white', lineHeight: '32px', marginRight: '16px' }}>出力対象期間</p>
          <WrapDiv>
            <DatePicker
              className='normalCalendar'
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectStart
              startDate={startDate}
              endDate={endDate}
              dateFormat='yyyy/MM/dd (EE)'
              locale='ja'
            />
            <CalenderIcon />
          </WrapDiv>
          <p style={{ color: 'white', fontSize: '1.4em', padding: '4px 8px 0 8px' }}>~</p>
          <WrapDiv>
            <DatePicker
              className='normalCalendar'
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat='yyyy/MM/dd (EE)'
              locale='ja'
            />
            <CalenderIcon />
          </WrapDiv>
          <TabSelector
            selectValue={searchEmpCond}
            setSelectValue={setSearchEmpCond}
            // selectedBackColor='#303F9F'
            title=''
            tabsName='searchEmp'
            dataList={searchEmpList}
          />
        </div>
        <TabSelector
          selectValue={exportType}
          setSelectValue={setExportType}
          title='エクスポート対象: '
          titleStyle={{ fontSize: '1.0em' }}
          margin='8px'
          tabsName='searchCondition'
          dataList={exportDataList}
        />
        <Center>
          <Button
            color='primary'
            variant='contained'
            data-value='aaa'
            onClick={onDownloadClick}
            style={{ backgroundColor: '#0036FF', marginLeft: '0' }}
          >
            CSVデータ作成
          </Button>

          {csvData
            ? (

              <div style={{ display: 'flex', textAlign: 'center', marginTop: '16px', marginBottom: '8px' }} title='ダウンロード'>
                <CSVLink data={csvData} filename={getCsvFileName(exportType, startDate, endDate)} target='_blank'>
                  <IconContext.Provider value={{ color: 'white' }}>
                    <FaCloudDownloadAlt size={32} />
                  </IconContext.Provider>
                </CSVLink>
                <p style={{ margin: '8px', color: 'white' }}>レコード件数: {csvRecordCount} 件</p>
              </div>

            )
            : (
              <div style={{ cursor: 'not-allowed' }}>
                <IconContext.Provider value={{ color: 'white' }}>
                  <FaCloudDownloadAlt size={32} />
                </IconContext.Provider>
              </div>
            )}
        </Center>
        {csvData && (
          <>
            <div style={{ color: 'white', margin: '16px' }}>先頭20件まで表示</div>
            <CsvToHtmlTable
              data={csvData.split('\n').slice(0, 20).join('\n')}
              csvDelimiter=','
              style={{ color: 'white', backgroundColor: 'blue' }}
              tableClassName='table'
              tableHeaderClassName='header'
              tableColumnClassName='cell'
            />
          </>
        )}
      </Container>

    </>
  )
}

export default CsvExport
