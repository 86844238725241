import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  staffId: null,
  staffName: null,
  companyId: null,
  companyName: null,
  warehouseId: null,
  warehouseName: null,
  floorId: null,
  floorName: null,
  zoneId: null,
  zoneName: null,
  grantNameId: null,
  grantName: null,
  menuList: null
}

const loginUserInfo = createSlice({
  name: 'loginUserInfo',
  initialState,
  reducers: {
    setLoginUserInfo (state, action) {
      const {
        staffId, staffName,
        companyId, companyName,
        warehouseId, warehouseName,
        floorId, floorName,
        zoneId, zoneName,
        grantNameId, grantName,
        menuList
      } = action.payload
      state.staffId = staffId
      state.staffName = staffName
      state.companyId = companyId
      state.companyName = companyName
      state.warehouseId = warehouseId
      state.warehouseName = warehouseName
      state.floorId = floorId
      state.floorName = floorName
      state.zoneId = zoneId
      state.zoneName = zoneName
      state.grantNameId = grantNameId
      state.grantName = grantName
      state.menuList = menuList
    }
  }

})

export const { setLoginUserInfo } = loginUserInfo.actions
export default loginUserInfo.reducer
