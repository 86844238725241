
import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { GrDrag } from 'react-icons/gr'

const Container = styled.div`
  padding: 2px 8px;
  /* border: 1px solid white; */
  color: ${props => props.selected ? '#fff' : '#333'};
  background: ${props => props.selected ? '#048b63' : '#fff'};
  :nth-child(even){
    background: ${props => props.selected ? '#048b63' : '#eee'};
  }
`

const ItemDiv = styled.div`
  padding: 0 4px 0 0;
  /* border: 1px solid red; */
  width: 100%;
  font-size: .9em;
`
const FlexDiv = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
`
const DragHandle = ({ provided }) => (
  <div
    {...provided.dragHandleProps}
    style={{ marginTop: '4px', marginRight: '4px' }}
  >
    <GrDrag />
  </div>

)
const categoryStyle = {
  backgroundColor: '#bbe2f1', borderRadius: '4px', padding: '4px', textAlign: 'center', fontSize: '.9em', color: '#333'
}
const OperationItem = ({ index, oid, item, selected, selectFunction }) => {
  return (
    <Draggable draggableId={oid} index={index}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          ref={provided.innerRef}
          selected={selected}
          onClick={() => selectFunction(item.id)}
        >
          <FlexDiv>
            <DragHandle provided={provided} />
            <ItemDiv style={{ flex: 1 }}>{item.id}</ItemDiv>
            <ItemDiv style={{ flex: 1 }}>{item.sort}</ItemDiv>
            <ItemDiv style={{ flex: 5 }}>{item.name}</ItemDiv>
            <ItemDiv style={{ flex: 2, ...categoryStyle }}>{item.categoryName}</ItemDiv>

          </FlexDiv>
        </Container>
      )}
    </Draggable>
  )
}

export default OperationItem
