/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Modal from '../../Common/Atoms/Modal'
import StaffSearch from './StaffSearch'
import PropTypes from 'prop-types'

const WrapSpan = styled.span`
  position: relative;
`

const Input = styled.input`
  font-size: 16px;
  max-width: 200px;
  height: 30px;
  letter-spacing: 0.05em;
  background: #eee;
  border-radius: 5px;
  border: none;
  text-indent: 8px;
  box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
`

const Button = styled.button`
  position: absolute;
  width: 50px;
  height: 18px;
  right: 6px;
  top: 6px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #0036ff;
  border-radius: 5px;
  border: none;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  color: white;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  &:hover{
      background: #0026b3;
  }
`

const StaffInfo = (props) => {
  const { staff } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)
  }, [staff])
  if (!staff) return null
  return (
    <WrapSpan>
      <Input disabled value={staff.staffName} />
      <Button onClick={() => setOpen(true)}>変更</Button>
      <Modal open={open} setOpen={setOpen} width='630px' height='calc(100vh - 80px)'>
        <StaffSearch {...props} />
      </Modal>
    </WrapSpan>

  )
}

StaffInfo.propTypes = {
  staff: PropTypes.object
}

export default StaffInfo
