import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import Chart from 'react-google-charts'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const DefaultUrl = '/api/chart'

const Spacer = styled.div`
  width: 100%;
`

const DataAnalyzeDetail = ({
  stackSummaryData,
  isLoading,
  percentViewCond
}) => {
  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Spacer />
        {!isLoading &&
          <div
            style={{ display: 'flex', backgroundColor: '#FFFFFF', width: 1220, height: 600, justifyContent: 'center', alignItems: 'center' }}
          ><FaSpinner className='icon-spin' size={50} />
          </div>}
        {isLoading &&
          <div style={{ margin: 10 }}>
            <Chart
              width='1220px'
              height='600px'
              chartType='BarChart'
              loader={<div>Loading Chart</div>}
              data={stackSummaryData}
              options={{
                title: '月別工程スタックサマリ',
                width: 1220,
                height: 600,

                isStacked: percentViewCond === 0 ? true : 'percent',
                bar: { groupWidth: '200px' },
                legend: { position: 'top', maxLines: 5 }
              }}
            />
          </div>}

      </div>
    </>
  )
}
DataAnalyzeDetail.propTypes = {
  stackSummaryData: PropTypes.object,
  isLoading: PropTypes.bool,
  percentViewCond: PropTypes.number
}
export default DataAnalyzeDetail
