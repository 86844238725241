import { createSlice } from '@reduxjs/toolkit'
import { GetApi, exportErrorLog, forceLogout } from '../ApiAxios'
import moment from 'moment'

const initialState = {
  lastTime: null
}

const lastTimeInfo = createSlice({
  name: 'lastTimeInfo',
  initialState,
  reducers: {
    setLastTimeInfo (state, action) {
      let timeInt = action.payload
      if (timeInt > 2400) {
        timeInt -= 2400
        const timeStr = ('0000' + timeInt).slice(-4)
        const today = moment().add(1, 'd').format('YYYY-MM-DD')
        const lastTimeStr = today + ' ' + timeStr.substring(0, 2) + ':' + timeStr.substring(2, 4) + ':00'
        state.lastTime = lastTimeStr
      } else {
        const timeStr = ('0000' + timeInt).slice(-4)
        const today = moment().format('YYYY-MM-DD')
        const lastTimeStr = today + ' ' + timeStr.substring(0, 2) + ':' + timeStr.substring(2, 4) + ':00'
        state.lastTime = lastTimeStr
      }
    }
  }
})

export const { setLastTimeInfo } = lastTimeInfo.actions
export default lastTimeInfo.reducer

// 非同期
// 最終時刻取得
export const getLastTime = (floorId, workDate) => async dispatch => {
  const apiUrl = '/api/whiteboard/lastTime'
  const data = {
    floorId: floorId,
    workDate: workDate
  }
  const result = await GetApi(apiUrl, data, true)
  if (result.errorDetail) {
    exportErrorLog(result)
    const errorStatus = result.errorDetail.response && result.errorDetail.response.status
    if (errorStatus === 401) {
      forceLogout()
    } else {
      window.alert('取得に失敗しました。')
    }
  } else {
    dispatch(setLastTimeInfo(result.data))
  }
}
