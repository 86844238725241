import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const InfoWrapUl = styled.ul`
  width: 74vw;
  margin: 12px 0;
  list-style: none;
  li {
    display: flex;
    margin: 12px 0px;
    label {
      color: white;
      margin-right: 16px;
    }
  }
`

// 登録者更新者情報
const CreateInfo = ({ info }) => {
  if (!info.createdAt) return null
  return (
    <InfoWrapUl>
      <li>
        <label>登録</label>
        <label>日時：{info.createdAt}</label>
        <label>登録者：{info.createdName}</label>
      </li>
      <li>
        <label>更新</label>
        <label>日時：{info.updatedAt}</label>
        <label>更新者：{info.updatedName}</label>
      </li>
    </InfoWrapUl>
  )
}

CreateInfo.propTypes = {
  info: PropTypes.object
}

export default CreateInfo
