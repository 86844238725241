import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const modalRoot = document.getElementById('modal-root')

const Background = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`

const Contents = styled.div`
  background: #fff;
  width: 600px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 20px;
  z-index: 11;
`

class Modal extends React.Component {
    handleClose = () => {
      this.props.onClose()
    };

    constructor (props) {
      super(props)
      this.el = document.createElement('div')
    }

    componentDidMount () {
      modalRoot.appendChild(this.el)
    }

    componentWillUnmount () {
      modalRoot.removeChild(this.el)
    }

    render () {
      return ReactDOM.createPortal(
        <Background>
          <Contents className={this.props.className || ''}>
            {this.props.title}
            {this.props.children}
            <p>
              <button onClick={this.handleClose}>閉じる</button>
            </p>
          </Contents>
        </Background>,
        this.el
      )
    }
}

export default Modal
