import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SwitchWrap = styled.div`
  letter-spacing : 0;
  text-align : center;
  font-size : 0.5rem;
  position : relative;
  margin : auto 16px auto 0;
`
const SwitchInput = styled.input`
  display: none;
  :checked ~ label {
    background: red;
    span:after {
      content : "${props => props.label}";
      padding : 0;
      color : #fff;
      font-weight: bold;
    }
    #swImg {
      transform : translateX(60px);
      background  : #fff;
    }
  }
`
const SwitchLabel = styled.label`
  line-height: 30px;
  height: 30px;
  display: block;
  box-sizing: content-box;
  border-radius: 15px;
  width: 100px;
  cursor: pointer;
  background: ${props => props.isNoChange ? '#555' : 'blue'};
  cursor: ${props => props.isNoChange ? 'no-drop' : 'pointer'};
  span:after{
    content : "${props => props.label}";
    padding : 0;
    color : #fff;
    font-size: 12px;
    font-weight: bold;
  }
`
const SwitchCircle = styled.div`
  position : absolute;
  width  : 20px;
  height  : 20px;
  background : ${props => props.isNoChange ? '#fff' : '#fff'};
  top: 5px;
  left: 10px;
  border-radius :10px;
  transition: .2s;
`

const SwitchButton = (props) => {
  const { switchName, flg, onChangeFlg, isNoChange, labels } = props
  const switchRef = useRef()

  useEffect(() => {
    if (switchRef) {
      switchRef.current.checked = flg === 0
    }
  }, [flg])

  const changeFlg = () => {
    if (!isNoChange) {
      switchRef.current.checked = switchRef.current.checked !== true
      onChangeFlg()
    }
  }

  return (
    <SwitchWrap onClick={() => changeFlg()}>
      <SwitchInput name={switchName} type='checkbox' ref={switchRef} label={labels?.right || 'なし'} />
      <SwitchLabel htmlFor={switchName} isNoChange={isNoChange} label={labels?.left || 'あり'}>
        <span />
        <SwitchCircle id='swImg' isNoChange={isNoChange} />
      </SwitchLabel>
    </SwitchWrap>
  )
}

SwitchButton.propTypes = {
  switchName: PropTypes.string,
  flg: PropTypes.number,
  onChangeFlg: PropTypes.func,
  isNoChange: PropTypes.bool
}

export default SwitchButton
