import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaChevronLeft, FaChevronRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import { restfulApiConfig } from '../../Common/Config'

const FlexLi = styled.li`
  display: flex;
  position: relative;
`

const TodayButton = styled.button`
  border: #777 1px solid;
  height: 32px;
  padding: 4px;
  cursor: pointer;
  border-radius: 3px;
  margin:auto 2px;
  &:hover {
    background: #ddd;
  }
`

const ArrowSpan = styled.span`
  height: 32px;
  width: 1vw;
  line-height: 35px;
  text-align: center;
  background: #ddd;
  margin: auto 1px;
  color: #333;
  cursor: pointer;
  border-radius: ${props => props.radius};
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.25);
  :hover {
    background: #ccc;
    color: #333;
    span {
      display: block;
    }
  }
`
const PopSpan = styled.span`
  position: absolute;
  display: none;
  top: -48px;
  left: 0;
  background: #fff;
  width: 102px;
  font-size: 12px;
  height: 46px;
  line-height: 20px;
  border-radius: 5px;
`

const DateChangeButtons = ({ dateStr, type, changeCalendar, isApprovedPage }) => {
  const now = new Date()
  const date = new Date(dateStr)
  const beforeWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
  const yesterday = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
  const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
  const nextWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7)
  const beforeMonth = new Date(date.getFullYear(), isApprovedPage && date.getDate() >= restfulApiConfig.startDay ? date.getMonth() - 0 : date.getMonth() - 1, date.getDate())
  const nextMonth = new Date(date.getFullYear(), isApprovedPage && date.getDate() >= restfulApiConfig.startDay ? date.getMonth() + 2 : date.getMonth() + 1, date.getDate())

  const onCalendarChange = (value) => {
    const dateStr = type === 2
      ? value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2)
      : value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2) + '-' + ('0' + value.getDate()).slice(-2)
    changeCalendar(dateStr)
  }

  const PopUp = ({ message, value, type }) => {
    const dateStr = type === 2
      ? value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2)
      : value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2) + '-' + ('0' + value.getDate()).slice(-2)
    return (
      <PopSpan>
        {message}<br />
        {dateStr}
      </PopSpan>
    )
  }
  PopUp.propTypes = {
    message: PropTypes.string,
    value: PropTypes.instanceOf(Date),
    type: PropTypes.string
  }

  return (
    <FlexLi>
      {type === 1 ? (
        <>
          <ArrowSpan radius='5px 0 0 5px' onClick={() => onCalendarChange(beforeWeek)}>
            <FaAngleDoubleLeft />
            <PopUp message='1週間前を表示' value={beforeWeek} type='month' />
          </ArrowSpan>
          <ArrowSpan radius='0' onClick={() => onCalendarChange(yesterday)}>
            <FaChevronLeft />
            <PopUp message='昨日を表示' value={yesterday} type='day' />
          </ArrowSpan>
        </>
      ) : (
        <ArrowSpan radius='5px 0 0 5px' onClick={() => onCalendarChange(beforeMonth)}>
          <FaChevronLeft />
          <PopUp message='1月前を表示' value={beforeMonth} type='day' />
        </ArrowSpan>
      )}
      <TodayButton onClick={() => onCalendarChange(now)}>
        {type === 2 ? '今月' : '今日'}
      </TodayButton>
      {type === 1 ? (
        <>
          <ArrowSpan radius='0' onClick={() => onCalendarChange(tomorrow)}>
            <FaChevronRight />
            <PopUp message='翌日を表示' value={tomorrow} type='day' />
          </ArrowSpan>
          <ArrowSpan radius='0 5px 5px 0' onClick={() => onCalendarChange(nextWeek)}>
            <FaAngleDoubleRight />
            <PopUp message='1週間後を表示' value={nextWeek} type='day' />
          </ArrowSpan>
        </>
      ) : (
        <ArrowSpan radius='0 5px 5px 0' onClick={() => onCalendarChange(nextMonth)}>
          <FaChevronRight />
          <PopUp message='1月後を表示' value={nextMonth} type='month' />
        </ArrowSpan>
      )}
    </FlexLi>
  )
}

DateChangeButtons.propTypes = {
  dateStr: PropTypes.string,
  type: PropTypes.number,
  changeCalendar: PropTypes.func,
  isApprovedPage: PropTypes.bool
}

export default DateChangeButtons
