import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { GetApi } from '../../Common/ApiAxios'
import ErrorHandling from '../Common/ErrorHandling'
import Calendar from '../../Common/Atoms/Calendar'
import Select from '../../Common/Atoms/Select'
import * as regex from '../../Common/Regex'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import { InputGroup, SelectGroup, HiddenInput } from '../Common/CommonInputGroup'
import { Alert } from '../Common/CommonStyled'
import { useYubinbango } from '../../Common/utils'
import { useAlert } from 'react-alert'
import { YubinbangoScript } from '../../Common/Atoms/ScriptTag'

const ImageArea = styled.div`
  position: absolute;
  right: 1vw;
  top: 1vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
`
const ImageWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 90px;
  background: #ccc;
`

const ImageButtonWrapper = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: space-evenly;
  padding: 0 16px 8px 16px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 90px;
  display: block;
  margin: 0 auto;
`
const ImageInputLabel = styled.label`
  font-size: 12px;
  background-color: #555555;
  color: white;
  border-radius: 10px;
  width: 50px;
  height: 24px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #adadad;
  }
`
export const StaffMasterForm = ({
  register, errors, imageRef, imageSrc, setImageSrc, setValue, getValues, staffDetail,
  watch, canWrite, grants, loginUserInfo, isPasswordChange, setIsPasswordChange, isDeleted, actionType,
  companyRef, warehouseRef, floorRef, zoneRef, companyList, selects, setSelects, isView,
  canReadStaffGrant, canWriteStaffGrant, staffGrantOptions
}) => {
  const [staffTypeOptions, setStaffTypeOptions] = useState(<></>)
  const [sexOptions, setSexOptions] = useState(<></>)
  const loginOptions = [
    <option value={false} key={1}>無</option>,
    <option value key={2}>有</option>
  ]
  const showSettingOptions = [
    <option value={0} key={1}>全て表示</option>,
    <option value={1} key={2}>実績工程に表示しない</option>,
    <option value={2} key={3}>作業工程管理に表示しない</option>
  ]
  const alert = useAlert()
  const companies = companyList.length > 0 ? companyList : null
  const company = companies && selects ? companies.find(company => company.companyId === selects.companyId) : null
  const warehouses = company ? company.warehouses : null
  const warehouse = warehouses && selects ? warehouses.find(warehouse => warehouse.warehouseId === selects.warehouseId) : null
  const floors = warehouse ? warehouse.floors : null
  const floor = floors && selects ? floors.find(floor => floor.floorId === selects.floorId) : null
  const zones = floor ? floor.zones : null

  function changeSetIsPasswordChange (e) {
    setIsPasswordChange(e)
  }

  useEffect(() => {
    if (companyList.length > 0) {
      setSelects({
        companyId: companyList[0].companyId,
        warehouseId: companyList[0].warehouses[0].warehouseId,
        floorId: companyList[0].warehouses[0].floors[0].floorId,
        zoneId: companyList[0].warehouses[0].floors[0].zones[0].zoneId
      })
    }
  // 会社リストが更新された場合のみ実行
  // eslint-disable-next-line
  }, [companyList])

  // スタッフ情報切り替え時
  useEffect(() => {
    setImageSrc(staffDetail.facePhotoFile)
    setBirthDt(staffDetail.birthDt)
    setEnterDt(staffDetail.enterDt)
    setRetireDt(staffDetail.retireDt)
    setIsClearImage(false)
  // スタッフ情報が変更された場合のみ実行
  // eslint-disable-next-line
  }, [staffDetail])

  // ファイル選択
  const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
  const handleChangeFile = useCallback((e) => {
    const files = e.target.files
    if (files.length > 0) {
      const imageUrl = createObjectURL(files[0])
      setImageSrc(imageUrl)
    } else {
      setImageSrc()
    }
  }, [setImageSrc, createObjectURL])

  register('isClearImage')
  const [isClearImage, setIsClearImage] = useState(false)
  const clearImage = () => {
    setImageSrc()
    setIsClearImage(true)
    setValue('isClearImage', true)
  }
  const clearRevertImage = () => {
    setImageSrc(staffDetail.facePhotoFile)
    setIsClearImage(false)
    setValue('isClearImage', false)
  }

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getStaffType()
      getSex()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // スタッフ区分取得
  const getStaffType = useCallback(async () => {
    const result = await GetApi('/api/master/classification/staffType')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setStaffTypeOptions(options)
    }
  }, [setStaffTypeOptions, alert])

  // 性別取得
  const getSex = useCallback(async () => {
    const result = await GetApi('/api/master/classification/sex')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setSexOptions(options)
    }
  }, [setSexOptions, alert])

  // 誕生日
  register({ name: 'birthDt' })
  const [birthDt, _setBirthDt] = useState()
  const setBirthDt = (date) => {
    _setBirthDt(date)
    setValue('birthDt', date)
  }

  // 入社日
  register({ name: 'enterDt' })
  const [enterDt, _setEnterDt] = useState()
  const setEnterDt = (date) => {
    _setEnterDt(date)
    setValue('enterDt', date)
  }

  // 退社日
  register({ name: 'retireDt' })
  const [retireDt, _setRetireDt] = useState()
  const setRetireDt = (date) => {
    _setRetireDt(date)
    setValue('retireDt', date)
  }

  // 倉庫を初期値に戻す
  const downSelectWarehouse = (companyId) => {
    if (companyId === '-1') return -1
    const company = companies.find(company => company.companyId === companyId)
    if (!company) return -1
    return company.warehouses[0] ? company.warehouses[0].warehouseId : -1
  }
  // フロアを初期値に戻す
  const downSelectFloor = (warehouseId) => {
    if (warehouseId === -1 || !warehouses) return -1
    const house = warehouses.find(house => house.warehouseId === warehouseId)
    if (!house) return -1
    return house.floors[0] ? house.floors[0].floorId : -1
  }
  // ゾーンを初期値に戻す
  const downSelectZone = (floorId) => {
    if (floorId === -1 || !floors) return -1
    const floor = floors.find(floor => floor.floorId === floorId)
    if (!floor) return -1
    return floor.zones[0] ? floor.zones[0].zoneId : -1
  }

  // 会社
  const Companies = () => {
    const selectCompany = useCallback((event) => {
      const companyId = event.target.value
      const warehouseId = downSelectWarehouse(companyId)
      const floorId = downSelectFloor(warehouseId)
      const zoneId = downSelectZone(floorId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    return (
      <Select
        margin='0 16px 0 0'
        selectValue={selects.companyId} selectRef={companyRef} disabled={isDeleted || !canWrite}
        onChange={event => selectCompany(event)}
      >
        {companies && companies.map(item => {
          if ((grants.writeGrantDivision === 1 || grants.writeGrantDivision === 3) && item.companyId !== loginUserInfo.companyId) return null
          return (
            <option key={item.companyId} value={item.companyId}>{item.companyName}</option>
          )
        })}
      </Select>
    )
  }

  // 倉庫
  const Warehouses = () => {
    const selectWarehouse = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = event.target.value - 0
      const floorId = downSelectFloor(warehouseId)
      const zoneId = downSelectZone(floorId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    return (
      <Select
        margin='0 16px 0 0'
        selectValue={selects.warehouseId} selectRef={warehouseRef} disabled={isDeleted || !canWrite}
        onChange={event => selectWarehouse(event)}
      >
        {warehouses && warehouses.map(item => {
          if ((grants.writeGrantDivision === 1 || grants.writeGrantDivision === 3) && item.warehouseId !== loginUserInfo.warehouseId) return null
          return (
            <option key={item.warehouseId} value={item.warehouseId}>{item.warehouseName}</option>
          )
        })}
      </Select>
    )
  }

  // フロア
  const Floors = () => {
    const selectFloor = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = selects.warehouseId
      const floorId = event.target.value - 0
      const zoneId = downSelectZone(floorId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    return (
      <Select
        margin='0 16px 0 0'
        selectValue={selects.floorId} selectRef={floorRef} disabled={isDeleted || !canWrite}
        onChange={event => selectFloor(event)}
      >
        <option value={-1}>設定なし</option>
        {floors && floors.map(item => {
          if (item.floorId === 0) return null
          return (
            <option key={item.floorId} value={item.floorId}>{item.floorName}</option>
          )
        })}
      </Select>
    )
  }

  // ゾーン
  const Zones = () => {
    const selectFloor = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = selects.warehouseId
      const floorId = selects.floorId
      const zoneId = event.target.value - 0
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId,
        floorId: floorId,
        zoneId: zoneId,
        categoryId: selects.categoryId
      })
    }, [])
    return (
      <Select
        margin='0 16px 0 0'
        selectValue={selects.zoneId || -1} selectRef={zoneRef} disabled={isDeleted || !canWrite}
        onChange={event => selectFloor(event)}
      >
        <option value={-1}>設定なし</option>
        {zones && zones.map(item => {
          if (item.zonId === 0) return null
          return (
            <option key={item.zoneId} value={item.zoneId}>{item.zoneName}</option>
          )
        })}
      </Select>
    )
  }

  // 郵便番号による自動補完
  const [zipCode, setZipCode] = useState(null)
  const { region, locality, street } = useYubinbango(zipCode)

  useEffect(() => {
    const { address1, address2, address3 } = getValues()
    !address1 && setValue('address1', region, true)
    !address2 && setValue('address2', locality, true)
    !address3 && setValue('address3', street, true)
  // 住所情報の変更検知時のみ実行する
  // eslint-disable-next-line
  }, [region, locality, street])

  const onChangePostalCode = useCallback(e => {
    setZipCode(e.currentTarget.value)
  }, [setZipCode])

  if (!selects) return null
  return (
    <MasterDetailWrap isView={isView}>
      <YubinbangoScript />
      <ul>
        <ImageArea>
          <ImageWrapper>
            {imageSrc && <Image src={imageSrc} />}
          </ImageWrapper>
          <ImageButtonWrapper>
            <ImageInputLabel htmlFor='staffImage'>参照</ImageInputLabel>
            {staffDetail?.facePhotoFile && !isClearImage && <ImageInputLabel onClick={clearImage}>クリア</ImageInputLabel>}
            {isClearImage && <ImageInputLabel onClick={clearRevertImage}>元に戻す</ImageInputLabel>}
          </ImageButtonWrapper>
          <input
            type='file'
            id='staffImage'
            ref={imageRef}
            onChange={handleChangeFile}
            disabled={isDeleted || !canWrite}
            style={{ display: 'none' }}
          />
        </ImageArea>
        <li style={{ height: '15px' }}><label /><div /></li>
        {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
        <li>
          <label>ID</label>
          <div>
            <InputGroup
              width='20vw'
              margin='12px 0 12px 0'
              placeholder='スタッフID(未入力時は自動採番)'
              name='staffId'
              register={register}
              rule={{
                maxLength: regex.maxLength(10),
                pattern: regex.halfWidthAlphaNumeric
              }}
              errors={errors}
              disabled={isDeleted || !canWrite || actionType === 'PUT'}
            />
            <InputGroup
              width='20vw'
              margin='12px 0 12px 16px'
              placeholder='連携用ID'
              name='cooperationId'
              register={register}
              rule={{
                maxLength: regex.maxLength(10),
                pattern: regex.halfWidthAlphaNumeric
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li style={{ display: canReadStaffGrant ? 'flex' : 'none' }}>
          <label>権限区分</label>
          <div>
            <SelectGroup
              name='grant.grantNameId'
              width='320px'
              margin='12px 0 12px'
              options={staffGrantOptions}
              register={register}
              rule={{}}
              errors={errors}
              disabled={isDeleted || !canWrite || !canWriteStaffGrant}
            />
            <HiddenInput
              name='grant.updatedId'
              register={register}
            />
            <HiddenInput
              name='grant.updatedAt'
              register={register}
            />
          </div>
        </li>
        <li>
          <label>氏名</label>
          <div>
            <InputGroup
              width='20vw'
              margin='12px 0 12px 0'
              placeholder='スタッフ名称'
              name='staffName'
              register={register}
              rule={{
                required: regex.required,
                maxLength: regex.maxLength(50)
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
            <InputGroup
              width='20vw'
              margin='12px 0 12px 16px'
              placeholder='スタッフ名称(カナ)'
              name='staffKana'
              register={register}
              rule={{
                maxLength: regex.maxLength(50),
                pattern: regex.kana
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>区分</label>
          <div>
            <SelectGroup
              name='staffDivision'
              width='192px'
              margin='0'
              options={staffTypeOptions}
              register={register}
              rule={{
                required: regex.required
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>入社日/退社日</label>
          <div>
            <Calendar
              margin='0 16px 0 0'
              name='enterDt'
              placeholder='入社日'
              changeCalendar={setEnterDt}
              isClearable
              nullable
              width='160px'
              dataValue={enterDt}
              disabled={isDeleted || !canWrite}
              prefix='/'
            />
            <Calendar
              margin='0'
              name='retireDt'
              placeholder='退社日'
              changeCalendar={setRetireDt}
              isClearable
              nullable
              width='160px'
              dataValue={retireDt}
              disabled={isDeleted || !canWrite}
              prefix='/'
            />
          </div>
        </li>
        <li>
          <label>性別</label>
          <div>
            <SelectGroup
              name='sex'
              width='120px'
              margin='12px 0 12px 0'
              options={sexOptions}
              register={register}
              rule={{
                required: regex.required
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>生年月日</label>
          <div>
            <Calendar
              margin='0'
              name='birthDt'
              placeholder='生年月日'
              changeCalendar={setBirthDt}
              isClearable
              nullable
              width='160px'
              dataValue={birthDt}
              disabled={isDeleted || !canWrite}
              prefix='/'
            />
          </div>
        </li>
        <li>
          <label>住所</label>
          <div>
            <InputGroup
              width='192px'
              margin='12px 0 12px 0'
              placeholder='郵便番号'
              name='zipCode'
              register={register}
              onChange={onChangePostalCode}
              rule={{
                maxLength: regex.maxLength(8)
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label />
          <div>
            <InputGroup
              width='192px'
              margin='12px 0 12px 0'
              placeholder='都道府県'
              name='address1'
              register={register}
              rule={{
                maxLength: regex.maxLength(5)
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label />
          <div>
            <InputGroup
              width='678px'
              margin='12px 0 12px 0'
              placeholder='地区町村'
              name='address2'
              register={register}
              rule={{
                maxLength: regex.maxLength(20)
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label />
          <div>
            <InputGroup
              width='678px'
              margin='12px 0 12px 0'
              placeholder='町域名'
              name='address3'
              register={register}
              rule={{
                maxLength: regex.maxLength(50)
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label />
          <div>
            <InputGroup
              placeholder='建物名'
              width='678px'
              margin='12px 0 12px 0'
              name='address4'
              register={register}
              rule={{
                maxLength: regex.maxLength(50)
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>電話番号</label>
          <div>
            <InputGroup
              width='320px'
              margin='12px 0 12px 0'
              placeholder='ご自宅'
              name='phoneNum'
              register={register}
              rule={{
                maxLength: regex.maxLength(20),
                pattern: regex.halfNumericHyphen
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
            <InputGroup
              width='320px'
              margin='12px 0 12px 16px'
              placeholder='携帯'
              name='mobileNum'
              register={register}
              rule={{
                maxLength: regex.maxLength(20),
                pattern: regex.halfNumericHyphen
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>メールアドレス</label>
          <div>
            <InputGroup
              width='320px'
              margin='12px 0 12px 0'
              placeholder='パソコン'
              name='pcMailAddress'
              register={register}
              rule={{
                maxLength: regex.maxLength(50),
                pattern: regex.mail
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
            <InputGroup
              width='320px'
              margin='12px 0 12px 16px'
              placeholder='携帯'
              name='mobileMailAddress'
              register={register}
              rule={{
                maxLength: regex.maxLength(50),
                pattern: regex.mail
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>所属</label>
          <div>
            <Companies />
            <Warehouses />
            <HiddenInput
              name='warehouseRelation.staffWarehouseId'
              register={register}
            />
            <HiddenInput
              name='warehouseRelation.updatedId'
              register={register}
            />
            <HiddenInput
              name='warehouseRelation.updatedAt'
              register={register}
            />
          </div>
        </li>
        <li>
          <label>初期配置</label>
          <div>
            <Floors />
            <Zones />
          </div>
        </li>
        <li>
          <label>表示設定</label>
          <div>
            <SelectGroup
              width='200px'
              margin='12px 0 12px 0'
              name='showSetting'
              register={register}
              options={showSettingOptions}
              rule={{ required: regex.required }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>ログイン有無</label>
          <div>
            <SelectGroup
              width='120px'
              margin='12px 0 12px 0'
              name='loginFlag'
              register={register}
              options={loginOptions}
              rule={{ required: regex.required }}
              errors={errors}
              disabled={isDeleted || !canWrite}
              onChange={(e) => {
                if (watch('loginFlag') === 'false') {
                  setValue('password', '')
                  setValue('confirmPassword', '')
                  setValue('isPasswordChange', false)
                }
              }}
            />
          </div>
        </li>
        <li>
          <label>パスワード</label>
          <div>
            <InputGroup
              width='320px'
              margin='12px 0 12px 0'
              name='password'
              type='password'
              register={register}
              rule={{
                maxLength: regex.maxLength(50),
                validate: {
                  customRequired: value => (watch('loginFlag') === 'true' && isPasswordChange && !value) ? 'ログインユーザーの場合は必須です' : null
                }
              }}
              errors={errors}
              disabled={isDeleted || !canWrite || !isPasswordChange || watch('loginFlag') === 'false'}
            />
            <InputGroup
              width='320px'
              margin='12px 0 12px 16px'
              placeholder='確認用'
              name='confirmPassword'
              type='password'
              register={register}
              rule={{
                maxLength: regex.maxLength(50),
                validate: {
                  confirm: value => value === watch('password') || 'パスワードが一致しません'
                }
              }}
              errors={errors}
              disabled={isDeleted || !canWrite || !isPasswordChange || watch('loginFlag') === 'false'}
            />
          </div>
        </li>
        <li style={{ height: 24 }}>
          <label />
          <div>
            <label>
              <input
                type='checkbox'
                ref={register}
                id='isPasswordChange'
                name='isPasswordChange'
                checked={isPasswordChange}
                onChange={e => changeSetIsPasswordChange(e.target.checked)}
                disabled={watch('loginFlag') === 'false' || isDeleted || !canWrite}
              />
              パスワードを更新する
            </label>
          </div>
        </li>
        <HiddenInput name='updatedId' register={register} />
        <HiddenInput name='updatedAt' register={register} />
        <li style={{ height: '15px' }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

StaffMasterForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  imageRef: PropTypes.any,
  imageSrc: PropTypes.string,
  setImageSrc: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  staffDetail: PropTypes.object,
  watch: PropTypes.func,
  canWrite: PropTypes.bool,
  isPasswordChange: PropTypes.bool,
  setIsPasswordChange: PropTypes.func,
  isDeleted: PropTypes.bool,
  actionType: PropTypes.string,
  companyRef: PropTypes.any,
  warehouseRef: PropTypes.any,
  floorRef: PropTypes.any,
  zoneRef: PropTypes.any,
  companyList: PropTypes.array,
  selects: PropTypes.object,
  setSelects: PropTypes.func,
  isView: PropTypes.bool
}

export default StaffMasterForm
