import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Staff from './Staff'
// import '../css/Zone.scss'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Modal from 'react-modal'
import moment from 'moment'
// import { FaUser } from 'react-icons/fa'
import { PutApi } from '../Common/ApiAxios'

const ZoneContainer = styled.div`
    opacity: 0.9;
    border: 1px dashed #000000;
    position: absolute;
`
const StaffContainer = styled.div`
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    height: ${props => props.height}px;
    width: 100%;
    overflow-y: auto;
    background-color: ${props => (props.isDraggingOver ? 'lightgray' : 'black')};
    // background-color: ${props => (props.isDraggingOver ? 'lightgray' : 'rgba(229, 243, 255, 50%)')};
`
const zoneStyle = {
  display: 'flex'
}
// const StaffCount = ({ count }) => {
//   return (
//     <>
//       <FaUser style={{ marginLeft: '10px' }} /><div>{count}人</div>
//     </>
//   )
// }

const Zone = props => {
  const [style, setStyle] = useState({})
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const rightClickPos = useRef([0, 0])
  const whiteboardMod = props.mode === 'whiteboard'

  useEffect(() => {
    setStyle({
      top: (props.posYStart) + 'px',
      left: (props.posXStart) + 'px',
      width: (props.posXEnd - props.posXStart) + 'px',
      height: (props.posYEnd - props.posYStart) + 'px'
    })
    // eslint-disable-next-line
  }, [])

  const onMouseDownEvent = (e, name, endTime) => {
    switch (e.button) {
      case 2: {
        if (props.mode === 'whiteboard') break
        console.log(`
         
            name: ${name}
            endTime: ${endTime}
         
        `)
        const x = e.nativeEvent.offsetX
        const y = e.nativeEvent.offsetY
        rightClickPos.current = [x, y]
        setShowContextMenu(true)
        break
      }
      default:
        setShowContextMenu(false)
        console.log('other')
        break
    }
  }

  let subtitle
  function afterOpenModal () {
    // references are now sync'd and can be accessed.
    subtitle.style.margin = '10px'
  }

  function closeEditModal () {
    setEditIsOpen(false)
  }

  function openEditZoneModal () {
    setShowContextMenu(false)
    setEditIsOpen(true)
  }

  // TODO component化
  // TODO 右クリックコンテキストメニュー => Floorに移動
  const ContextMenu = useCallback(() => {
    const menuStyle = {
      display: showContextMenu ? 'block' : 'none',
      margin: '5px 20px',
      position: 'absolute',
      backgroundColor: 'lightgray',
      top: `${rightClickPos.current[1]}px`,
      left: `${rightClickPos.current[0]}px`,
      height: '150px',
      width: '120px',
      zIndex: 99999
    }

    return (
      <div style={menuStyle}>
        <div
          className='contextMenuItem'
          onClick={e => openEditZoneModal(true)}
          onContextMenu={e => e.preventDefault()}
        >ゾーン終了時間設定
        </div>
        <div
          className='contextMenuItem'
          // onClick={e => openEditZoneModal(true)}
          onContextMenu={e => e.preventDefault()}
        >ゾーン詳細情報
        </div>
        <div
          className='contextMenuItem'
          // onClick={e => openEditZoneModal(true)}
          onContextMenu={e => e.preventDefault()}
        >ゾーン内予定作業一覧
        </div>
      </div>
    )
  }, [showContextMenu])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  }

  const editZoneEndTime = (e) => {
    e.preventDefault()

    console.log(e.target.endtime.value)
    console.log(props)
    const data = {
      endTime: parseInt(e.target.endtime.value),
      targetDate: moment().format('YYYY-MM-DD') // TODO
    }
    PutApi(`/api/whiteboard/zoneEndTime/${props.zoneId}`, data, true)
      .then(result => {
        if (result.errorDetail) {
          console.error(result.errorDetail)
        } else {
          console.log(result)
          props.getZoneEndTime(props.floorId)
        }
      })

    closeEditModal()
  }

  const inputStyle = {
    margin: '10px',
    height: 25
  }

  function cancelEdit () {
    closeEditModal()
  }

  const ZoneEditModal = () => {
    const endTime = props.zoneEndTime == null
      ? 0
      : parseInt(props.zoneEndTime.replace('~', '').replace(':', '').trim())

    return (
      <Modal
        isOpen={editIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeEditModal}
        style={customStyles}
        contentLabel='Edit Zone Name Modal'
        ariaHideApp={false}
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}>ゾーン情報編集</h2>
        <form onSubmit={editZoneEndTime}>
          <div style={{ display: 'flex' }}>
            <div>ゾーン名</div>
            <div>{props.zoneName}</div>
          </div>
          <div>
            <label htmlFor='endtime'>最終時間</label>
            <input
              style={inputStyle} type='number' name='endtime' id='endtime' min={0} max={3600}
              defaultValue={endTime === 0 ? '0' : endTime}
            />
          </div>
          <div>
            <button>登録</button>
            <button type='button' onClick={cancelEdit}>キャンセル</button>
          </div>
        </form>
      </Modal>
    )
  }

  const sortStaffs = (staffs) => {
    const sortedStaffs = staffs.map(s => {
      if (s.plannedWorks != null) {
        // console.log(s.staffName, s.plannedWorks)
        s.start = null
        s.end = null
        s.startTime = null
        s.endTime = null
        const plan = s.plannedWorks.filter(w => w.plannedStart != null)
        if (plan.length !== 0) {
          // console.table(plan)
          const start = Math.min(...plan.map(p => parseInt(p.plannedStart.replace(':', ''))))
          const end = Math.max(...plan.map(p => parseInt(p.plannedEnd.replace(':', ''))))
          // console.log(start, end)

          const startTime = String(start).padStart(4, '0').slice(0, 2) + ':' + String(start).padStart(4, '0').slice(-2)
          const endTime = String(end).padStart(4, '0').slice(0, 2) + ':' + String(end).padStart(4, '0').slice(-2)
          // console.log(startTime, endTime)
          s.start = start
          s.end = end
          s.startTime = startTime
          s.endTime = endTime
        }
      }
      return s
    })
    sortedStaffs.sort((a, b) => {
      if (a.start == null && b.start == null) return 0
      if (a.start == null) return 1
      if (b.start == null) return -1
      if (a.start < b.start) return -1
      if (a.start > b.start) return 1
      if (a.start === b.start) {
        if (a.end < b.end) return -1
        if (a.end > b.end) return 1
        return 0
      }
      return 0
    })

    return sortedStaffs
  }
  const _staffs = sortStaffs(props.staffs)
  const ZoneHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 70px;
    line-height: 70px;
    width: 100%;
    color: #FFF;
    background: #333;
    font-size: 16px;
  `
  return (
    <ZoneContainer
      style={style}
    >
      <div style={zoneStyle}>
        <ZoneHeader
          onMouseDown={e => onMouseDownEvent(e, props.zoneName, props.zoneEndTime)}
          onContextMenu={e => e.preventDefault()}
        >
          <p className='zoneName'>{props.zoneName}</p>
          <p className='zoneEndTime'>{props.zoneEndTime}</p>
        </ZoneHeader>
      </div>
      <Droppable droppableId={props.zoneId.toString()}>
        {(dropprovided, snapshot) => (
          // width: (props.posXEnd - props.posXStart) + 'px',
          // height: (props.posYEnd - props.posYStart) + 'px'
          // _staffs.count
          <StaffContainer
            ref={dropprovided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            height={(parseInt(props.posYEnd) - parseInt(props.posYStart) - 75)}
          >
            {_staffs.map((staff, index) => {
              if (whiteboardMod) {
                return (
                  <Staff
                    key={staff.staffId}
                    index={index}
                    {...staff}
                    provided={null}
                    openModal={props.openModal}
                  />
                )
              }
              return (
                <Draggable
                  index={index} key={staff.staffId}
                  draggableId={staff.staffName + staff.staffId.toString()}
                >
                  {(dragprovided, snapshot) => (
                    <Staff
                      key={staff.staffId}
                      index={index}
                      {...staff}
                      provided={dragprovided}
                      snapshot={snapshot}
                      // func={props.func}
                      openModal={props.openModal}
                    />
                  )}
                </Draggable>
              )
            })}
            {dropprovided.placeholder}
          </StaffContainer>
        )}
      </Droppable>
      <ZoneEditModal />
      <ContextMenu />
    </ZoneContainer>
  )
}

Zone.propTypes = {
  mode: PropTypes.string,
  posXStart: PropTypes.number,
  posXEnd: PropTypes.number,
  posYStart: PropTypes.number,
  posYEnd: PropTypes.number,
  zoneId: PropTypes.number,
  getZoneEndTime: PropTypes.func,
  floorId: PropTypes.number,
  zoneEndTime: PropTypes.string,
  staffs: PropTypes.array,
  openModal: PropTypes.func,
  zoneName: PropTypes.string
}

export default Zone
