import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  useQuery
} from '@apollo/client'

import { LABOR_SUMMARY } from './Schema'

const Label = ({ children, style }) => <div style={{ paddingRight: '8px', paddingLeft: '8px', lineHight: '30px', ...style }}>{children}</div>
Label.propTypes = {
  children: PropTypes.string
}
const LoadingLabel = () => <div style={{ top: '25px', left: '300px', position: 'absolute' }}>Loading...</div>

const FlexDiv = styled.div`
  display: flex; 
  align-items: center;
  height: 30px;
  line-height: 30px;
  top: 20px;
  left: 200px;
  position: absolute;
`

const fontSizeStyle = { fontSize: '0.9em' }
const GetLaborSummary = ({ staffId, workDateFrom, workDateTo }) => {
  // console.log(workDateFrom, workDateTo)
  const { loading, error, data } = useQuery(LABOR_SUMMARY, {
    fetchPolicy: 'network-only',
    variables: { staffId: staffId, workDateFrom: workDateFrom, workDateTo: workDateTo }
  })

  error && console.log(error)
  if (loading) return <LoadingLabel />
  if (error) return <p />
  console.log(data)
  return (
    <FlexDiv>
      <Label style={fontSizeStyle}>
        出勤日数:
      </Label>
      <div style={fontSizeStyle}>
        {data.personalLaborSummary.totalDays}日
      </div>
      <Label style={fontSizeStyle}>
        労働時間:
      </Label>
      <div style={fontSizeStyle}>
        {data.personalLaborSummary.totalHours}h
      </div>
      <Label style={fontSizeStyle}>
        進捗残業時間:
      </Label>
      <div style={fontSizeStyle}>
        {data.personalLaborSummary.progressOvertime}h
      </div>
      <Label style={fontSizeStyle}>
        進捗深夜残業時間:
      </Label>
      <div style={fontSizeStyle}>
        {data.personalLaborSummary.progressOvertimeMidnight}h
      </div>
    </FlexDiv>
  )
}
GetLaborSummary.propTypes = {
  staffId: PropTypes.string,
  workDateFrom: PropTypes.string,
  workDateTo: PropTypes.string
}
export default GetLaborSummary
