import React from 'react'

import { DateTime } from 'luxon'
import { withStyles } from '@material-ui/core/styles'
import { TableCell, Tooltip } from '@material-ui/core'
import { getLegalHours } from '../MonthlyLabor/helper'
import { getFirstAndLastDateFromYmd } from '../../Common/utils'

const styles = () => ({
  root: {
    '&:last-child': {
      borderRight: '1px solid rgba(224, 224, 224, 1)'
    },
    borderLeft: '1px solid rgba(224, 224, 224, 1)'
  }
})

const StyledCell = withStyles(styles)(TableCell)

export const buildAnnualLabor = (annualLabor, totalHours = 0) => {
  const today = DateTime.local().toFormat('yyyy-MM-dd')
  const thisMonth = getYyyyMm(today)

  const ret = []
  for (const data of annualLabor) {
    // console.log(data)
    const tmp = {}
    tmp.id = data.staffId
    tmp.staffId = data.staffId
    tmp.staffName = data.staffName
    let tmpTotal = 0
    for (const m of data.summaryList) {
      const stdWorkingHours = calcStandardWorkingHours(m.month)
      const monthStr = m.month.substring(5)
      const fieldName = `month${monthStr}`
      tmp[fieldName] = m.month > thisMonth ? 0 : m.workTime

      const statusFieldName = `month${monthStr}Warning`
      tmp[statusFieldName] = m.month > thisMonth ? false : (tmp[fieldName] - stdWorkingHours) >= 30
      tmpTotal += tmp[fieldName]
    }
    tmp.total = tmpTotal
    tmp.totalWarning = getSub(tmpTotal, totalHours) > 0
    // console.log(tmp)
    ret.push(tmp)
  }
  // console.log(ret)
  return ret
}

export const buildPailHolidays = (paidHolidays, yearStr) => {
  const ret = []
  const monthArr = []
  for (let i = 0; i < 12; i++) {
    const yearMonthStr = DateTime.fromFormat(yearStr + '-04', 'yyyy-MM').plus({ month: i }).toFormat('yyyy-MM')
    monthArr.push(yearMonthStr)
  }
  for (const data of paidHolidays.paidHolidays) {
    // console.log(data)
    const tmp = {}
    tmp.id = data.staffId
    tmp.staffId = data.staffId
    tmp.staffName = data.staffName
    let tmpTotal = 0

    // console.log(data.details)

    for (const m of monthArr) {
      let count = 0
      let days = null
      for (const detail of data.details) {
        if (m === detail.targetMonth) {
          count = detail.targetMonthDetail.count
          days = detail.targetMonthDetail.days ? detail.targetMonthDetail.days : null
          tmpTotal += count
        }
      }
      const monthStr = m.substr(5, 2)
      tmp[`month${monthStr}`] = count
      tmp[`month${monthStr}Warning`] = false
      tmp[`month${monthStr}Days`] = days
    }

    tmp.total = tmpTotal
    tmp.totalWarning = tmpTotal < 5
    // console.log(tmp)
    ret.push(tmp)
  }
  console.log(ret)
  return ret
}

const calcStandardWorkingHours = (month) => {
  // console.log(month)
  const fromDate = DateTime.fromFormat(month + '-' + '21', 'yyyy-MM-dd').plus({ months: -1 })
  const toDate = DateTime.fromFormat(month + '-' + '20', 'yyyy-MM-dd')
  // console.log(fromDate, toDate)
  const daysCount = toDate.diff(fromDate, ['days'])
  const standadOvertime = getLegalHours(daysCount.days + 1)
  // console.log(standadOvertime)
  return standadOvertime
}

function getSub (a, b) {
  const sub = a - b
  const ret = Math.round((a - b) * 10) / 10
  return sub > 0 ? '+' + ret : ret
}

export const renderCell = (classes, index, header, row, key, totalHours) => {
  const alertClassCss = { color: 'red', fontWeight: 'bold' }
  const days = row[key + 'Days']
  const tooltipContext = (days && days.length > 0)
    ? days.map(t => <p key={t}>{t}</p>)
    : null

  const cell = (
    <StyledCell
      key={index}
      width={header.width}
      align={header.numeric == null ? 'center' : header.numeric ? 'right' : 'left'}
      className={classes.tableCell}
    >
      {
        header.label === '合計'
          ? row.totalWarning
            ? <Tooltip title={getSub(Number(row[key]), totalHours)} placement='top' style={alertClassCss}><p>{row[key]} </p></Tooltip>
            : <Tooltip title={getSub(Number(row[key]), totalHours)} placement='top'><p>{row[key]} </p></Tooltip>
          : (row[key + 'Warning'])
            ? <p style={alertClassCss}>{row[key]}</p>
            : <p>{row[key]} </p>
      }

    </StyledCell>
  )
  return (
    <>
      {tooltipContext ? (
        <Tooltip
          placement='top' classes={{ tooltip: classes.customTooltip }}
          title={<span>{tooltipContext}</span>}
        >
          {cell}
        </Tooltip>

      ) : (cell)}
    </>
  )
}

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const getYyyyMm = (dateStr) => {
  // dateStrの月を月の開始日、終了日から判断する
  const [_, lastDay] = getFirstAndLastDateFromYmd(dateStr)
  return lastDay.substr(0, 7)
}
