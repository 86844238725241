/* eslint-env browser */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const MenuUl = styled.ul`
  display: block;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: ${props => props.leftPosition}px;
  height: ${props => props.height}px;
  list-style: none;
  background: #eee;
  border: none;
  border-radius: 5px;
  padding: 5px;
  box-sizing: content-box;
`
const MenuLabelLi = styled.li`
  margin: 2px 5px;
  height: 20px
`
const MenuLi = styled.li`
  margin : 2px 5px;
  height: 20px
`
const DeleteButton = styled.button`
  width: 50px;
  height: 18px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #ff0053;
  border-radius: 5px;
  border: none;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  color: white;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
  &:hover{
      background: #b30063;
  }
`
// 削除ボタン
export const VariableDeleteButton = ({ clickFunction, name }) => {
  return (
    <DeleteButton
      className='menuButtons'
      onClick={(e) => {
        e.stopPropagation()
        clickFunction()
      }}
    >
      {name}
    </DeleteButton>
  )
}
VariableDeleteButton.propTypes = {
  clickFunction: PropTypes.func,
  name: PropTypes.string
}

const Label = styled.label`
  color: #333;
  background: ${props => props.selected ? 'lime' : '#ccc'};
  display: block;
  width: 50px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
`
export const SelectWorkPlace = ({ labor, changeFunction }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Label
        className='menuButtons'
        onClick={e => {
          e.stopPropagation()
          changeFunction(labor, 1)
        }}
        selected={labor.workPlace === 1}
        style={{ borderRadius: '5px 0 0 5px', borderRight: '1px solid #333' }}
      >出勤
      </Label>
      <Label
        className='menuButtons'
        onClick={e => {
          changeFunction(labor, 2)
        }}
        selected={labor.workPlace === 2}
        style={{ borderRadius: '0 5px 5px 0' }}
      >リモワ
      </Label>
    </div>
  )
}
SelectWorkPlace.propTypes = {
  labor: PropTypes.object,
  changeFunction: PropTypes.func
}

// 右クリックメニュー
const ContextMenu = ({ leftPosition, menuList }) => {
  if (localStorage.getItem('processingFlg') === 'true') return null
  return (
    <MenuUl
      leftPosition={leftPosition}
      className='contextMenu'
      height={28 + (menuList != null ? menuList.length * 20 : 0)}
    >
      <MenuLabelLi>MENU</MenuLabelLi>
      {menuList && menuList.map(menu => {
        return (
          <MenuLi key={menu.name}>
            {menu.component}
          </MenuLi>
        )
      })}
    </MenuUl>
  )
}

ContextMenu.propTypes = {
  leftPosition: PropTypes.number,
  menuList: PropTypes.array
}

export default ContextMenu
