import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  laborStaffId: null
}

const MonthlyLaborSummarySlice = createSlice({
  name: 'monthlyLaborStaff',
  initialState,
  reducers: {
    setLaborStaffId (state, action) {
      console.log('setLaborStaffId called', state, action)
      state.laborStaffId = action.payload
    }
  }
})

export const LaborStaffStates = () => {
  return useSelector((state) => state.monthlyLaborStaff)
}

export const { setLaborStaffId } = MonthlyLaborSummarySlice.actions
export default MonthlyLaborSummarySlice.reducer
