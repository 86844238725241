const Config = () => {
  if (process.env.NODE_ENV === 'test') {
    // test
    return {
      restful: {
        startDay: process.env.REACT_APP_START_DAY,
        host: process.env.REACT_APP_TEST_API_URL,
        measurementId: '',
        googleMapKey: ''
      }
    }
  } else if (process.env.NODE_ENV === 'production') {
    // production
    return {
      restful: {
        startDay: process.env.REACT_APP_START_DAY,
        host: process.env.REACT_APP_PROD_API_URL,
        measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE_PROD,
        graphql: process.env.REACT_APP_PROD_GRAPHQL_SERVER_URL,
        googleMapKey: process.env.REACT_APP_PROD_GOOGLE_MAP_KEY
      }
    }
  } else if (process.env.NODE_ENV === 'staging') {
    // development
    return {
      restful: {
        startDay: process.env.REACT_APP_START_DAY,
        host: process.env.REACT_APP_DEV_API_URL,
        measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE_DEV,
        graphql: process.env.REACT_APP_DEV_GRAPHQL_SERVER_URL,
        googleMapKey: process.env.REACT_APP_DEV_GOOGLE_MAP_KEY
      }
    }
  }
  // local
  return {
    restful: {
      startDay: process.env.REACT_APP_START_DAY,
      host: process.env.REACT_APP_LOCAL_API_URL,
      measurementId: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE_LOCAL,
      graphql: process.env.REACT_APP_LOCAL_GRAPHQL_SERVER_URL,
      googleMapKey: process.env.REACT_APP_LOCAL_GOOGLE_MAP_KEY
    }
  }
}
export const restfulApiConfig = Config().restful
