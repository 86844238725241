/* eslint-env browser */
import React from 'react'
import styled from 'styled-components'

const HourDiv = styled.div`
  width: 60px;
  color: white;
  font-family: 'Roboto';
  font-size: 10px;
  line-height: 30px;
  text-indent: 5px;
  border-left: 2px solid rgb(153, 153, 153);
`

const Hours = React.memo(() => {
  const hours = []
  for (let i = 0; i < 36; i++) {
    hours.push(i)
  }
  return (
    <>
      {hours.map(d => {
        return <HourDiv key={d}>{('0' + d).slice(-2) + ':00'}</HourDiv>
      })}
    </>
  )
})

export default Hours
