/* eslint-env browser */
import { createSlice } from '@reduxjs/toolkit'
import { GetApi } from '../../Common/ApiAxios'
import { useSelector } from 'react-redux'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { getFirstAndLastDate } from '../../Common/utils'

const initialState = {
  dateRecords: null,
  laborApproval: null,
  laborTemplate: []
}

const LaborSlice = createSlice({
  name: 'individualMonthly',
  initialState,
  reducers: {
    // レイバーset
    SetDateRecords: (state, action) => {
      state.dateRecords = action.payload
    },
    SetLaborApproval: (state, action) => {
      state.laborApproval = action.payload
    },
    setLaborTemplate: (state, action) => {
      state.laborTemplate = action.payload
    }
  }
})

export const LaborStates = () => {
  return useSelector((state) => state.laborSlice)
}

export const LaborPredictionStates = () => {
  return useSelector((state) => state.individualMonthly)
}

export const {
  SetDateRecords,
  SetLaborApproval,
  setLaborTemplate
} = LaborSlice.actions

export default LaborSlice.reducer

// レイバー一覧取得
export const getIndividualMonthlyLabors = (staff, month, laborToggle) => async dispatch => {
  const [from, to] = getFirstAndLastDate(month)
  const apiUrl = `/api/labor/individual/monthly/${staff.staffId}/${from}/${to}/${laborToggle}`
  console.log(apiUrl)
  const result = await GetApi(apiUrl, null)
  if (result.errorDetail) {
    dispatch(SetLoadSpinner(false))
  } else {
    dispatch(SetDateRecords(result.data))
    dispatch(SetLoadSpinner(false))
  }
}

export const getLaborApproval = (floorId, zoneId, staff, from, to) => async dispatch => {
  const staffId = staff === '-' ? '-' : staff.staffId
  const floor = floorId === -1 ? '-' : floorId
  const zone = zoneId === -1 ? '-' : zoneId
  const apiUrl = `/api/labor/approved/${floor}/${zone}/${staffId}/${from}/${to}`
  // console.log('🚀 ~ file: IndividualMonthlySlice.js ~ line 69 ~ apiUrl', apiUrl)

  const result = await GetApi(apiUrl, null)
  if (result.errorDetail) {
    dispatch(SetLoadSpinner(false))
  } else {
    dispatch(SetLaborApproval(result.data))
    dispatch(SetLoadSpinner(false))
  }
}
