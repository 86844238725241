import React, { useState, useRef, useEffect } from 'react'
import MasterSearch from '../../Common/Molecules/MasterSearch'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import GrantDetail from './GrantDetail'
import { GetApi, PostApi, PutApi, DeleteApi } from '../../Common/ApiAxios'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import FUNC from '../../Common/FunctionEnum'
import Input from '../../Common/Atoms/Input'
import { getLoginUserInfo } from '../../Common/StaffInfo'
export const DefaultUrl = '/api/master/grant'

// メインコンポーネント
const Grant = () => {
  const dispatch = useDispatch()
  const [dataList, setDataList] = useState(null)
  const [grantMix, setGrantMix] = useState()
  const [selectedGrantNameId, setSelectedGrantNameId] = useState()
  const searchIdRef = useRef()
  const searchNameRef = useRef()
  const inputGrantNameRef = useRef()
  const sortRef = useRef()
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const funcGrants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.GRANT)
  const canWrite = funcGrants && funcGrants.writeGrantDivision === 2
  const alert = useAlert()
  const [pagination, setPagination] = useState({})
  const [divisions, setDivisions] = useState([])

  // 権限リスト取得
  const apiGetGrantList = async (page) => {
    dispatch(SetLoadSpinner(true))
    const apiUrl = DefaultUrl
    const data = {
      grantNameId: (funcGrants.readGrantDivision === 1 || funcGrants.readGrantDivision === 3) ? loginUserInfo.grantNameId : searchIdRef.current.value,
      grantName: searchNameRef.current.value,
      page: page
    }
    const result = await GetApi(apiUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      alert.error('リストの取得に失敗しました')
    } else {
      setPagination(result.data)
      setDataList(result.data.responseList.map(item => {
        return {
          id: item.grantNameId,
          name: item.grantName
        }
      }))
    }
  }

  // 権限詳細情報取得
  const apiGetGrantDetail = async (grantNameId) => {
    dispatch(SetLoadSpinner(true))
    const apiUrl = DefaultUrl + '/' + grantNameId
    const result = await GetApi(apiUrl, null)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      alert.error('詳細取得に失敗しました')
    } else {
      setGrantMix(result.data)
      if (inputGrantNameRef.current) {
        inputGrantNameRef.current.value = result.data.grantDetail.grantName
        sortRef.current.value = result.data.grantDetail.sort
      }
    }
  }

  // 権限区分リスト取得
  const apiGetGrantDivisionList = async () => {
    dispatch(SetLoadSpinner(true))
    const apiUrl = '/api/master/classification/byId'
    const result = await GetApi(apiUrl, { id: 74 })
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      alert.error('詳細取得に失敗しました')
    } else {
      setDivisions(result.data.values)
    }
  }

  // リストから権限選択
  const selectGrant = (grantNameId) => {
    setSelectedGrantNameId(grantNameId)
    apiGetGrantDetail(grantNameId)
  }

  // 権限スイッチクリック動作
  const onChangeFlg = (serviceId, manageId, funcId, division, flg) => {
    const copyObj = Object.assign({}, grantMix)
    const changeFlg = copyObj
      .menuList.find(menu => menu.serviceMenuId === serviceId)
      .manageMenuList.find(menu => menu.manageMenuId === manageId)
      .functionMenuList.find(menu => menu.functionMenuId === funcId)
    if (division === 'read') {
      if (flg === '0' || flg === '1' || flg === '3') {
        // 権限無、所属のみ、自分のみの場合書き込み権限もそろえる
        changeFlg.readGrantDivision = flg
        changeFlg.writeGrantDivision = flg
      } else {
        changeFlg.readGrantDivision = flg
      }
    } else {
      if (flg === '2') {
        changeFlg.readGrantDivision = flg
        changeFlg.writeGrantDivision = flg
      } else {
        changeFlg.writeGrantDivision = flg
      }
    }
    setGrantMix(copyObj)
  }

  // 登録処理
  const apiPostGrant = async (grantName, sort) => {
    dispatch(SetLoadSpinner(true))
    grantMix.grantDetail.grantName = grantName
    grantMix.grantDetail.sort = sort
    const apiUrl = DefaultUrl
    const data = {
      grantDetail: grantMix.grantDetail,
      menuList: grantMix.menuList
    }
    const result = await PostApi(apiUrl, data, true)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      alert.error('登録に失敗しました')
    } else {
      setGrantMix(null)
      apiGetGrantList(1)
      alert.success('登録しました')
    }
  }

  // 更新処理
  const apiPutGrant = async (grantNameId, grantName, sort) => {
    dispatch(SetLoadSpinner(true))
    grantMix.grantDetail.grantName = grantName
    grantMix.grantDetail.sort = sort
    const apiUrl = DefaultUrl + '/' + grantNameId
    const data = {
      grantDetail: grantMix.grantDetail,
      menuList: grantMix.menuList
    }
    const result = await PutApi(apiUrl, data, true)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      alert.error('更新に失敗しました')
    } else {
      setGrantMix(result.data)
      apiGetGrantList(1)
      getLoginUserInfo(dispatch) // 更新時、ログインユーザー情報再取得
      alert.success('更新しました')
    }
  }

  // 削除処理
  const apiDeleteGrant = async (grantNameId) => {
    dispatch(SetLoadSpinner(true))
    const flgString = grantMix.grantDetail.delFlag ? '有効化' : '無効化'
    const apiUrl = DefaultUrl + '/' + grantNameId
    const data = {
      delFlag: grantMix.grantDetail.delFlag
    }
    const result = await DeleteApi(apiUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      alert.error(flgString + 'に失敗しました')
    } else {
      setGrantMix(result.data)
      alert.success(flgString + 'しました')
    }
  }

  const createView = () => {
    apiGetGrantDetail(-1)
    setSelectedGrantNameId(null)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      apiGetGrantList(1)
    }
  }
  const pageSelectFunc = (pageId) => {
    apiGetGrantList(pageId)
  }

  useEffect(() => {
    apiGetGrantDivisionList()
    createView()
    document.title = '権限マスタ | Core First'
    // eslint-disable-next-line
  }, [])

  if (!funcGrants) return null
  return (
    <MasterWrap>
      <MasterSearch title='権限マスタ' onSearch={() => apiGetGrantList(searchNameRef.current.value, 1)}>
        <label style={{ flex: '0 0 70px' }}>権限ID</label>
        <Input
          type='number' name='grantNameId' width='200px' inputRef={searchIdRef} onKeyDown={(e) => onKeyDown(e)}
          disabled={funcGrants.readGrantDivision === 1 || funcGrants.readGrantDivision === 3}
        />
        <label style={{ flex: '0 0 70px' }}>権限名称</label>
        <Input
          type='text' name='grantName' width='200px' inputRef={searchNameRef} onKeyDown={(e) => onKeyDown(e)}
          disabled={funcGrants.readGrantDivision === 1 || funcGrants.readGrantDivision === 3}
        />
      </MasterSearch>
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedGrantNameId}
          selectFunction={(id) => selectGrant(id)}
          createFunction={() => createView()}
          canWrite={canWrite}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
        />
        {grantMix &&
          <GrantDetail
            grantDetail={grantMix.grantDetail}
            menuList={grantMix.menuList}
            inputGrantNameRef={inputGrantNameRef}
            sortRef={sortRef}
            onChangeFlg={(serviceId, manageId, funcId, division, flg) => onChangeFlg(serviceId, manageId, funcId, division, flg)}
            onUpdate={(grantNameId, grantName, sort) => apiPutGrant(grantNameId, grantName, sort)}
            onDelete={(grantNameId) => apiDeleteGrant(grantNameId)}
            onCreate={(grantName, sort) => apiPostGrant(grantName, sort)}
            canWrite={canWrite}
            divisions={divisions}
          />}
      </main>
    </MasterWrap>
  )
}

export default Grant
