import React from 'react'
import { FaSpinner, FaCloudDownloadAlt } from 'react-icons/fa'
import Chart from 'react-google-charts'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SideMenu from '../Common/Molecules/SideMenu'
import { Tooltip } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import { IconContext } from 'react-icons'
import moment from 'moment'

export const DefaultUrl = '/api/chart'

// <img src={QrIos} alt='iOSダウンロード' style={{ 'object-fit': 'contain' }} />

const StaffOvertimeDiv = styled.div`
.cssHeaderRow {
  background-color: #007fff;
  padding: 10px;
}
.cssHeaderCell {
  color: #FFFFFF;
  padding: 5px !important;
  border: solid 1px #FFFFFF;
  width: 175px;
  padding: 5px;
}
`
const FlexDiv = styled.div`
  display: flex;
`
const FlexRow = styled(FlexDiv)`
  line-height: 39px;
`
const FlexItem = styled.div`
  width: ${props => props.width || '30'}px;
  padding: 0 0 0 10px;
  height: 40px;
`

const CategorySummaryDiv = styled.div`
  background: #222;
  margin-left: 25px;
  color: white;
`
const Spacer = styled.div`
  width: 100%;
`

const CategorySummaryHeader = () => (
  <FlexRow style={{ fontWeight: 'bold', borderBottom: '1px solid white', width: 300 }}>
    <FlexItem width={170}>工程名</FlexItem>
    <FlexItem width={90} style={{ textAlign: 'right' }}>時間</FlexItem>
    <FlexItem width={60} style={{ textAlign: 'right' }}>(%)</FlexItem>
  </FlexRow>
)

const CategorySummaryBody = styled.div`
  height: calc(100vh - 50px);
  overflow-y: scroll;
`

const DataAnalyzeDetail = ({
  operationSummaryData,
  categorySummaryData,
  stackSummaryData,
  staffOvertimeSummaryData,
  isLoading,
  rangeMonth
}) => {
  const getOperationTitle = (title) => {
    var showTitle = title
    if (title.length > 7) {
      showTitle = title.substring(0, 7) + '...'
    }
    return (
      <Tooltip title={title}>
        <div className='dataanalyze-operationname'>
          {showTitle}
        </div>
      </Tooltip>
    )
  }

  const getCsvFileName = () => {
    const fromDate = moment(rangeMonth.fromDate).format('YYYY-MM-DD')
    const toDate = moment(rangeMonth.toDate).format('YYYY-MM-DD')
    return `工程サマリー_${fromDate}-${toDate}.csv`
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ margin: 10 }}>
          {!isLoading &&
            <div
              style={{ display: 'flex', backgroundColor: '#FFFFFF', width: 600, height: 400, justifyContent: 'center', alignItems: 'center' }}
            ><FaSpinner className='icon-spin' size={50} />
            </div>}
          {isLoading &&
            <Chart
              width='600px'
              height='400px'
              chartType='PieChart'
              loader={<div>Loading Chart</div>}
              data={operationSummaryData.data}
              options={{
                title: '工程別サマリー',
                sliceVisibilityThreshold: 0,
                pieResidueSliceLabel: 'その他'
              }}
              rootProps={{ 'data-testid': '1' }}
            />}
        </div>
        <div style={{ margin: 10 }}>
          {!isLoading &&
            <div
              style={{ display: 'flex', backgroundColor: '#FFFFFF', width: 600, height: 400, justifyContent: 'center', alignItems: 'center' }}
            ><FaSpinner className='icon-spin' size={50} />
            </div>}
          {isLoading &&
            <Chart
              width='600px'
              height='400px'
              chartType='PieChart'
              loader={<div>Loading Chart</div>}
              data={categorySummaryData.data}
              options={{
                title: 'カテゴリ別サマリー',
                sliceVisibilityThreshold: 0,
                pieResidueSliceLabel: 'その他'
              }}
              rootProps={{ 'data-testid': '1' }}
            />}
        </div>
        <Spacer />
        <StaffOvertimeDiv style={{ margin: 10 }}>
          <div style={{ display: 'flex', backgroundColor: '#CCCCCC', width: 1220, justifyContent: 'flex-end', padding: 10, fontWeight: 'bold' }}>
            原因種別/回数/残業工数
          </div>
          <div style={{ display: 'flex', backgroundColor: '#FFFFFF', width: 1220 }}>
            <div style={{ width: 175, display: 'flex', justifyContent: 'flex-end' }} />
            <div style={{ width: 350, display: 'flex', justifyContent: 'flex-end', borderRight: 'solid 1px #000000', padding: 5 }}>
              顧客起因
            </div>
            <div style={{ width: 350, display: 'flex', justifyContent: 'flex-end', borderRight: 'solid 1px #000000', padding: 5 }}>
              スタッフ起因
            </div>
            <div style={{ width: 350, display: 'flex', justifyContent: 'flex-end', borderRight: 'solid 1px #000000', padding: 5 }}>
              社員起因
            </div>
          </div>
          <Chart
            width='1220px'
            height='70px'
            chartType='Table'
            loader={<div>Loading Chart</div>}
            data={[
              [
                { type: 'string', label: '予見種別' },
                { type: 'number', label: '回数' },
                { type: 'number', label: '残業工数' },
                { type: 'number', label: '回数' },
                { type: 'number', label: '残業工数' },
                { type: 'number', label: '回数' },
                { type: 'number', label: '残業工数' }
              ],
              ['予見型',
                staffOvertimeSummaryData.customerReason.yokenNum, staffOvertimeSummaryData.customerReason.yokenHours,
                staffOvertimeSummaryData.staffReason.yokenNum, staffOvertimeSummaryData.staffReason.yokenHours,
                staffOvertimeSummaryData.employeeReason.yokenNum, staffOvertimeSummaryData.employeeReason.yokenHours
              ],
              ['突発型',
                staffOvertimeSummaryData.customerReason.toppatsuNum, staffOvertimeSummaryData.customerReason.toppatsuHours,
                staffOvertimeSummaryData.staffReason.toppatsuNum, staffOvertimeSummaryData.staffReason.toppatsuHours,
                staffOvertimeSummaryData.employeeReason.toppatsuNum, staffOvertimeSummaryData.employeeReason.toppatsuHours
              ]
            ]}
            formatters={[
              {
                type: 'ColorFormat',
                column: 1,
                options: {
                  width: 120
                },
                ranges: [
                  [-20000, 0, 'white', 'orange'],
                  [20000, null, 'red', '#33ff33']
                ]
              }
            ]}
            options={{
              allowHtml: true,
              showRowNumber: false,
              width: '100%',
              height: '100%',
              cssClassNames: {
                headerRow: 'cssHeaderRow',
                headerCell: 'cssHeaderCell'
              }
            }}
            rootProps={{ 'data-testid': '3' }}
          />
        </StaffOvertimeDiv>
        <Spacer />
        <div style={{ margin: 10 }}>
          <Chart
            width='1220px'
            height='300px'
            chartType='ColumnChart'
            loader={<div>Loading Chart</div>}
            data={stackSummaryData}
            options={{
              title: '月別工程スタックサマリ',
              width: 1220,
              height: 400,
              isStacked: true,
              bar: { groupWidth: '100px' },
              legend: { position: 'top' }
            }}
          />
        </div>
        <div>
          <SideMenu isView width='350px' isClose>
            <CategorySummaryDiv>
              {operationSummaryData.data && operationSummaryData.data.length > 0 && (
                <div style={{ textAlign: 'center', marginTop: '16px', marginBottom: '8px' }} title='ダウンロード'>
                  <CSVLink data={operationSummaryData.csvData} filename={getCsvFileName()} target='_blank'>
                    <IconContext.Provider value={{ color: 'white' }}>
                      <FaCloudDownloadAlt size={32} />
                    </IconContext.Provider>
                  </CSVLink>
                  <p style={{ color: 'white' }}>CSVダウンロード</p>
                </div>
              )}
              <CategorySummaryHeader />
              <CategorySummaryBody>
                {operationSummaryData.csvData && operationSummaryData.csvData.length > 0 && operationSummaryData.csvData.map((s, index) => (
                  <div key={index}>
                    {index !== 0 &&
                      <FlexRow style={{ borderBottom: '1px solid white', width: 300 }} key={s.index}>
                        <FlexItem width={170}>{getOperationTitle(s[0])}</FlexItem>
                        <FlexItem width={90} style={{ textAlign: 'right' }}>{s[1]} h</FlexItem>
                        <FlexItem width={60} style={{ textAlign: 'right' }}>{s[2]}%</FlexItem>
                      </FlexRow>}
                  </div>
                ))}
              </CategorySummaryBody>
            </CategorySummaryDiv>
          </SideMenu>
        </div>
      </div>
    </>
  )
}
DataAnalyzeDetail.propTypes = {
  operationSummaryData: PropTypes.object,
  categorySummaryData: PropTypes.object,
  stackSummaryData: PropTypes.object,
  staffOvertimeSummaryData: PropTypes.object,
  isLoading: PropTypes.bool
}
export default DataAnalyzeDetail
