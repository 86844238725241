import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { GetApi, exportErrorLog, PostApi, DeleteApi } from '../Common/ApiAxios'
// import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'
import { getHour, getMin, getDate, clearAuthCookie } from '../Common/utils'
import { OPERATION_TYPE } from '../Common/Consts'
// 定数
const GANT_LEFT = 15 // ガントチャートのLEFの位置
const GANT_TOP = 0 // ガントチャートのTOPの位置
const GANT_BOTTOM = 50 // ガントチャートのBOTTOMの位置
const DRAW_TASK_TOP = GANT_TOP + 5
const DRAW_TASK_BOTTOM = GANT_BOTTOM - 6
const DRAW_RADIUS = 5
const MINUTES_15_WIDTH = 15 // 15分の幅
const MINUTES_60_WIDTH = 15 * 4 // 60分の幅
const MAX_HOUR = 36
const MAX_PROCESS_SIZE = 144 // 36時間を15分単位で

const initialState = {
  staffs: {},
  operations: null,
  recentOperations: null,
  favoriteOperations: null,
  selectOperation: null,
  labors: null,
  laborHours: null,
  selectOpeWorkPlace: null,
  attendance: null
}

const LaborSlice = createSlice({
  name: 'labor',
  initialState,
  reducers: {
    SetAttendance: (state, action) => {
      state.attendance = action.payload
    },

    // 工程一覧取得
    SetOperations: (state, action) => {
      state.operations = action.payload
    },

    // 最近使用した工程一覧取得
    SetRecentOperations: (state, action) => {
      state.recentOperations = action.payload
    },

    // お気に入り工程一覧取得
    SetFavoriteOperations: (state, action) => {
      state.favoriteOperations = action.payload
    },

    // レイバー一覧取得
    SetLabors: (state, action) => {
      state.labors = action.payload
    },

    // 工数一覧取得
    SetLaborHours: (state, action) => {
      const newLaborHours = []
      const dbLabors = action.payload
      dbLabors && dbLabors.forEach(labor => {
        const { staffId, startIndex, endIndex, operationName, operationType } = labor
        if (operationType !== OPERATION_TYPE.OFF_HOUR) {
          const target = newLaborHours.filter(item => item.staffId === staffId)
          if (target.length > 0) {
            target[0].hours += (endIndex - startIndex) * 0.25
          } else {
            const item = {
              staffId: staffId,
              hours: 0.0,
              transferHours: 0.0
            }
            if (operationType == OPERATION_TYPE.TRANSFER_HOLIDAY) {
              item.hours = 0.0
              item.transferHours = (endIndex - startIndex) * 0.25
            } else {
              item.hours = (endIndex - startIndex) * 0.25
              item.transferHours = 0.0
            }
            newLaborHours.push(item)
          }
        }
      })
      state.laborHours = newLaborHours
    },

    // 工程選択
    setOperation: (state, action) => {
      state.selectOperation = action.payload
    },

    // 登録工程の出勤区分
    setOpeWorkPlace: (state, action) => {
      state.selectOpeWorkPlace = action.payload
    },

    // 初期処理
    init: (state, action) => {
      const { labors, nowTimeIndex } = action.payload
      initTime(nowTimeIndex)
      if (labors.laborItem && labors.laborItem.length > 0) {
        labors.laborItem.forEach((staff, index) => {
          state.staffs[staff.staffId] = staff
        })
      }
    }
  }
})

export const LaborStates = () => {
  return useSelector((state) => state.laborSlice)
}

export const {
  SetOperations,
  SetRecentOperations,
  SetFavoriteOperations,
  SetLabors,
  SetLaborHours,
  init,
  setOperation,
  setOpeWorkPlace,
  SetAttendance
} = LaborSlice.actions

export default LaborSlice.reducer

// 非同期
// 工程一覧取得
export const apiGetOperations = (data) => async dispatch => {
  const apiUrl = '/api/labor/operations'
  // dispatch(SetLoadSpinner(true))
  const result = await GetApi(apiUrl, data)
  // dispatch(SetLoadSpinner(false))
  if (result.errorDetail) {
    exportErrorLog(result)
    clearAuthCookie()
    return null
  } else {
    dispatch(SetOperations(result.data))
  }
}

// 最近使用した工程一覧取得
export const apiGetRecentOperations = (data) => async dispatch => {
  const apiUrl = '/api/master/operation/history/' + data
  // dispatch(SetLoadSpinner(true))
  const result = await GetApi(apiUrl, data)
  // dispatch(SetLoadSpinner(false))
  if (result.errorDetail) {
    exportErrorLog(result)
    clearAuthCookie()
    return null
  } else {
    dispatch(SetRecentOperations(result.data.body))
  }
}

// お気に入り工程一覧取得
export const apiGetFavoriteOperations = (data) => async dispatch => {
  const apiUrl = '/api/master/operation/favorite/' + data
  // dispatch(SetLoadSpinner(true))
  const result = await GetApi(apiUrl, data)
  // dispatch(SetLoadSpinner(false))
  if (result.errorDetail) {
    exportErrorLog(result.data.responseList)
    clearAuthCookie()
    return null
  } else {
    dispatch(SetFavoriteOperations(result.data.responseList))
  }
}

// お気に入り工程登録
export const apiPostFavoriteOperations = (data) => async dispatch => {
  const apiUrl = '/api/master/operation/favorite'
  // dispatch(SetLoadSpinner(true))
  const result = await PostApi(apiUrl, data, true)
  // dispatch(SetLoadSpinner(false))
  if (result.errorDetail) {
    exportErrorLog(result.data.responseList)
    // clearAuthCookie()
    return null
  } else {
    dispatch(SetFavoriteOperations(result.data.responseList))
  }
}

// お気に入り工程削除
export const apiDeleteFavoriteOperations = (data) => async dispatch => {
  const apiUrl = '/api/master/operation/favorite'
  // dispatch(SetLoadSpinner(true))
  const result = await DeleteApi(apiUrl, data, true)
  // dispatch(SetLoadSpinner(false))
  if (result.errorDetail) {
    exportErrorLog(result.data.responseList)
    // clearAuthCookie()
    return null
  } else {
    dispatch(SetFavoriteOperations(result.data.responseList))
  }
}

// 非同期
// レイバー情報取得
export const apiGetLabors = (commonSearch, isPlanned, register, workPlace, nowTimeIndex, grants, loginUserInfo, staffDivisionFilter) => async dispatch => {
  const { warehouseId, floorId, zoneId, workDate, includeRetired } = commonSearch
  const registerStatus = register // 1:全部データ 2:登録済 3:未登録
  const apiUrl = '/api/labor/labors'
  const data = {
    warehouseId: warehouseId,
    floorId: floorId,
    zoneId: zoneId,
    workDate: workDate,
    registerStatus: registerStatus,
    workPlace: workPlace,
    staffDivisionFilter: staffDivisionFilter,
    isPlanned: isPlanned,
    staffId: grants && grants.readGrantDivision === 3 ? loginUserInfo.staffId : null,
    includeRetired: includeRetired
  }
  const result = await GetApi(apiUrl, data)
  if (result.errorDetail) {
    exportErrorLog(result)
    clearAuthCookie()
    return null
  } else {
    // dispatch(SetLabors(null)) // TODO nullで初期化しても問題ないか調査
    dispatch(SetLabors(result.data))
    dispatch(init({ labors: result.data, nowTimeIndex: nowTimeIndex }))
    dispatch(SetLaborHours(result.data))
  }
}

export const getPlannedAttendance = (commonSearch, loginUserInfo) => async dispatch => {
  const { warehouseId, floorId, zoneId, workDate } = commonSearch
  const fromDate = workDate
  const toDate = workDate

  const url = `/api/labor/attendance/${warehouseId}/${floorId}/${zoneId}/${fromDate}/${toDate}/-`
  const result = await GetApi(url)

  if (result.errorDetail) {
    exportErrorLog(result)
    clearAuthCookie()
    return null
  } else {
    // console.log('🚀 ~ file: LaborSlice.js ~ line 178 ~ result.data', result.data)
    dispatch(SetAttendance(result.data))
  }
}

export const getPlannedPersonalAttendance = (staffId, fromDate, toDate) => async dispatch => {
  const url = `/api/labor/attendance/-/-/-/${fromDate}/${toDate}/${staffId}`
  console.log('===url: ', url)
  const result = await GetApi(url)

  if (result.errorDetail) {
    exportErrorLog(result)
    clearAuthCookie()
    return null
  } else {
    // console.log('🚀 ~ file: LaborSlice.js ~ line 178 ~ result.data', result.data)
    dispatch(SetAttendance(result.data))
  }
}
// ------------------------------------------------------------- 初期設定 --------------------------------------------------------

// ガントチャートの目盛りの初期化
export function initTime(nowTimeIndex) {
  const timeCanvas = document.getElementById('timeCanvas')
  if (!timeCanvas) return null
  const ctx = timeCanvas.getContext('2d')
  ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight) // キャンバス全クリア
  ctx.fillStyle = 'rgb(255,255,255)'
  ctx.font = '10px Roboto'
  for (let i = 0; i <= MAX_HOUR; i++) {
    drawLine(ctx, GANT_LEFT + MINUTES_60_WIDTH * i)
    ctx.fillText((i.toString(10) < 10 ? '0' : '') + i.toString(10) + ':00', GANT_LEFT + MINUTES_60_WIDTH * i + 5, 18)
  }
  // 現在時刻インデックス ずれが出るので一旦不要
  // ctx.beginPath()
  // ctx.strokeStyle = 'rgba(255, 0, 0, 0.8)'
  // ctx.moveTo(nowTimeIndex * MINUTES_15_WIDTH + GANT_LEFT, 0)
  // ctx.lineTo(nowTimeIndex * MINUTES_15_WIDTH + GANT_LEFT, 30)
  // ctx.closePath()
  // ctx.stroke()
  // ctx = null
}

// 行の背景描写
export const RowBackGround = (ctx, nowTimeIndex, startHour = null, startMin = null, endHour = null, endMin = null, isHoliday = false) => {
  ctx.clearRect(0, 0, 2220, 50)
  for (let i = 0; i < MAX_HOUR; i++) {
    for (let j = 0; j < 4; j++) {
      ctx.fillStyle = (j % 2 === 0) ? '#eee' : '#fff'
      if (startHour != null && endHour != null) {
        if (
          ((i === startHour && j >= (Math.floor(startMin / 15))) || i > startHour) &&
          ((i === endHour && (j < (Math.floor(endMin / 15)))) || i < endHour)
        ) {
          if (isHoliday) {
            ctx.fillStyle = (j % 2 === 0) ? 'rgba(235, 110, 160, 0.8)' : 'rgba(235, 110, 160, 0.6)'
          } else {
            ctx.fillStyle = (j % 2 === 0) ? 'rgba(0, 127, 255, 0.4)' : 'rgba(0, 127, 255, 0.3)'
          }
        }
      }

      ctx.fillRect(GANT_LEFT + MINUTES_60_WIDTH * i + MINUTES_15_WIDTH * j, GANT_TOP, MINUTES_15_WIDTH, GANT_BOTTOM)
    }
    // 1時間ごとの軸線
    ctx.beginPath()
    ctx.strokeStyle = 'rgba(50, 50, 50, 0.5)'
    ctx.moveTo(GANT_LEFT + MINUTES_60_WIDTH * i, GANT_TOP)
    ctx.lineTo(GANT_LEFT + MINUTES_60_WIDTH * i, GANT_BOTTOM)
    ctx.closePath()
    ctx.stroke()
  }
  ctx.fillStyle = '#eee'
  ctx.fillRect(GANT_LEFT + MINUTES_60_WIDTH * 36 + MINUTES_15_WIDTH * 0, GANT_TOP, MINUTES_15_WIDTH * 3, GANT_BOTTOM)

  // 36時間目の線
  ctx.beginPath()
  ctx.strokeStyle = 'rgba(50, 50, 50, 0.3)'
  ctx.moveTo(GANT_LEFT + MINUTES_60_WIDTH * MAX_HOUR, GANT_TOP)
  ctx.lineTo(GANT_LEFT + MINUTES_60_WIDTH * MAX_HOUR, GANT_BOTTOM)
  ctx.closePath()
  ctx.stroke()

  // 現在時刻インデックス
  ctx.beginPath()
  ctx.strokeStyle = 'rgba(255, 0, 0, 0.8)'
  ctx.moveTo(nowTimeIndex * MINUTES_15_WIDTH + GANT_LEFT, GANT_TOP)
  ctx.lineTo(nowTimeIndex * MINUTES_15_WIDTH + GANT_LEFT, GANT_BOTTOM)
  ctx.closePath()
  ctx.stroke()
}

// ---------------------------------------- 動作部  -------------------------------------------

// マウス位置から工程の位置へ変換
export function mouseXToTaskIndex(x) {
  let result = -1

  // 36時間を15分ずつチェック
  for (let i = 0; i <= MAX_PROCESS_SIZE; i++) {
    if (x >= GANT_LEFT + MINUTES_15_WIDTH * i) result = i
  }

  return result
}

// 日時からindexに変換
export function changeIndexByDt(dateTime, workDate) {
  if (dateTime === null || workDate === null) return null
  const dateStr = dateTime.getFullYear() + '/' + ('00' + (dateTime.getMonth() + 1)).slice(-2) + '/' + ('00' + dateTime.getDate()).slice(-2)
  // 割り算は切り捨て 1時間前より表示
  return dateTime.getHours() * 4 + Math.floor((dateTime.getMinutes() / 15)) + (dateStr === workDate.replace(/-/g, '/') ? 0 : 96)
}

const isStartEndTimeNotNull = startEndTime => Array.isArray(startEndTime) && startEndTime.length > 0
const getStartEndTime = startEndTime => {
  const workDt = isStartEndTimeNotNull(startEndTime) && startEndTime[0].workDt
  const startWorkDate = isStartEndTimeNotNull(startEndTime) && getDate(startEndTime[0].startDt)
  const endWorkDate = isStartEndTimeNotNull(startEndTime) && getDate(startEndTime[0].endDt)

  const startHour = isStartEndTimeNotNull(startEndTime)
    ? (startWorkDate > workDt) ? parseInt(getHour(startEndTime[0].startDt)) + 24 : parseInt(getHour(startEndTime[0].startDt))
    : null
  const startMin = isStartEndTimeNotNull(startEndTime)
    ? parseInt(getMin(startEndTime[0].startDt))
    : null

  // console.log('🚀 ~ file: LaborSlice.js ~ line 284 ~ drawNewLabor ~ endWorkDate', endWorkDate, workDt, endWorkDate > workDt)
  const endHour = isStartEndTimeNotNull(startEndTime)
    ? (endWorkDate > workDt) ? parseInt(getHour(startEndTime[0].endDt)) + 24 : parseInt(getHour(startEndTime[0].endDt))
    : null
  const endMin = isStartEndTimeNotNull(startEndTime)
    ? parseInt(getMin(startEndTime[0].endDt))
    : null
  // console.log('🚀 ~ file: LaborSlice.js ~ line 296 ~ startHour', startHour, startMin, endHour, endMin)
  return { startHour, startMin, endHour, endMin }
}
// 新規描画
export function drawNewLabor(ctx, labor, labors, nowTimeIndex, startEndTime) {
  const { startHour, startMin, endHour, endMin } = getStartEndTime(startEndTime)
  const hasHoliday = startEndTime ? startEndTime.find(element => element.holidayOperation === 1) : null
  if (labor && (labor.startIndex < 0 || labor.endIndex > MAX_PROCESS_SIZE)) return null
  ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight) // キャンバス全クリア
  RowBackGround(ctx, nowTimeIndex, startHour, startMin, endHour, endMin, Boolean(hasHoliday)) // 背景
  initTime(nowTimeIndex)
  // 既存レイバーリスト描画
  labors.forEach(item => {
    drawsq(ctx, item.startPixel, item.endPixel, item.backColor)
    drawText(item, ctx)
  })
  if (labor) {
    // 追加レイバーの描画
    drawsq(ctx, labor.startPixel, labor.endPixel, labor.backColor)
    drawText(labor, ctx)
  }
}

// 移動、リサイズ描画
export function drawMoveResizeLabor(ctx, labor, labors, nowTimeIndex, startEndTime) {
  const { startHour, startMin, endHour, endMin } = getStartEndTime(startEndTime)
  if (labor.startIndex < 0 || labor.endIndex > MAX_PROCESS_SIZE) return null
  ctx.clearRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight) // キャンバス全クリア
  RowBackGround(ctx, nowTimeIndex, startHour, startMin, endHour, endMin)// 背景
  initTime(nowTimeIndex)
  // 既存レイバーリスト描画 (移動中のレイバー以外)
  labors.forEach(item => {
    if (item.branchNo !== labor.branchNo) {
      drawsq(ctx, item.startPixel, item.endPixel, item.backColor)
      drawText(item, ctx)
    }
  })
  // 移動中のレイバー
  drawsq(ctx, labor.startPixel, labor.endPixel, labor.backColor)
  drawText(labor, ctx)
}

function bytes(str) {
  return (encodeURIComponent(str).replace(/%../g, 'x').length)
}

// テキスト描画
function drawText(item, ctx) {
  if (item.endIndex - item.startIndex > 3) {
    ctx.font = '12px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = 'white'
    ctx.textAlign = 'center'
    let viewName = item.operationName
    const nameLength = bytes(viewName)
    const indexDiff = item.endIndex - item.startIndex
    if (indexDiff * 4 < nameLength) {
      // console.log(item.operationName.slice(0, indexDiff))
      viewName = viewName.slice(0, indexDiff) + '...'
    }
    ctx.fillText(viewName, (item.endPixel - item.startPixel) / 2 + item.startPixel, 25, item.endPixel - item.startPixel)
    ctx.save()
  }
}

// 角丸四角描写
function drawsq(ctx, startPixel, endPixel, color) {
  const x = startPixel
  const y = DRAW_TASK_TOP
  const w = endPixel - startPixel
  const h = DRAW_TASK_BOTTOM - DRAW_TASK_TOP
  const r = DRAW_RADIUS
  ctx.beginPath()
  ctx.lineWidth = 1
  ctx.fillStyle = color
  ctx.moveTo(x, y + r)
  ctx.arc(x + r, y + h - r, r, Math.PI, Math.PI * 0.5, true)
  ctx.arc(x + w - r, y + h - r, r, Math.PI * 0.5, 0, 1)
  ctx.arc(x + w - r, y + r, r, 0, Math.PI * 1.5, 1)
  ctx.arc(x + r, y + r, r, Math.PI * 1.5, Math.PI, 1)
  ctx.closePath()
  ctx.fill()
  ctx.strokeStyle = 'rgb(255, 255, 255)'
  ctx.setLineDash([2, 2])
  ctx.stroke()
}

function drawLine(ctx, startPixel) {
  ctx.beginPath()
  ctx.strokeStyle = 'rgb(150, 150, 150)'
  ctx.moveTo(startPixel, 0)
  ctx.lineTo(startPixel, 30)
  ctx.closePath()
  ctx.stroke()
}
