import React from 'react'
import styled from 'styled-components'
import { FiHelpCircle } from 'react-icons/fi'

const ManualLink = () => {
  const ManualA = styled.a`
    color: white;
  `

  // console.log(`

  //     window.location.pathname: ${window.location.pathname}

  //   `)
  const pathname = window.location.pathname.toLowerCase()

  return (
    <div style={{}}>
      <ManualA href={`https://paluser:cfm001@pal-iot-platform.web.app${pathname}`} target='_blank'><FiHelpCircle size='1.5rem' /></ManualA>
    </div>
  )
}

export default ManualLink
