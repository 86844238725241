import React, { useState, useEffect, useRef } from 'react'
import { DefaultUrl, PostMasterParameter } from './MasterImport'
import { DeleteApi, PostApi } from '../../Common/ApiAxios'
import { useForm } from 'react-hook-form'
import { HiddenInput } from '../Common/CommonInputGroup'
import { Form, Alert } from '../Common/CommonStyled'
import ErrorHandling from '../Common/ErrorHandling'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import Button from '../../Common/Atoms/NormalButton'
import { useAlert } from 'react-alert'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch } from 'react-redux'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import PropTypes from 'prop-types'
import CSVReader from 'react-csv-reader'
import DataGrid from 'react-data-grid'
import 'react-data-grid/dist/react-data-grid.css'
import encodingJapanese from 'encoding-japanese'
import { CSVLink } from 'react-csv'

const MasterImportMasterForm = ({
  register, errors, isDeleted,
  companyRef, warehouseRef,
  selects, setSelects, canWrite, companyList, importCsvRef, importCsv, setImportCsv, importColumn, setImportColumn, selectedId
}) => {
  // 選択中CSVプレビュー初期化

  const [fileEncodingSetting, setFileEncodingSetting] = useState('UTF-8')
  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: header => transformHeaderFunc(header)
  }

  const transformHeaderFunc = (header) => {
    const _header = importColumn.find(column => column.name === header)
    if (_header) {
      header = _header.key
    }
    return header.toLowerCase().replace(/\W/g, '_')
  }

  useEffect(() => {
    if (companyList.length > 0) {
      setSelects({
        companyId: companyList[0].companyId,
        warehouseId: companyList[0].warehouses[0].warehouseId
      })
    }
  }, [companyList])

  const onFileLoadedFunc = (data, fileInfo) => {
    var file = document.querySelector('.csv-input').files[0]
    var reader = new window.FileReader()
    reader.onload = function (event) {
      const loadFileEncoding = encodingJapanese.detect(event.target.result)

      if (fileEncodingSetting.toLowerCase() !== loadFileEncoding.toLowerCase()) {
        setFileEncodingSetting(loadFileEncoding)
        const node = document.querySelector('.csv-input')
        const event = document.createEvent('HTMLEvents')
        event.initEvent('change', true, false)
        node.dispatchEvent(event)
      } else {
        console.log(data)
        setImportCsv(data)
      }
    }
    reader.readAsBinaryString(file)
  }

  const getCsvHeader = () => {
    const parent = []
    const header = []

    for (const value of importColumn) {
      header.push(value.name)
    }

    parent.push(header)
    return parent
  }

  const getViewCsvData = () => {
    const viewData = []
    for (let i = 0; i < importCsv.length; i++) {
      if (i >= 10) {
        break
      }
      viewData.push(importCsv[i])
    }
    return viewData
  }

  const getCsvFileName = () => {
    return `import_${selectedId}_template.csv`
  }

  if (!selects) return null
  return (
    <MasterDetailWrap isView='true'>
      <ul>
        <li style={{ height: '15px' }}><label /><div /></li>
        {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
        <li>
          <label> インポートファイル<span style={{ color: 'red' }}>*</span></label>
          <div>
            <div>
              <CSVReader fileEncoding={fileEncodingSetting} parserOptions={papaparseOptions} onFileLoaded={(data, fileInfo) => onFileLoadedFunc(data, fileInfo)} inputId='csv-input' />
            </div>
          </div>
        </li>
        <li>
          <label>テンプレートダウンロード</label>
          <div>
            <div>
              <CSVLink data={getCsvHeader()} filename={getCsvFileName()} target='_blank'>インポートテンプレートファイルダウンロード</CSVLink>
            </div>
          </div>
        </li>
        <li style={{ height: '250px' }}>
          <label>インポートデータ</label>
          <div>
            <div style={{ height: '250px', width: '100%' }}>
              <DataGrid
                columns={importColumn}
                rows={getViewCsvData()}
                height={200}
                minColumnWidth={40}
              />
            </div>
          </div>
        </li>
        <HiddenInput
          name='updatedId'
          register={register}
        />
        <HiddenInput
          name='updatedAt'
          register={register}
        />
        <li style={{ height: '15px' }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

MasterImportMasterForm.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  isDeleted: PropTypes.bool,
  companyRef: PropTypes.any,
  warehouseRef: PropTypes.any,
  selects: PropTypes.object,
  setSelects: PropTypes.func,
  canWrite: PropTypes.bool,
  companyList: PropTypes.array,
  importCsvRef: PropTypes.any,
  importCsv: PropTypes.any,
  setImportCsv: PropTypes.func,
  importColumn: PropTypes.any,
  setImportColumn: PropTypes.func,
  selectedId: PropTypes.string
}

const MasterImportDetails = ({ detail, setDetail, onSearch, selectedId, setSelectedId, companyList, canWrite, importCsv, setImportCsv, importColumn, setImportColumn }) => {
  const { register, handleSubmit, errors, control } = useForm({ validateCriteriaMode: 'all' })
  const actionType = 'POST'
  const isDeleted = false
  const [selects, setSelects] = useState()
  const companyRef = useRef()
  const warehouseRef = useRef()
  const alert = useAlert()
  const importCsvRef = useRef()
  const dispatch = useDispatch()

  // インポートカラム情報をFormに反映
  useEffect(() => {
    setImportColumn(detail)
  }, [detail])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody) {
      dispatch(SetLoadSpinner(true))
      var result
      result = await PostApi(`${DefaultUrl}`, requestBody, true)

      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        /*
        setDetail({})
        setSelectedId(null)
        */
      }
    }

    data.taskName = PostMasterParameter[selectedId].id
    data.targetMaster = PostMasterParameter[selectedId].name
    data.status = 'wait'
    data.importData = JSON.stringify(importCsv)

    sendPost(data)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (id, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${id}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
      if (result.data) alert.info('無効化しました')
    }
    if (detail.masterImportId !== undefined) {
      sendDelete(detail.masterImportId, { updatedAt: detail.updatedAt })
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      <MasterImportMasterForm
        register={register} errors={errors} control={control} isDeleted={isDeleted}
        companyRef={companyRef} warehouseRef={warehouseRef}
        selects={selects} setSelects={setSelects} canWrite={canWrite}
        companyList={companyList} detail={detail}
        importCsvRef={importCsvRef} importCsv={importCsv} setImportCsv={setImportCsv} importColumn={importColumn} setImportColumn={setImportColumn} selectedId={selectedId}
      />
      <MasterDetailButtons style={{ display: canWrite ? 'flex' : 'none' }}>
        <Button
          name={actionType === 'PUT' ? detail.delFlag ? '有効化' : '更新' : '登録'}
          width='90px'
          padding='0'
          type='submit'
        />
        {!detail.delFlag && detail.delFlag !== undefined &&
          <Button
            display='none' // 第一フェーズでは非表示
            height='20px'
            width='90px'
            padding='0'
            name='削除'
            type='button'
            onClick={(e) => onClickHandleDelete(e)}
          />}
      </MasterDetailButtons>
    </Form>
  )
}

MasterImportDetails.propTypes = {
  canWrite: PropTypes.bool,
  companyList: PropTypes.array,
  detail: PropTypes.array,
  setDetail: PropTypes.func,
  onSearch: PropTypes.func,
  selectedId: PropTypes.string,
  setSelectedId: PropTypes.func,
  importCsv: PropTypes.any,
  setImportCsv: PropTypes.func,
  importColumn: PropTypes.any,
  setImportColumn: PropTypes.func
}

export default MasterImportDetails
