import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

function SortableTableHead (props) {
  const {
    grants,
    headCells,
    onSelectAllClick,
    numSelected,
    rowCount,
    classes,
    order,
    orderBy,
    onRequestSort
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const createHeadCell = (headCell) => {
    return (
      <div>
        {
          headCell.label.split(' ').map((h, idx) => <p key={idx}>{h}<br /></p>)
        }
      </div>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='right'
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontWeight: 'bold', align: 'center' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {createHeadCell(headCell)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>

        ))}
        <TableCell
          padding='checkbox'
        >
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            }
            disabled={!(grants && (grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1))}
            label='承認'
            labelPlacement='end'
            classes={{ label: classes.label }}
          />
        </TableCell>
        <TableCell style={{ fontWeight: 'bold', align: 'center' }}>詳細</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default SortableTableHead

SortableTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}
