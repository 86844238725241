/* eslint-env browser */
import React, { useState, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styled from 'styled-components'
import CalenderIcon from '../../../image/svg/CalenderIcon.js'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import PropTypes from 'prop-types'
registerLocale('ja', ja)

// 共通検索用
const WrapDiv = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 16px'};
  display: flex;
  width: 100px;

  .react-datepicker-popper{
    z-index: 6;
  }
  .react-datepicker__current-month {
    display: none;
  }
  .react-datepicker__month-select, .react-datepicker__year-select {
    border: none;
    background: #eee;
    height: 20px;
    line-height: 20px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 280px;
    height: ${props => props.height};
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-wrapper {
    flex: 1;
  }
  .react-datepicker__month .react-datepicker__month-text {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .normalCalendar {
    height: 32px;
    position: relative;
    text-indent: 8px;
    width: 100px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: '#fff';
    border-radius: 5px;
    border: none;
    line-height: 16px;
    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
    color: #333;
    z-index: 1;
    &:focus {
      background: #fff;
      outline: none;
      border: 2px solid #0036ff;
      box-sizing: border-box;
      text-indent: 14px;
    }
    ::placeholder {
      color: #ccc;
    }


  }
  svg {
    position: absolute;
    top: 5px;
    right: 3px;
    pointer-events: none;
    z-index:2;
  }

  ::after{
    display: ${props => props.isViewIcon ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background: #0036ff;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
    z-index:1;
  }

`

const TodayButton = styled.button`
  border: #777 1px solid;
  padding: 4px;
  cursor: pointer;
  border-radius: 3px;
  height: 33px;
  &:hover {
    background: #ddd;
  }
`
const ArrowSpan = styled.span`
  height: 32px;
  width: 1vw;
  line-height: 35px;
  text-align: center;
  background: #ddd;
  margin: ${props => props.margin};
  color: #333;
  cursor: pointer;
  border-radius: ${props => props.radius};
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.25);
  :hover {
    background: #ccc;
    color: #333;
    span {
      display: block;
    }
  }
`
const PopSpan = styled.span`
  position: absolute;
  display: none;
  top: -48px;
  left: 0;
  background: #fff;
  width: 82px;
  font-size: 12px;
  height: 46px;
  line-height: 20px;
  border-radius: 5px;
`
const PopUp = ({ message, value }) => {
  const dateStr = value.getFullYear() + '-' + ('0' + (value.getMonth() + 1)).slice(-2)
  return (
    <PopSpan>
      {message}<br />
      {dateStr}
    </PopSpan>
  )
}
PopUp.propTypes = {
  message: PropTypes.string,
  value: PropTypes.instanceOf(Date)
}

const Calendar = ({
  dataValue, changeCalendar, placeholder, width, margin, disabled, display, prefix, isViewIcon, type
}) => {
  const format = type === 2 ? 'yyyy/MM' : 'yyyy/MM/dd' // カレンダータイプ 1 or nullなら年月日 2は年月
  const [startDate, setStartDate] = useState(dataValue && new Date(dataValue))
  const date = new Date(dataValue)
  const beforeMonth = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
  const onCalendarChange = (changeDate) => {
    setStartDate(changeDate)
    localStorage.setItem('normalCalendar', changeDate)
  }
  const onCalendarClose = () => {
    const localDate = localStorage.getItem('normalCalendar')
    if (!localDate || localDate === 'undefined' || localDate === 'null') return null
    const date = new Date(localDate)
    const dateStr = type === 2
      ? date.getFullYear() + `${prefix || '-'}` + ('00' + (date.getMonth() + 1)).slice(-2)
      : date.getFullYear() + `${prefix || '-'}` + ('00' + (date.getMonth() + 1)).slice(-2) + `${prefix || '-'}` + ('00' + date.getDate()).slice(-2)
    if (dataValue !== dateStr) {
      changeCalendar(dateStr)
    }
    localStorage.removeItem('normalCalendar')
  }
  useEffect(() => {
    setStartDate(dataValue && new Date(dataValue))
  }, [dataValue])

  return (
    <>
      <WrapDiv
        margin={margin} width={width} display={display} isViewIcon={isViewIcon} disabled={disabled}
        height={type === 2 ? '175px' : 'unset'}
      >
        <DatePicker
          className='normalCalendar'
          dateFormat={format}
          locale='ja'
          selected={startDate}
          onChange={changeDate => onCalendarChange(changeDate)}
          onCalendarOpen={() => localStorage.setItem('normalCalendar', startDate)}
          onCalendarClose={() => onCalendarClose()}
          peekNextMonth
          showMonthDropdown={type === 1 || type == null}
          showYearDropdown={type === 1 || type == null}
          showMonthYearPicker={type === 2}
          dropdownMode='select'
          placeholderText={placeholder}
        />
        <CalenderIcon />
      </WrapDiv>
      <div style={{ position: 'relative', display: 'flex' }}>
        <ArrowSpan
          radius='5px 0 0 5px' margin='0 3px 0 0'
          onClick={() => {
            onCalendarChange(beforeMonth)
            onCalendarClose()
          }}
        >
          <FaChevronLeft />
          <PopUp message='1月前を表示' value={beforeMonth} />
        </ArrowSpan>
        <TodayButton onClick={() => {
          onCalendarChange(new Date())
          onCalendarClose()
        }}
        >
          今月
        </TodayButton>
        <ArrowSpan
          radius='0 5px 5px 0' margin='0 16px 0 3px'
          onClick={() => {
            onCalendarChange(nextMonth)
            onCalendarClose()
          }}
        >
          <FaChevronRight />
          <PopUp message='1月後を表示' value={nextMonth} />
        </ArrowSpan>
      </div>
    </>
  )
}

Calendar.propTypes = {
  dataValue: PropTypes.string,
  changeCalendar: PropTypes.func,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  display: PropTypes.string,
  prefix: PropTypes.string,
  isViewIcon: PropTypes.bool,
  type: PropTypes.number
}

export default Calendar
