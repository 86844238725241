/** gtag.js読み込み用関数 */
export const initializeGA = (measurementId) => {
  const SCRIPT1_NAME = 'gtag'
  const SCRIPT2_NAME = 'gtagScript'

    // scriptが既にある場合は一度削除
    // eslint-disable-next-line no-unused-expressions
    document.getElementById(SCRIPT1_NAME)?.remove()
    // eslint-disable-next-line no-unused-expressions
    document.getElementById(SCRIPT2_NAME)?.remove()

    // トラッキングID or 測定IDが空の場合は終了
    if (!measurementId || measurementId === '') return

    // gtag.jsをheadタグに埋め込み
    const script1 = document.createElement('script')
    script1.id = SCRIPT1_NAME
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
    script1.async = true
    document.head.appendChild(script1)

    // 実行用scriptをheadタグに埋め込み
    const script2 = document.createElement('script')
    script2.id = SCRIPT2_NAME
    script2.text = `window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', '${measurementId}');`
    document.head.appendChild(script2)
}
