import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import useReactRouter from 'use-react-router'
import logo from '../image/svg/whiteLogo.svg'
import menuButton from '../image/menu-btn-2.png'
import CurrentDatetime from './Common/CurrentDatetime'
import RemainingTime from './Common/RemainingTime'
import StaffInfo from './Common/StaffInfo'
import Navigator from './Navigator'
import ManualLink from './ManualLink'
import '../css/Header.scss'
import Theme from './Common/Theme'
import GetLaborSummary from './graphql/GetLaborSummary'
import { getFirstAndLastDateFromYmd } from './Common/utils'

const FlexDiv = styled.div`
  display: flex; 
  align-items: center;
  height: 30px;
`
const WhiteLogoImg = styled.img`
  width: 130px;
  position: absolute;
  top: 20px;
  left: 60px;
`
const MenuImg = styled.img`
  cursor: pointer;
  margin: 0;
  width: 30px;
  position: absolute;
  top: 15px;
  left: 15px;
`
const HeaderWrap = styled.header`
  background: ${props => props.theme.header.background};
  position: sticky;
  top: 0;
  z-index: 5;
`
const style = {
  color: 'yellow',
  position: 'absolute',
  top: '20px',
  left: '700px',
  fontSize: '1.2em',
  height: '30px',
  lineHeight: '30px',
  verticalAlign: 'bottom'
}
const Location = () => {
  const loc = window.location.hostname
  const env = loc === 'localhost'
    ? 'localhost'
    : loc.indexOf('-dev') > 0 ? 'Staging' : ''
  return (
    <span style={style}>
      {env}
    </span>
  )
}
const Header = (props) => {
  const { location } = useReactRouter()
  const [showMenuFlg, setShowMenuFlg] = useState('none')
  const showMenu = () => {
    setShowMenuFlg(showMenuFlg === 'none' ? 'block' : 'none')
  }
  const { staffId } = useSelector(state => state.loginUserInfo)
  const { workDate } = useSelector(state => state.commonSearch)
  const [firstDate, setFirstDate] = useState()
  const [lastDate, setLastDate] = useState()
  // サイドメニュー判定
  const sideMenuPosition = useSelector(state => state.sideMenuPosition)
  const existSideMenu = document.getElementById('SideMenu')
  const marginRight = existSideMenu ? sideMenuPosition.position : '0px'

  // ホワイトボード表示時のみ
  const showTimeFlg = location.pathname === '/whiteboard/whiteboard'

  const isLaborPage = location.pathname.indexOf('/labor') >= 0

  useEffect(() => {
    console.log(workDate)
    const [_firstDate, _lastDate] = getFirstAndLastDateFromYmd(workDate)
    console.log(_firstDate, _lastDate)
    setFirstDate(_firstDate)
    setLastDate(_lastDate)
  }, [workDate])

  // console.log('🚀 ~ file: Header.js ~ line 57 ~ Header ~ location', location)
  // console.log(window.location.hostname)
  return (
    <Theme themeName='default'>
      <HeaderWrap className='header'>
        <div className='header-container'>
          <Navigator showFlg={showMenuFlg} setShowFlg={setShowMenuFlg} />
          <MenuImg src={menuButton} alt='menuButton' onClick={showMenu} />
          <Link to='/' className='logo'>
            <p>
              <WhiteLogoImg src={logo} alt='Logo' />
              <Location />
            </p>
          </Link>
          {isLaborPage &&
            <GetLaborSummary
              staffId={staffId}
              workDateFrom={firstDate}
              workDateTo={lastDate}
            />}
          <FlexDiv style={{ marginRight: marginRight }}>
            {showTimeFlg && (
              <>
                <CurrentDatetime />
                <RemainingTime />
              </>
            )}
            {/* <Temperature /> */}
            <StaffInfo />
            <ManualLink />
          </FlexDiv>
        </div>
      </HeaderWrap>
    </Theme>
  )
}

export default Header
