/* eslint-env browser */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SummaryDiv = styled.div`
  position: absolute;
  bottom: -20px;
  left: 15px;
  width: 150px;
  color: white;
`
const LineDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const Summary = ({ records }) => {
  // todo 休暇を外す場合は条件追加
  if (!records) return null
  const attendanceDays = records.filter(record => {
    return record.sumHour !== '0.0H'
  })
  const countLabor = records.reduce((tmp, record) => {
    return tmp + (record.sumHour.replace('H', '') - 0)
  }, 0)
  return (
    <SummaryDiv>
      <LineDiv>
        <span>出勤日数：</span>
        <span>{attendanceDays.length}日</span>
      </LineDiv>
      <LineDiv>
        <span>合計工数：</span>
        <span>{countLabor}H</span>
      </LineDiv>
    </SummaryDiv>
  )
}
Summary.propTypes = {
  records: PropTypes.array
}
export default Summary
