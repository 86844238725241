import React, { useState, useRef, useEffect } from 'react'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import MasterSearch from '../../Common/Molecules/MasterSearch'
import { GetApi } from '../../Common/ApiAxios'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import ErrorHandling from '../Common/ErrorHandling'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import MasterImportDetails from './MasterImportDetails'

export const DefaultUrl = '/api/master/masterImport'

export const MasterList = [
  { id: 'm_staff', name: 'スタッフマスタ' },
  { id: 'm_skills', name: 'スキルマスタ' },
  { id: 'm_categories', name: 'カテゴリーマスタ' },
  { id: 'm_units', name: '単位マスタ' },
  { id: 'm_operations', name: '工程マスタ' },
  { id: 'm_quantities', name: '物量マスタ' },
  { id: 'm_company', name: '会社マスタ' },
  { id: 'm_warehouses', name: '倉庫マスタ' },
  { id: 'm_floors', name: 'フロアマスタ' },
  { id: 'm_zones', name: 'ゾーンマスタ' },
  { id: 'm_time_frames', name: '作業時間帯マスタ' },
  { id: 'm_credentials_apikeys', name: '認証APIキーマスタ' }
]

export const PostMasterParameter = {
  m_staff: { id: 'm_staff', name: 'スタッフマスタ' },
  m_skills: { id: 'm_skills', name: 'スキルマスタ' },
  m_categories: { id: 'm_categories', name: 'カテゴリーマスタ' },
  m_units: { id: 'm_units', name: '単位マスタ' },
  m_operations: { id: 'm_operations', name: '工程マスタ' },
  m_quantities: { id: 'm_quantities', name: '物量マスタ' },
  m_company: { id: 'm_company', name: '会社マスタ' },
  m_warehouses: { id: 'm_warehouses', name: '倉庫マスタ' },
  m_floors: { id: 'm_floors', name: 'フロアマスタ' },
  m_zones: { id: 'm_zones', name: 'ゾーンマスタ' },
  m_time_frames: { id: 'm_time_frames', name: '作業時間帯マスタ' },
  m_credentials_apikeys: { id: 'm_credentials_apikeys', name: '認証APIキーマスタ' }
}

// メインコンポーネント
const MasterImportMaster = () => {
  const idRef = useRef()
  const nameRef = useRef()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const { companyId, warehouseId } = commonSearch
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState([])
  const [selectedId, setSelectedId] = useState()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.FLOOR)
  const canWrite = grants && grants.writeGrantDivision === 2
  const alert = useAlert()
  const dispatch = useDispatch()
  const [companyList, setCompanyList] = useState([])
  const [importCsv, setImportCsv] = useState([])
  const [pagination, setPagination] = useState({})
  const [importColumn, setImportColumn] = useState([])

  const masterImportHeader = {
    m_staff: [{ key: 'staff_id', name: 'スタッフID', resizable: true },
      { key: 'cooperation_id', name: '連携用ID', resizable: true },
      { key: 'staff_name', name: '氏名', resizable: true },
      { key: 'staff_kana', name: '氏名（カナ）', resizable: true },
      { key: 'staff_division', name: '区分', resizable: true },
      { key: 'enter_dt', name: '入社日', resizable: true },
      { key: 'retire_dt', name: '退社日', resizable: true },
      { key: 'sex', name: '性別', resizable: true },
      { key: 'birth_dt', name: '生年月日', resizable: true },
      { key: 'zip_code', name: '郵便番号', resizable: true },
      { key: 'address1', name: '都道府県', resizable: true },
      { key: 'address2', name: '市区町村', resizable: true },
      { key: 'address3', name: '町域名', resizable: true },
      { key: 'address4', name: '建物名', resizable: true },
      { key: 'phone_num', name: '電話番号（自宅）', resizable: true },
      { key: 'mobile_num', name: '電話番号（携帯）', resizable: true },
      { key: 'pc_mail_address', name: 'メールアドレス（パソコン）', resizable: true },
      { key: 'mobile_mail_address', name: 'メールアドレス（携帯）', resizable: true },
      { key: 'company', name: '所属（会社）', resizable: true },
      { key: 'warehouse', name: '所属（倉庫）', resizable: true },
      { key: 'floor', name: '初期配置（フロア）', resizable: true },
      { key: 'zone', name: '初期配置（ゾーン）', resizable: true },
      { key: 'skill', name: 'スキル', resizable: true },
      { key: 'grant', name: '権限', resizable: true },
      { key: 'login_flag', name: 'ログイン有無', resizable: true },
      { key: 'password', name: 'パスワード', resizable: true }
    ],
    m_skills: [{ key: 'id', name: 'ID', resizable: true }, { key: 'skill_name', name: 'スキルネーム', resizable: true }],
    m_categories: [{ key: 'id', name: 'ID', resizable: true }, { key: 'category_name', name: 'カテゴリー名', resizable: true }],
    m_units: [{ key: 'id', name: 'ID', resizable: true }, { key: 'unit_name', name: '単位名', resizable: true }],
    m_operations: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'category', name: 'カテゴリー', resizable: true },
      { key: 'company', name: '会社', resizable: true },
      { key: 'warehouse', name: '倉庫', resizable: true },
      { key: 'floor', name: 'フロア', resizable: true },
      { key: 'zone', name: 'ゾーン', resizable: true },
      { key: 'operation_name', name: '工程名', resizable: true },
      { key: 'sort', name: 'ソート順', resizable: true }
    ],
    m_quantities: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'unit_id', name: '単位', resizable: true },
      { key: 'company', name: '会社', resizable: true },
      { key: 'warehouse', name: '倉庫', resizable: true },
      { key: 'floor', name: 'フロア', resizable: true },
      { key: 'quantity_name', name: '工程名', resizable: true }
    ],
    m_company: [
      { key: 'company_id', name: '会社ID', resizable: true },
      { key: 'company_name', name: '会社名', resizable: true },
      { key: 'company_kana', name: '会社名(カナ)', resizable: true },
      { key: 'zip_code', name: '郵便番号', resizable: true },
      { key: 'address1', name: '都道府県', resizable: true },
      { key: 'address2', name: '市区町村', resizable: true },
      { key: 'address3', name: '町域名', resizable: true },
      { key: 'address4', name: '建物名', resizable: true },
      { key: 'phone_num', name: '電話番号', resizable: true },
      { key: 'fax_num', name: 'FAX番号', resizable: true },
      { key: 'web_url', name: 'ウェブサイト', resizable: true },
      { key: 'cooperation_id', name: '連携用ID', resizable: true }
    ],
    m_warehouses: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'company', name: '会社', resizable: true },
      { key: 'warehouse_name', name: '倉庫名', resizable: true },
      { key: 'address', name: '住所', resizable: true }
    ],
    m_floors: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'company', name: '会社', resizable: true },
      { key: 'warehouse', name: '倉庫', resizable: true },
      { key: 'floor_name', name: 'フロア名', resizable: true }
    ],
    m_zones: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'company', name: '会社', resizable: true },
      { key: 'warehouse', name: '倉庫', resizable: true },
      { key: 'floor', name: 'フロア', resizable: true },
      { key: 'zone_name', name: 'ゾーン名', resizable: true },
      { key: 'pos_x_start', name: '開始X座標', resizable: true },
      { key: 'pos_x_end', name: '終了X座標', resizable: true },
      { key: 'pos_y_start', name: '開始Y座標', resizable: true },
      { key: 'pos_y_end', name: '終了Y座標', resizable: true },
      { key: 'end_time', name: '終了時間', resizable: true }
    ],
    m_time_frames: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'company', name: '会社', resizable: true },
      { key: 'warehouse', name: '倉庫', resizable: true },
      { key: 'floor', name: 'フロア', resizable: true },
      { key: 'time_frame_name', name: '作業時間帯名称', resizable: true },
      { key: 'start_time', name: '開始時間', resizable: true },
      { key: 'end_time', name: '終了時間', resizable: true },
      { key: 'sort_order', name: 'ソート順', resizable: true }
    ],
    m_credentials_apikeys: [
      { key: 'id', name: 'ID', resizable: true },
      { key: 'name', name: '認証APIキー名', resizable: true }
    ]
  }

  const selectItem = async (id) => {
    console.log(id)
    dispatch(SetLoadSpinner(true))
    setDetail([])
    setImportCsv([])
    document.querySelector('.csv-input').value = ''
    setSelectedId(id)
    const result = masterImportHeader[id]
    dispatch(SetLoadSpinner(false))
    setDetail(result)
    // ErrorHandling(result, alert) || setDetail(result.data)
  }

  const createNew = () => {
    setDetail([])
    setSelectedId(null)
    setImportCsv([])
    document.querySelector('.csv-input').value = ''
  }

  const getMasterImports = async ({ createId, ...data }) => {
    dispatch(SetLoadSpinner(true))
    const result = MasterList
    const resultList = MasterList
    setDataList(resultList)
    setPagination(result.data)
    dispatch(SetLoadSpinner(false))
  }

  const onSearch = (createId) => {
    getMasterImports(
      {
        page: 1
      }
    )
  }

  const pageSelectFunc = (pageId) => {
    getMasterImports(
      {
        companyId: companyId,
        warehouseId: warehouseId,
        masterImportId: idRef.current.value,
        masterImportName: nameRef.current.value,
        createId: null,
        page: pageId
      }
    )
  }
  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 1
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    if (result.data.length === 0) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }

  useEffect(() => {
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  }, [loginUserInfo])

  useEffect(() => {
    document.title = 'マスタインポート | Core First'
  }, [])

  if (!grants) return null
  return (
    <MasterWrap>
      <MasterSearch firstSearch title='マスタインポート' onSearch={() => onSearch()} />
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={canWrite}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
        />
        <MasterImportDetails
          companyList={companyList}
          detail={detail}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setDetail={setDetail}
          canWrite={canWrite}
          onSearch={onSearch}
          importCsv={importCsv}
          setImportCsv={setImportCsv}
          importColumn={importColumn}
          setImportColumn={setImportColumn}
        />
      </main>
    </MasterWrap>
  )
}

export default MasterImportMaster
