import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { DefaultUrl } from './Floor'
import { DeleteApi, MultipartApi } from '../../Common/ApiAxios'
import { useForm } from 'react-hook-form'
import { InputGroup, HiddenInput } from '../Common/CommonInputGroup'
import { Form, Alert } from '../Common/CommonStyled'
import ErrorHandling from '../Common/ErrorHandling'
import MasterDetailWrap from '../../Common/Wrap/MasterDetailWrap'
import Button from '../../Common/Atoms/NormalButton'
import { useAlert } from 'react-alert'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import SelectAddValue from '../../Common/Atoms/SelectAddValue'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import PropTypes from 'prop-types'
import CreaterInfo from '../../Common/Molecules/CreaterInfo'

const ImageWrapper = styled.div`
  width: 20vw;
  height: 200px;
  background: #eee;
`
const Image = styled.img`
  height: 100%;
  display: block;
  margin: 0 auto;
`
const ImageInputLabel = styled.label`
  font-size: 12px;
  background-color: #555555;
  color: white;
  border-radius: 10px;
  width: 50px;
  height: 24px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #adadad;
  }
`

const FloorMasterForm = ({
  register, errors, isDeleted, companyRef, warehouseRef, detail,
  selects, setSelects, canWrite, companyList, imageRef, imageSrc, setImageSrc,
  grants, loginUserInfo
}) => {
  const companies = companyList.length > 0 ? companyList : null
  const company = companies && selects ? companies.find(company => company.companyId === selects.companyId) : null
  const warehouses = company ? company.warehouses : null

  // ファイル選択
  const createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL
  const handleChangeFile = (e) => {
    const files = e.target.files
    if (files.length > 0) {
      const imageUrl = createObjectURL(files[0])
      setImageSrc(imageUrl)
    } else {
      setImageSrc()
    }
  }

  // 選択中画像プレビュー初期化
  useEffect(() => {
    if (imageSrc) {
      setImageSrc(imageSrc)
    } else {
      setImageSrc()
    }
  // 選択画像変更時のみ実行
  // eslint-disable-next-line
  }, [imageSrc])

  useEffect(() => {
    if (companyList.length > 0) {
      setSelects({
        companyId: companyList[0].companyId,
        warehouseId: companyList[0].warehouses[0].warehouseId
      })
    }
  // 会社リスト変更時のみ実行
  // eslint-disable-next-line
  }, [companyList])

  // 倉庫を初期値に戻す
  const downSelectWarehouse = (companyId) => {
    if (companyId === '-1') return -1
    const company = companies.find(company => company.companyId === companyId)
    if (!company) return -1
    return company.warehouses[0] ? company.warehouses[0].warehouseId : -1
  }

  // 会社
  const Companies = () => {
    const selectCompany = useCallback((event) => {
      const companyId = event.target.value
      const warehouseId = downSelectWarehouse(companyId)
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId
      })
    }, [])
    return (
      <li>
        <label>会社</label>
        <div>
          <SelectAddValue
            width='320px'
            margin='12px 0 12px 0'
            selectValue={selects.companyId} selectRef={companyRef} disabled={isDeleted || !canWrite}
            onChange={event => selectCompany(event)}
          >
            {companies && companies.map(item => {
              if (grants.writeGrantDivision === 1 &&
                (
                  (!detail.companyId && item.companyId !== loginUserInfo.companyId) ||
                  (detail.companyId && detail.companyId === loginUserInfo.companyId && item.companyId !== loginUserInfo.companyId)
                )
              ) return null
              return (
                <option key={item.companyId} value={item.companyId}>{item.companyName}</option>
              )
            })}
          </SelectAddValue>
        </div>
      </li>
    )
  }

  // 倉庫
  const Warehouses = () => {
    const selectWarehouse = useCallback((event) => {
      const companyId = selects.companyId
      const warehouseId = event.target.value - 0
      setSelects({
        companyId: companyId,
        warehouseId: warehouseId
      })
    }, [])
    return (
      <li>
        <label>倉庫</label>
        <div>
          <SelectAddValue
            width='320px'
            margin='12px 0 12px 0'
            selectValue={selects.warehouseId} selectRef={warehouseRef} disabled={isDeleted || !canWrite}
            onChange={event => selectWarehouse(event)}
          >
            {warehouses && warehouses.map(item => {
              if (grants.writeGrantDivision === 1 &&
                (
                  (!detail.warehouseId && item.warehouseId !== loginUserInfo.warehouseId) ||
                  (detail.warehouseId && detail.warehouseId === loginUserInfo.warehouseId && item.warehouseId !== loginUserInfo.warehouseId)
                )
              ) return null
              return (
                <option key={item.warehouseId} value={item.warehouseId}>{item.warehouseName}</option>
              )
            })}
          </SelectAddValue>
        </div>
      </li>
    )
  }

  if (!selects) return null
  return (
    <MasterDetailWrap isView='true'>
      <ul>
        <li style={{ height: '15px' }}><label /><div /></li>
        {isDeleted && <li><label /><div><Alert>削除データ</Alert></div></li>}
        <li>
          <label>ID</label>
          <InputGroup
            width='320px'
            margin='12px 0 12px 0'
            disabled
            placeholder='自動採番'
            name='floorId'
            register={register}
            errors={errors}
          />
        </li>
        <li>
          <label> フロア名称<span style={{ color: 'red' }}>*</span></label>
          <div>
            <InputGroup
              width='320px'
              margin='12px 0 12px 0'
              name='floorName'
              register={register}
              rule={{
                required: '必須項目です',
                maxLength: { value: 50, message: '50文字以内で設定してください' }
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li style={{ height: '250px' }}>
          <label>イメージファイル</label>
          <div>
            <div>
              <ImageWrapper>
                {imageSrc && <Image src={imageSrc} />}
              </ImageWrapper>
              <ImageInputLabel htmlFor='floorImage'>参照</ImageInputLabel>
              <input
                type='file'
                id='floorImage'
                ref={imageRef}
                onChange={handleChangeFile}
                disabled={isDeleted || !canWrite}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </li>
        <Companies />
        <Warehouses />
        <HiddenInput
          name='updatedId'
          register={register}
        />
        <HiddenInput
          name='updatedAt'
          register={register}
        />
        <li style={{ height: '15px' }}><label /><div /></li>
      </ul>
    </MasterDetailWrap>
  )
}

FloorMasterForm.propTypes = {
  detail: PropTypes.object,
  register: PropTypes.any,
  errors: PropTypes.any,
  isDeleted: PropTypes.bool,
  companyRef: PropTypes.any,
  warehouseRef: PropTypes.any,
  selects: PropTypes.object,
  setSelects: PropTypes.func,
  canWrite: PropTypes.bool,
  companyList: PropTypes.array,
  imageRef: PropTypes.any,
  imageSrc: PropTypes.any,
  setImageSrc: PropTypes.func,
  grants: PropTypes.object,
  loginUserInfo: PropTypes.object
}

const FloorDetails = ({ detail, setDetail, onSearch, setSelectedId, companyList, canWrite, grants, imageSrc, setImageSrc }) => {
  const { register, handleSubmit, setValue, errors, control, reset } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isDeleted, setIsDeleted] = useState(false)
  const [selects, setSelects] = useState()
  const companyRef = useRef()
  const warehouseRef = useRef()
  const imageRef = useRef()
  const alert = useAlert()
  const dispatch = useDispatch()
  const { loginUserInfo } = useSelector(state => state)

  useEffect(() => {
    if (actionType === 'POST') {
      setIsDeleted(false)
    }
  }, [actionType])

  // フロア情報をFormに反映
  useEffect(() => {
    if (detail.floorId === undefined) {
      setActionType('POST')
      setImageSrc()
      reset()
      if (companyRef.current) {
        companyRef.current.selectedIndex = 0
        warehouseRef.current.selectedIndex = 0
        setSelects({
          companyId: companyRef.current.value,
          warehouseId: warehouseRef.current.value - 0
        })
      }
    } else {
      setIsDeleted(detail.delFlag)
      setValue('floorId', detail.floorId)
      setValue('floorName', detail.floorName)
      setValue('updatedId', detail.updatedId)
      setValue('updatedAt', detail.updatedAt)
      setValue('updatedName', detail.updatedName)
      setValue('createdId', detail.createdId)
      setValue('createdAt', detail.createdAt)
      setValue('createdName', detail.createdName)
      setImageSrc(detail.floorImage)
      setActionType('PUT')
      setSelects({
        companyId: detail.companyId,
        warehouseId: detail.warehouseId
      })
    }
  // 詳細情報変更時のみ実行
  // eslint-disable-next-line
  }, [detail])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      dispatch(SetLoadSpinner(true))
      var result
      switch (actionType) {
        case 'POST':
          result = await MultipartApi(`${DefaultUrl}`, actionType, requestBody, data)
          break
        case 'PUT':
          result = await MultipartApi(`${DefaultUrl}/${data.floorId}`, actionType, requestBody, data)
          break
        default:
          result = false
          break
      }
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setDetail({})
        setSelectedId(null)
        onSearch(null)
        setActionType('POST')
      }
    }
    data.warehouseId = warehouseRef.current.value - 0
    const submitData = new window.FormData()
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    if (imageRef.current && imageRef.current.files.length > 0) {
      submitData.append('floorImage', imageRef.current.files[0])
      data.floorImage = imageRef.current.files[0].name
    }
    if (actionType === 'PUT') {
      data.floorId && sendPost(submitData, data)
    } else {
      sendPost(submitData, data)
    }
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (id, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${id}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setDetail(result.data)
      if (result.data) alert.info('無効化しました')
    }
    if (detail.floorId !== undefined) {
      sendDelete(detail.floorId, { updatedAt: detail.updatedAt })
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FloorMasterForm
        register={register} errors={errors} control={control} isDeleted={isDeleted}
        companyRef={companyRef} warehouseRef={warehouseRef}
        selects={selects} setSelects={setSelects} canWrite={canWrite}
        companyList={companyList} detail={detail}
        imageRef={imageRef} imageSrc={imageSrc} setImageSrc={setImageSrc}
        grants={grants} loginUserInfo={loginUserInfo}
      />
      <MasterDetailButtons style={{ display: canWrite ? 'flex' : 'none' }}>
        <Button
          name={actionType === 'PUT' ? detail.delFlag ? '有効化' : '更新' : '登録'}
          height='20px'
          width='90px'
          padding='0'
          type='submit'
        />
        {!detail.delFlag && detail.delFlag !== undefined &&
          <Button
            display='none' // 第一フェーズでは非表示
            height='20px'
            width='90px'
            padding='0'
            name='削除'
            type='button'
            onClick={(e) => onClickHandleDelete(e)}
          />}
      </MasterDetailButtons>
      <CreaterInfo info={detail} />
    </Form>
  )
}

FloorDetails.propTypes = {
  grants: PropTypes.object,
  canWrite: PropTypes.bool,
  companyList: PropTypes.array,
  imageSrc: PropTypes.any,
  setImageSrc: PropTypes.func,
  detail: PropTypes.object,
  setDetail: PropTypes.func,
  onSearch: PropTypes.func,
  setSelectedId: PropTypes.func
}

export default FloorDetails
