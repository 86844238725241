import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SetPosition } from '../Slice/SideMenuPositionSlice'

const WrapDiv = styled.div`
  display: ${props => props.isView ? 'block' : 'none'};
  width: ${props => props.closeFlag ? '30px' : props.width};
  background: ${props => props.workPlace === 2 ? '#330' : '#222'};
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100vh;
  z-index: 5;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    border:none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112, 1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
`
const ContentsDiv = styled.div`
  display: ${props => props.closeFlag ? 'none' : 'block'};
`
const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: 10px;
  top: 50vh;
  position: absolute;
  cursor: pointer;
  transition: .2s;
  ${props => !props.closeFlag || 'transform: rotate(0.5turn)'};
`

// サイドメニュー
const SideMenu = ({ isView, children, width, isClose = false }) => {
  const [closeFlag, setCloseFlag] = useState(isClose)
  const dispatch = useDispatch()
  const sideMenuRef = useRef()
  const { laborSlice } = useSelector(state => state)
  const workPlace = laborSlice.selectOpeWorkPlace

  useEffect(() => {
    dispatch(SetPosition(width))
    dispatch(SetPosition(sideMenuRef.current?.offsetWidth))
  // フラグ変更時のみ実行
  // eslint-disable-next-line
  }, [closeFlag])

  return (
    <WrapDiv closeFlag={closeFlag} isView={isView} id='SideMenu' ref={sideMenuRef} width={width} workPlace={workPlace}>
      <ContentsDiv closeFlag={closeFlag}>
        {children}
      </ContentsDiv>
      <Triangle onClick={() => setCloseFlag(!closeFlag)} closeFlag={closeFlag} />
    </WrapDiv>
  )
}

SideMenu.propTypes = {
  width: PropTypes.string,
  isView: PropTypes.bool,
  children: PropTypes.any
}

export default SideMenu
