import React from 'react'
import QrImageAndroidDev from '../../image/appDownload/QR_download_android_dev.png'
import QrImageIOSDev from '../../image/appDownload/QR_download_ios_dev.png'
import QrImageAndroidProd from '../../image/appDownload/QR_download_android_prod.png'
import QrImageIOSProd from '../../image/appDownload/QR_download_ios_prod.png'
import styled from 'styled-components'
import { FaDownload } from 'react-icons/fa'

// <img src={QrIos} alt='iOSダウンロード' style={{ 'object-fit': 'contain' }} />

const AppDownloadQr = () => {
  const QrBaseBlock = styled.div`
  display: flex;
  justify-content: center;
`

  const ContentBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: #EEEEEE;
    width:60%;
    justify-content: center;
    align-items: 'center';
  `
  const ExplainBlock = styled.div`
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
    `

  const ATagWrap = styled.a.attrs(props => ({
    // 初期値
    margin: props.margin || '10px',
    width: props.width || '95px',
    display: props.display || 'block'
  }))`
    margin: ${props => props.margin};
    width: ${props => props.width};
    min-width: ${props => props.width};
    display: ${props => props.display};
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: #0036ff;
    height: 36px;
    border-radius: 5px;
    border: none;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    color: white;
    text-decoration: none;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.4), inset 0 2px 0 0 rgba(255,255,255,0.4);
    &:hover{
        background: #0026b3;
    }
`
  const getQrImage = (os = 'android') => {
    const host = window.location.hostname
    if (host === 'core-first-dev.pal-style.co.jp') {
      if (os === 'android') {
        return QrImageAndroidDev
      } else {
        return QrImageIOSDev
      }
    } else {
      if (os === 'android') {
        return QrImageAndroidProd
      } else {
        return QrImageIOSProd
      }
    }
  }

  const getDownloadPageUrl = (os = 'android') => {
    const host = window.location.hostname
    if (host === 'core-first-dev.pal-style.co.jp') {
      if (os === 'android') {
        return 'https://deploygate.com/distributions/3df0ec70c6faf977ceab25906e08cb2793609e0c'
      } else {
        return 'https://deploygate.com/distributions/8dc231a0202f10ff23ebc8df82d34b1725fa8c6b'
      }
    } else {
      if (os === 'android') {
        return 'https://deploygate.com/distributions/432f0cd73cd0075c0a68cf3487c80b5284e004b7'
      } else {
        return 'https://deploygate.com/distributions/09608b83f78ff5e6c5c2055d5e8f9d94e32fbe06'
      }
    }
  }

  return (
    <QrBaseBlock>
      <ContentBlock>
        <div sytle={{ width: '100%' }}>
          <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '40px' }}>
            CoreFirstアプリインストール
          </div>
          <ContentBlock style={{ width: '100%' }}>
            <ExplainBlock>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={getQrImage('android')} alt='ダウンロード' style={{ 'object-fit': 'contain' }} />
              </div>
              <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '30px', fontWeight: 'bold' }}>
                {`Androidアプリ (${process.env.NODE_ENV})`}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ATagWrap width='200px' target='_blank' href={getDownloadPageUrl('android')}><FaDownload />ダウンロードページへ</ATagWrap>
              </div>
            </ExplainBlock>
            <ExplainBlock>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={getQrImage('ios')} alt='ダウンロード' style={{ 'object-fit': 'contain' }} />
              </div>
              <div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '30px', fontWeight: 'bold' }}>
                {`iOSアプリ (${process.env.NODE_ENV})`}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ATagWrap width='200px' target='_blank' href={getDownloadPageUrl('ios')}><FaDownload />ダウンロードページへ</ATagWrap>
              </div>
            </ExplainBlock>
            <hr />
          </ContentBlock>
          <ExplainBlock>
            <div style={{ fontSize: '20px', display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <FaDownload style={{ color: '#693' }}>add_circle</FaDownload>
              </div>
              <div style={{ paddingLeft: '10px' }}>
                インストール方法
              </div>
            </div>
          </ExplainBlock>
          <ExplainBlock>
            ・QRコードを読み取るか、リンクをクリックしてダウンロードページに遷移します。遷移後は表示の説明に従ってインストールしてください<br />
          </ExplainBlock>
          <ExplainBlock>
            アプリのログインID、及びパスワードはWEB版と同じものを使用可能です。
          </ExplainBlock>
          <ExplainBlock>
            <span style={{ color: 'red' }}>※iOS版はインストール後「アプリの開発元を信頼する」の設定があります。未設定の場合は下記を参考に設定してください。</span><br />
            <a href='https://support.apple.com/ja-jp/HT204460'>https://support.apple.com/ja-jp/HT204460</a><br />
          </ExplainBlock>
        </div>

      </ContentBlock>
    </QrBaseBlock>
  )
}
export default AppDownloadQr
