import React, { useState, useRef, useEffect, useCallback } from 'react'
import MasterListView from '../../Common/Molecules/MasterListView'
import MasterWrap from '../../Common/Wrap/MasterWrap'
import Search from '../../Common/Search'
import { GetApi } from '../../Common/ApiAxios'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import ErrorHandling from '../Common/ErrorHandling'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { useDispatch, useSelector } from 'react-redux'
import OperationDetails from './OperationDetails'
import Input from '../../Common/Atoms/Input'
import Select from '../../Common/Atoms/Select'

export const DefaultUrl = '/api/master/operation'

// メインコンポーネント
const OperationMaster = () => {
  const operationIdRef = useRef()
  const operationNameRef = useRef()
  const typeRef = useRef()
  const categoryIdRef = useRef()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const { companyId, warehouseId, floorId, zoneId } = commonSearch
  const [dataList, setDataList] = useState([])
  const [detail, setDetail] = useState({})
  const [selectedId, setSelectedId] = useState()
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.OPERATION)
  // const canWrite = grants && grants.writeGrantDivision === 2
  const alert = useAlert()
  const dispatch = useDispatch()
  const [categoryList, setCategoryList] = useState([]) // カテゴリー一覧取得
  const [companyList, setCompanyList] = useState([]) // ゾーンまでそろっている会社情報取得
  const [pagination, setPagination] = useState({})
  const [typeSearchList, setSearchTypeList] = useState([]) // 工程種別一覧取得
  const [typeOptions, setTypeOptions] = useState(<></>)

  const selectItem = async (id) => {
    dispatch(SetLoadSpinner(true))
    setSelectedId(id)
    const result = await GetApi(`${DefaultUrl}/${id}`)
    dispatch(SetLoadSpinner(false))
    console.log(result.data)
    ErrorHandling(result, alert) || setDetail(result.data)
  }

  const createNew = () => {
    setDetail({})
    setSelectedId(null)
  }

  // 初期化メソッド
  useEffect(() => {
    async function getOptions () {
      getType()
    }
    getOptions()
  // 初回描画時のみ実行
  // eslint-disable-next-line
  }, [])

  // 工程種別取得
  const getType = useCallback(async () => {
    const result = await GetApi('/api/master/classification/operationType')
    if (Array.isArray(result.data.values)) {
      const options = result.data.values.map(value =>
        <option value={value.valueId} key={value.valueId}>{value.valueName}</option>
      )
      setSearchTypeList(result.data.values)
      setTypeOptions(options)
    }
  }, [setTypeOptions])

  const getOperations = async (createId, pageId) => {
    dispatch(SetLoadSpinner(true))
    const data = {
      companyId: companyId,
      warehouseId: warehouseId,
      floorId: floorId,
      zoneId: zoneId,
      categoryId: categoryIdRef.current.value,
      operationId: operationIdRef.current.value,
      operationName: operationNameRef.current.value,
      type: typeRef.current.value,
      page: pageId
    }
    const result = await GetApi(DefaultUrl, data)
    dispatch(SetLoadSpinner(false))
    if (result.errorDetail) {
      ErrorHandling(result, alert)
      return null
    } else {
      const resultList = result.data.responseList.map(item => {
        return {
          id: item.operationId,
          name: item.operationName
        }
      })
      setDataList(resultList)
      setPagination(result.data)
      const isSelected = resultList.find(item => item.id === (createId || selectedId))
      if (!isSelected) {
        setSelectedId(null)
        setDetail({})
      }
    }
  }

  const onSearch = (createId) => {
    getOperations(createId, 1)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      getOperations(null, 1)
    }
  }
  const pageSelectFunc = (pageId) => {
    getOperations(null, pageId)
  }

  // カテゴリー一覧
  const onCategorySearch = async () => {
    dispatch(SetLoadSpinner(true))
    const result = await GetApi('/api/master/category', null)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    const resultList = result.data.responseList.map(item => {
      return {
        id: item.categoryId,
        name: item.categoryName
      }
    })
    setCategoryList(resultList)
  }

  // 会社情報一覧
  const onCompanyInfoSearch = async () => {
    dispatch(SetLoadSpinner(true))
    const data = {
      notBlankStatus: 3 // 会社-倉庫-フロア-ゾーンが欠損なしのレコード
    }
    const result = await GetApi('/api/companyInfo', data)
    dispatch(SetLoadSpinner(false))
    if (ErrorHandling(result, alert)) return null
    if (result.data.length === 0) {
      alert.error('必要データが揃っていません', { onClose: () => { window.location.href = '/' } })
    }
    setCompanyList(result.data)
  }

  useEffect(() => {
    onCategorySearch()
    if (loginUserInfo.companyId) {
      onCompanyInfoSearch()
    }
  // ログインユーザー情報変更検知時のみ実行
  // eslint-disable-next-line
  }, [loginUserInfo])

  useEffect(() => {
    document.title = '工程マスタ | Core First'
  }, [])

  if (!grants) return null
  return (
    <MasterWrap>
      <Search
        title='工程マスタ' onClick={onSearch}
        showWarehouses showFloors showZones addAllWarehouse addAllFloor addAllZone showButton
        inSubCompany inSubChildren
        grants={grants}
      >
        <li>
          <label>カテゴリー</label>
          <Select name='categoryId' selectRef={categoryIdRef}>
            <option value='-1'>全て</option>
            {categoryList && categoryList.map(item => {
              return (
                <option key={item.id} value={item.id}>{item.name}</option>
              )
            })}
          </Select>
        </li>
        <li>
          <label>ID</label>
          <Input type='text' name='operationId' inputRef={operationIdRef} onKeyDown={(e) => onKeyDown(e)} />
        </li>
        <li>
          <label>名称</label>
          <Input type='text' name='operationName' inputRef={operationNameRef} onKeyDown={(e) => onKeyDown(e)} />
        </li>
        <li>
          <label>工程種別</label>
          <Select name='type' selectRef={typeRef}>
            <option value='0'>全て</option>
            {typeSearchList && typeSearchList.map(item => {
              return (
                <option key={item.valueId} value={item.valueId}>{item.valueName}</option>
              )
            })}
          </Select>
        </li>
      </Search>
      <main>
        <MasterListView
          dataList={dataList}
          selectedId={selectedId}
          selectFunction={(id) => selectItem(id)}
          createFunction={() => createNew()}
          canWrite={grants.writeGrantDivision === 2 || grants.writeGrantDivision === 1}
          pageSelectFunc={pageSelectFunc}
          pagination={pagination}
          isIdView
        />
        <OperationDetails
          companyList={companyList}
          categoryList={categoryList}
          onCategorySearch={() => onCategorySearch()}
          typeList={typeOptions}
          detail={detail}
          setSelectedId={setSelectedId}
          setDetail={setDetail}
          onSearch={onSearch}
          canWrite={
            grants.writeGrantDivision === 2 ||
            (grants.writeGrantDivision === 1 && ((detail.warehouseId === loginUserInfo.warehouseId) || !detail.warehouseId))
          }
          grants={grants}
        />
      </main>
    </MasterWrap>
  )
}

export default OperationMaster
