import React, { useState, useEffect, useRef } from "react";
import { PostApi, DeleteApi, PutApi } from "../../Common/ApiAxios";
import { useForm } from "react-hook-form";
import ErrorHandling from "../Common/ErrorHandling";
import { InputGroup, HiddenInput } from "../Common/CommonInputGroup";
import MasterDetailWrap from "../../Common/Wrap/MasterDetailWrap";
import { Form, Alert } from "../Common/CommonStyled";
import Button from "../../Common/Atoms/NormalButton";
import { useAlert } from "react-alert";
import { DefaultUrl } from "./Category";
import MasterDetailButtons from "../../Common/Wrap/MasterDetailButtons";
import PropTypes from "prop-types";
import CreaterInfo from "../../Common/Molecules/CreaterInfo";
import { CompactPicker } from "react-color";

const CategoryMasterForm = ({
  register,
  errors,
  isDeleted,
  canWrite,
  detail,
}) => {
  const [categoryColor, setCategoryColor] = useState();
  useEffect(() => {
    setCategoryColor(detail.categoryColor);
    // eslint-disable-next-line
  }, [detail]);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const onSelectColor = (color) => {
    setCategoryColor(color);
    palletOpen();
  };
  const palletOpen = () => {
    setColorPickerOpen(!colorPickerOpen);
  };
  return (
    <MasterDetailWrap isView="true">
      <ul>
        <li style={{ height: "15px" }}>
          <label />
          <div />
        </li>
        {isDeleted && (
          <li>
            <label />
            <div>
              <Alert>削除データ</Alert>
            </div>
          </li>
        )}
        <HiddenInput
          name="categoryId"
          type="hidden"
          register={register}
          rule={{}}
          errors={errors}
        />
        <li>
          <label>
            カテゴリー名称<span style={{ color: "red" }}>*</span>
          </label>
          <div>
            <InputGroup
              width="320px"
              margin="0"
              name="categoryName"
              placeholder="カテゴリー名称"
              register={register}
              rule={{
                required: "必須項目です",
                maxLength: {
                  value: 50,
                  message: "50文字以内で設定してください",
                },
              }}
              errors={errors}
              disabled={isDeleted || !canWrite}
            />
          </div>
        </li>
        <li>
          <label>カテゴリ色</label>
          <div>
            <HiddenInput
              name="categoryColor"
              type="hidden"
              value={categoryColor}
              register={register}
              rule={{}}
              errors={errors}
            />
            <div
              onClick={() => {
                palletOpen();
              }}
              style={{
                paddingLeft: 5,
                paddingRight: 5,
                paddingTop: 10,
                width: 100,
                height: 30,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#000000",
                borderRadius: 10,
                backgroundColor: categoryColor,
                fontSize: 10,
                textAlign: "center",
                justifyContent: "center",
                color: categoryColor ? "white" : "black",
              }}
            >
              {categoryColor ? categoryColor : "未設定"}
            </div>
            <div style={{ position: "absolute", zIndex: "2" }}>
              {colorPickerOpen && (
                <CompactPicker
                  color={categoryColor || ""}
                  onChange={(color) => {
                    onSelectColor(color.hex);
                  }}
                />
              )}
            </div>
            <div>
              <Button
                height="20px"
                width="90px"
                padding="0"
                name="未設定にする"
                type="button"
                onClick={() => {
                  setCategoryColor("");
                }}
              />
            </div>
          </div>
        </li>
        <HiddenInput name="updatedId" register={register} />
        <HiddenInput name="updatedAt" register={register} />
        <li style={{ height: "15px" }}>
          <label />
          <div />
        </li>
      </ul>
    </MasterDetailWrap>
  );
};

CategoryMasterForm.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  isDeleted: PropTypes.bool,
  canWrite: PropTypes.bool,
  detail: PropTypes.any,
};

const CategoryDetails = ({
  onSearch,
  setSelectedId,
  detail,
  setDetail,
  canWrite,
}) => {
  const { register, handleSubmit, setValue, errors, reset } = useForm({
    validateCriteriaMode: "all",
  });
  const [actionType, setActionType] = useState("POST");
  const [isDeleted, setIsDeleted] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    if (actionType === "POST") {
      setIsDeleted(false);
    }
  }, [actionType]);

  // 単位情報をFormに反映
  useEffect(() => {
    if (detail.id === undefined) {
      setActionType("POST");
      reset();
    } else {
      setIsDeleted(detail.delFlag);
      setValue("categoryId", detail.id);
      setValue("categoryName", detail.categoryName);
      setValue("categoryColor", detail.categoryColor);
      setValue("createdName", detail.createdName);
      setValue("createdAt", detail.createdAt);
      setValue("updatedName", detail.updatedName);
      setValue("updatedAt", detail.updatedAt);
      setValue("updatedId", detail.updatedId);
      setActionType("PUT");
    }
  }, [detail, reset, setValue]);

  // POST・PUT処理
  const onSubmit = (data, e) => {
    async function sendPost(requestBody) {
      var result;
      switch (actionType) {
        case "POST":
          result = await PostApi(`${DefaultUrl}`, requestBody, true);
          break;
        case "PUT":
          result = await PutApi(
            `${DefaultUrl}/${requestBody.categoryId}`,
            requestBody,
            true
          );
          break;
        default:
          result = false;
          break;
      }
      if (!ErrorHandling(result, alert)) {
        isDeleted
          ? alert.success("有効化しました")
          : alert.success("保存しました");
        setDetail({});
        setSelectedId(null);
        onSearch(null, 1);
        setActionType("POST");
      }
    }
    if (actionType === "PUT") {
      data.categoryId && sendPost(data);
    } else {
      sendPost(data);
    }
  };

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = (e) => {
    async function sendDelete(categoryId) {
      const result = await DeleteApi(`${DefaultUrl}/${categoryId}`, "");
      ErrorHandling(result, alert) || setDetail(result.data);
      if (result.data) alert.info("無効化しました");
    }
    if (detail.id !== undefined) {
      sendDelete(detail.id);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CategoryMasterForm
        register={register}
        errors={errors}
        isDeleted={isDeleted}
        canWrite={canWrite}
        detail={detail}
      />
      <MasterDetailButtons style={{ display: canWrite ? "flex" : "none" }}>
        <Button
          name={
            actionType === "PUT" ? (detail.delFlag ? "有効化" : "更新") : "登録"
          }
          height="20px"
          width="90px"
          padding="0"
          type="submit"
        />
        {!detail.delFlag && detail.delFlag !== undefined && (
          <Button
            display="none" // 第一フェーズでは非表示
            height="20px"
            width="90px"
            padding="0"
            name="削除"
            type="button"
            onClick={(e) => onClickHandleDelete(e)}
          />
        )}
      </MasterDetailButtons>
      <CreaterInfo info={detail} />
    </Form>
  );
};

CategoryDetails.propTypes = {
  canWrite: PropTypes.bool,
  detail: PropTypes.object,
  setDetail: PropTypes.func,
  onSearch: PropTypes.func,
  setSelectedId: PropTypes.func,
};

export default CategoryDetails;
