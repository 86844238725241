import React, { useEffect, useState, useCallback } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
// React Big Calendar
import * as dates from 'date-arithmetic'
import { Calendar, Views, Navigate, momentLocalizer } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'moment/locale/ja'
import moment from 'moment'

import '../../css/Labor/styles.scss'
import TabSelector from '../Common/Atoms/TabSelector'
import Search from '../Common/Search'
import FUNC from '../Common/FunctionEnum'
import { GetApi, PutApi, exportErrorLog } from '../Common/ApiAxios'
import { SetLoadSpinner } from '../Common/Slice/LoadSpinnerSlice'

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar)

const LaborTitle = styled.h1`
  color: #FFF;
  padding: 12px 16px;
  font-size: 1.2rem;
  background-color: #222;
`

const LaborList = styled.div`
  height: 30vh;
  width: 20vw;
`

const LaborItem = styled.div`
  padding: 6px 0px 6px 16px;
  font-size: 0.9rem;
`

const LaborContainer = styled.div`
  margin: 0 32px;
  background-color: #333;
  border-radius: 5px;
`

class MyWeek extends React.Component {
  render () {
    const { date } = this.props
    const range = MyWeek.range(date)

    return <TimeGrid {...this.props} range={range} eventOffset={15} />
  }
}

MyWeek.range = date => {
  const start = date
  const end = dates.add(start, 1, 'day')

  let current = start
  const range = []

  while (dates.lte(current, end, 'day')) {
    range.push(current)
    current = dates.add(current, 1, 'day')
  }

  return range
}

MyWeek.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -1, 'day')

    case Navigate.NEXT:
      return dates.add(date, 1, 'day')

    default:
      return date
  }
}

MyWeek.title = date => {
  return '工程一覧'
}

const PersonalLabor = () => {
  const dispatch = useDispatch()
  const { loginUserInfo, commonSearch } = useSelector(state => state)
  const grants = loginUserInfo.menuList &&
    loginUserInfo.menuList.find(menu => menu.functionMenuId === (FUNC.RESULT_LABOR))
  // const canViewOperationList = grants && (
  //   grants.writeGrantDivision === 2 ||
  //   (grants.writeGrantDivision === 1 && commonSearch.warehouseId === loginUserInfo.warehouseId) ||
  //   (grants.writeGrantDivision === 3 && commonSearch.warehouseId === loginUserInfo.warehouseId)
  // )
  const companyId = commonSearch.companyId
  const warehouseId = commonSearch.warehouseId
  const floorId = commonSearch.floorId
  const workDate = commonSearch.workDate
  const loginStaffId = loginUserInfo.staffId

  const [labors, setLabors] = useState([])
  const [resultLabors, setResultLabors] = useState([])
  const [events, setEvents] = useState([])
  const [register, setRegister] = useState(1)
  const registerList = [
    { value: 1, name: '予定' },
    { value: 2, name: '実績' }
  ]
  const getDateTime = dt => {
    const d = moment(dt)
    const ret = new Date(d.year(), d.month() + 1, d.date(), d.hour(), d.minute(), d.second())
    return ret
  }
  const fetchLabors = useCallback(
    async () => {
      try {
        const result = await GetApi(`/api/labor/labors?warehouseId=${warehouseId}&floorId=${floorId}&workDate=${workDate}&isPlanned=true&registerStatus=1&staffId=${loginStaffId}`)
        const ret = await result.data
        setLabors(ret)
        const _events = [...events]
        for (const labor of ret) {
          _events.push({
            id: labor.laborId,
            title: labor.operationName,
            start: getDateTime(labor.startDt),
            end: getDateTime(labor.endDt)
          })
        }
        // カレンダーイベントに追加
        setEvents(_events)

        const result2 = await GetApi(`/api/labor/labors?warehouseId=${warehouseId}&floorId=${floorId}&workDate=${workDate}&isPlanned=false&registerStatus=1&staffId=admin`)
        const ret2 = await result2.data
        setResultLabors(ret2)
        dispatch(SetLoadSpinner(false))
      } catch (error) {
        dispatch(SetLoadSpinner(false))
      }
    },
    [warehouseId, floorId, workDate]
  )

  const modLabor = async (id, start, end) => {
    const startIndex = moment(start).hour() * 4 + moment(start).minute() / 15
    const endIndex = moment(end).hour() * 4 + moment(end).minute() / 15
    console.log(`

        ----------------
        onEventDrop
        ---------------
        id: ${id}
        start: ${start}
        end: ${end}
        hour: ${startIndex}
        minites: ${endIndex}

      `)

    const url = '/api/labor'
    const payload = {
      staffId: loginStaffId,
      workDate: workDate,
      labor: {
        laborId: id,
        staffId: loginStaffId,
        workDate: workDate,
        startIndex: startIndex,
        endIndex: endIndex
      },
      isPlanned: true
    }

    console.log('payload')
    console.log(payload)
    const ret = await PutApi(url, payload, true)
    console.log(ret)
    if (ret.errorDetail) exportErrorLog(ret.errorDetail)

    return ret
  }
  useEffect(() => {
    fetchLabors()
    // fetchLabors(false)
    return () => {
      console.log('cleanup')
    }
  }, [companyId, warehouseId, floorId, workDate])

  const onEventResize = async (data) => {
    const { event, start, end } = data
    const ret = await modLabor(event.id, start, end)
    if (!ret.errorDetail) {
      fetchLabors()
    }
  }

  const onEventDrop = async (data) => {
    const { event, start, end } = data
    const ret = await modLabor(event.id, start, end)
    if (!ret.errorDetail) {
      fetchLabors()
    }
  }

  const ColoredDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        backgroundColor: 'white'
      }
    })

  const _today = moment(workDate)
  const today = new Date(_today.year(), _today.month() + 1, _today.date())
  const eventStyleGetter = () => {
    const style = { backgroundColor: 'aquablue', border: '1px solid aquablue' }
    return { style: style }
  }

  if (!grants) return null
  // TODO DnDでPUT
  // TODO 予実がない場合のAPIの返りJSON確認
  // TODO 個人工程画面へのリンク
  // TODO APIエラーハンドリング確認
  // TODO 予定実績色分け
  // TODO １週間表示
  return (
    <>
      <Search
        title='個人工程'
        addStyle='padding: 0 0 0 16px;'
        showButton={false} showWarehouses showFloors showZones showCalendar
        addAllFloor addAllZone
        inSubCompany
        grants={grants}
      />
      <div style={{ display: 'flex' }}>
        <div>
          <TabSelector
            selectValue={register}
            setSelectValue={setRegister}
            title=''
            tabsName='registers'
            dataList={registerList}
          />
          <DnDCalendar
            date={today}
            defaultDate={today}
            defaultView={Views.WEEK}
            events={events}
            eventPropGetter={eventStyleGetter}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            resizable
            showMultiDayTimes
            step={15}
            style={{ height: '80vh', width: '50vw', marginLeft: '16px' }}
            timeslots={4}
            toolbar={false}
            views={{ month: true, week: MyWeek }}
            components={{
              timeSlotWrapper: ColoredDateCellWrapper
            }}
          />
        </div>
        <LaborContainer>
          <LaborTitle>
            予定作業
          </LaborTitle>
          <LaborList>
            {labors?.map((item) => {
              if (item.laborId === 0) return null
              return (
                <div key={item.laborId} style={{ color: '#FFF', margin: '6px 0', display: 'flex' }}>
                  <LaborItem>{moment(item.startDt).format('HH:mm:ss')}</LaborItem>
                  <LaborItem> - </LaborItem>
                  <LaborItem>{moment(item.endDt).format('HH:mm:ss')} </LaborItem>
                  <LaborItem>{item.operationName} </LaborItem>
                </div>
              )
            })}
          </LaborList>

          <LaborTitle>
            実績作業
          </LaborTitle>
          <LaborList>
            {resultLabors?.map((item) => {
              if (item.laborId === 0) return null
              return (
                <div key={item.laborId} style={{ color: '#FFF', margin: '6px 0', display: 'flex' }}>
                  <LaborItem>{moment(item.startDt).format('HH:mm:ss')}</LaborItem>
                  <LaborItem> - </LaborItem>
                  <LaborItem>{moment(item.endDt).format('HH:mm:ss')} </LaborItem>
                  <LaborItem>{item.operationName} </LaborItem>
                </div>
              )
            })}
          </LaborList>
        </LaborContainer>
      </div>
    </>
  )
}

export default PersonalLabor
