import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  // 会社情報
  companyList: {}
}

const CompanyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState,
  reducers: {
    // 会社情報セット
    SetCompanyInfo: (state, action) => {
      state.companyList = action.payload
    }
  }
})

export const CompanyInfoStates = () => {
  return useSelector((state) => state)
}

export const {
  SetCompanyInfo
} = CompanyInfoSlice.actions

export default CompanyInfoSlice.reducer
