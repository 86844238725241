import { GetApi, exportErrorLog } from '../Common/ApiAxios'

const getCoreWorkStatus = async (warehouseId, workDate) => {
  const data = { warehouseId: warehouseId, targetDate: workDate }
  const result = await GetApi('/api/attendance/status', data)
  if (result.errorDetail) {
    exportErrorLog(result)
    return null
  }

  return result.data.status
}

export { getCoreWorkStatus }
