import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useAlert } from 'react-alert'

import { PostApi, exportErrorLog, forceLogout } from '../Common/ApiAxios'
import Staff from '../WhiteBoard/Staff'
import ListView from '../Common/Atoms/ListView'
import TemplateButtons from './TemplateButtons'

const StaffListWrap = styled.div`
    // padding: 10px;
    background: #333;
    height: 100vh;
`

const StaffsLabel = styled.label`
    width: 100%;
    // text-align: center;
    padding-left: 30px;
    display: block;
    height: 50px;
    line-height: 50px;
    background: #555;
    color: white;
`

const StaffsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-left: 30px;
    width: 400px;
    background: #555;
    overflow-y: scroll;
    height: calc(50vh - 50px);
`

const TemplateContainer = styled.div`
    height: calc(50vh - 100px);
`
// ドロップしているかしていないかでカラー判定
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightgray' : '#333'
})

export const StaffList = (props) => {
  const { provided, style, data, placeholder } = props
  return (
    <StaffsContainer
      ref={provided && provided.innerRef}
      {...((provided) ? provided.draggableProps : {})}
      style={style}
    >
      {data.map((staff, index) => {
        if (!staff) return null
        const { staffId } = staff
        return (
          <Draggable
            index={index} key={staffId}
            draggableId={staff.staffName + '_' + staffId.toString()}
          >
            {(dragprovided, snapshot) => (
              <Staff
                key={staffId}
                index={index}
                {...staff}
                provided={dragprovided}
              />
            )}
          </Draggable>
          // <StaffCard
          //   key={staffId}
          //   staffName={staffName}
          //   id={staffId}
          //   index={index}
          // />
        )
      })}
      {placeholder}
    </StaffsContainer>
  )
}

export const DroppableStaffList = (props) => {
  const columnId = 'stafflist' // TODO 固定値変更
  return (
    <Droppable droppableId={columnId} key={columnId}>
      {(provided, snapshot) => {
        return (
          <StaffList
            style={getListStyle(snapshot.isDraggingOver)}
            data={props.data}
            provided={provided}
            placeholder={provided.placeholder}
          />
        )
      }}
    </Droppable>
  )
}

export const Staffs = ({ getZoneStaffs, timeFrameId, staffsJson }) => {
  const alert = useAlert()

  const onReflect = async (templateId, from, to) => {
    const start = moment(from)
    const end = moment(to)
    var registDates = []
    while (start.unix() <= end.unix()) {
      console.log(start.format('YYYY-MM-DD'))
      registDates.push(start.format('YYYY-MM-DD'))

      start.add(1, 'days')
    }
    console.log(`

        templateId: ${templateId}
        from: ${from}
        to: ${to}

    `)
    // テンプレート情報取得
    // const templates = getPlacementTemplates()

    // console.log(templates)
    // Modal表示
    // 反映日付でループ
    const dateDiff = moment(to, 'YYYY-MM-DD').diff(moment(from, 'YYYY-MM-DD'), 'days')
    console.log(`

        dateDiff: ${dateDiff}

    `)
    // 指定日付の配置情報に登録
    console.log(registDates)
    const result = await PostApi('/api/whiteboard/reflectTemplate', {
      templateId: templateId,
      fromDate: from,
      toDate: to
    }, true)
    if (result.errorDetail) {
      exportErrorLog(result)
      if (result.errorDetail.response && result.errorDetail.response.status === 401) {
        forceLogout()
      } else {
        alert.error('取得に失敗しました。')
      }
    } else {
      getZoneStaffs(timeFrameId)
      alert.success('テンプレートを反映しました。')
    }
  }
  const template = useSelector(state => state.mapping.template)
  const [selectedId, setSelectedId] = useState()
  const style = `
    width: 100%;
    li {
      width: 100%;
      padding-left: 30px;
    } 
  `

  useEffect(() => {
    if (template[0]) setSelectedId(template[0].id)

    return () => {
      console.log('clean up')
    }
  }, [template])

  return (
    <StaffListWrap>
      <StaffsLabel>スタッフ一覧</StaffsLabel>
      <DroppableStaffList data={staffsJson} />
      <StaffsLabel>テンプレート一覧</StaffsLabel>
      <TemplateContainer>

        <ListView
          addStyle={style}
          dataList={template}
          selectedId={selectedId}
          selectFunction={(id) => setSelectedId(id)}
          height='calc(50vh - 120px)'
        />
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
        <TemplateButtons
          templates={template}
          onReflect={onReflect}
          action='reflect'
        />
        {/* </div> */}
      </TemplateContainer>

    </StaffListWrap>
  )
}

StaffList.propTypes = {
  provided: PropTypes.object,
  style: PropTypes.object,
  data: PropTypes.array,
  placeholder: PropTypes.object
}

DroppableStaffList.propTypes = {
  data: PropTypes.array
}

Staffs.propTypes = {
  staffsJson: PropTypes.array,
  getZoneStaffs: PropTypes.func,
  timeFrameId: PropTypes.number
}

export default Staffs
