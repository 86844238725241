import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedId: -1,
  zones: []
}
const editZone = createSlice({
  name: 'editZone',
  initialState,
  reducers: {
    setSelectedId (state, action) {
      const { selectedId } = action.payload
      state.selectedId = selectedId
    },
    setZones (state, action) {
      console.log('setZones called', state, action)
      state.zones = action.payload
    }
  }
})

export const { setSelectedId, setZones } = editZone.actions
export default editZone.reducer
