import React from 'react'
import PropTypes from 'prop-types'
// import { FaExclamationTriangle } from 'react-icons/fa'
// import { FaUserAlt, FaClock } from 'react-icons/fa'
import '../../css/Staff.scss'

const Staff = (props) => {
  // const gender =
  //           (props.sex === 'male') ? 'staff__male'
  //             : (props.sex === 'female') ? 'staff__female' : null
// 出勤ステータス
// 1:勤務中
// 2:休憩中
// 3:残業中
// 4:退勤
// 5:遅刻
// 6:欠勤
// 7:休日
  const workStatus =
          (props.workStatus === 7) ? 'staff__off-duty'
            : (props.workStatus === 1) ? 'staff__at-work'
              : (props.workStatus === 2) ? 'staff__break-time'
                : (props.workStatus === 4) ? 'staff__leave-work' : 'staff__off-duty'
  // (props.attendance === '未出勤') ? 'staff__not-present'
  // (props.attendance === '未出勤') ? 'staff__off-duty'
  //   : (props.attendance === '勤務中') ? 'staff__at-work'
  //     : (props.attendance === '休憩') ? 'staff__break-time'
  //       : (props.attendance === '退勤') ? 'staff__leave-work' : 'staff__off-duty'

  // var wbgtStatus
  // switch (props.wbgtStatus) {
  //   case 0:
  //     wbgtStatus = 'wbgtstatus-normal'
  //     break
  //   case 1:
  //     wbgtStatus = 'wbgtstatus-warning'
  //     break
  //   case 2:
  //     wbgtStatus = 'wbgtstatus-hardwarning'
  //     break
  //   case 3:
  //     wbgtStatus = 'wbgtstatus-danger'
  //     break
  //   default:
  //     break
  // }

  // const alertStyle = (wbgtStatus) => ({
  //   position: 'absolute',
  //   top: '5px',
  //   right: '5px',
  //   color: '#eb3424',
  //   backgroundColor: '#fffd54',
  //   display: wbgtStatus > 0 ? 'inline' : 'none'
  // })
  const { provided, openModal = f => f } = props
  if (props.shiftStart != null) {
    console.log(`
  
      props.staffId: ${props.staffId}
      props.staffName: ${props.staffName}
      props.shiftStart: ${props.shiftStart}
      props.shiftEnd: ${props.shiftEnd}
  
    `)
  }

  if (!provided) {
    return (
      <div
        style={{ cursor: 'pointer' }}
        className={'staff ' + workStatus} // + ' ' + wbgtStatus}
        onClick={() =>
          openModal(props.staffId)}
      >
        <div className='staffName'>{props.staffName}</div>
        {props.shiftStart == null ? <div /> : <div className='shift'>{props.shiftStart} ～ {props.shiftEnd}</div>}
        {/* {props.startTime == null ? <div /> : <div className='shift'>{props.startTime} ～ {props.endTime}</div>} */}
      </div>
    )
  }

  return (
    <div
      className={'staff ' + workStatus} // + ' ' + wbgtStatus}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={() =>
        openModal(props.staffId)}
    >
      {/* <div className={'contents staff__' + props.attendance}><FaUserAlt />{props.staffName}</div>
      <div className={'contents staff__' + props.attendance}><FaClock />{props.shiftStart} ～ {props.shiftEnd}</div> */}
      <div className='staffName'>{props.staffName}</div>
      {props.shiftStart == null ? <div /> : <div className='shift'>{props.shiftStart} ～ {props.shiftEnd}</div>}
      {/* <FaExclamationTriangle size={24} style={alertStyle(props.wbgtStatus)} /> */}
    </div>
  )
}

Staff.propTypes = {
  attendance: PropTypes.string,
  provided: PropTypes.object,
  openModal: PropTypes.func,
  staffId: PropTypes.string,
  staffName: PropTypes.string,
  shiftStart: PropTypes.string,
  shiftEnd: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  workStatus: PropTypes.number
}
export default Staff
