import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import Typography from '@material-ui/core/Typography'
import { IconContext } from 'react-icons'
import { FaCloudDownloadAlt } from 'react-icons/fa'

import TabSelector from '../../Common/Atoms/TabSelector'
import Modal from 'react-modal'
import {
  apiGetLabors,
  changeIndexByDt,
  getPlannedAttendance,
  getPlannedPersonalAttendance,
} from '../LaborSlice'
import {
  getIndividualMonthlyLabors,
  getLaborApproval,
  SetDateRecords,
} from '../IndividualMonthly/IndividualMonthlySlice'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import { SetCommonSearch } from '../../Common/Slice/SearchSlice'
import { restfulApiConfig } from '../../Common/Config'
import Search from '../../Common/Search'
import FUNC from '../../Common/FunctionEnum'
import LaborApprovalTable from './LaborApprovalTable'
import LaborApprovalSortTable from './LaborApprovalSortTable'
import StaffInfo from '../IndividualMonthly/StaffInfo'
import Calendar from '../IndividualMonthly/Calendar'
import {
  buildPersonalLabors,
  buildDivisionLabors,
  buildUnapprovedLabors,
  buildOvertimeInfo,
  buildOvertimeAlert,
} from './helper'
import {
  headCells,
  headCellsDep,
  headCellsUnapproved,
  headCellsOvertime,
  headCellsOvertimeAlert,
} from './const'
import { GetApi } from '../../Common/ApiAxios'
import { getFirstAndLastDate } from '../../Common/utils'
import OvertimeTable from './OvertimeTable'
import OvertimeAlertTable from './OvertimeAlertTable'
import CalenderIcon from '../../../image/svg/CalenderIcon.js'
import { CSVLink } from 'react-csv'

const Title = '実績工程承認'

const FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
`

const MenuDiv = styled.div`
  display: flex;
  position: relative;
  color: #fff;
  flex-wrap: wrap;
  margin-bottom: 12px;
  h3 {
    line-height: 30px;
    font-size: 16px;
    margin-right: 16px;
  }
`

const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    width: '50%',
    height: '75%',
    opacity: 1.0,
    padding: '2px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: 10,
  },
}

const ModalTitle = styled.h1`
  text-align: center;
  margin: 10px;
  font-size: 1.5em;
  color: #333;
`

function compare(a, b) {
  // console.log(a, b)
  if (a.startIndex < b.startIndex) {
    return -1
  }
  if (a.startIndex > b.startIndex) {
    return 1
  }
  return 0
}

const filterRow = (rows, grantLevel, staffId) => {
  if (grantLevel === 3) {
    return rows.filter((row) => row.staffId === staffId)
  }

  return rows
}

const LaborApproval = () => {
  const {
    loginUserInfo,
    individualMonthly,
    commonSearch,
    laborSlice,
  } = useSelector((state) => state)
  const date = new Date()
  const dispatch = useDispatch()
  const [searchCond, setSearchCond] = useState(0)
  const [staff, setStaff] = useState()
  const [month, setMonth] = useState(() => {
    if (date.getDate() >= restfulApiConfig.startDay) {
      if (date.getMonth() === 11) {
        return date.getFullYear() + 1 + '-' + ('0' + '1').slice(-2)
      } else {
        return (
          date.getFullYear() + '-' + ('0' + (date.getMonth() + 2)).slice(-2)
        )
      }
    } else {
      return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2)
    }
  })
  const [overtimeInfo, setOvertimeInfo] = useState()
  const [overtimeAlert, setOvertimeAlert] = useState()
  const [register, setRegister] = useState(1) // TODO 不要か調査
  const [workPlace, setWorkPlace] = useState(0) // TODO 不要か調査
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [timelineLabors, setTimelineLabors] = useState([])
  const [updateTime, setUpdateTime] = useState()
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [csvDownloadModalIsOpen, setCsvDownloadModalIsOpen] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [csvData, setCsvData] = useState(null)
  const [csvRecordCount, setCsvRecordCount] = useState()
  // useReducer
  const [unapproved, localDispatch] = useReducer((state, action) => {
    // return action
    return buildUnapprovedLabors(action)
  }, null)

  const nowTimeIndex =
    commonSearch.workDate && changeIndexByDt(new Date(), commonSearch.workDate)
  const grants =
    searchCond !== 4 // 残業アラート
      ? loginUserInfo &&
        loginUserInfo.menuList &&
        loginUserInfo.menuList.find(
          (menu) => menu.functionMenuId === FUNC.LABOR_APPROVAL
        ) // 工程承認(残業アラート以外)
      : loginUserInfo &&
        loginUserInfo.menuList &&
        loginUserInfo.menuList.find(
          (menu) => menu.functionMenuId === FUNC.OVERTIME_ALERT
        ) // 残業アラート

  const searchCondList = [
    { value: 0, name: '個人別' },
    { value: 1, name: '部門別' },
    { value: 2, name: '未承認一覧' },
    { value: 3, name: '残業申請一覧' },
    { value: 4, name: '残業アラート' },
  ]

  // 工程詳細モーダル画面
  const handleDetailClick = (id) => (e) => {
    e.stopPropagation()
    let sortedLabors
    let tmp
    switch (searchCond) {
      // 個人別
      case 0:
        tmp =
          individualMonthly &&
          individualMonthly.dateRecords.filter((d) => d.dateValue === id)
        sortedLabors = tmp[0].labors.concat()
        break
      // 部門別
      case 1:
        tmp = laborSlice && laborSlice.labors.filter((l) => l.staffId === id)
        sortedLabors = tmp.concat()
        break
      // 未承認一覧
      case 2:
        tmp = unapproved && unapproved.filter((u) => u.id === id)
        sortedLabors = tmp[0].labors.concat()
        break
      default:
        break
    }

    sortedLabors.sort(compare)
    const fromFormat = 'yyyy-MM-dd HH:mm:ss.S'
    const toFormat = 'yyyy/MM/dd HH:mm'
    setTimelineLabors(
      sortedLabors.map((l) => ({
        operationName: l.operationName,
        operationType: l.operationType,
        startDt: DateTime.fromFormat(l.startDt, fromFormat).toFormat(toFormat),
        endDt: DateTime.fromFormat(l.endDt, fromFormat).toFormat(toFormat),
        workPlaceName: l.workPlaceName,
      }))
    )
    setModalIsOpen(true)
  }

  const afterOpenModal = () => {
    console.log('')
  }
  const closeModal = () => {
    setModalIsOpen(false)
  }

  const closeCsvdonwloadModal = () => {
    setCsvDownloadModalIsOpen(false)
  }

  const onDownloadClick = async () => {
    console.log(`
    startDate: ${startDate}
    endDate: ${endDate}
    warehouseId: ${commonSearch.warehouseId}
    floorId: ${commonSearch.floorId}
    zoneId: ${commonSearch.zoneId}
    `)
    dispatch(SetLoadSpinner(true))
    console.log(DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd'))
    const argsArr = []
    argsArr.push(
      `workDateFrom=${DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')}`
    )
    argsArr.push(
      `workDateTo=${DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')}`
    )
    commonSearch.warehouseId != null &&
      commonSearch.warehouseId !== -1 &&
      argsArr.push(`warehouseId=${commonSearch.warehouseId}`)
    commonSearch.floorId != null &&
      commonSearch.floorId !== -1 &&
      argsArr.push(`floorId=${commonSearch.floorId}`)
    commonSearch.zoneId != null &&
      commonSearch.zoneId !== -1 &&
      argsArr.push(`zoneId=${commonSearch.zoneId}`)

    const urlArgs =
      argsArr.join('&') +
      '&registerStatus=1&workPlace=0&staffDivisionFilter=0&isPlanned=false'
    // const urlArgs = 'warehouseId=17&workDateFrom=2021-04-01&workDateTo=2021-04-30&registerStatus=1&workPlace=0&staffDivisionFilter=0&isPlanned=false'
    const { data } = await GetApi(
      `/api/download/csv/labor?${urlArgs}`
    ).catch(() => dispatch(SetLoadSpinner(false)))

    setCsvData(data)
    setCsvRecordCount(data.split('\r\n').length - 2)
    dispatch(SetLoadSpinner(false))
    return true
  }

  const getCsvFileName = () => {
    const fromDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
    const toDate = DateTime.fromJSDate(endDate).toFormat('yyyy-MM-dd')
    return `レイバー情報_${fromDate}-${toDate}.csv`
  }

  useEffect(() => {
    document.title = `${Title} | Core First`
  }, [])

  // 個人別勤怠
  useEffect(() => {
    if (searchCond !== 0) return

    if (commonSearch.warehouseId && staff && staff.staffId && month) {
      dispatch(SetLoadSpinner(true))
      dispatch(SetDateRecords(null))
      dispatch(getIndividualMonthlyLabors(staff, month, 'result'))
      const [beginningOfMonth, endOfMonth] = getFirstAndLastDate(month)
      dispatch(getLaborApproval('-', '-', staff, beginningOfMonth, endOfMonth))
      getOvertimeInfo(
        commonSearch.warehouseId,
        '-',
        '-',
        staff.staffId,
        beginningOfMonth,
        endOfMonth
      )
      dispatch(
        getPlannedPersonalAttendance(
          staff.staffId,
          beginningOfMonth,
          endOfMonth
        )
      )
    }
    if (!staff && loginUserInfo && loginUserInfo.staffId) {
      setStaff({
        staffId: loginUserInfo.staffId,
        staffName: loginUserInfo.staffName,
      })
      dispatch(
        SetCommonSearch({
          payCompanyId: loginUserInfo.companyId,
          payWarehouseId: loginUserInfo.warehouseId,
          payFloorId:
            loginUserInfo.floorId == null ? -1 : loginUserInfo.floorId,
          payZoneId: loginUserInfo.zoneId == null ? -1 : loginUserInfo.zoneId,
          payWorkDate: commonSearch.workDate,
        })
      )
    }
    // eslint-disable-next-line
  }, [month, staff, loginUserInfo, updateTime, searchCond])

  const getOvertimeInfo = async (
    warehouseId,
    floorId,
    zoneId,
    staffId,
    fromDate,
    toDate
  ) => {
    console.log('=== getOvertimeInfo ===')

    const url = `/api/labor/overtime/${warehouseId}/${floorId}/${zoneId}/${staffId}/${fromDate}/${toDate}`
    console.log(url)
    const result = await GetApi(url)
    console.log('=== result ===')
    console.log(result.data)
    setOvertimeInfo(result.data)
  }

  // 部門別勤怠
  const getOvertimeAlert = async (
    warehouseId,
    floorId,
    zoneId,
    staffId,
    fromDate,
    toDate
  ) => {
    const warehouse = warehouseId !== -1 ? `&warehouseId=${warehouseId}` : ''
    const floor = floorId && floorId !== '-' ? `&floorId=${floorId}` : ''
    const zone = zoneId && zoneId !== '-' ? `&zoneId=${zoneId}` : ''
    const urlArgs = `staffDivisionFilter=0&sort=staff_id&${warehouse}${floor}${zone}`
    const url = `/api/labor/alert-summary/${fromDate}/${toDate}?${urlArgs}`
    console.log(url)
    dispatch(SetLoadSpinner(true))
    const result = await GetApi(url)
    console.log('=== result ===')
    console.log(result.data)
    const data = await result.data
    setOvertimeAlert(data)
    dispatch(SetLoadSpinner(false))
    console.log('======= after dispatch false')
  }

  const calcMonth = (workDate) => {
    var workDateObj = new Date(workDate)
    var baseDate = workDate.substring(0, 7)
    if (workDateObj.getDate() >= restfulApiConfig.startDay) {
      workDateObj = new Date(workDate.substring(0, 7) + '-01')
      workDateObj.setMonth(workDateObj.getMonth() + 1)
      baseDate =
        workDateObj.getFullYear() +
        '-' +
        ('0' + (workDateObj.getMonth() + 1)).slice(-2)
    }
    return baseDate
  }

  useEffect(() => {
    if (searchCond !== 1) return

    if (
      commonSearch.companyId &&
      commonSearch.workDate &&
      loginUserInfo.companyId &&
      grants
    ) {
      const { warehouseId, floorId, zoneId, workDate } = commonSearch
      const floorIdParam = floorId === -1 || floorId == null ? '-' : floorId
      const zoneIdParam = zoneId === -1 || zoneId == null ? '-' : zoneId

      dispatch(SetLoadSpinner(true)) // 読込時、スピナー表示

      dispatch(
        apiGetLabors(
          commonSearch,
          false,
          register,
          workPlace,
          nowTimeIndex,
          grants,
          loginUserInfo
        )
      )
      dispatch(SetDateRecords(null))
      // 承認データ
      dispatch(
        getLaborApproval(floorIdParam, zoneIdParam, '-', workDate, workDate)
      )
      // 残業申請データ
      getOvertimeInfo(
        warehouseId,
        floorIdParam,
        zoneIdParam,
        '-',
        workDate,
        workDate
      )
      // 予定工程
      dispatch(getPlannedAttendance(commonSearch, loginUserInfo))
      // dispatch(SetLoadSpinner(false))// 読込時、スピナー表示
    }
  }, [commonSearch, loginUserInfo, updateTime, searchCond])

  // 未承認一覧
  useEffect(() => {
    // 月毎の部署別の未承認一覧を取得
    if (searchCond !== 2) return

    const getUnapprovedList = async (
      warehouseId,
      floorId,
      zoneId,
      from,
      to
    ) => {
      const url = '/api/labor/staffApprovalList'
      const data = {
        warehouseId: warehouseId,
        floorId: floorId === -1 ? null : floorId,
        zoneId: zoneId === -1 ? null : zoneId,
        workDateFrom: from,
        workDateTo: to,
        registerStatus: 2,
        approvedStatus: 2,
      }
      console.log(data)
      dispatch(SetLoadSpinner(true))
      const ret = await GetApi(url, data)
      localDispatch(ret.data)
      dispatch(SetLoadSpinner(false))

      return ret
    }

    if (
      commonSearch.companyId &&
      commonSearch.workDate &&
      loginUserInfo.companyId &&
      grants
    ) {
      const { warehouseId, floorId, zoneId, workDate } = commonSearch // workDateの取れ方調査（初回表示時は、yyyy/MM/ddでそれ以降はyyyy/MMになっている）
      const baseDate = calcMonth(workDate)
      const [beginningOfMonth, endOfMonth] = getFirstAndLastDate(baseDate)
      const today = DateTime.local()
      const endDate =
        today.toFormat('yyyy-MM-dd') < endOfMonth
          ? today.toFormat('yyyy-MM-dd')
          : endOfMonth

      getUnapprovedList(warehouseId, floorId, zoneId, beginningOfMonth, endDate)
    }
  }, [commonSearch, loginUserInfo, updateTime, searchCond])

  // 残業申請一覧
  useEffect(() => {
    if (searchCond !== 3) return

    if (
      commonSearch.companyId &&
      commonSearch.workDate &&
      loginUserInfo.companyId &&
      grants
    ) {
      const { warehouseId, floorId, zoneId, workDate } = commonSearch
      const floorIdParam = floorId === -1 ? '-' : floorId
      console.log(zoneId)
      const zoneIdParam = zoneId === -1 || zoneId == null ? '-' : zoneId
      const baseDate = calcMonth(workDate)
      const [beginningOfMonth, endOfMonth] = getFirstAndLastDate(baseDate)
      setFrom(beginningOfMonth)
      setTo(endOfMonth)
      getOvertimeInfo(
        warehouseId,
        floorIdParam,
        zoneIdParam,
        '-',
        beginningOfMonth,
        endOfMonth
      )
    }
  }, [commonSearch, loginUserInfo, searchCond, month])

  // 残業アラート
  useEffect(() => {
    if (searchCond !== 4) return

    const { warehouseId, floorId, zoneId, workDate } = commonSearch
    console.log(zoneId)
    if (
      grants.readGrantDivision === 1 &&
      zoneId !== loginUserInfo.zoneId &&
      !(zoneId == null || zoneId === -1)
    ) {
      setOvertimeAlert([])
      return
    }
    if (
      commonSearch.companyId &&
      commonSearch.workDate &&
      loginUserInfo.companyId &&
      grants
    ) {
      const floorIdParam = floorId === -1 ? '-' : floorId
      console.log(zoneId, loginUserInfo.zoneId)
      // ロジのみ、他部署のデータは見えないようにする
      const zoneIdParam =
        loginUserInfo.warehouseId === 24 && grants.readGrantDivision === 1 // ロジ、かつ、所属のみ読込権限
          ? zoneId === -1 || zoneId == null
            ? loginUserInfo.zoneId === -1
              ? '-'
              : loginUserInfo.zoneId
            : zoneId
          : zoneId === -1 || zoneId == null
          ? '-'
          : zoneId
      const baseDate = calcMonth(workDate)
      const [beginningOfMonth, endOfMonth] = getFirstAndLastDate(baseDate)
      setFrom(beginningOfMonth)
      setTo(endOfMonth)
      console.log('------------ before api')
      getOvertimeAlert(
        warehouseId,
        floorIdParam,
        zoneIdParam,
        '-',
        beginningOfMonth,
        endOfMonth
      )
      dispatch(SetLoadSpinner(true))
      console.log('------------ after api')
    }
  }, [commonSearch, loginUserInfo, searchCond, month])

  // CSVダウンロード
  useEffect(() => {
    if (searchCond !== 1) return
    setCsvData(null)
  }, [commonSearch, startDate, endDate])

  // 個人別
  const personalLabors = []
  if (
    searchCond === 0 &&
    laborSlice?.attendance &&
    individualMonthly?.dateRecords
  ) {
    individualMonthly.dateRecords.map((d) => {
      const plannedAttendance = laborSlice.attendance.attendance
      const lbr = buildPersonalLabors(
        d,
        individualMonthly.laborApproval,
        plannedAttendance
      )
      personalLabors.push(lbr)
    })
  }
  // 部門別
  const divisionLabors = []
  if (
    searchCond === 1 &&
    laborSlice?.labors &&
    laborSlice?.laborHours &&
    laborSlice?.attendance &&
    individualMonthly?.laborApproval
  ) {
    const labors = laborSlice.labors
    const staffs = laborSlice.laborHours
    const plannedAttendance = laborSlice.attendance.attendance
    staffs.map((s) => {
      const staffLabors = labors.filter((l) => s.staffId === l.staffId)
      if (staffLabors.length > 0) {
        const ret = buildDivisionLabors(
          s,
          staffLabors,
          individualMonthly.laborApproval,
          commonSearch.workDate,
          plannedAttendance
        )
        divisionLabors.push(ret)
      }
    })
  }

  // 共通検索コンポーネント
  let searchComponent
  switch (searchCond) {
    case 0: // 個人別
      searchComponent = (
        <div style={{ display: 'flex', paddingLeft: '8px', paddingTop: '3px' }}>
          <StaffInfo staff={staff} setStaff={setStaff} grants={grants} />
          <Calendar
            dataValue={month}
            changeCalendar={(dateString) => {
              setMonth(dateString)
            }}
            type={2}
            width="100px"
            isViewIcon
          />
        </div>
      )
      break
    case 1: // 部門別
      searchComponent = (
        <Search
          title=""
          addStyle="padding: 0 0 0 16px;"
          showButton={false}
          showWarehouses
          showFloors
          showZones
          showCalendar
          addAllWarehouse
          addAllFloor
          addAllZone
          inSubCompany
          inSubWarehouse
          grants={grants}
          calendarType={1}
          showSpinner={false}
        />
      )
      break
    case 2: // 未承認
    case 3: // 残業一覧
    case 4: // 残業アラート
      searchComponent = (
        <Search
          title=""
          addStyle="padding: 0 0 0 16px;"
          showButton={false}
          showWarehouses
          showFloors
          showZones
          showCalendar
          addAllWarehouse
          addAllFloor
          addAllZone
          inSubCompany
          inSubWarehouse
          grants={grants}
          calendarType={2}
          showSpinner={false}
          isApprovedPage
        />
      )
      break
    default:
      searchComponent = null
      break
  }

  if (!grants) return <div style={{ color: 'white' }}>権限がありません</div>
  return (
    <>
      <MenuDiv>
        <FlexDiv>
          <h3>勤怠承認</h3>
          <TabSelector
            selectValue={searchCond}
            setSelectValue={setSearchCond}
            title=""
            tabsName="searchCondition"
            dataList={searchCondList}
          />
          {/* 共通検索 */}
          {searchComponent}
        </FlexDiv>
      </MenuDiv>
      {searchCond === 2 ? (
        unapproved && (
          <LaborApprovalSortTable
            size="small"
            width="100%"
            headCells={headCellsUnapproved}
            rows={unapproved}
            overtimeInfo={overtimeInfo}
            handleDetailClick={handleDetailClick}
            setUpdateTime={setUpdateTime}
            title="未承認一覧"
            month={month}
            grants={grants}
          />
        )
      ) : searchCond === 3 ? (
        overtimeInfo && (
          <OvertimeTable
            size="small"
            width="100%"
            headCells={headCellsOvertime}
            rows={buildOvertimeInfo(overtimeInfo)}
            title=""
            from={from}
            to={to}
          />
        )
      ) : searchCond === 4 ? (
        overtimeAlert && (
          <OvertimeAlertTable
            size="small"
            width="100%"
            headCells={headCellsOvertimeAlert}
            rows={buildOvertimeAlert(
              filterRow(
                overtimeAlert,
                grants.readGrantDivision,
                loginUserInfo.staffId
              )
            )}
            title=""
            from={from}
            to={to}
          />
        )
      ) : (
        <LaborApprovalTable
          size="small"
          width="100%"
          searchCond={searchCond}
          headCells={searchCond === 0 ? headCells : headCellsDep}
          rows={searchCond === 0 ? personalLabors : divisionLabors}
          approvedLabor={individualMonthly && individualMonthly.laborApproval}
          overtimeInfo={overtimeInfo}
          handleDetailClick={handleDetailClick}
          handleCsvDownloadClick={
            searchCond === 0 ? null : setCsvDownloadModalIsOpen
          }
          setUpdateTime={setUpdateTime}
          title={
            searchCond === 0
              ? `${staff?.staffId} ${staff?.staffName}`
              : commonSearch.workDate
          }
          month={searchCond === 0 ? month : null}
          staffId={searchCond === 0 ? staff?.staffId : null}
          grants={grants}
        />
      )}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Timeline Modal"
        ariaHideApp={false}
      >
        <ModalTitle>工程詳細</ModalTitle>
        {timelineLabors.length > 0
          ? timelineLabors.map((tl, idx) => (
              <Timeline key={tl.startDt}>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography color="textSecondary">{tl.startDt}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot
                      color={tl.operationType !== 1 ? 'secondary' : 'primary'}
                    />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography>{tl.operationName}</Typography>
                  </TimelineContent>
                </TimelineItem>
                {timelineLabors.length === idx + 1 ? (
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography color="textSecondary">{tl.endDt}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>退勤</Typography>
                    </TimelineContent>
                  </TimelineItem>
                ) : null}
              </Timeline>
            ))
          : 'None'}
      </Modal>
      <Modal
        isOpen={csvDownloadModalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeCsvdonwloadModal}
        style={{
          ...modalStyle,
          content: { width: '800px', background: 'black', margin: 'auto' },
        }}
        contentLabel="Timeline Modal"
        ariaHideApp={false}
      >
        <ModalTitle style={{ color: 'white', paddingBottom: '32px' }}>
          CSVダウンロード
        </ModalTitle>
        <Search
          title=""
          addStyle="padding: 0 0 0 16px;"
          showButton={false}
          showWarehouses
          showFloors
          showZones
          addAllWarehouse
          addAllFloor
          addAllZone
          inSubCompany
          grants={grants}
        />

        <div style={{ display: 'flex', padding: '32px 16px 64px 16px' }}>
          <p
            style={{ color: 'white', lineHeight: '32px', marginRight: '16px' }}
          >
            出力対象期間
          </p>
          <WrapDiv>
            <DatePicker
              className="normalCalendar"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              // startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy/MM/dd (EE)"
              locale="ja"
            />
            <CalenderIcon />
          </WrapDiv>
          <p
            style={{
              color: 'white',
              fontSize: '1.4em',
              padding: '4px 8px 0 8px',
            }}
          >
            ~
          </p>
          <WrapDiv>
            <DatePicker
              className="normalCalendar"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              // startDate={startDate}
              // endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy/MM/dd (EE)"
              locale="ja"
            />
            <CalenderIcon />
          </WrapDiv>
        </div>

        <Button
          color="primary"
          variant="contained"
          data-value="aaa"
          onClick={onDownloadClick}
        >
          CSVデータ作成
        </Button>
        {csvData && (
          <div
            style={{
              textAlign: 'center',
              marginTop: '16px',
              marginBottom: '8px',
            }}
            title="ダウンロード"
          >
            <CSVLink data={csvData} filename={getCsvFileName()} target="_blank">
              <IconContext.Provider value={{ color: 'white' }}>
                <FaCloudDownloadAlt size={32} />
              </IconContext.Provider>
            </CSVLink>
            <p style={{ color: 'white' }}>レコード件数: {csvRecordCount} 件</p>
          </div>
        )}
      </Modal>
    </>
  )
}

const WrapDiv = styled.div`
  position: relative;
  margin: '16px';
  display: flex;
  width: '480px';

  .calendarIconWap {
    display: 'block';
  }

  .react-datepicker-popper {
    z-index: 6;
  }
  /* .react-datepicker__current-month {
    display: none;
  } */
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    border: none;
    background: #eee;
    height: 20px;
    line-height: 20px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 280px;
    height: ${(props) => props.height};
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-wrapper {
    flex: 1;
  }
  .react-datepicker__month .react-datepicker__month-text {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__input-container {
    ::after {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background: #0036ff;
      cursor: pointer;
      border-radius: 0 5px 5px 0;
      pointer-events: none;
      z-index: 2;
    }
  }

  .normalCalendar {
    height: 32px;
    position: relative;
    text-indent: 8px;
    width: 140px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: '#fff';
    border-radius: 5px;
    border: none;
    line-height: 16px;
    box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.35);
    color: #333;
    z-index: 1;
    cursor: pointer;
    &:focus {
      background: #fff;
      outline: none;
      border: 2px solid #0036ff;
      box-sizing: border-box;
      text-indent: 8px;
    }
    ::placeholder {
      color: #ccc;
    }
  }
`

const Button = styled.button`
  display: block;
  width: 120px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  background: #0036ff;
  border-radius: 5px;
  border: none;
  line-height: 28px;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.4),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.4);
  &:hover {
    background: #0026b3;
  }
`

export default LaborApproval
