import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const GrantSelectWrap = styled.select`
  width: ${props => props.width || '120px'};
  min-width: ${props => props.width || '120px'};
  font-size: 16px;
  letter-spacing: 0.05em;
  height: 36px;
  border-radius: 5px;
  border: none;
  line-height: 16px;
  text-indent: 8px;
  color: #333;
  cursor: ${props => props.isNoChange ? 'no-drop' : 'pointer'};
  margin : auto;
  background: #eee;
`

const GrantSelect = (props) => {
  const { selectName, flg, onChangeFlg, isNoChange, children } = props
  const selectRef = useRef()

  useEffect(() => {
    if (selectRef) {
      selectRef.current.value = flg
    }
  }, [flg])

  const changeFlg = (e) => {
    if (!isNoChange) {
      onChangeFlg(e)
    }
  }

  return (
    <GrantSelectWrap name={selectName} onChange={(e) => changeFlg(e)} ref={selectRef} disabled={isNoChange} isNoChange={isNoChange}>
      {children}
    </GrantSelectWrap>
  )
}

GrantSelect.propTypes = {
  selectName: PropTypes.string,
  flg: PropTypes.number,
  onChangeFlg: PropTypes.func,
  isNoChange: PropTypes.bool,
  children: PropTypes.any
}

export default GrantSelect
