import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { DefaultUrl } from './Staff'
import StaffMasterForm from './StaffMasterForm'
import StaffSkillMasterForm from './StaffSkillMasterForm'
import { DeleteApi, MultipartApi, GetApi } from '../../Common/ApiAxios'
import Button from '../../Common/Atoms/NormalButton'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import FUNC from '../../Common/FunctionEnum'
import { useAlert } from 'react-alert'
import { SetLoadSpinner } from '../../Common/Slice/LoadSpinnerSlice'
import ErrorHandling from '../Common/ErrorHandling'
import TabSelector from '../../Common/Atoms/TabSelector'
import MasterDetailButtons from '../../Common/Wrap/MasterDetailButtons'
import PropTypes from 'prop-types'
import CreaterInfo from '../../Common/Molecules/CreaterInfo'
import { grantDecision } from '../../Common/utils'

const Form = styled.form`
  width: 100%;
  height: 100%;
  .react-tabs {
    ul {
      li {
        &[aria-selected="true"]{
          button{
            background: #555;
            color: white;
          }
        }
      }
    }
  }
  .panel_body {
    display :none;
    &.selected {
      display: block;
    }
  }
`

const StaffDetails = ({ companyList, setSelectedId, onSearch, staffDetail, setStaffDetail, grants }) => {
  const { register, handleSubmit, setValue, watch, errors, reset, getValues, control } = useForm({ validateCriteriaMode: 'all' })
  const [actionType, setActionType] = useState('POST')
  const [isDeleted, setIsDeleted] = useState(false)
  const [imageSrc, setImageSrc] = useState()
  const [isPasswordChange, setIsPasswordChange] = useState(false)
  const imageRef = useRef()
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const alert = useAlert()
  const dispatch = useDispatch()
  const [selects, setSelects] = useState()
  const companyRef = useRef()
  const warehouseRef = useRef()
  const floorRef = useRef()
  const zoneRef = useRef()

  // 情報をFormに反映
  useEffect(() => {
    getGrantOptions()
    if (staffDetail.staffId === undefined) {
      setActionType('POST')
      reset()
      setIsPasswordChange(false)
      setIsDeleted(false)
      if (companyRef.current) {
        companyRef.current.selectedIndex = 0
        warehouseRef.current.selectedIndex = 0
        floorRef.current.selectedIndex = 0
        zoneRef.current.selectedindex = 0
        setSelects({
          companyId: companyRef.current.value,
          warehouseId: warehouseRef.current.value - 0,
          floorId: floorRef.current.value - 0,
          zoneId: zoneRef.current.value - 0
        })
      }
    } else {
      setIsDeleted(staffDetail.delFlag)
      setValue('staffId', staffDetail.staffId)
      setValue('staffName', staffDetail.staffName)
      setValue('staffKana', staffDetail.staffKana)
      setValue('staffDivision', staffDetail.staffDivision)
      setValue('departmentId', staffDetail.departmentId)
      setValue('loginFlag', staffDetail.loginFlag)
      setValue('showSetting', staffDetail.showSetting)
      setValue('enterDt', staffDetail.enterDt)
      setValue('retireDt', staffDetail.retireDt)
      setValue('sex', staffDetail.sex)
      setValue('birthDt', staffDetail.birthDt)
      setValue('zipCode', staffDetail.zipCode)
      setValue('address1', staffDetail.address1)
      setValue('address2', staffDetail.address2)
      setValue('address3', staffDetail.address3)
      setValue('address4', staffDetail.address4)
      setValue('phoneNum', staffDetail.phoneNum)
      setValue('mobileNum', staffDetail.mobileNum)
      setValue('pcMailAddress', staffDetail.pcMailAddress)
      setValue('mobileMailAddress', staffDetail.mobileMailAddress)
      setValue('cooperationId', staffDetail.cooperationId)
      setValue('updatedAt', staffDetail.updatedAt)
      setValue('updatedId', staffDetail.updatedId)
      setValue('grant.grantNameId', staffDetail.grant.grantNameId)
      setValue('grant.updatedId', staffDetail.grant.updatedId)
      setValue('grant.updatedAt', staffDetail.grant.updatedAt)
      setValue('warehouseRelation.staffWarehouseId', staffDetail.warehouseRelation.staffWarehouseId)
      setValue('warehouseRelation.updatedId', staffDetail.warehouseRelation.updatedId)
      setValue('warehouseRelation.updatedAt', staffDetail.warehouseRelation.updatedAt)
      setValue('isClearImage', false)
      setActionType('PUT')
      setSelects({
        companyId: staffDetail.warehouseRelation.companyId,
        warehouseId: staffDetail.warehouseRelation.warehouseId,
        floorId: staffDetail.warehouseRelation.floorId,
        zoneId: staffDetail.warehouseRelation.zoneId
      })
    }
    if (imageRef.current) imageRef.current.value = ''
  // スタッフ情報の変更検知時のみ実行
  // eslint-disable-next-line
  }, [staffDetail])

  // POST・PUT処理
  const onSubmit = (data) => {
    async function sendPost (requestBody, data) {
      var result
      dispatch(SetLoadSpinner(true))
      switch (actionType) {
        case 'POST':
          result = await MultipartApi(`${DefaultUrl}`, actionType, requestBody, data)
          break
        case 'PUT':
          result = await MultipartApi(`${DefaultUrl}/${staffDetail.staffId}`, actionType, requestBody, data)
          break
        default:
          result = false
          break
      }
      dispatch(SetLoadSpinner(false))
      if (!ErrorHandling(result, alert)) {
        isDeleted ? alert.success('有効化しました') : alert.success('保存しました')
        setStaffDetail({})
        setSelectedId(null)
        onSearch(null, 1)
        setActionType('POST')
      }
    }
    const submitData = new window.FormData()
    if (!data.isPasswordChange) {
      data.password = ''
      data.confirmPassword = ''
    }
    data.warehouseRelation.warehouseId = warehouseRef.current.value - 0
    data.warehouseRelation.floorId = floorRef.current.value - 0
    data.warehouseRelation.zoneId = zoneRef.current.value - 0
    submitData.append('formData', new window.Blob([JSON.stringify(data)], { type: 'application/json' }))
    if (imageRef.current && imageRef.current.files.length > 0) {
      submitData.append('facePhotoFile', imageRef.current.files[0])
      data.facePhotoFile = imageRef.current.files[0].name
    }
    data.staffName && sendPost(submitData, data)
  }

  // DELETEはForm状態に影響されないため個別で定義
  const onClickHandleDelete = () => {
    async function sendDelete (staffId, data) {
      dispatch(SetLoadSpinner(true))
      const result = await DeleteApi(`${DefaultUrl}/${staffId}`, data, true)
      dispatch(SetLoadSpinner(false))
      ErrorHandling(result, alert) || setStaffDetail(result.data)
      if (result.data) alert.info('無効化しました')
    }
    if (staffDetail.staffId !== undefined) {
      // const lastUpdatedAt = moment(staffDetail.updatedAt, 'YYYY/MM/DD HH:mm:ss')
      const data = { lastUpdatedAt: staffDetail.updatedAt }
      sendDelete(staffDetail.staffId, data)
    }
  }

  const desisionWarehouseId = staffDetail.warehouseRelation && staffDetail.warehouseRelation.warehouseId
  const desisionStaffId = staffDetail.staffId
  const canWriteStaffBasic = grantDecision('write', FUNC.STAFF, loginUserInfo, desisionWarehouseId, desisionStaffId)
  const canWriteStaffSkill = grantDecision('write', FUNC.STAFF_SKILL, loginUserInfo, desisionWarehouseId, desisionStaffId)
  const canWriteStaffGrant = grantDecision('write', FUNC.STAFF_GRANT, loginUserInfo, desisionWarehouseId, null)

  // タブ
  const [selectTab, setSelectTab] = useState(1)
  const formList = [{ value: 1, name: '基本情報' }]
  if (grantDecision('read', FUNC.STAFF_SKILL, loginUserInfo, desisionWarehouseId, desisionStaffId)) {
    formList.push({ value: 2, name: 'スキル' })
  }

  // スタッフ権限一覧取得
  const [staffGrantOptions, setStaffGrantOptions] = useState(<></>)
  const getGrantOptions = async () => {
    const result = await GetApi('/api/master/grant/grantNames')
    if (!ErrorHandling(result, alert) && Array.isArray(result.data)) {
      const options = result.data.map(value =>
        <option value={value.grantNameId} key={value.grantNameId}>{value.grantName}</option>
      )
      setStaffGrantOptions(options)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className='h-adr'>
        <TabSelector
          selectValue={selectTab}
          setSelectValue={setSelectTab}
          tabsName='staffMaster'
          dataList={formList}
          margin='0 0 12px 0'
        />
        <StaffMasterForm
          isView={selectTab === 1}
          register={register}
          errors={errors}
          isDeleted={isDeleted}
          imageRef={imageRef}
          imageSrc={imageSrc}
          setImageSrc={setImageSrc}
          getValues={getValues}
          control={control}
          setValue={setValue}
          staffDetail={staffDetail}
          watch={watch}
          canWrite={canWriteStaffBasic}
          grants={grants}
          loginUserInfo={loginUserInfo}
          isPasswordChange={isPasswordChange}
          setIsPasswordChange={setIsPasswordChange}
          actionType={actionType}
          companyList={companyList}
          companyRef={companyRef} warehouseRef={warehouseRef} floorRef={floorRef} zoneRef={zoneRef}
          selects={selects} setSelects={setSelects}
          canReadStaffGrant={grantDecision('read', FUNC.STAFF_GRANT, loginUserInfo, desisionWarehouseId, desisionStaffId)}
          canWriteStaffGrant={canWriteStaffGrant}
          staffGrantOptions={staffGrantOptions}
        />
        <StaffSkillMasterForm
          isView={selectTab === 2}
          register={register}
          setValue={setValue}
          isDeleted={isDeleted}
          defaultSkills={staffDetail.skills}
          canWrite={canWriteStaffSkill}
        />
        <MasterDetailButtons>
          <Button
            display={canWriteStaffBasic ? 'block' : 'none'}
            name={actionType === 'PUT' ? (staffDetail.delFlag ? '有効化' : '更新') : '登録'}
            height='20px'
            width='90px'
            padding='0'
            margin='0 0 12px 0'
            type='submit'
          />
          {!staffDetail.delFlag && staffDetail.delFlag !== undefined &&
            <Button
              display='none' // 第一フェーズでは非表示
              height='20px'
              width='90px'
              padding='0'
              name='削除'
              type='button'
              margin='0 0 12px 10px'
              onClick={(e) => onClickHandleDelete(e)}
            />}
        </MasterDetailButtons>
        <CreaterInfo info={staffDetail} />
      </Form>
    </>
  )
}

StaffDetails.propTypes = {
  staffDetail: PropTypes.object,
  companyList: PropTypes.array,
  setSelectedId: PropTypes.func,
  onSearch: PropTypes.func,
  setStaffDetail: PropTypes.func,
  grants: PropTypes.object
}

export default StaffDetails
