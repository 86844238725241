// APIレスポンスエラーハンドリング
const ErrorHandling = (result, alert) => {
  if ('errorDetail' in result) {
    const errorResponse = result.errorDetail.response
    if (!errorResponse) {
      alert.error('error')
      return true
    }
    if (!errorResponse.status) {
      alert.error('error')
      return true
    }
    if (errorResponse.data && errorResponse.data.message) {
      alert.error(errorResponse.data.message)
      return true
    }
    switch (errorResponse.status) {
      case 209:
        alert.error('データ更新割り込みエラー')
        break
      case 400:
        alert.error('パラメーターエラー')
        break
      case 401:
        alert.error('アクセストークンエラー')
        break
      case 500:
        alert.error('サーバーエラー')
        break
      default:
        alert.error('その他エラー')
    }
    return true
  }
  return false
}

export default ErrorHandling
