import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { DateTime } from 'luxon'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import red from '@material-ui/core/colors/red'
import TabSelector from '../../Common/Atoms/TabSelector'
import { getLegalHours, getDaysCountInMonth } from '../MonthlyLabor/helper'
import { restfulApiConfig } from '../../Common/Config'
import {
  renderCell,
  stableSort,
  getComparator
} from './helper'
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  }
}))

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f3f3f3'
    },
    '&:hover': {
      backgroundColor: '#ffffe0 !important'
    }
  }
}))(TableRow)

const searchEmpList = [
  { value: 0, name: '全データ' },
  { value: 1, name: '社員' },
  { value: 2, name: '社員以外' }
]

const searchAlertList = [
  { value: 0, name: '全データ' },
  { value: 1, name: 'アラート有' }
]

const TableToolbar = (props) => {
  const classes = useToolbarStyles()
  // const { from, to } = props
  const { from, to, searchEmpCond, setSearchEmpCond, searchAlertCond, setSearchAlertCond } = props
  // const [searchEmpCond, setSearchEmpCond] = useState(0)

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        {from} ～ {to}
      </Typography>
      <div style={{ padding: '10px 8px 0 0', display: 'flex' }}>
        <TabSelector
          selectValue={searchEmpCond}
          setSelectValue={setSearchEmpCond}
          selectedBackColor='#303F9F'
          title=''
          tabsName='searchEmp'
          dataList={searchEmpList}
        />

        <TabSelector
          selectValue={searchAlertCond}
          setSelectValue={setSearchAlertCond}
          selectedBackColor={red[500]}
          title=''
          tabsName='searchAlert'
          dataList={searchAlertList}
        />
      </div>
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  searchEmpCond: PropTypes.number,
  setSearchEmpCond: PropTypes.func,
  searchAlertCond: PropTypes.number,
  setSearchAlertCond: PropTypes.func
}

// TODO 共通化
/**
 * Header
 * @param {*} props
 */
function AnnualLaborTableHead (props) {
  const {
    from,
    headCells,
    order,
    orderBy,
    onRequestSort,
    setTotalHours
  } = props
  const classes = useToolbarStyles()
  // TODO -> util
  const zeroPadding = (s, digit) => {
    return ('0'.repeat(digit) + s).slice(digit * -1)
  }
  function getToday () {
    const dt = new Date()
    const y = dt.getFullYear()
    const m = ('00' + (dt.getMonth() + 1)).slice(-2)
    const d = ('00' + dt.getDate()).slice(-2)
    return (y + '-' + m + '-' + d)
  }
  const calcMonth = (workDate) => {
    let workDateObj = new Date(workDate)
    let baseDate = workDate.substring(0, 7)
    if (workDateObj.getDate() >= restfulApiConfig.startDay) {
      workDateObj = new Date(workDate.substring(0, 7) + '-01')
      workDateObj.setMonth(workDateObj.getMonth() + 1)
      baseDate = workDateObj.getFullYear() + '-' + ('0' + (workDateObj.getMonth() + 1)).slice(-2)
    }
    return baseDate
  }

  const getAnnualLegalHours = (from) => {
    let total = 0
    const fromDate = DateTime.fromFormat(from, 'yyyy-MM')
    const thisMonth = calcMonth(getToday())
    for (let i = 0; i < 12; i++) {
      const targetDate = fromDate.plus({ months: i }).toFormat('yyyy-MM')
      if (thisMonth < targetDate) break
      const days = getDaysCountInMonth(targetDate)
      const legalHours = getLegalHours(days)
      total += legalHours
    }
    total = Math.round(total * (10 ^ 5)) / (10 ^ 5)
    setTotalHours(total)
    return total
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  const createHeadCell = (headCell) => {
    let hours
    const thisYear = Number(from.substring(0, 4))
    const totalHours = getAnnualLegalHours(from)
    return (
      <div>
        {headCell.label.split(' ').map((h, idx) => {
          const header = h.replace('月', '')
          if (parseInt(header)) {
            const thisMonth = calcMonth(getToday())
            const month = Number(header)
            const targetYear = (month >= 0 && month <= 3) ? thisYear + 1 : thisYear
            const targetDate = targetYear.toString() + '-' + zeroPadding(month, 2)
            const days = getDaysCountInMonth(targetDate)
            const legalHours = getLegalHours(days)
            hours = thisMonth < targetDate ? null : legalHours + 'h'
          } else if (header === '合計') {
            hours = totalHours + 'h'
          }
          return (
            <p key={idx}>{h} {hours}<br /></p>
          )
        })}
      </div>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.tableCell}
            style={{ fontWeight: 'bold', align: 'center' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {createHeadCell(headCell)}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? '' : ''}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  )
}

AnnualLaborTableHead.propTypes = {
  from: PropTypes.string,
  headCells: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  setTotalHours: PropTypes.func
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  tableContainer: {
    // maxHeight: '100vh'
  },
  table: {
    minWidth: 750
  },
  tableCell: {
    paddingRight: 5
  }
}))

/**
 * テーブル本体
 */
export default function AnnualLaborTable (props) {
  // const alert = useAlert()
  const { headCells, rows, from, to, setTotalHours, totalHours } = props
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('dataValue')
  const [searchEmpCond, setSearchEmpCond] = useState(0)
  const [searchAlertCond, setSearchAlertCond] = useState(0)
  const filterEmpRows = rows => {
    if (searchEmpCond === 0) return rows

    return searchEmpCond === 1
      ? rows.filter(r => r.staffId.length === 7 && r.staffId.substring(0, 1) === '9')
      : rows.filter(r => r.staffId.length !== 7 || r.staffId.substring(0, 1) !== '9')
  }

  const filterAlertRows = rows => {
    if (searchAlertCond === 0) return rows

    return rows.filter(r => {
      for (const property in r) {
        if (property.indexOf('Warning') >= 0) {
          if (r[property]) {
            return true
          }
        }
      }
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          title={props.title}
          from={from}
          to={to}
          searchEmpCond={searchEmpCond}
          setSearchEmpCond={setSearchEmpCond}
          searchAlertCond={searchAlertCond}
          setSearchAlertCond={setSearchAlertCond}
        />
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='table'
            stickyHeader
          >
            <AnnualLaborTableHead
              from={from}
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              setTotalHours={setTotalHours}
            />
            <TableBody>
              {/* ソート追加 */}
              {stableSort(filterAlertRows(filterEmpRows(rows)), getComparator(order, orderBy))
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      role='row'
                    >
                      {
                        row && Object.keys(row).map((key, _index) => {
                          const dataHeader = headCells.filter(h => h.id === key)
                          if (dataHeader.length > 0) {
                            return (
                              <React.Fragment key={_index}>
                                {renderCell(classes, _index, dataHeader[0], row, key, totalHours)}
                              </React.Fragment>
                            )
                          }
                        })
                      }

                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

AnnualLaborTable.propTypes = {
  title: PropTypes.string,
  headCells: PropTypes.array,
  rows: PropTypes.array.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  totalHours: PropTypes.number,
  setTotalHours: PropTypes.func
}
