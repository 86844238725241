import React from 'react'
// import SearchInfo from '../SearchInfo'
import Search from '../Common/Search'
import EditableFloors from '../EditableFloor'
import FUNC from '../Common/FunctionEnum'
import { useSelector } from 'react-redux'

const EditZone = () => {
  const loginUserInfo = useSelector(state => state.loginUserInfo)
  const grants = loginUserInfo.menuList && loginUserInfo.menuList.find(menu => menu.functionMenuId === FUNC.ZONE)
  if (!grants) return null
  return (
    <div>
      <Search
        title='ゾーンマスタ' showButton={false}
        showWarehouses showFloors
        grants={grants}
        relationStatus='2'
      />
      <EditableFloors />
    </div>
  )
}

export default EditZone
