import { GetApi } from '../ApiAxios'

const GetAnnualLabors = (startDate, endDate, commonSearch, grant, staffId) => {
  const { warehouseId, floorId, zoneId } = commonSearch
  const urlArgs = buildArgs(warehouseId, floorId, zoneId, grant, staffId)
  const uri = `/api/labor/monthly-labor-summary/${startDate}/${endDate}?includePlanned=1&includeManager=1&${urlArgs}`
  return GetApi(uri)
}

function buildArgs (warehouseId, floorId, zoneId, grant, staffId) {
  const warehouse = warehouseId !== -1 ? `&warehouseId=${warehouseId}` : ''
  const floor = (floorId && (floorId !== '-' && floorId !== -1)) ? `&floorId=${floorId}` : ''
  const zone = (zoneId && (zoneId !== '-' && zoneId !== -1)) ? `&zoneId=${zoneId}` : ''
  const staffFilter = grant === 3
    ? `&staffId=${staffId}`
    : grant === 1
      ? `&bossStaffId=${staffId}`
      : ''
  return `sort=staff_id&${warehouse}${floor}${zone}${staffFilter}`
}
export default GetAnnualLabors
