import React from 'react'

import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { cell2, StyledTableRow } from './helper'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  }
}))

const TableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { from, to } = props

  return (
    <Toolbar
      className={classes.root}
    >
      <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
        {from} ～ {to}
      </Typography>

    </Toolbar>
  )
}

TableToolbar.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string
}
/**
 * Header
 * @param {*} props
 */
function LaborApprovalTableHead (props) {
  const { headCells } = props
  const classes = useToolbarStyles()

  const createHeadCell = (headCell) => {
    return (
      <div>
        {headCell.label.split(' ').map((h, idx) => <p key={idx}>{h}<br /></p>)}
      </div>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes.tableCell}
            style={{ fontWeight: 'bold', align: 'center' }}
          >
            {
              createHeadCell(headCell)
            }
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  )
}

LaborApprovalTableHead.propTypes = {
  headCells: PropTypes.array
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableCell: {
    paddingRight: 5
  }
}))

/**
 * テーブル本体
 */
export default function OvertimeTable (props) {
  // const alert = useAlert()
  const { headCells, rows, from, to } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          // numSelected={selected.length}
          title={props.title}
          from={from}
          to={to}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='table'
          >
            <LaborApprovalTableHead
              classes={classes}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row) => {
                return (
                  <StyledTableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    role='row'
                  >
                    {
                      row && Object.keys(row).map((key, _index) => {
                        const dataHeader = headCells.filter(h => h.id === key)
                        // console.log('=== overtimeReason', overtimeReason)
                        console.log(dataHeader)
                        if (dataHeader.length > 0) {
                          return (
                            <React.Fragment key={_index}>
                              {cell2(classes, _index, dataHeader[0], row, key)}
                            </React.Fragment>
                          )
                        }
                      })
                    }

                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

OvertimeTable.propTypes = {
  title: PropTypes.string,
  headCells: PropTypes.array,
  rows: PropTypes.array.isRequired
}
