import styled from 'styled-components'

export const Form = styled.form`
  width: 40%;
  height: 100%;
`

export const Alert = styled.div`
    color: rgb(97, 26, 21);
    border: 1px solid #f44336;
    background-color: whitesmoke;
    display: flex;
    padding: 6px 16px;
    margin-bottom: 8px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
    font-weight: 400;
    place-content: center;
    line-height: 1;
`

export const SearchButton = styled.button`
background: linear-gradient(0, #607d8b 100%, #607d8b 100%);
border-radius: 3px;
margin-left: 8px;
width: 90px;
height: 36px;
border: 0;
color: white;
line-height: 1;
padding: 0;
box-shadow: 0 3px 5px 2px rgba(207, 216, 220, .3);
`

export const CreateButton = styled.button`
width: 120px;
height: 36px;
border-radius: 12px;
background-color: white;
color: black;
border: 1px solid white;
line-height: 1;
`

export const StyledNewButton = styled.button`
background: linear-gradient(0, #607d8b 100%, #607d8b 100%);
border-radius: 3px;
border: 0;
color: white;
height: 30px;
line-height: 1;
padding: 0 30px;
box-shadow: 0 3px 5px 2px rgba(207, 216, 220, .3);
`

export const SelectWrapper = styled.div`
  width: 80%;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 0px;
`
