/* eslint-env browser */
import React, { useState, useCallback, useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import styled from 'styled-components'
import CalenderIcon from '../../../image/svg/CalenderIcon.js'
import PropTypes from 'prop-types'
registerLocale('ja', ja)

// 共通検索用
const WrapDiv = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 16px'};

  .react-datepicker-popper {
    z-index: 10000;
    width: 200px;
  }
  .react-datepicker__month-container {
    float: left;
    width: 280px;
    ${props => props.isDateYMD ? '' : 'height: 175px;'}
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .react-datepicker__month-wrapper {
    flex: 1;
  }
  .react-datepicker__month .react-datepicker__month-text {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__header {
    ${props => props.isDateYMD ? '' : 'height: 30px;'}
  }

  .normalCalendar {
    height: 32px;
    display: ${props => props.display || 'block'};
    position: relative;
    text-indent: 12px;
    width: ${props => props.width || '160px'};
    min-width: ${props => props.width || '160px'};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.05em;
    background: #eee;
    border-radius: 5px;
    border: none;
    line-height: 12px;
    box-shadow: inset 0 3px 5px 0 rgba(0,0,0,0.35);
    color: #333;
    &:focus {
      background: #fff;
      outline: none;
      border: 2px solid #0036ff;
      box-sizing: border-box;
      text-indent: 14px;
    }
    ::placeholder {
      color: #ccc;
    }
  }
  svg {
    position: absolute;
    top: 6px;
    right: 12px;
    pointer-events: none;
    z-index:2;
  }

  ::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 32px;
    background: #0036ff;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    pointer-events: none;
    z-index:1;
  }

`

const MonthCalendar = ({ value, changeCalendar, isDateYMD = false, onChange }) => {
  const [fromDate, setFromDate] = useState(value?.fromDate || new Date())
  const [toDate, setToDate] = useState(value?.toDate || new Date())

  const onChangeFromDate = useCallback(date => {
    setFromDate(date)
    typeof onChange === 'function' && onChange(date, toDate)
  }, [toDate, onChange])

  const onChangeToDate = useCallback(date => {
    setToDate(date)
    typeof onChange === 'function' && onChange(fromDate, date)
  }, [fromDate, onChange])

  useEffect(() => {
    setFromDate(value?.fromDate || fromDate)
    setToDate(value?.toDate || toDate)
  // eslint-disable-next-line
  }, [value])

  return (
    <>
      <WrapDiv isDateYMD={isDateYMD}>
        <DatePicker
          className='normalCalendar'
          dateFormat={isDateYMD ? 'yyyy/MM/dd' : 'yyyy/MM'}
          locale='ja'
          selected={fromDate}
          onChange={onChangeFromDate}
          peekNextMonth
          showMonthYearPicker={!isDateYMD}
        />
        <CalenderIcon />
      </WrapDiv>
      <label>～</label>
      <WrapDiv isDateYMD={isDateYMD}>
        <DatePicker
          className='normalCalendar'
          dateFormat={isDateYMD ? 'yyyy/MM/dd' : 'yyyy/MM'}
          locale='ja'
          selected={toDate}
          onChange={onChangeToDate}
          peekNextMonth
          showMonthYearPicker={!isDateYMD}
        />
        <CalenderIcon />
      </WrapDiv>
    </>
  )
}

MonthCalendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  changeCalendar: PropTypes.func,
  isDateYMD: PropTypes.bool
}

export default MonthCalendar
