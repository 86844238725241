import { GetApi } from '../ApiAxios'
import { DateTime } from 'luxon'

const GetPaidHolidays = (warehouseId, floorId, workDate) => {
  console.log(workDate)
  const dt = workDate.length === 10 // yyyy-MM-dd
    ? DateTime.fromFormat(workDate, 'yyyy-MM-dd')
    : DateTime.fromFormat(workDate, 'yyyy-MM')

  const targetDt = dt.month >= 1 && dt.month <= 3
    ? dt.minus({ year: 1 })
    : dt

  const firstDate = `${targetDt.year}-04-01`
  const lastDate = `${targetDt.plus({ year: 1 }).year}-03-30`

  console.log(`
  
  firstDate: ${firstDate}
  lastDate: ${lastDate}
  
  `)

  const warehouseIdCondition = warehouseId && warehouseId !== -1
    ? `&warehouseId=${warehouseId}`
    : ''

  const floorIdCondition = floorId && floorId !== -1
    ? `&floorId=${floorId}`
    : ''

  const uri = `/api/labor/holidayLabors?&workDateFrom=${firstDate}&workDateTo=${lastDate}${warehouseIdCondition}${floorIdCondition}`
  console.log(uri)
  return GetApi(uri)
}

export default GetPaidHolidays
