import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  companyId: null,
  warehouseId: null,
  floorId: null,
  template: [],
  coreStaffs: []
}

const mappingReducer = createSlice({
  name: 'selectFloor',
  initialState,
  reducers: {
    changeWarehouse (state, action) {
      console.log('changeWarehouse called', state, action)
      state.warehouseId = action.payload
    },
    changeFloor (state, action) {
      console.log('changeFloor called', state, action)
      state.floorId = action.payload
    },
    setCompanySearchInfo (state, action) {
      const { companyId, warehouseId, floorId } = action.payload
      console.info('setCompanyInof', companyId, warehouseId, floorId)
      state.companyId = companyId
      state.warehouseId = warehouseId
      state.floorId = floorId
    },
    setTemplate (state, action) {
      const { template } = action.payload
      state.template = template
      // eslint-disable-next-line
      state.template.map(t => {
        t.name = t.templateName
      })
    },
    setCoreStaffs (state, action) {
      const { coreStaffs } = action.payload
      state.coreStaffs = coreStaffs
    }
  }
})

export const {
  changeWarehouse,
  changeFloor,
  setCompanySearchInfo,
  setTemplate,
  setCoreStaffs
} = mappingReducer.actions

export default mappingReducer.reducer
